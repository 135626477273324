import React from 'react';
import styled from 'styled-components';

const ShareDocLeft = styled.div`
    min-width: 172px;
    display: ${props => (props.isLeft ? 'flex' : 'none')};
    padding-top: ${props => (props.mobile || props.expand ? '0px' : '98px')};
`;

export default function DocumentLeft({ 
    mobile, expand, isLeft, name, children
}) {
    return (
        <ShareDocLeft className="left share_layout" isLeft={isLeft} mobile={mobile} expand={expand}>
            <div className="list_label noClick"> {name} </div>

            { children }
        </ShareDocLeft>
    );
}