import { ADD_SHARED_SURVEY, SET_LAST_REPLIED_UUID, SET_SURVEY_REPLIED } from "./actionType";

export function addSharedSurvey(uuid, data) {

    return {
        type: ADD_SHARED_SURVEY,
        uuid,
        data
    }
}

export function setSurveyReplied(uuid, replied) {
    return {
        type: SET_SURVEY_REPLIED,
        uuid, 
        replied
    }
}