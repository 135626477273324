import { isCheckPermission } from "../base/localuser";
import { getDisplayName } from "../base/settings";
import { ADD_LOCAL_MEMBER, ADD_MEMBER, ADD_PINNED, ADD_RECORD, DELETE_MEMBER, DELETE_PINNED, DELETE_RECORD, LEFT_MEMBER, SET_BRIDGE_ID, SET_GROUP_MEMBERS, SET_MEMBER_VOICE, UPDATE_MEMBER } from "./actionType";
import { PARTICIPANT_STATUS, PERMISSION } from "./constants";
import { getGroupMemberByUserId, getMemberByUserID, getUserIDByMemberId } from "./functions";

/**
 * 로컬 사용자 저장 
 * @param {object} member 
 * @returns 
 */
export function addLocalMember(member) {
    return (dispatch, getState) => {
        const nickname = getDisplayName(getState);

        dispatch({
            type: ADD_LOCAL_MEMBER,
            member: {
                ...member,
                nickname,
                local: true,
                handler: false,
                status: PARTICIPANT_STATUS.OCCUPIDE
            }
        });
    }
}

/**
 * 로컬 사용자 퇴장
 * @param {stirng} member_uuid 
 * @returns 
 */
export function deleteMember(member_uuid) {
    return (dispatch, getState) => {
        const user_uuid = getUserIDByMemberId(getState, member_uuid);
        const member = getMemberByUserID(getState, user_uuid);

        if (!member) return;
        dispatch({
            type: DELETE_MEMBER,
            member
        });
    }
}

/**
 * 사용자만 VACATED로 변경 
 * @param {} member 
 * @returns 
 */
export function leftMember(member) {
    return {
        type: LEFT_MEMBER,
        member
    };
}

/**
 * 한번에 모든 사용자 저장 
 * @param {Array<Object>} members 
 */
export function addMembers(members, member_uuid) {
    return (dispatch, getState) => {
        members.map(async m => {
            if (m.member_uuid === member_uuid) return
            if (m.status === PARTICIPANT_STATUS.BANNED) return;

            let nickname = m.nickname;
            if (!nickname || nickname !== "") {
                const group = getGroupMemberByUserId(getState, m.user_uuid);
                
                const response = await APP.API.getAllParticipant({ user_id: m.user_uuid, group_id: "" });
                if (response.complete && response.message && response.message.length > 0) {
                    nickname = response.message[0]?.nickname;
                } 
            }

            dispatch(addMember({ ...m, nickname, local: false, existed: true }));
        });
    }
}

/**
 * 사용자 손들기 
 * * @param {string} user_uuid 
 * @param {boolean} handler
 */
export function setHandlerMember(user_uuid, handler = null, isRemote = false) {
    return async (dispatch, getState) => {
        const member = getMemberByUserID(getState, user_uuid);
        const cHandler = handler === null ? !member.handler : handler;

        if (!isRemote) {
            const response = await APP.mateManagement.raiseHand(user_uuid, cHandler);
            if (response.status !== 200) {
                console.log("error");
                return;
            }
        }

        const { hands_up, hands_up_time } = getState()["features/base/settings"];
        if (hands_up === 'fadeout') {
            // 일시적인 손들기
            if (!isRemote && member.local && cHandler === true) {
                setTimeout(() => {
                    dispatch(setHandlerMember(user_uuid, false));
                }, hands_up_time);
            }
        }
        
        dispatch({
            type: UPDATE_MEMBER,
            member: {
                ...member,
                hands_up: cHandler
            }
        });
    }  
}

/**
 * 사용자 저장 
 * @param {objcet} member 
 * @returns 
 */
export function addMember(member) {
    if (member.type === 'hidden') return;
    return {
        type: ADD_MEMBER,
        member
    }
}

/**
 * 사용자 pin 설정 추가 
 * @param {string} user_uuid 
 * @param {Boolean} pinned 
 * @returns 
 */
export function setMemberPinned(user_uuid, pinned, isRemote) {
    return async (dispatch, getState) => {
        if (!isRemote) {
            if (isCheckPermission(getState, PERMISSION.PIN)) {
                const response = await APP.mateManagement.setMemberPinned(user_uuid, pinned);
                if (response.status !== 200) {
                    console.log("error");
                    return;
                }
            } else return ;
        }

        dispatch({
            type: pinned ? ADD_PINNED : DELETE_PINNED,
            user_uuid
        });

        const member = getMemberByUserID(getState, user_uuid);
        dispatch({
            type: UPDATE_MEMBER,
            member: {
                ...member,
                pinned
            }
        });
    }    
}

/**
 * 권한 변경 요청
 * @param {stirng} user_uuid 
 * @param {stirng} role 
 * @param {Boolean} isRemote 
 * @returns 
 */
export function setMemberRole(user_uuid, role, isRemote, isGroup) {
    return async (dispatch, getState) => {
        if (!isRemote) {
            if (isCheckPermission(getState, PERMISSION.MEMBER_ROLE)) {
                const response = await APP.mateManagement.setMemberRole(user_uuid, role);
                if (response.status !== 200) {
                    console.log("error");
                    return;
                }
            } else return 
        }

        const member = getMemberByUserID(getState, user_uuid);
        if (member && member.role !== role) {
            if (isGroup) {
                dispatch({
                    type: UPDATE_MEMBER,
                    member: {
                        ...member,
                        role_name : role
                    }
                });
            } else {
                dispatch({
                    type: UPDATE_MEMBER,
                    member: {
                        ...member,
                        role
                    }
                });
            }
           
        }
    }   
}

/**
 * 녹화 사용자 
 * @param {String} user_uuid 
 * @param {Boolean} record 
 * @param {Boolean} isRemote 
 * @returns 
 */
export function setMemberRecord(user_uuid, record, isRemote = false) {
    return async (dispatch, getState) => {
        if (!isRemote) {
            const response = await APP.mateManagement.setRecording(user_uuid, record);
            if (!response) return;
            if (response.status !== 200) {
                console.log("error");
                return;
            }
        }

        dispatch({
            type: record ? ADD_RECORD : DELETE_RECORD,
            user_uuid
        });

        const member = getMemberByUserID(getState, user_uuid);
        dispatch({
            type: UPDATE_MEMBER,
            member: {
                ...member,
                recording: record
            }
        });
    }
}

/**
 * 사용자 강제 퇴장
 */
export function kickMemeber(user_uuid: string, isRemote = false) {
    return async (dispatch, getState) => {
        if (!isRemote) {
            if (isCheckPermission(getState, PERMISSION.KICK)) {
                const response = await APP.mateManagement.kickMemeber(user_uuid);
                if (response.status !== 200) {
                    console.log("error");
                    return;
                }
            } else return 
        }

        const member = getMemberByUserID(getState, user_uuid);
        if (member) {
            dispatch({
                type: UPDATE_MEMBER,
                member: {
                    ...member,
                    status: PARTICIPANT_STATUS.VACATED
                }
            });
        }
    } 
}

/**
 * 사용자 정보 업데이트
 * @param {object} member 
 * @returns 
 */
export function updateMember(member) {
    return {
        type: UPDATE_MEMBER,
        member
    }
}

/**
 * 사용자 브릿지 아이디 
 * @param {string} user_uuid 
 * @param {string} bridge_session_id 
 * @returns 
 */
export function setBridgeId(user_uuid, bridge_session_id) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_BRIDGE_ID,
            user_uuid,
            bridge_session_id
        });

        const member = getMemberByUserID(getState, user_uuid);
        if (member && member.jitsi_id !== bridge_session_id) {
            dispatch({
                type: UPDATE_MEMBER,
                member: {
                    ...member,
                    jitsi_id: bridge_session_id
                }
            });
        }
    }
}

/**
 * 상태 값에 따른 업데이트 
 * @param {string} status 
 * @param {string} user_uuid 
 * @returns 
 */
export function setStatusList(status, user_uuid) {
    return {
        type: SET_STATUS_LIST,
        status, user_uuid
    }
}

/**
 * 보이스 사용자 업데이트 
 * @param {string} jitsi_id 
 * @returns 
 */
export function setMemberVoice(jitsi_id) {
    return async (dispatch, getState) => {
        const bridgeList = getState()["features/memeber"].bridgeList;
        const focusVoice = [...bridgeList.entries()].find(([user_uuid, bridge_id]) => bridge_id === jitsi_id);
        
        if (focusVoice) {
            dispatch({
                type: SET_MEMBER_VOICE,
                user_uuid: focusVoice[0]
            });
        } else {
            dispatch({
                type: SET_MEMBER_VOICE,
                user_uuid: null
            });
        }
    }
}

/**
 * 그룹 멤버 
 * @param {Object} members 
 * @returns 
 */
export function setGroupMembers(members) {
    return {
        type: SET_GROUP_MEMBERS,
        members
    }
}