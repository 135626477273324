const GRID_MODE = 'GRID_MODE';
const PIN_MODE = 'PINNED_MODE';
const VAD_MODE = 'VAD_MODE';
const SEMINAR_MODE = 'SEMINAR_MODE';

const SCREEN_SHARE_MODE = 'shared_screen';
const DOCUMENT_SHARE_MODE = 'document';
const WHITE_BOARD_MODE = 'whiteboard';
const COMMON_NOTE = 'common_note';
const VOD_MODE = 'vod';

export const videoLayoutMode = {
    default: GRID_MODE,
    grid: GRID_MODE,
    document: DOCUMENT_SHARE_MODE,
    pin: PIN_MODE,
    seminar: SEMINAR_MODE,
    screen: SCREEN_SHARE_MODE,
    white: WHITE_BOARD_MODE,
    voice: VAD_MODE,    
    note: COMMON_NOTE,
    vod: VOD_MODE,
};

export const SHARE_MODE_LIST = [
    WHITE_BOARD_MODE,
    DOCUMENT_SHARE_MODE,
    COMMON_NOTE,
    VOD_MODE
];

export const GROUP_VIDEO_LIST = [
    GRID_MODE,
    PIN_MODE,
    SEMINAR_MODE
];

export const VIDEO_MODE_LIST = [
    ...GROUP_VIDEO_LIST,
    VAD_MODE
];

export const VISIBLE_LIST = [
    ...SHARE_MODE_LIST,
    SCREEN_SHARE_MODE,    
];

export const INIT_VISIBLE = {
    [SCREEN_SHARE_MODE]: false,
    [VOD_MODE]: false,
    [WHITE_BOARD_MODE]: false,
    [DOCUMENT_SHARE_MODE]: false,
    [COMMON_NOTE]: false
};

export const layoutType = {
    desktop: 'desktop',
    tablet: 'tablet',
    mobile: 'mobile'
}

export const HORIZONTAL_MODE = [
    ...SHARE_MODE_LIST,
    PIN_MODE,
    VAD_MODE,
    SEMINAR_MODE
];

export const SHARE_PERMISSION_MODE = [
    ...SHARE_MODE_LIST,
    SCREEN_SHARE_MODE
]