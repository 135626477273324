import { ReducerRegistry, set } from '../base/redux';

import { SET_MAX_RECEIVER_VIDEO_QUALITY, SET_PREFERRED_VIDEO_QUALITY } from './actionTypes';
import { VIDEO_QUALITY_LEVELS } from './constants';

const DEFAULT_STATE = {
    maxReceiverVideoQuality: VIDEO_QUALITY_LEVELS.ULTRA,
    minHeightForQualityLvl: new Map(),
    preferredVideoQuality: VIDEO_QUALITY_LEVELS.ULTRA
};

DEFAULT_STATE.minHeightForQualityLvl.set(180, VIDEO_QUALITY_LEVELS.LOW);
DEFAULT_STATE.minHeightForQualityLvl.set(360, VIDEO_QUALITY_LEVELS.STANDARD);
DEFAULT_STATE.minHeightForQualityLvl.set(720, VIDEO_QUALITY_LEVELS.HIGH);

ReducerRegistry.register('features/video-quality-persistent-storage', (state = {}, action) => {
    switch (action.type) {
        case SET_PREFERRED_VIDEO_QUALITY: {
            const { preferredVideoQuality } = action;

            return {
                ...state,
                persistedPrefferedVideoQuality: preferredVideoQuality
            };
        }
    }

    return state;
});

ReducerRegistry.register('features/video-quality', (state = DEFAULT_STATE, action) => {
    
    switch (action.type) {
        case SET_MAX_RECEIVER_VIDEO_QUALITY:
            return set(
                state,
                'maxReceiverVideoQuality',
                action.maxReceiverVideoQuality);
                
        case SET_PREFERRED_VIDEO_QUALITY: {
            const { preferredVideoQuality } = action;

            return {
                ...state,
                preferredVideoQuality
            };
        }
    }

    return state;
});
