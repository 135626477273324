import { CLEAR_ROOM, CONFERENCE_LEFT } from "../base/conference";
import { ReducerRegistry } from "../base/redux";
import {
    ADD_MESSAGE,
    CLEAR_MESSAGES,
    CLOSE_CHAT,
    OPEN_CHAT,
    RESET_SEND_FILE_LIST,
    SET_PRIVATE_MESSAGE_RECIPIENT,
    SET_SEND_FILE_LIST,
    SET_UNREAD_COUNT,
    UPDATE_MESSAGE
} from "./actionTypes";
import { isTransferableFile } from "./functions";

const DEFAULT_STATE = {
    isOpen: false,
    lastReadMessage: undefined,
    messages: [],
    privateMessageRecipient: [],
    sendFileList: new Map(),
    unreadCount: 0
};

ReducerRegistry.register('features/chat', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_SEND_FILE_LIST :
            const { files } = action;

            let list = new Map();
            for (let i=0; i< files.length; i ++ ) {
                const file = files[i];
                
                if (file) {
                    const transfer = isTransferableFile(file);
                    
                    const prevTransfer = list.get(transfer);
                    if (prevTransfer) {
                        list.set(transfer, [...prevTransfer, file]);
                    } else {
                        list.set(transfer, [file]);
                    }
                    
                }
            }

            return {
                ...state,
                sendFileList: list
            }
        case RESET_SEND_FILE_LIST: 
            return {
                ...state,
                sendFileList: new Map()
            };

        case ADD_MESSAGE: {
            const message = JSON.parse(action.message);
            
            const newMessage = {
                isMe: action.isMe,
                uuid: message?.uuid,
                displayName: message?.displayName,
                message: message?.content,
                messageType: message?.type, 
                recipient: message?.recipient,
                timestamp: action.timestamp,
                error: action.error,
                ...message
            };
            
            const messages = [
                ...state.messages,
                newMessage
            ];
            
    
            return {
                ...state,
                lastReadMessage: action.hasRead ? newMessage : state.lastReadMessage,
                messages
            };
        }
        case UPDATE_MESSAGE: 
            const { uuid, content } = JSON.parse(action.messageDetails);

            return {
                ...state, 
                messages: state.messages.map(value => 
                    value.uuid === uuid
                    ? {...value, message: content}
                    : value
                )
            }
        
        case CLEAR_MESSAGES:
            return {
                ...state,
                lastReadMessage: undefined,
                messages: []
            };

        case SET_PRIVATE_MESSAGE_RECIPIENT:
            return {
                ...state,
                privateMessageRecipient: action.participant
            };

        case OPEN_CHAT:
            return {
                ...state,
                isOpen: true,
                privateMessageRecipient: action.participant
            };

        case CLOSE_CHAT:
            return {
                ...state,
                isOpen: false,
                lastReadMessage: state.messages.length - 1,
                privateMessageRecipient: action.participant
            };
        
        case SET_UNREAD_COUNT:
            return {
                ...state,
                unreadCount: action.count !== undefined ? action.count : state.unreadCount + 1
            }

        case CLEAR_ROOM:
            return {
                ...DEFAULT_STATE,
                isOpen: state.isOpen
            }

        case CONFERENCE_LEFT:
            return {
                ...DEFAULT_STATE
            }
        }

       
        
        return state;
});
