import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { AbstractDialog } from "../../../base/dialog";
import { getScreenType } from "../../../base/util";
import { SwitchChatButton } from "../../../chat";
import { SwitchDeviceSetButton } from "../../../device/components";
import { SwitchGroupButton } from "../../../groups";
import { SwitchParticipantRight } from "../../../participant-right";
import SwitchRecordButton from "../../../recording/components/SwitchRecordButton";
import { RECORDING_TYPE } from "../../../recording/constants";
import { SwitchRoomLockButton } from "../../../room-lock/components";
import { SwitchSurveyButton } from "../../../survey";
import { layoutType } from "../../../video-layout";
import { SwitchVirtualBackgroundButton } from "../../../virtual-background";
import { FullScreenButton } from "../fullscreen";
import { SwitchDualMonitorButton } from "../switch-layout";

const OptionStyled = styled.div`
    display: flex;
    flex-direction: column;

    dl {
        width: 98%;
        padding: 8px 0;
        &:not(dl:nth-last-child(1)) {
            border-bottom: 2px solid;
        }

        .icon {
            display: flex;
            padding: 8px 0;
            cursor: pointer;

            &:not(.icon:nth-last-of-type(1)) {
                border-bottom: 1px solid #505050;
                height: 22px; 
                display: flex;
                justify-content: center;
                align-items: center;
            }            
    
            svg { margin: 0; width: 35%; }
            span { margin-left: 0; }

            &:hover {
                color: rgb(72,162,241);
            }
        }
    }    
`;

export default function OptionMenuLayout() {
    const [type, setType] = useState();
    const permissions = useSelector(state => state['features/base/localuser'].permissions);

    useEffect(() => {
        const screenType = getScreenType();
        setType(screenType);

        setTimeout(() => {
            window.addEventListener('click', handleOutsideClick);
        });

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        }
    }, []);

    const handleOutsideClick = e => {
        if (e && (e.target.closest('#dialog') || e.target.parentNode && e.target.parentNode.closest('#dialog'))) return;

        APP.UI.closeModal();
    };

    return (
        <AbstractDialog name={"option"} width={280}>
            <OptionStyled>
                { type === layoutType.mobile && <dl>
                    <SwitchParticipantRight isOption={true} type={type} /> 
                    <SwitchChatButton isOption={true} type={type} /> 
                </dl> }
                <dl>
                    {/* <SwitchGridOption /> */}
                    <SwitchSurveyButton />
                    <SwitchVirtualBackgroundButton isOption={true} /> 
                    <FullScreenButton isOption={true} />
                    <SwitchDualMonitorButton permissions={permissions} />
                    <SwitchRecordButton type={RECORDING_TYPE.PERSONAL} />
                    <SwitchRecordButton type={RECORDING_TYPE.SERVER} />
                    <SwitchGroupButton isOption={true} />
                </dl>
                <dl>
                    <SwitchRoomLockButton />
                    <SwitchDeviceSetButton />
                </dl>
            </OptionStyled>            
        </AbstractDialog>
    )
}
