import React, { useEffect, useRef, useState } from "react";
import { connect } from "../../redux";
import { getFitSize, getItemByUUIDAndIndex } from "../functions";
import { getDocumentItemByUUID } from "../../../share-document";
import * as pdfjsLib from "pdfjs-dist";
import { shallowEqual } from "react-redux";
pdfjsLib.GlobalWorkerOptions.workerSrc = `/libs/pdf.worker.min.js`;

function PDFViewer({
    uuid, index, scale, isWidthFit,
    page, isWhiteBoard, fullCanvas, setDrawCanvas, canvasPos
}) {    
    const pdfElement = useRef();
    const [ pdf, setPdf ] = useState({ document: null, page: null});

    useEffect(() => {
        return () => {
            APP.UI.showLoading(false);
        }
    }, []);

    useEffect(() => {
        if (!isWhiteBoard) {
            const loadDocument = async () => {
                const drawList = APP.canvasList.get(page);
                if (drawList && drawList.pdf) {
                    setPdf({ document: drawList.pdf, page });
                } else {
                    const loadingTask = await pdfjsLib.getDocument({ 
                        url: `/docconv/get/${page}`, 
                        cMapUrl: '/cmaps/', 
                        cMapPacked: true 
                    });
            
                    const newPdf = await loadingTask.promise;
                    setPdf({ document: newPdf, page });
                    
                    APP.canvasList.set(page, { pdf: newPdf });
                }        
            }
    
            APP.UI.showLoading(true);
            
            if (pdf.page === page) drawImage();
            else {
                if (page) loadDocument();
                else APP.UI.showLoading(false);
            }
        }        
    }, [fullCanvas, isWhiteBoard, page, pdf, isWidthFit, index, canvasPos, scale], shallowEqual);

    

    const drawImage = () => {
        const drawList = APP.canvasList.get(page);
        if (drawList && drawList[index]) {
            const item = drawList[index];
            drawPdfCanvas(item.style, item.canvas);
        } else {
            pdf.document && pdf.document.getPage(index + 1)
                .then(image => {
                    const imageStyle = { width: image.view[2], height: image.view[3] };

                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d', { alpaha: false });

                    canvas.width = imageStyle.width * 3;
                    canvas.height = imageStyle.height * 3;

                    const viewport = image.getViewport({ scale: 3 });
                    const renderTask = image.render({ canvasContext: context, viewport });
                    renderTask.promise.then(() => {
                        drawPdfCanvas(imageStyle, canvas);

                        APP.canvasList.set(page, { ...drawList, [index]: {style: imageStyle, canvas} });
                    });
                });
        }
        
    }

    /**
     * 캔버스 파일에 그리기
     * @param {object} imageStyle 
     * @param {*} canvas 
     * @returns 
     */
    const drawPdfCanvas = (imageStyle, canvas) => {
        if (pdfElement.current) {
            const context = pdfElement.current.getContext('2d');
            const dpr = window.devicePixelRatio;
            const drawStyle = getFitSize(fullCanvas, imageStyle, isWidthFit);

            setDrawCanvas(drawStyle);
            pdfElement.current.width = drawStyle.width * scale;
            pdfElement.current.height = drawStyle.height * scale;
            context.fillStyle = "#fff";
            context.fillRect( canvasPos.x, canvasPos.y, drawStyle.width * scale, drawStyle.height * scale );
            canvas && context.drawImage(canvas, canvasPos.x, canvasPos.y, drawStyle.width * scale, drawStyle.height * scale );
            context.scale(dpr, dpr);
            context.setTransform(1, 0, 0, 1, 0, 0);
        }       

        APP.UI.showLoading(false);
    }

    return (
        <canvas id="pdf" className="pdf" ref={pdfElement} />
    )
}

function _mapStateToProps(state, ownProps) {
    const item = getDocumentItemByUUID(state, ownProps.uuid);

    
    return {
        page: item?.page,
        isWhiteBoard: item?.isWhiteBoard
    }
}

export default connect(_mapStateToProps)(PDFViewer);