import { css } from "styled-components";

const sizes = {
    desktop: 2048,
    tabletV: 1024,
    tabletH: 720,
    mobile: 450
}

export default Object.keys(sizes).reduce((acc, label, index) => {
    acc[label] = (...args) => css`    
        @media (max-width: ${sizes[label]}px) {
            ${css(...args)};
        }`
    return acc;    
}, {});