import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { setRoomPassword } from "..";
import { translate } from "../../base/i18n";
import { CHECKING_ROOM_PASSWORD_MODAL_ID } from "../../options";
import AbstractPopup from "../../options/components/AbstractPopup";

const PasswordStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    input {
        text-align: center;
        width: 90%;
        height: 32px;
        border-radius: 10px;
        padding: 0 15px;
        box-sizing: border-box;
        font-size: 12px;
    }
`;

function SetPassword({ t }) {
    const dispatch = useDispatch();
    const inputRef = useRef();
    const onSubmit = () => {
        const password = inputRef.current.value;

        if (!password || password.trim() === '') {
            alert(t("account.placeholderInputPassword"));
            return;
        }

        dispatch(setRoomPassword(password)); 
        APP.UI.showLoading(true);
        APP.mateManagement.connection();       
    };
    return (
        <PasswordStyled>
            <span className="guid">{ t('conference.passwordLabel')} </span>

            <input type="text" 
                placeholder={ t("roomLock.inputPassword") } 
                ref={inputRef}
            />
            
            <dl>
                <p className="btn btn_large btn_on" onClick={onSubmit}
                    style={{ padding: '4px 28px', fontSize: '20px' }}
                >{ t("device.enter") }</p>
            </dl>
        </PasswordStyled>
    )
}

class CheckPassword extends AbstractPopup {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this._setModalID(CHECKING_ROOM_PASSWORD_MODAL_ID);
    }

    _renderContent() {
        return (
            <>
                <SetPassword 
                    t={this.props.t}
                    dispatch={this.props.dispatch}
                />
            </>
        )
    }

    render() {
        const content = this._renderContent();

        return (
            <div className="option_pop center" style={{width: "400px"}}>
                { this._renderPopupHeader(this.props.t, 'options.security') }
                { this._renderPopupBody(content) }
            </div>
        )
    }
}

export default translate(CheckPassword);
