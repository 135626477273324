/**
 * 현재 비디오 모드 변경 
 * @param {string} mode 
 * @param {Boolean} isRemote
 * @param {Object} visibleList
 * @returns 
 */
export const SET_VIDEO_LAYOUT = 'SET_VIDEO_LAYOUT';

/**
 * 화면에 보이는 기본 값 설정
 * @param {number} gridCount 
 * @param {boolean} isRemote 
 * @returns 
 */
export const SET_GRID_COUNT = 'SET_GRID_COUNT';

/**
 * 레이아웃 스타일 값 변경
 * @param {Number} width 
 * @param {Number} height 
 * @returns 
 */
export const SET_LAYOUT_STYLE = 'SET_LAYOUT_STYLE';

/**
 * 화면에 보일 사용자 수 
 * @param {Number} count 
 * @returns 
 */
export const SET_SHOW_COUNT = 'SET_SHOW_COUNT';

/**
 * 화면에 보일 옵션 수정 
 * @param {object} option 
 * @returns 
 */
export const SET_SHOW_OPTION = 'SET_SHOW_OPTION';

/**
 * 화면에 보이는 사용자 리스트 
 * @param {Array} showMembers 
 * @returns 
 */
export const SET_SHOW_MEMBERS = 'SET_SHOW_MEMBERS';

/**
 * 화면페이지 
 * @param {Number} page 
 * @returns 
 */
export const SET_PAGE = 'SET_PAGE';

/**
 * 화면 페이징
 * @param {Number} maxPage 
 * @returns 
 */
export const SET_MAX_PAGE = 'SET_MAX_PAGE';

/**
 * 화면 듀얼 모니터
 * @param {Boolean} isDual 
 * @returns 
 */
export const SET_DUAL_MONITOR = 'SET_DUAL_MONITOR';

/**
 * 모바일
 * @param {Boolean} isDual 
 * @returns 
 */
export const SET_IS_MOBILE = 'SET_IS_MOBILE';

/**
 * 
 * @param {Boolean} expand 
 * @returns 
 */
export const SET_EXPAND = 'SET_EXPAND';