import React, { useState } from "react";
import { useSelector } from "react-redux";
import { G_FOCUS_PP_LIST, G_GUIDE, focusGroup } from "../constants";
import { getGroupById } from "../functions";
import GroupTable from "./GroupTable";
import GroupSearchBar from "./GroupSearchBar";
import { FocusButtonStyled } from "./styles";
import { IconButton } from "../../base/buttons";
import { getHostMembers, getMemberList } from "../../member";

export default function FocusGroup({
    t, focusItem, isMobile, setShow
}) {
    const group = useSelector(state => getGroupById(state, focusItem));
    const members = useSelector(state => getMemberList(state, { 
        excludeIds: [...getHostMembers(state)], groupIds: [focusItem], excludeGroup: false
    }, false, true, true));
    const [ filter, setFilter ] = useState();

    return (
        <div className="section section_content">
            { group && <GroupTable tableConfig={focusGroup} contentList={members} filter={filter}> 
                    <div style={{ display: "flex" }}>
                        <h2><p className="overText">{ group.name }</p></h2>
                        <h2 className="count">({ t("common.person", { count: members.length }) })</h2>
                    </div>
                    <GroupSearchBar setFilter={setFilter} />
                </GroupTable> }

            <FocusButtonStyled>
                { isMobile && <IconButton clsName="user_btn" mode={"previous"} name={t("common.previous")} onClick={() => setShow(G_GUIDE)} /> }
                <IconButton clsName="user_btn" mode="setUser" name={ t("group.addGroupMember") } onClick={() => setShow(G_FOCUS_PP_LIST)} />
            </FocusButtonStyled> 
        </div>
    )
}