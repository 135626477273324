import { assign, ReducerRegistry } from '../base/redux';

import {
    SET_DEVICE_STATUS,
    SET_PREJOIN_DEVICE_ERRORS,
} from './actionTypes';

const DEFAULT_STATE = {
    videoInput: {
        deviceStatusText: {titleKey: 'device.cameraOk', message: ""},
        deviceStatusType: 'ok',
    }, audioInput: {
        deviceStatusText: {titleKey: 'device.microphoneOk', message: ""},
        deviceStatusType: 'ok',
    },
};

ReducerRegistry.register('features/device', (state = DEFAULT_STATE, action) => {
    
    switch (action.type) {
        case SET_PREJOIN_DEVICE_ERRORS: {
            const status = getStatusFromErrors(action.value);
            
            return {
                ...state,
                ...status
            };
        }

        case SET_DEVICE_STATUS: {
            const { kind, deviceStatusText, deviceStatusType } = action.value;    
                 
            return {
                ...state,
                [kind] : {
                    deviceStatusText,
                    deviceStatusType
                }
            };
        }

        default:
            return state;
        }
    },
);

function getStatusFromErrors(errors) {
    const { audioOnlyError, videoOnlyError, audioAndVideoError } = errors;

    if (audioAndVideoError) {
        if (audioOnlyError) {
            if (videoOnlyError) {
                return {
                    deviceStatusType: 'warning',
                    deviceStatusText: 'device.audioAndVideoError',
                    rawError: audioAndVideoError.message
                };
            }

            return {
                deviceStatusType: 'warning',
                deviceStatusText: 'device.audioOnlyError',
                rawError: audioOnlyError.message
            };
        }

        return {
            deviceStatusType: 'warning',
            deviceStatusText: 'device.videoOnlyError',
            rawError: audioAndVideoError.message
        };
    }

    return {
        deviceStatusType: 'ok',
        deviceStatusText: 'device.lookGood',
        rawError: ''
    };
}