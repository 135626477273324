import { isCheckPermission } from "../base/localuser";
import { PERMISSION } from "../member";
import { SET_DUAL_MONITOR, SET_EXPAND, SET_GRID_COUNT, SET_IS_MOBILE, SET_LAYOUT_STYLE, SET_MAX_PAGE, SET_PAGE, SET_SHOW_COUNT, SET_SHOW_MEMBERS, SET_SHOW_OPTION, SET_VIDEO_LAYOUT } from "./actionType";
import { INIT_VISIBLE, SHARE_MODE_LIST, SHARE_PERMISSION_MODE, VIDEO_MODE_LIST, VISIBLE_LIST, videoLayoutMode } from "./constant";
import { getExpand } from "./functions";

/**
 * 현재 비디오 모드 변경 
 * @param {string} mode 
 * @param {Boolean} isRemote
 * @param {Object} visibleList
 * @returns 
 */
let layoutMode;
export function setVideoLayoutMode(mode: String, isRemote: Boolean = false, isCompulsion: Boolean = false) {
    return async (dispatch, getState) => {
        const visibleList = {
            [videoLayoutMode.screen]: false,
            [videoLayoutMode.vod]: false,
            [videoLayoutMode.white]: false,
            [videoLayoutMode.document]: false,
            [videoLayoutMode.note]: false
        };
        if (VISIBLE_LIST.includes(mode)) {
            visibleList[mode] = true;
        }

        if (!mode) mode = layoutMode;
        if (!isRemote) {
            const permission = SHARE_PERMISSION_MODE.includes(mode) ?
                isCheckPermission(getState, PERMISSION.SHARE) :
                isCheckPermission(getState, PERMISSION.LAYOUT);
            if (permission || isCompulsion) {
                const response = await APP.mateManagement.setLayoutMode(mode, visibleList);
                if (response.status !== 200) {
                    console.log("error");
                    return;
                }
            } else {
                return;
            }
        }

        if (VIDEO_MODE_LIST.includes(mode)) layoutMode = mode;
        dispatch({
            type: SET_VIDEO_LAYOUT,
            mode,
            visibleList,
            isRemote
        });
    }
}

/**
 * 화면에 보이는 기본 값 설정
 * @param {number} gridCount 
 * @param {boolean} isRemote 
 * @returns 
 */
export function setGridCount(gridCount: Number, isRemote: Boolean = false) {
    return async (dispatch, getState) => {
        if (!isRemote) {
            if (isCheckPermission(getState, PERMISSION.LAYOUT)) {
                const response = await APP.mateManagement.requestSetGridCount(gridCount);
                if (response.status !== 200) {
                    console.log("error");
                    return;
                }
            } else return;
        }

        dispatch({
            type: SET_GRID_COUNT,
            gridCount
        });
    }
}

/**
 * 레이아웃 스타일 값 변경
 * @param {Number} width 
 * @param {Number} height 
 * @returns 
 */
export function setLayoutContentStyle(width: Number, height: Number) {
    return {
        type: SET_LAYOUT_STYLE, 
        width, height
    }
}

/**
 * 화면에 보일 사용자 수 
 * @param {Number} count 
 * @returns 
 */
export function setShowCount(count: Number) {
    return {
        type: SET_SHOW_COUNT,
        count
    }
}

/**
 * 화면에 보일 옵션 수정 
 * @param {object} option 
 * @returns 
 */
export function setShowOption(count: Number, option: object) {
    return {
        type: SET_SHOW_OPTION,
        count,
        option
    }
}

/**
 * 화면에 보이는 사용자 리스트 
 * @param {Array} showMembers 
 * @returns 
 */
export function setShowMembers(showMembers: Array) {
    return {
        type: SET_SHOW_MEMBERS,
        showMembers
    }
}

/**
 * 화면페이지 
 * @param {Number} page 
 * @returns 
 */
export function setPage(page: Number) {
    return {
        type: SET_PAGE,
        page
    }
}

/**
 * 화면 페이징
 * @param {Number} page 
 * @returns 
 */
export function setMaxPage(maxPage: Number) {
    return {
        type: SET_MAX_PAGE,
        maxPage
    }
}

/**
 * 화면 듀얼 모니터
 * @param {Boolean} isDual 
 * @returns 
 */
export function setDualMonitor(isDual: Boolean) {
    return {
        type: SET_DUAL_MONITOR,
        isDual
    }
}

/**
 * 모바일
 * @param {Boolean} isDual 
 * @returns 
 */
export function setIsMobile(isMobile: Boolean) {
    return {
        type: SET_IS_MOBILE,
        isMobile
    }
}

/**
 * 
 * @param {Boolean} expand 
 * @returns 
 */
export function setExpand(expand: Boolean) {
    return {
        type: SET_EXPAND,
        expand
    }
}
