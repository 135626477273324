import styled from "styled-components";

export const PasswordStyled = styled.div`
    padding: 10px 0 20px;
    span { text-align: center; }
    .guid { text-align: center; word-break: keep-all; p {margin: 4px 0;}}
    .password {
        cursor: pointer;
        width: 180px; height: 21px; background-color: #4d4d4d;
        position: relative; border-radius: 10px;
        margin: 10px auto;

        &_true { background: #1facff; color: #fff; }
    }

    .security {
        position: absolute;
        background-position: -496px -304px;
        width: 23px; height: 21px;
        
        &_false { left: 0; }
        &_true { right: 0; }
    }

    .notSecurity {
        width: 100%;
        height: 100%;
        position: absolute;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .input_pwd { 
        text-align: center; 
        input {
            text-align: center;
            width: 180px;
            height: 32px;
            border-radius: 10px;
            padding: 0 15px;
            box-sizing: border-box;
            font-size: 12px;
        }
    }

    .locked_btn {
        display: flex;

        span {
            margin: 16px auto;
        }
    }
`;