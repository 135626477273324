
export class TaskQueue {
    constructor() {
        this._queue = [];
        this._currentTask = null;

        this._onTaskComplete = this._onTaskComplete.bind(this);
    }

    clear() {
        this._queue = [];
        this._currentTask = null;
    }

    /**
     * 대기열에 새 기능을 추가합니다. 없으면 즉시 호출됩니다.
     */
    enqueue(taskFunction) {
        this._queue.push(taskFunction);
        this._executeNext();
    }

    /**
     * 대기열에 있는 작업이 현재 실행되고 있지 않으면 첫 번째 작업을 호출합니다.
     */
    _executeNext() {
        if (this._currentTask) {
            // console.log('Task queued while a task is in progress.');

            return;
        }

        this._currentTask = this._queue.shift() || null;

        if (this._currentTask) {
            try {
                this._currentTask(this._onTaskComplete);
            } catch (error) {
                console.error(`Task execution failed: ${error}`);
                this._onTaskComplete();
            }
        }
    }

    /**
     * 대기열의 다음 함수를 호출할 준비를 합니다.
     */
    _onTaskComplete() {
        this._currentTask = null;
        this._executeNext();
    }
}
