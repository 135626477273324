import React from "react";
import { getDocumentItemByUUID, getFocusItemByMode, getFocusItemDocumentList } from "../../../share-document";
import { translate } from "../../i18n";
import { connect } from "../../redux";
import CanvasDraw from "./CanvasDraw";
import { getPdfItemByUUIDAndIndex } from "../functions";
import PDFViewer from "./PDFViewer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function CanvasDrawLayout({
    uuid, index, mode, isVod,
    local, scale, canvasPos, drawCanvas, fullCanvas, setDrawCanvas, setCanvasPos,
    canvasOption, tool, focusTool, setFocusTool, isWhiteBoard,
    handlerUpdateCanvasTool,
    children
}) {
    const { t } = useTranslation();
    const focusItem = useSelector(state => getDocumentItemByUUID(state, uuid));

    if (!focusItem) {
        if (!uuid) {
            return (
                <div className="noDocument">
                    <span className="icoImg documet_img" > </span>
                    <span>{t("document.resAddDocument", { mode: t(`options.${mode}`) })}</span>
                </div>
            )
        } else {
            return (
                <div className="noDocument">
                    <span className="icoImg documet_img" > </span>
                    <span>{t("document.no_document", { mode: t(`options.${mode}`) })}</span>
                </div>
            )
        }        
    } else {
        return (
            <div style={{ width: drawCanvas.width * scale, height: drawCanvas.height * scale, margin: isVod ? 0 :"auto" }}>  
                { children }
                { !isWhiteBoard && <PDFViewer 
                    drawCanvas={drawCanvas}
                    fullCanvas={fullCanvas}
                    canvasPos={canvasPos}
                    scale={scale}
                    setDrawCanvas={setDrawCanvas}
                    isWidthFit={canvasOption?.isWidthFit}
                    uuid={uuid} index={index}
                /> }
                <CanvasDraw
                    mode={mode}
                    isWhiteBoard={isWhiteBoard}
                    local={local}
                    tool={tool} scale={scale}
                    drawCanvas={drawCanvas}
                    fullCanvas={fullCanvas}
                    uuid={uuid} index={index}
                    canvasOption={canvasOption}
                    canvasPos={canvasPos} setCanvasPos={setCanvasPos}
                    focusTool={focusTool} setFocusTool={setFocusTool}
                    handlerUpdateCanvasTool={handlerUpdateCanvasTool}
                />
            </div>
       )
    } 
}

function _mapStateToProps(state, ownProps) {
    const { uuid, index } = getFocusItemByMode(state, ownProps.mode);

    return {
        uuid, index
    }
}

export default translate(connect(_mapStateToProps)(CanvasDrawLayout));