import { debounce } from "lodash";
import { getCurrentConference, getGroupId, getMainRoomRole } from "../base/conference";
import { ReducerRegistry, StateListenerRegistry } from "../base/redux";
import { ROLE } from "../member";
import { REMOVE_GROUPS, SET_END_GROUP, SET_GROUP, SET_GROUP_DUAL, SET_GROUP_STATUS, SET_START_GROUP } from "./actionTypes";
import { startLocalGroup } from "./functions";
import { getGroupStatus } from "./functions";

const DEFAULT_STATE = {
    groups: new Map(),
    groupIds: new Set(),
    start_groups: new Set(),
    end_groups: new Set(),
    isDualMonitor: false,
    status: false
};

ReducerRegistry.register("features/groups", (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_GROUP:
            state.groups.set(action.group_id, action.group);
            state.groupIds.add(action.group_id);
            return { ...state };

        case SET_END_GROUP:
            state.start_groups.delete(action.group_id);
            state.end_groups.add(action.group_id);            
            return { ...state };

        case SET_START_GROUP:
            state.end_groups.delete(action.group_id);
            state.start_groups.add(action.group_id);
            return { ...state };

        case SET_GROUP_DUAL:
            return {
                ...state,
                isDualMonitor: action.isDual
            }

        case REMOVE_GROUPS:
            action.groups.map(uuid => {
                state.groups.delete(uuid);
                state.start_groups.delete(uuid);
                state.end_groups.delete(uuid);
                state.groupIds.delete(uuid);
            });

            return {
                ...state
            }

        case SET_GROUP_STATUS:
            return {
                ...state, 
                status: action.status
            }
    }

    return state;
});

StateListenerRegistry.register(state => {
    return {
        startGroup: startLocalGroup(state),
        status: getGroupStatus(state)
    };
}, ({ startGroup, status }, store, prev) => {
        const conferenceJoined = getCurrentConference(store.getState());
        if (!conferenceJoined) return;

        const { start, group_id: assginGroupId, role} = startGroup;
        // 그룹이 종료 된 경우 
        const currentGroupId = getGroupId(store.getState);        
        if (start) {
            if (assginGroupId !== currentGroupId) {
                APP.mateManagement.changeMeetingRoom(currentGroupId, assginGroupId);
            }
        } else {
            if (currentGroupId !== '') APP.mateManagement.changeMeetingRoom(currentGroupId, "");
        }         
    }, {
        deepEquals: true
    });