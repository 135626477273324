// @flow

import { Component } from 'react';

import { NOTIFICATION_TYPE } from '../constants';

export default class AbstractNotification extends Component {
    static defaultProps = {
        appearance: NOTIFICATION_TYPE.NORMAL,
        isDismissAllowed: true
    };

    constructor(props) {
        super(props);
        
        this._onDismissed = this._onDismissed.bind(this);
    }

    _getDescription() {
        const {
            description,
            descriptionArguments,
            descriptionKey
        } = this.props.data;

        const descriptionArray = [];

        descriptionKey
            && descriptionArray.push(this.props.t(descriptionKey, descriptionArguments));

        description && descriptionArray.push(description);
        return descriptionArray;
    }

    _getDescriptionKey() {
        return this.props.descriptionKey;
    }
    
    _onDismissed() {
        this.props.onDismissed(this.props.uid);
    }
}
