import { throttle } from "lodash";
import { NOTIFICATION_TIMEOUT, showNotification } from "../notifications";
import {
    ADD_MESSAGE,
    CLEAR_MESSAGES,
    CLOSE_CHAT,
    OPEN_CHAT,
    SEND_MESSAGE,
    SET_PRIVATE_MESSAGE_RECIPIENT,
    ENDPOINT_MESSAGE_RECEIVED,
    SET_SEND_FILE_LIST,
    RESET_SEND_FILE_LIST,
    UPDATE_MESSAGE,
    SET_UNREAD_COUNT,
} from "./actionTypes";

export function toggleChat() {
    return (dispatch, getState) => {
        const isOpen = getState()["features/chat"].isOpen;
        if (isOpen) {
            dispatch(closeChat());
        } else {
            dispatch(openChat());
        }
    };
}

export function openChat() {
    return {
        type: OPEN_CHAT
    }
}

export function closeChat() {
    return {
        type: CLOSE_CHAT,
    };
}

export function endpointMessageReceived(participant, json) {
    return {
        type: ENDPOINT_MESSAGE_RECEIVED,
        participant,
        json,
    };
}

export function addMessage(messageDetails) {
    return {
        type: ADD_MESSAGE,
        ...messageDetails,
    };
}

export function updateMessage(messageDetails) {
    return {
        type: UPDATE_MESSAGE,
        messageDetails
    };
}

export function clearMessages() {
    return {
        type: CLEAR_MESSAGES,
    };
}

export function sendMessage(message, ignorePrivacy = false) {
    return {
        type: SEND_MESSAGE,
        ignorePrivacy,
        message,
    };
}
export function setPrivateMessageRecipient(participant) {
    return {
        participant,
        type: SET_PRIVATE_MESSAGE_RECIPIENT,
    };
}

export function setUnreadCount(count) {
    return {
        type: SET_UNREAD_COUNT,
        count
    }
}

/**
 * 파일 전송 
 */
export function setSendFileList(files, isType) {
    return {
        type: SET_SEND_FILE_LIST,
        files, 
        isType
    }
}

export function resetSendFileList() {
    return {
        type: RESET_SEND_FILE_LIST
    }
}

let unReadChat = [];

/**
 * 참가자 합류를 알리는 알림
 */
const _throttledNotifyUnReadMessage = throttle((dispatch) => {

    const unReadChatCount = unReadChat.length;

    let notificationProps;

    if (unReadChatCount === 1) {
        notificationProps = {
            descriptionKey: 'notify.unReadOneChatCount',
            descriptionArguments: {
                message: unReadChat[0].substr(0, 20)
            },
            titleArguments: {
                count: unReadChatCount
            },
            titleKey: 'notify.unReadChatCount',
        };
    } else {
        notificationProps = {
            titleArguments: {
                count: unReadChatCount
            },
            titleKey: 'notify.unReadChatCount'
        };
    }

    if (notificationProps) {
        dispatch(showNotification(notificationProps, NOTIFICATION_TIMEOUT));
    }   

    unReadChat = [];
}, 1000, { leading: false });

/**
 * 참가자의 알림 표시를 대기열에 넣습니다.
 */
export function showChatNotification(chat) {
    if (chat.type === "file") unReadChat.push(chat.content.name);
    else unReadChat.push(chat.content);

    return (dispatch) => _throttledNotifyUnReadMessage(dispatch);
}