import { SETTINGS_UPDATED, SET_DUAL_OPTION } from './actionTypes';

export function updateSettings(settings) {
    return {
        type: SETTINGS_UPDATED,
        settings
    };
}

export function setDualOption(option) {
    return {
        type: SET_DUAL_OPTION,
        option
    }
}