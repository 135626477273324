import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AbstractDialog } from "../../base/dialog";
import { translate } from "../../base/i18n";
import { toggleE2EE } from "../../e2ee/actions";
import { setRoomPassword } from "../action";
import { PasswordStyled } from "./styles";

function SetPasswordLayout({
    t, dispatch, password, locked, e2eeEnabled
}) {
    return (
        <AbstractDialog name="security" width={400}>
            { APP.function.security.lock && <SetRoomRock 
                t={t}
                dispatch={dispatch}
                password={password}
                locked={locked}
            /> }
            { APP.function.security.lock && APP.function.security.e2ee && <hr /> }
            { APP.function.security.e2ee && <SetE2EE t={t}
                dispatch={dispatch}
                enabled={e2eeEnabled}
            /> }
        </AbstractDialog>
    )
}

function SetRoomRock({
    t, dispatch, locked, password
}) {
    const [isLocked, setLocked] = useState(false);
    const [newPassword, setNewPassword] = useState(password);

    useEffect(() => {
        const isLocked = password ? true : false;
        setLocked(isLocked);
    }, [password]);

    const onClick = () => {
        if (locked) {
            dispatch(setRoomPassword(''));
            return;
        } 

        dispatch(setRoomPassword(newPassword));
    }

    const _onPasswordChanged = e => {
        const { value } = e.target;

        setNewPassword(value);
    }

    const _onhandlerLocked = () => {
        setLocked(!isLocked);
    };

    return (
        <PasswordStyled>
            { locked ? 
                <div className="guid">
                    <p>{ t("roomLock.lockGuid") } </p>
                    <p>{ t("roomLock.password") } : {password}</p>
                </div>            
                :
                <>
                    <p className="guid"> { t("roomLock.noLockGuid") } </p>                            
                    <div className="nonSelect">
                        <dl className={`password password_${isLocked}`} onClick={_onhandlerLocked}>
                            <span className={`icoImg security security_${isLocked}`}></span>
                            <span className="notSecurity">{t(`roomLock.${isLocked}Lock`)}</span>
                        </dl>
                        { isLocked && <dl className="input_pwd">
                            <input type="text" placeholder={ t("roomLock.inputPassword") } defaultValue={password} onChange={_onPasswordChanged}/>
                        </dl> } 
                    </div>
                </>
            }
            <div className="locked_btn">
                { isLocked && <span className="btn btn_middle" onClick={onClick}>
                    { locked ? t("dialog.release") : t("dialog.add") }
                </span> }
            </div>
        </PasswordStyled>
    )
}

function SetE2EE({ t, dispatch, enabled }) {
    const [isLocked, setLocked] = useState(enabled);
    const _onhandlerLocked = () => {
        setLocked(!isLocked);
    };

    useEffect(() => {
        dispatch(toggleE2EE(isLocked));
    }, [isLocked]);

    return (
        <PasswordStyled>
            <p className="guid"> 
                { t("roomLock.e2eeGuid") }
            </p>
            <dl className={`password password_${isLocked} nonSelect`} onClick={_onhandlerLocked}>
                <span className={`icoImg security security_${isLocked}`}></span>
                <span className="notSecurity">{t(`roomLock.${isLocked}E2EE`)}</span>
            </dl>
        </PasswordStyled>
    )
}

function _mapStateToProps(state) {
    const { password, locked } = state['features/room-lock'];
    return {
        password, locked,
        e2eeEnabled: state['features/e2ee'].enabled
    }
}

export default translate(connect(_mapStateToProps)(SetPasswordLayout));