import React, { useRef } from "react";
import { IconButton } from "../../base/buttons";
import { SearchButtonStyled } from "./styles";

export default function GroupSearchBar({
    setFilter
}) {
    const searchRef = useRef();

    const filterText = () => {
        if (searchRef.current) {
            const search = searchRef.current.value;

            setFilter(search);
        }
    }

    return (
        <SearchButtonStyled>
            <input ref={searchRef} type="text" palceholder="검색어 입력" onKeyUp={filterText} />
            <IconButton mode="search" size={16} onClick={filterText} />
        </SearchButtonStyled>
    )
}