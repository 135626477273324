import React from "react";
import styled from "styled-components"

export default function ClickButton({
    clsName, size, color, line = false, background, hBackground, hColor, text, hover = true, checked,
    onClick
}) {
    return (
        <ButtonStyled className={clsName} line={line} size={size} color={color} background={background}
            hBackground={hBackground} hColor={hColor}
            hover={hover} checked={checked} onClick={onClick}>
            { text }
        </ButtonStyled>
    )
}

const ButtonStyled  = styled.button`
    padding: 4px ${props => props.size}px 7px;
    background: ${props => props.background ? props.background : '#4d4d4d' };
    color: ${props => props.color ? props.color : '#b3b3b3' };
    ${props => props.line ? `border: 2px solid ${props.color}` : 'border: none' };
    border-radius: 5px; 
    
    ${props => props.checked && `
        background: ${props.hBackground ? props.hBackground : "#12a3f8"};
        color: #fff;
    `}

    ${props => props.hover ? `
        cursor: pointer;
        &:hover {
            background: ${props.hBackground ? props.hBackground : "#12a3f8"};
            color:  ${props.hColor ? props.hColor : "#fff"};
    }` : null};

    &.record { margin-top: 16px;}
`;