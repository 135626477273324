import { ReducerRegistry, set } from "../redux";
import { SET_LOADING } from "./actionTypes";

const DEFAULT_STATE = {
    isLoad: false,
    text: null
};

ReducerRegistry.register("features/base/loading", (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state, 
                isLoad: action.isLoad,
                text: action.text
            }
    }
    return state;
});