import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FooterIconButton } from "../../../base/buttons";
import { getStartScreenShare, toggleScreenShare } from "../../../screen-share";

export default function SwitchScreenShareButton() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const shared = useSelector(state => getStartScreenShare(state));

    if (!APP.layout.mode.screenShare) return null;
    
    const toggleScreen = () => {
        dispatch(toggleScreenShare(!shared, true));
    }

    return (
        <FooterIconButton mode="screen" name={t("conference.screenShare")} 
            checked={shared} onClick={toggleScreen} /> 
    )
}