export const NOTIFY_CAMERA_ERROR = 'NOTIFY_CAMERA_ERROR';

export const NOTIFY_MIC_ERROR = 'NOTIFY_MIC_ERROR';

export const SET_AUDIO_INPUT_DEVICE = 'SET_AUDIO_INPUT_DEVICE';

export const SET_VIDEO_INPUT_DEVICE = 'SET_VIDEO_INPUT_DEVICE';

export const UPDATE_DEVICE_LIST = 'UPDATE_DEVICE_LIST';

export const ADD_PENDING_DEVICE_REQUEST = 'ADD_PENDING_DEVICE_REQUEST';

export const REMOVE_PENDING_DEVICE_REQUESTS = 'REMOVE_PENDING_DEVICE_REQUESTS';

export const CHECK_AND_NOTIFY_FOR_NEW_DEVICE = 'CHECK_AND_NOTIFY_FOR_NEW_DEVICE';

export const DEVICE_PERMISSIONS_CHANGED = 'DEVICE_PERMISSIONS_CHANGED';
