import { REMOVE_GROUPS, SET_END_GROUP, SET_GROUP, SET_GROUP_DUAL, SET_GROUP_STATUS, SET_START_GROUP } from "./actionTypes";

/**
 * 그룹 하나 변경 
 * @param {string} group_id 
 * @param {Object} group 
 * @returns 
 */
export function setGroup(group_id, group) {
    return {
        type: SET_GROUP,
        group_id,
        group
    }
}

/**
 * 종료된 그룹 변경
 * @param {string} group_id 
 * @returns 
 */
export function setEndGroup(group_id) {
    return {
        type: SET_END_GROUP,
        group_id
    }
}

/**
 * 시작된 그룹 변경
 * @param {string} group_id 
 * @returns 
 */
export function setStartGroup(group_id) {
    return {
        type: SET_START_GROUP,
        group_id
    }
}

/**
 * 그룹 듀얼 모니터 수정 
 * @param {Boolean} isDual 
 * @returns 
 */
export function setGroupDual(isDual) {
    return {
        type: SET_GROUP_DUAL,
        isDual
    }
}

/**
 * 그룹 삭제 
 * @param {Array} groups 
 * @returns 
 */
export function removeGroups(groups) {
    return {
        type: REMOVE_GROUPS,
        groups
    }
}

/**
 * 그룹 상태 변경
 * @param {Boolean} status 
 * @returns 
 */
export function setGroupStatus(status, alertMessage, isRemote) {
    return {
        type: SET_GROUP_STATUS,
        status, alertMessage, isRemote
    }
}