import React from "react";
import styled from "styled-components";

function RightLabel({
    children
}) {
    return (
        <RightHeaderStyled>
            <ul className="nonSelect">
                { children }
            </ul>
        </RightHeaderStyled>
    )
}

export default RightLabel;

const RightHeaderStyled = styled.div`
    background: #f2f2f2;
    width: 100%;
    height: 35px;
    padding: 0 8px;
    display: flex;
    box-sizing: border-box;

    ul { 
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 35px;
    }
    
    li {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 12px;

        &:nth-child(1) {
            margin-left: 0;
        }
        &:nth-last-child(1) {
            margin-right: 0;
        }
    }

    .title {
        color: #000;
        font-size: 13px;
        font-weight: 600;
        margin: 0;
    }

    .arrow { color: #000; }
`;