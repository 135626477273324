import React, { useState } from "react";
import { MicTest } from "./MicTest";
import Recorder from "../recorder";

export default function AudioInputTest({
    t, previewAudioTrack
}) {
    const [prevRecord, setPrevRecord] = useState(null);
    const [file, setFile] = useState(null);
    const [isRecord, setIsRecord] = useState(false);

    const onClick = () => {
        if (prevRecord) {
            prevRecord.stop();
            return;
        }
        const testClass = new MicTest(setFile, setIsRecord, setPrevRecord);
        const recorder = new Recorder(testClass);

        if (!recorder.isSupportedBrowser()) {
            APP.UI.alertMessage("record.notAvaliable");
            return;
        }

        recorder.loadAudioStraem([ {track: previewAudioTrack} ]);
        // start record 
        recorder.start(true);
        setPrevRecord(recorder);
        
        setTimeout(() => {
            recorder.stop();
        }, 4000);
    }

    return (
        <>
            <div style={{textAlign: "center", flex: 0}}>
                <audio preload="auto" autoPlay 
                    webkit-playsinline="webkit-playsinline"
                    playsInline="playsInline"
                    src={file}
                /> 
            </div>

            { APP.function.setDevice.micTest && 
                <button className={`btn btn_middle ${isRecord ? "btn_on" : ""}`} onClick={onClick}>
                    {t(`deviceSelection.testAudio`)}
                </button> }
        </>
    )
}