import { 
    SET_LOCAL_USER_PROPERTY,
    SET_LOCAL_USER,
    LOGOUT,
    SET_USER_TOKEN,
    SET_PRECENT
} from "./actionTypes";

export function updateLocalUser(data) {
    return {
        type: SET_LOCAL_USER,
        data
    };
};

export const logoutUser = () => ({
    type: LOGOUT
});

export function setUserToken(token) {
    return {
        type: SET_USER_TOKEN,
        token
    };
}

export function setLocalUserProperty(data) {
    return {
        type: SET_LOCAL_USER_PROPERTY,
        data
    }
}

export function setPrecent(precent) {
    return {
        type: SET_PRECENT,
        precent
    }
}