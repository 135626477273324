import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ClickButton, IconButton } from "../../../base/buttons";
import { updateSettings } from "../../../base/settings";
import { getLocalIDMember, setHandlerMember } from "../../../member";

const KEEP = 'keep';
const FADE_OUT = 'fadeout';
export default function HandlerSetting({
    hands_up, hands_up_time, handlerUpdatePolicy
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const local_user = useSelector(state => getLocalIDMember(state));

    const [ type, setType ] = useState(hands_up);
    const [ time, setTime ] = useState(hands_up_time);

    useEffect(() => {
        setType(hands_up);
    }, [hands_up]);

    useEffect(() => {
        setTime(hands_up_time / 1000);
    }, [hands_up_time]);

    const onSave = () => {
        handlerUpdatePolicy({ hands_up: type, hands_up_time: time * 1000 });

        if (type === "keep") {
            dispatch(setHandlerMember(local_user, true));
        } else {
            dispatch(setHandlerMember(local_user, false));
        }

        APP.UI.closeModal();
    }

    return (
        <>
            <div className="guid" dangerouslySetInnerHTML={{ __html: t('options.handlerGuid') }} />
            <div className="device_wrapper">
                {[KEEP, FADE_OUT].map(item => {
                    const onClick = () => {
                        setType(item);
                    }

                    const onChange = e => {
                        const value = e.target.value;

                        setTime(value);
                    }

                    return (
                        <div className="item" key={item}>
                            <IconButton clsName={`button_${type === item ? "on" : "off"}`}
                                mode={`${item}_hand`} size={24} name={t(`options.${item}Handler`)} onClick={onClick}/>

                            { type === FADE_OUT && item === FADE_OUT &&
                                <span className="fadeout_text">
                                    { t("options.setTime") }
                                    <input type="number" value={time || 10} onChange={onChange} />
                                    { t("options.sec") }
                                </span>}
                        </div>
                    )
                })}
            </div>

            <ClickButton text={ t("common.add") } size={24} onClick={onSave} />
        </>
    )
}