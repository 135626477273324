/* @flow */
import {
    SET_AUDIO_MUTED,
    SET_AUDIO_AVAILABLE,
    SET_CAMERA_FACING_MODE,
    SET_VIDEO_AVAILABLE,
    SET_VIDEO_MUTED,
    STORE_VIDEO_TRANSFORM,
    TOGGLE_CAMERA_FACING_MODE
} from './actionTypes';
import {
    MEDIA_TYPE,
    VIDEO_MUTISM_AUTHORITY
} from './constants';

export function setAudioAvailable(available) {
    return {
        type: SET_AUDIO_AVAILABLE,
        available
    };
}

export function setAudioMuted(muted, ensureTrack = false) {
    return {
        type: SET_AUDIO_MUTED,
        ensureTrack,
        muted
    };
}

export function setCameraFacingMode(cameraFacingMode) {
    return {
        type: SET_CAMERA_FACING_MODE,
        cameraFacingMode
    };
}

export function setVideoAvailable(available) {
    return {
        type: SET_VIDEO_AVAILABLE,
        available
    };
}

export function setVideoMuted(
        muted,
        mediaType = MEDIA_TYPE.VIDEO,
        authority = VIDEO_MUTISM_AUTHORITY.USER,
        ensureTrack = false) {
    
    return (dispatch, getState) => {
        const oldValue = getState()['features/base/media'].video.muted;
        const newValue = muted ? oldValue | authority : oldValue & ~authority;
        
        return dispatch({
            type: SET_VIDEO_MUTED,
            authority,
            mediaType,
            ensureTrack,
            muted: newValue
        });
    };
}

export function storeVideoTransform(streamId, transform) {
    return {
        type: STORE_VIDEO_TRANSFORM,
        streamId,
        transform
    };
}

export function toggleCameraFacingMode() {
    return {
        type: TOGGLE_CAMERA_FACING_MODE
    };
}
