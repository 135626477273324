import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { FIGURE_LIST, PART_ERASER, PEN, TEXT } from "../constants";
import { getPdfDrawByUUIDAndIndexAndKey } from "../functions";
import FigureCanvas from "./FigureCanvas";
import LineCanvas from "./LineCanvas";
import TextCanvas from "./TextCanvas";

let numClicks = 0;
let timeOut;
export default React.memo(function CanvasDrawItem({ 
    id, drawCanvas, line, uuid, index, selected, scale, stageStyle,
    setFocusTextarea,
    handlerDeleteLine,
    handlerSelectedLine, 
    handlerChangeCanvasPoint
}) {
    const dispatch = useDispatch();
    const item = line ? line : useSelector(state => getPdfDrawByUUIDAndIndexAndKey(state, uuid, index, id), shallowEqual);

    const getContent = () => {
        const canvasTool = item.canvasTool;
        
        const onSelect = () => handlerSelectedLine(id);
        const onDelete = () => handlerDeleteLine(id);        
        const onChange = (points, canvasTool) => handlerChangeCanvasPoint(id, points, canvasTool)
        
        let ratio = (drawCanvas.width / canvasTool.width) * scale;
        ratio = isNaN(ratio) || ratio === 0 ? 1 : ratio;

        if (canvasTool.tool === PEN.name || canvasTool.tool === PART_ERASER.name) {
            return (
                <LineCanvas key={id} ratio={ratio} points={item.points} 
                    canvasTool={canvasTool} 
                    handlerDeleteLine={onDelete || null} />
            );
        } else if (FIGURE_LIST.includes(canvasTool.tool)) {
            return (
                <FigureCanvas key={id} ratio={ratio} points={item.points} canvasTool={canvasTool} 
                    selected={selected === id}
                    handlerSelectedLine={onSelect}
                    handlerChangeCanvasPoint={onChange}
                    handlerDeleteLine={onDelete}
                />
            );
        } else if (canvasTool.tool === TEXT.name) {
            return (
                <TextCanvas key={id} ratio={ratio} points={item.points} canvasTool={canvasTool} 
                    selected={selected === id}
                    drawText={line ? false : true}
                    scale={scale}
                    setFocusTextarea={setFocusTextarea}
                    stageWidth={stageStyle.width}
                    stageHeight={stageStyle.height}
                    handlerSelectedLine={onSelect}
                    handlerChangeCanvasPoint={onChange}
                    handlerDeleteLine={onDelete} 
                />
            )
        }
    }

    return getContent();
});