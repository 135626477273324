import { layoutType } from "../../video-layout";

export const getUserAgentType = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    var isMobile = /iPhone|Android/i.test(navigator.userAgent);
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

    if (isMobile) {
        return layoutType.mobile;
    } else if (isTablet){
        return layoutType.tablet;
    } else {
        return layoutType.desktop;
    }
};

export const getScreenType = (width) => {
    width = width || window.innerWidth;
    return width < 450 ? layoutType.mobile
        : width > 1024 ? layoutType.desktop
        : layoutType.tablet;
};

export const isIOSDevice = () => {
    return /(iPhone|iPod|iPad)/i.test(navigator.platform);
}

export const isAndroidDevice = () => {
    return /(android)/i.test(navigator.userAgent.toLowerCase());
}