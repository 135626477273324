import { toState } from "../redux";

export const getUserAccessToken = (state) => {
    return state['features/base/localuser'].token?.access_token;
}

export const isUserLogin = (state) => {
    return state['features/base/localuser'].user ? true : false;
}

export const getUserRole = (stateful) => {
    const state = toState(stateful);
    try {
        const { user } = state['features/base/localuser'];
        return user.role.name;
    } catch {
        return false;
    }
    
}

export const getUserPermission = (stateful) => {
    const state = toState(stateful);
    const user = state['features/base/localuser'].user;
    return user?.role?.permissions;
}

export const getLocalUserName = (stateful) => {
    const { user } = toState(stateful)['features/base/localuser']; 

    return user?.auth_name;
}

export const getLocalUserUUID = (stateful) => {
    const { user } = toState(stateful)['features/base/localuser']; 
    
    return user?.user_id;
}

export const isCheckPermission = (stateful, premission) => {
    const state = toState(stateful);
    const { permissions } = state['features/base/localuser'];
    
    return permissions && permissions.indexOf(premission) !== -1 ? true : false;
}