import { debounce } from "lodash";
import { getConferenceState, getCurrentConference, getGroupId, getJitsiRoom } from "../base/conference";
import { ReducerRegistry, StateListenerRegistry } from "../base/redux";
import { getBridgeIDByUserID, getLocalIDMember } from "../member";
import { SET_DUAL_MONITOR, SET_EXPAND, SET_GRID_COUNT, SET_IS_MOBILE, SET_LAYOUT_STYLE, SET_MAX_PAGE, SET_PAGE, SET_SHOW_COUNT, SET_SHOW_MEMBERS, SET_SHOW_OPTION, SET_VIDEO_LAYOUT } from "./actionType";
import { setMaxPage, setPage, setShowMembers, setShowOption } from "./actions";
import { videoLayoutMode } from "./constant";
import { getCurrentMode, getDualMonitor, getJitsiQuality, getLayoutWidthAndHeight, getMembers, getOption, getPage, getPinnedLayoutMembers, getShowMembers, getVoiceLayoutMember } from "./functions";
import { getGroupStatus } from "../groups";
import { getPropertyValue } from "../base/settings";
import { openChat } from "../chat";
import { toggleParticipantRight } from "../participant-right";
import { getLocalSharingStatus, getScreenSharingId, getScreenUserID } from "../screen-share";

const DEFAULT_STATE = {
    screenModal: false,
    isDualMonitor: false,
    mode: videoLayoutMode.grid,
    viewVisible: {
        [videoLayoutMode.screen]: false,
        [videoLayoutMode.vod]: false,
        [videoLayoutMode.white]: false,
        [videoLayoutMode.document]: false,
        [videoLayoutMode.note]: false
    },
    layoutWidth: 0,
    layoutHeight: 0,
    gridCount: 10,
    showCount: 10,
    page: 1,
    maxPage: 1,
    option: {
        excludePin: false,
        excludeVoice: false,
        excludeGroup: false        
    },
    expand: false,
    showMembers: [],
    isMobile: false
};

ReducerRegistry.register('features/video-layout', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_VIDEO_LAYOUT:
            return {
                ...state,
                mode: action.mode,
                viewVisible: action.visibleList
            }

        case SET_GRID_COUNT:
            return {
                ...state,
                gridCount: action.gridCount
            }

        case SET_LAYOUT_STYLE:
            return {
                ...state,
                layoutWidth: action.width,
                layoutHeight: action.height
            }

        case SET_SHOW_OPTION:
            return {
                ...state,
                showCount: action.count,
                option: action.option
            }

        case SET_SHOW_MEMBERS:
            return {
                ...state,
                showMembers: action.showMembers
            }

        case SET_SHOW_COUNT:
            return {
               ...state,
               showCount: action.count 
            }

        case SET_PAGE:
            return {
                ...state,
                page: action.page
             }

        case SET_MAX_PAGE:
            return {
                ...state,
                maxPage: action.maxPage
            }

        case SET_DUAL_MONITOR:
            return {
                ...state,
                isDualMonitor: action.isDual
            }

        case SET_IS_MOBILE:
            return {
                ...state,
                isMobile: action.isMobile
            }

        case SET_EXPAND:
            return {
                ...state,
                expand: action.expand
            }
    }

    return state;
});

StateListenerRegistry.register(state => {
    const conferenceJoined = getCurrentConference(state);
    const mode = getCurrentMode(state);
    const layoutStyle = getLayoutWidthAndHeight(state);
    
    const { count, option } = getOption(state, mode, layoutStyle);
    return {
        conferenceJoined,
        count, option
    }
}, ({ conferenceJoined, count, option }, store) => {
        if (conferenceJoined === false) return;       

        store.dispatch(setShowOption(count, option));       
    }, {
        deepEquals: true
    });

StateListenerRegistry.register(state => {
    const conferenceJoined = getCurrentConference(state);
    const { members, maxPage } = getMembers(state);
    const { page } = getPage(state);
    
    return {
        conferenceJoined,
        members, maxPage, page
    }
}, ({ conferenceJoined, members, maxPage, page }, store) => {
    if (conferenceJoined === false) return;

    const { getState, dispatch } = store;
    if (maxPage !== 0 && page > maxPage) {
        store.dispatch(setPage(maxPage));
        return;
    } else if (page < 1) {
        store.dispatch(setPage(1));
        return;
    }

    maxPage !== Infinity && dispatch(setMaxPage(maxPage));
    dispatch(setShowMembers(members));    
}, {
    deepEquals: true
});

StateListenerRegistry.register(state => {
    const conference = getCurrentConference(state);
    if (!conference) return false;

    const mode = getCurrentMode(state);
    const members = getShowMembers(state);
    const pinMembers = getPinnedLayoutMembers(state, mode);
    const voice = getVoiceLayoutMember(state);
    const bridgeList = state["features/memeber"].bridgeList;
    let screenTrackId;
    let screenUserId;
    const localScreen = getLocalSharingStatus(state);
    if (mode === videoLayoutMode.screen) {
        screenTrackId = getScreenSharingId(state);
        screenUserId = getScreenUserID(state);
    }

    const result = getJitsiQuality(mode, members, pinMembers, voice, bridgeList, { track_id: screenTrackId, user_id: screenUserId, isMe: localScreen }, getLocalIDMember(state));
    return result;
}, (result, store) => {
        if (result) {
            const conference = getJitsiRoom(store.getState())?.conference?.conference;

            if (!conference) return;
            
            try {
                conference.setReceiverConstraints(result);
            } catch (err) {
                console.log(err)
            }
        }    
    }, {
        deepEquals: true
    });

StateListenerRegistry.register(state => {
    const dualMonitor = getDualMonitor(state);
    const dualOption = getPropertyValue(state, 'dualOption');

    return {
        dualMonitor,
        dualOption
    }
}, ({dualMonitor, dualOption}, store) => {
    if (dualMonitor && dualOption) {
        store.dispatch(openChat());
        store.dispatch(toggleParticipantRight(true));
    }
}, {
    deepEquals: true
});
