import { debounce } from "lodash";
import { setLoading } from "../../features/base/loading";
import { closeModal, updateModalState } from "../../features/base/modal";
import messageHandler from "./util/MessageHandler";

const UI = {};
let prevLoading = false;
UI.toggleVideo = () => {
   APP.mateManagement.toggleVideoMuted(true);
}
UI.toggleAudio = () => {
   APP.mateManagement.toggleAudioMuted(true);
}

UI.updateAudioLevel = (level) => {
   const element = document.getElementById("audioLevel");
   const volume = level * 100;
   
   if (element) element.style.background = `linear-gradient(to right, #29abe2 0%, #29abe2 ${volume}%, #d5d4d3 ${volume}%, #d5d4d3 100%)`;
}

UI.openModal = (type) => {
   APP.store.dispatch(updateModalState(type));
}
UI.closeModal = () => {
   APP.store.dispatch(closeModal());
}

UI.alertMessage = (message) => {
   alert(message);
}

UI.showLoading = (isLoading, text = null) => {
   APP.store.dispatch(setLoading(isLoading, text));
};
UI.messageHandler = messageHandler;

export default UI;

