import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "../../base/buttons";
import { translate } from "../../base/i18n";
import { MEDIA_TYPE } from "../../base/media";
import { getLocalTrack, getTrackState } from "../../base/tracks";
import { toggleBackgroundEffect } from "../actions";
import { VIRTUAL_BACKGROUND_TYPE } from "../constants";
import { checkBlurSupport } from "../functions";

function SwitchVirtualBackgroundButton({ t }) {
    if (!APP.layout.option.blur) return null;

    const dispatch = useDispatch();
    const blurEnabled = useSelector(state => state["features/virtual-background"].backgroundEffectEnabled) ? true : false;
    const _track = useSelector(state => getLocalTrack(getTrackState(state), MEDIA_TYPE.VIDEO)?.track);

    const onClick =  () => {
        if (checkBlurSupport()) {
            applyVirtualBackground();
            APP.UI.closeModal();
        } else {
            alert(`${t(common.enableBrowser)}`)
        }
    }

    const applyVirtualBackground = useCallback(async () => {
        await dispatch(toggleBackgroundEffect({
            backgroundType: VIRTUAL_BACKGROUND_TYPE.BLUR,
            enabled: !blurEnabled,
            blurValue: 25,
            selectedThumbnail: 'blur'
        }, _track));
    }, [ blurEnabled, _track ]);

    return (
        <IconButton mode="blur"
            name={t(`options.${blurEnabled}VirtualBack`)} size={22} 
            onClick={onClick} />
    )
}

export default translate(SwitchVirtualBackgroundButton);