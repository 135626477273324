import styled from "styled-components";

export const ChatBodyStyled = styled.div`
    width: 100%;
    flex: 1;
    overflow: auto; 
    box-sizing: border-box;
    background: #fff;

    .message {
        box-sizing: border-box;
        padding: 4px 8px;
        white-space: pre;

        .sender { font-size: 12px; }

        &_true > .sender { color #e60000; }
        &_false > .sender { color #123; }

        &.text {
            .message_context {
                display: inline-flex;
                align-items: flex-end;

                .time {
                    margin: 6px;
                    position: relative;
                    top: 4px;
                    font-size: 10px;
                    color: #a5a5a5;
                }
                .message {
                    padding: 5px 10px 6px;
                    border-radius: 10px;
                    display: inline-block;
                    margin-top: 5px;
                    max-width: 90%;
                    white-space: pre-wrap;
                    word-break: break-all;
                } 
            } 

            &.local {
                text-align: right;
                
                .sender {
                    text-align: right;
                }
                .message_context {
                    .message {
                        background: #0d8fdf;
                        color: white;
                        text-align: left;
                    }                            
                }
            }
            
            &.remote {
                text-align: left;
                .message_context {
                    flex-direction: row-reverse;
                    
                    .message {
                        background: #f2f2f2;
                        color: black;   
                        text-align: left;
                    }
                                 
                }                        
            }  
        }

        &.file {
            .message_context {
                overflow: hidden;
                text-overflow: ellipsis;
            }
            
            &.message_false > .sender {
                color: #fff;
            } 

            width: 100%;
            padding: 8px; margin: 1px 0;
            background: #123;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;                    

            .message_text {
                width: 80%; margin-left: 0;                       

                .sender {
                    font-size: 13px;
                    margin-bottom: 5px;
                }
            }

            .message_file{
                float: right;
                cursor: pointer;
                right: 15px;
                display: inline-flex;
                flex-direction: column;
                margin: 0;

                .message_download {
                    width: 26px; height: 22px; 
                    margin-bottom: 5px;
                    background-position: -428px -210px; 
                }
            }
            
        }
    }
`;

export const ChatFooterStyled = styled.div`
    margin: 0;
    min-height: 92px;
    .chatFile { display: none; }
    .chat_file_icon { display: flex; }

    .select_member {
        border: 1px solid;
        border-radius: 4px;
        padding: 4px 12px;

        span:first-child {
            &::after {
                content: "|";
                padding: 0 12px;
                color: #d5d5d5!important;
            }
        }

        span { cursor: pointer; }

        .text_on {
            color: #000;
            font-weight: 600;
        }

    }

    .choice_box {
        display: inline-flex;
        flex-wrap: wrap;
        max-height: 84px;
        overflow: auto;
        margin: 0;

        .item {    
            flex-basis: auto;
            margin: 4px;
            padding: 4px 8px;
            border: 1px solid;
            box-sizing: border-box;
            cursor: pointer;
            
            &:hover {
                color: #e60000;
            }
        }
    }

    .chat_participant { 
        width: 100%;
        border-bottom: 1px solid #d5d5d5;
        border-top: 1px solid #d5d5d5;
        overflow: auto;
        background: #fff;

        .close {
            text-align: center;
            background-color: #f2f2f2;
            padding: 4px 0;
            &:hover {
                color: #000;
            }
        }

        .list {
            max-height: 125px;
            overflow: auto;

            dl {
                width: 100%;
                padding: 4px 12px;
                box-sizing: border-box;
                border-bottom: 1px solid #f2f2f2;
                cursor: pointer;

                dl:nth-last-child(1) {
                    border: none;
                }

                .check {
                    background-position: -260px -284px; width: 11px; height: 11px; transform: scale(1.3);
                    margin-right: 20px;

                    &_true {
                        background-position: -260px -298px; 
                    }
                }

                &:hover {
                    background-color: #f2f2f2;
                    .check {
                        background-position: -260px -298px; 
                    }
                }
            }
        }    
    }

    textarea {
        width: calc(100% - 2px);  
        height: 55px; 
        resize: none; 
        box-sizing: border-box; 
        padding: 10px; margin: 1px;
    }

    
    // emoji
    #smileysContainer {
        padding: 10px;
        background: rgba(0,0,0,0.3);
        position: absolute;
        margin-top: -65px;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        
        div {
            cursor: pointer;
            margin: 0;
        }
    }
    .smiley {
        width: auto!important; height: auto!important; margin: 0!important; vertical-align: auto!important;
    }
`;

export const ChatPopStyled = styled.div`
    .send_body {
        padding: 4px 12px;

        .title {
            font-size: 13px; font-weight: 700;
        }

        .chat_list {
            padding: 10px;

            &::before {
                content: "-";
                margin: 3px 8px;
                display: inline-block;
            }
        }
    }

    button {
        display: flex;
    }    
`;