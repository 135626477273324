import React, {  useEffect, useState } from 'react';
import { JitsiTrackEvents } from '../../lib-jitsi-meet';

const AUDIO_LEVEL_DOTS = 5;
const CENTER_DOT_INDEX = Math.floor(AUDIO_LEVEL_DOTS / 2);

export default function AudioLevelIndicator  ({
    audioTrack
}) {
    const [audioLevel, setAudioLevel] = useState(0);

    useEffect(() => {
        if (!audioTrack) return;
        audioTrack.track && audioTrack.track.on(JitsiTrackEvents.TRACK_AUDIO_LEVEL_CHANGED, updateAudioLevel);

        const passedAudioLevel = audioTrack?.track?.audioLevel; 
        const level = typeof passedAudioLevel === 'number' && !isNaN(passedAudioLevel) ? Math.min(passedAudioLevel * 1.2, 1) : 0;
        setAudioLevel(level);     
        
        return () => {
            if (audioTrack) audioTrack?.track && audioTrack?.track.off(JitsiTrackEvents.TRACK_AUDIO_LEVEL_CHANGED, updateAudioLevel);
        }
    }, [audioTrack]);

    useEffect(() => {
        return () => {
            setAudioLevel(0); 
            if (audioTrack) audioTrack.track && audioTrack.track.off(JitsiTrackEvents.TRACK_AUDIO_LEVEL_CHANGED, updateAudioLevel);
        }
    }, []);

    const updateAudioLevel = (level) => {
        setAudioLevel(level);
    }    
    
    const stretchedAudioLevel = AUDIO_LEVEL_DOTS * audioLevel;

    const audioLevelDots = [];

    for (let i = 0; i < AUDIO_LEVEL_DOTS; i++) {
        const distanceFromCenter = CENTER_DOT_INDEX - i;
        const audioLevelFromCenter = stretchedAudioLevel - Math.abs(distanceFromCenter);
        const cappedOpacity = Math.min(1, Math.max(0, audioLevelFromCenter));
        let className;

        if (distanceFromCenter === 0) {
            className = 'audiodot-middle';
        } else if (distanceFromCenter < 0) {
            className = 'audiodot-top';
        } else {
            className = 'audiodot-bottom';
        }

        if (!isNaN(cappedOpacity)) {
            audioLevelDots.push(
                <span
                    className = { className }
                    key = { i }
                    style = {{ opacity: cappedOpacity }} />
            );
        };       
    }

    return (
        <span className='audioindicator-container'>
            { audioLevelDots }
        </span>
    );
}