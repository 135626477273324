// @flow

import i18next from 'i18next';

/**
 * The builtin languages.
 */
const _LANGUAGES = {
    // Korean
    'ko': {
        languages: require('../../../lang/languages-ko.json'),
        main: require('../../../lang/main-ko')
    },
    'ko_edu': {
        languages: require('../../../lang/languages-ko.json'),
        main: require('../../../lang/main-ko_edu')
    },
    'en': {
        languages: require('../../../lang/languages-en.json'),
        main: require('../../../lang/main-en')
    },
    'ja': {
        languages: require('../../../lang/languages-en.json'),
        main: require('../../../lang/main-ja')
    }
};

// Register all builtin languages with the i18n library.
for (const name in _LANGUAGES) { // eslint-disable-line guard-for-in
    const { languages, main } = _LANGUAGES[name];

    i18next.addResourceBundle(
        name,
        'languages',
        languages,
        /* deep */ true,
        /* overwrite */ true);
    i18next.addResourceBundle(
        name,
        'main',
        main,
        /* deep */ true,
        /* overwrite */ true);
}
