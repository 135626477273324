import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PERMISSION } from "../../member";
import { isCheckPermission } from "../../base/localuser";
import { IconButton } from "../../base/buttons";
import { getRecordStatus } from "../functions";
import { useTranslation } from "react-i18next";
import { setLocalRecord, setServerRecord } from "../action";

export default React.memo(function SwitchRecordButton({
    type
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const permission = useSelector(state => isCheckPermission(state, type));
    const isRecord = useSelector(state => getRecordStatus(state, type));

    if (!permission) return null;
    if (!APP.layout.option.recorder) return null;
    if (type === PERMISSION.SELF_RECORD && !APP.function.record.local) return null;
    else if (type === PERMISSION.SERVER_RECORD && !APP.function.record.server) return null;

    const onClick = () => {
        const onChange = type === PERMISSION.SELF_RECORD ? setLocalRecord : setServerRecord;

        dispatch(onChange(!isRecord));
    }

    return (
        <IconButton mode={type} 
            name={ type === PERMISSION.SELF_RECORD ? t(`recording.${isRecord}Personal`) : t(`recording.${isRecord}Server`) }
            size={22}
            onClick={onClick} />
    )
})