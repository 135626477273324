import { v4 as uuidv4 } from 'uuid';

import moment from 'moment';
import { addCanvasList } from '../base/canvas';
import { getLocalIDMember } from '../member';
import { videoLayoutMode } from '../video-layout';
import { ADD_SHARE_ITEM, ADD_SHARE_LIST, DELETE_SHARE_ITEM, SET_FOCUS_SHARE_ITEM, SET_VISIBLE_DOCUMENT_LIST } from './actionTypes';
import { DEFAULT_DOCUMENT, DEFAULT_INDEX } from './constants';
import { checkSharePermission, getDocumentItemByUUID, getFocusItemByMode, isFocusAblePage, parseCanvaseLine } from './functions';

export function addDocumentFile(file: Object) {
    return async (dispatch: Dispatch<any>, getState: Function) => {
        const uuid = uuidv4();
        const user_uuid = getLocalIDMember(getState);

        const data = {
            uuid,
            file: {name: file.name},
            isWhiteBoard: false,
            shared: false,
            page: undefined,
            list: undefined,
            count: undefined,
            complete: false,   
            user_uuid,
            config: videoLayoutMode.document
        };

        await dispatch(addShareItem(data));

        const response = await APP.API.convertShareDocument(file, uuid);
        if (response.complete) {
            console.log("test")
            dispatch(updateShareItem(uuid, { uuid: response.message.uuid, isWhiteBoard: false }));

            return true;
        } else {
            dispatch(removeShareItem(uuid));
            return { message: response.message, uuid };
        }        
    }   
}

export function addDocumentItem(option) {
    return async (dispatch: Dispatch<any>, getState: Function) => {
        const uuid = uuidv4();
        const user_uuid = getLocalIDMember(getState);
        const date = moment().format("HH:DD:SS");

        const data = {
            uuid,
            file: {name: date },
            isWhiteBoard: true,
            shared: false,
            page: undefined,
            list: [],
            count: 0,
            complete: false,   
            user_uuid,
            ...option
        };

        
        dispatch(addShareItem(data));    
    }  
}

/**
 * 문서 파일 저장 
 * {
 *     type: ADD_SHARE_ITEM,
 *     data: {
 *          file: Object,
 *          isWhiteBoard: Boolean,
 *          shared: Boolean,
 *          page: String,
 *          list: Array,
 *          count: Number,
 *          complete: Boolean,
 *          user_uuid: String
 *      };
 *  }
 */
export function addShareItem(data: Object, isRemote: Boolean = false) {
    return async (dispatch: Dispatch<any>, getState: Function) => {
        if (!isRemote) {
            if (checkSharePermission(getState, 'share') ) {
                // 화이트 보드 일때만 공유, 문서 공유 일때에는 문서 변환 완료 시 공유 
                const response = await APP.mateManagement.shareItem(data);
                if (response.status !== 200) return;
                
                if (data.isWhiteBoard) dispatch(tempFocusShareItem(data.uuid, DEFAULT_INDEX, data.config));
            } else return;
        }

        dispatch(addShare(data));
    }
}

function addShare(data) {
    return (dispatch: Dispatch<any>, getState: Function) => {
        const prevDocument = getDocumentItemByUUID(getState, data.uuid);
        
        dispatch({
            type: ADD_SHARE_ITEM,
            data, prevDocument
        });
    };
}

/**
 * 문서 파일 업데이트 
 * {
 *     type: UPDATE_SHARE_ITEM,
 *     uuid: String,
 *     data: {
 *          file?: Object,
 *          isWhiteBoard?: Boolean,
 *          shared?: Boolean,
 *          page?: String,
 *          list?: Array,
 *          count?: Number,
 *          complete?: Boolean,
 *          user_uuid?: String
 *      };
 *  }
 */
export function updateShareItem(uuid: String, data: Object, isRemote: Boolean = false) {
    return async (dispatch: Dispatch<any>, getState: Function) => {        
        let newDocument;
        const prevDocument = getDocumentItemByUUID(getState, uuid);
        // 이전 문서 삭제 
        if (prevDocument) {
            newDocument = { ...prevDocument, ...data };
        }

        if (!isRemote) {           
            if (data.uuid !== uuid) {
                const deleteResponse = await dispatch(removeShareItem(uuid, false, false));
                if (!deleteResponse) return false;
            }            
            
            if (checkSharePermission(getState, 'share') ) { 
                if (newDocument.shared) {
                    const response = await APP.mateManagement.shareItem(newDocument);
                    if (response.status !== 200) return;
                }

                if ((newDocument.shared && !prevDocument.shared) || (newDocument.complete && !prevDocument.complete)) {
                    dispatch(setFocusShareItem(newDocument.uuid, DEFAULT_INDEX, newDocument.config));
                }
            }
        } else {
            newDocument = data;
        }
        
        newDocument && await dispatch(addShareItem(newDocument, isRemote));
    } 
}


/**
 * 문서 파일 삭제 
 * {
 *      type: DELETE_SHARE_ITEM,
 *      uuid: String
 * }
 */
export function removeShareItem(uuid: String, isRemote: Boolean = false, isFocus: Boolean = true) {
    return async (dispatch: Dispatch<any>, getState: Function) => {
        

        if (!isRemote) {
            if (checkSharePermission(getState, 'share') ) {      
                const response = await APP.mateManagement.deleteShareItem(uuid);
                if (response.status !== 200) return false;
                else {
                    const document = getDocumentItemByUUID(getState, uuid);
                    if (document) {
                        const focusItem = getFocusItemByMode(getState, document.config);
                        if (uuid === focusItem.uuid) {
                            if (document.config === videoLayoutMode.white) {
                                await dispatch(setFocusShareItem(DEFAULT_DOCUMENT, DEFAULT_INDEX, document.config));
                            } else {
                                await dispatch(setFocusShareItem(null, null, document.config));
                            }
                        }
                    }   
                }
            } else return false;
        }

        dispatch({
            type: DELETE_SHARE_ITEM,
            uuid
        });

        return true;
    }
}

/**
 * 아직 저장되지 않은 문서 포커스
 * @param {*} uuid 
 * @param {*} index 
 * @param {*} mode 
 * @param {*} isRemote 
 * @returns 
 */
export function tempFocusShareItem(uuid: String, index: String, mode: String, isRemote: Boolean = false) {
    return (dispatch: Dispatch<any>, getState: Function) => {
        if (!isRemote) {
            if (checkSharePermission(getState, 'share') ) {      
                APP.mateManagement.focusShareItem(uuid, index, mode);
            } else return;
        }

        dispatch({
            type: SET_FOCUS_SHARE_ITEM,
            uuid, index, mode
        });              
    }
}

/**
 * 문서 포커스 
 * {
 *      type: SET_FOCUS_SHARE_ITEM,
 *      uuid, index, mode
 * }
 */
export function setFocusShareItem(uuid: String, index: String, mode: String, isRemote: Boolean = false) {
    return (dispatch: Dispatch<any>, getState: Function) => {
        if (!isRemote) {
            // const document = getDocumentItemByUUID(getState, uuid);
            // if (document && ( document.isWhiteBoard || document.count > index && index >= 0)) {
            if (checkSharePermission(getState, 'share') ) {      
                APP.mateManagement.focusShareItem(uuid, index, mode);
            } else return;
            // }
        }

        dispatch({
            type: SET_FOCUS_SHARE_ITEM,
            uuid, index, mode
        });              
    }
}

/**
 * 
 */
export function changeFocusPage(uuid, page, mode) {
    return (dispatch: Dispatch<any>, getState: Function) => {
        const isAble = isFocusAblePage(getState, uuid, page);

        if (isAble) {
            dispatch(setFocusShareItem(uuid, page, mode));
            return true;
        } else {
            return false;
        }
    }
}

/**
 * 문서 파일 리스트 저장
 * {
 *      type: ADD_SHARE_LIST,
 *      whiteboard_list, document_list 
 * }
 */
export function addDocumentList(list: Array, member_uuid: String) {
    return (dispatch: Dispatch<any>, getState: Function) => {
        const whiteboard_list = new Set();
        const document_list = new Set();
        const vod_list = new Set();
        const visible_list = new Set();
        const canvas_list = new Map();

        const user_uuid = getLocalIDMember(getState);
    
        list.map(item => {
            const document = {
                uuid: item.uuid,
                file: item.file,
                isWhiteBoard: item.isWhiteBoard,
                shared: item.shared,
                page: item.page,
                list: item.list,
                count: item.count,
                complete: item.complete,
                config: item.config || videoLayoutMode.white,
                user_uuid
            };

            if (member_uuid === item.member_uuid || item.shared) {
                if (document.config === videoLayoutMode.white ) {
                    whiteboard_list.add(document.uuid);
                } else if (document.config === videoLayoutMode.document) {
                    document_list.add(document.uuid);
                } else if (document.config === videoLayoutMode.vod) {
                    vod_list.add(document.uuid);
                }
                
                visible_list.add(document);
            }

            const canvasList = parseCanvaseLine(item.canvasList);
            canvas_list.set(item.uuid, canvasList);
        });

        dispatch(addCanvasList(canvas_list));
        dispatch({
            type: ADD_SHARE_LIST,
            whiteboard_list, document_list, vod_list, visible_list
        });
    };
}

/**
 * 
 */
export function setVisibleDocumentList(viewVisible) {
    const document = [ ...Array.from(viewVisible.keys()) ];

    return ({
        type: SET_VISIBLE_DOCUMENT_LIST,
        list: Array.from(new Set(document))
    })
}