import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactNewWindowStyles } from "react-new-window-styles";
import { useDispatch, useSelector } from "react-redux";
import { getMainRoomRole } from "../../base/conference";
import { setGroupDual } from "../actions";
import { SHOW_GROUP_ROLE_LIST } from "../constants";
import { getGroupDualMonitor, getGroupStatus } from "../functions";
import GroupLayout from "./GroupLayout";
import GroupPanelLayout from "./start/GroupPanelLayout";

export default React.memo(function GroupDualLayout({
    isMobile
}) {
    const mainRole = useSelector(state => getMainRoomRole(state));
    const isGroupDual = useSelector(state => getGroupDualMonitor(state));

    const newWindowNode = useRef();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [ outerStyle, setOuterStyle ] = useState({ width: 0, height: 0 });
    const [ windowOpen, setWindowOpen ] = useState(false);

    const setContainerStyle = (e) => {
        const { innerWidth, innerHeight } = e.target;
        
        setOuterStyle({ width: innerWidth, height: innerHeight });
    }

    const unload = () => {
        dispatch(setGroupDual(false));
    }

    const open = e => {
        e.addEventListener("resize", setContainerStyle);
    }

    const alertMessage = (message, confirmMessage) => {
        if (newWindowNode.current) {
            const elementWindow = newWindowNode.current.state.externalWindow;

            message && elementWindow.alert(t(message));
            if (confirmMessage) {
                return elementWindow.confirm(t(confirmMessage));
            }
        } else {
            message && alert(t(message));
            if (confirmMessage) {
                return confirm(t(confirmMessage));
            }
        }
    }

    useEffect(() => {
        if (isGroupDual && newWindowNode) {
            const element = newWindowNode.current.state.externalWindow;
            if (element && element.window) {
                element.window.addEventListener('resize', setContainerStyle);
            }
        } 
    }, [isGroupDual]);

    if (!isGroupDual) return null;
    if ((mainRole && !SHOW_GROUP_ROLE_LIST.includes(mainRole))) return null;
    return (
        <ReactNewWindowStyles 
            ref={newWindowNode}
            title="Meemate2"
            windowProps={{width: '960px', height: '600px'}} 
            onClose={unload}
            copyStyles={true}>            
            <GroupDualContainer isMobile={isMobile} alertMessage={alertMessage}/>
        </ReactNewWindowStyles>
    )
});

function GroupDualContainer({
    isMobile, alertMessage
}) {
    const startGroup = useSelector(state => getGroupStatus(state));
   
    return (
        <div style={{
                width: "100%", height: "100%", 
                display: "flex", justifyContent: "center", alignItems: "center"
            }}
        >  
            {/* 현재 그룹이 하나라도 시작된 경우 */}
            { startGroup  
                ? <GroupPanelLayout isMobile={isMobile} alertMessage={alertMessage} />
                : <GroupLayout isMobile={isMobile} alertMessage={alertMessage} /> }
        </div>
    )
}