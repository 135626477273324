import moment from "moment";
import React, { lazy, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "../../../../assets/css/common.scss";
import { setRoom } from "../../../base/conference";
import { translate } from "../../../base/i18n";
import { getLocalUserUUID, getUserAccessToken } from "../../../base/localuser";
import { ENTER_ROOM } from "../../../lobby/constants";
import { RoomOptionStyle } from "./RoomStyled";
import { START } from "./constants";

const RoomList = lazy(() => import("./RoomList"));
const ModifyRoom = lazy(() => import("./ModifyRoom"));

let prevPathName = null;
function MyPage({
    t, dispatch, isModal, aToken, member_id, pathRoomName
}) {
    let history = useHistory();
    const [page, setPage] = useState(0);   
    const [status, setStatus] = useState(START);
    const [choiceValue, setChoiceValue] = useState(null); 

    useEffect(() => {
        setPage(0);
    }, []);

    useEffect(() => {
        setPage(0);
    }, [status]);


    const enterRoom = async item => {
        const duration = item.pre_entering_duration / 60000;
        const start_time = Date.parse(moment(item.start_time));
        
        if (Date.parse(moment(new Date()).add(duration, 'm').toDate()) <= start_time) {
            alert('입장 가능 시간이 아닙니다.');
            return;
        } 

        dispatch(setRoom({ name: item.code }, ENTER_ROOM));
    }
    
    return (
        <>
            {
                member_id ? 
                <RoomOptionStyle isModal={isModal}>
                    <div className="room-container">
                        { choiceValue === null ? 
                            <RoomList t={t} 
                                page={page}
                                isModal={isModal} 
                                aToken={aToken}
                                status={status}
                                setStatus={setStatus}
                                member_id={member_id}
                                setPage={setPage} 
                                setChoiceValue={setChoiceValue}
                                enterRoom={enterRoom} />
                            : choiceValue === "add" ?
                                <ModifyRoom t={t} 
                                    isModal={isModal}
                                    choiceValue={null}
                                    aToken={aToken}
                                    setChoiceValue={setChoiceValue}
                                />
                            : <ModifyRoom t={t} 
                                isModal={isModal}
                                choiceValue={choiceValue}
                                aToken={aToken}
                                setChoiceValue={setChoiceValue}
                            />
                        }
                    </div>
                </RoomOptionStyle>
                : null 
            }
        </>
    )
}

function _mapStateToProps(state) {
    return {
        aToken: getUserAccessToken(state),
        member_id: getLocalUserUUID(state), 
    }
}

export default translate(connect(_mapStateToProps)(MyPage));
