import { CONFERENCE_LEFT } from "../base/conference";
import { ReducerRegistry } from "../base/redux";
import { TOGGLE_PARTICIPANT_RIGHT } from "./actionType";

const DEFAULT_STATE = {
    isOpen: false
};

ReducerRegistry.register('features/participant-right', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case TOGGLE_PARTICIPANT_RIGHT:
            return {
                isOpen: action.data
            };
            
        case CONFERENCE_LEFT:
            return {
                isOpen: false
            }
    }

    return state;
});
