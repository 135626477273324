import i18next from "i18next";
import { getUserAccessToken, setLocalUserProperty, setUserToken } from ".";
import { storage } from "../localStorage";
import { ReducerRegistry, StateListenerRegistry } from "../redux";
import { getSearchData } from "../util";
import { LOGOUT, SET_LOCAL_USER_PROPERTY, SET_PRECENT, SET_USER_TOKEN } from "./actionTypes";
import { showNotification } from "../../notifications";
import { getTracksByParticipantId } from "../tracks";
import { MEDIA_TYPE } from "../media";
import { isTrackStreamingStatusInactive, isTrackStreamingStatusInterrupted } from "../../connection-indicator/functions";

const DEFAULT_STATE = {
    user: null,
    token: null,
    permissions: [], 
    precent: undefined
};

ReducerRegistry.register('features/base/localuser', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_LOCAL_USER_PROPERTY:
            return {
                ...state,
                ...action.data
            }

        case SET_USER_TOKEN:
            return {
                ...state,
                token: action.token
            };

        case LOGOUT:
            const defaultState = DEFAULT_STATE;

            return defaultState;

        case SET_PRECENT:
            return {
                ...state,
                precent: action.precent
            }

        default:
            return state;
    }
});

StateListenerRegistry.register(state => getUserAccessToken(state),
    async (access_token, { dispatch }) => {
        if (!access_token) return;
        APP.mateManagement.setAccessToken(access_token);
        const { message, complete } = await APP.API.getUser(access_token);

        const storage_key = getSearchData('storage_key');
        if (!complete) {
            storage.remove(storage_key || 'token');
            dispatch(setUserToken(null));
            return;
        }

        const token = { access_token };
        if (storage_key) {
            const data = storage.get(storage_key);

            data && storage.save(storage_key, { 
                ...data,
                token 
            });
        } else {
            storage.save('token', token);
        }
        
        dispatch(setLocalUserProperty({
            user: message.user
        }));
    }
);
