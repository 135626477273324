import React from "react";
import { connect } from "react-redux";
import { FooterIconButton } from "../../../base/buttons";
import { translate } from "../../../base/i18n";
import { OPTION_ETC_MODAL_ID } from "../../constants";

import AbstractSwitchButton from "../AbstractSwitchButton";

function EtcSwitchButton({ t, isOpen }) {
    const onClick = () => APP.UI.openModal(OPTION_ETC_MODAL_ID);

    return (
        <FooterIconButton mode="option" name={t("conference.option")} checked={isOpen} onClick={onClick} />
    )
}

function _mapStateToProps(state) {
    const { name } = state["features/base/modal"];
    return {
        isOpen: name === OPTION_ETC_MODAL_ID ? true : false,
    }
}


export default translate(connect(_mapStateToProps)(EtcSwitchButton));