import { CLEAR_ROOM, CONFERENCE_LEFT, getCurrentConference, getRoomOption } from "../base/conference";
import { getUserAccessToken } from "../base/localuser";
import { ReducerRegistry, StateListenerRegistry } from "../base/redux";
import { SET_ROOM_LOCKED, SET_ROOM_PASSWORD } from "./actionType";

const DEFAULT_STATE = {
    locked: false,
    password: null
};

ReducerRegistry.register('features/room-lock', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_ROOM_PASSWORD:
            return {
                ...state,
                locked: action.password ? true : false,
                password: action.password
            };

        case SET_ROOM_LOCKED:
            return {
                ...state,
                locked: action.locked
            };

        case CONFERENCE_LEFT:
            return {
                locked: false,
                password: null
            };
    }

    return state;
});

StateListenerRegistry.register(state => state['features/room-lock'].password, 
    (password, { getState }, oldPassword) => {
        if (getCurrentConference(getState())) {
            
            if (password !== oldPassword) {
                const aToken = getUserAccessToken(getState());
                const { uuid: meeting_uuid } = getRoomOption(getState());
                if (aToken && meeting_uuid) APP.API.setPropertyRoom({ data: { password }, aToken, meeting_uuid });
            }
        }        
    }
);