import { throttle } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { MdArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { CommonNoteLayout } from "../../base/CommonNote";
import { getUserAgentType } from "../../base/util";
import { VodLayout } from "../../base/vod";
import { isChatOpen } from "../../chat";
import { GroupDualLayout } from "../../groups";
import { getLocalIDMember } from "../../member";
import { isParticipantOpen } from "../../participant-right";
import { ShareDocumentLayout } from "../../share-document";
import { setExpand, setIsMobile, setLayoutContentStyle } from "../actions";
import { HORIZONTAL_MODE, VIDEO_MODE_LIST, layoutType, videoLayoutMode } from "../constant";
import { getCurrentMode, getDualMonitor, getExpand, getFilmStyle } from "../functions";
import DualLayout from "./DualLayout";
import MemberScreen from "./MemberScreen";
import { MembersLayout, PinsLayout, SeminarLayout, VoiceLayout } from "./MembersLayout";
import MobileScreen from "./MobileScreen";
import ScreenScreen from "./ScreenScreen";
import { LayoutContainerStyled, ShareLayoutStyled } from "./styles";

export default React.memo(function LayoutContainer({
    screenType
}) {
    const dispatch = useDispatch();
    const layoutContent = useRef();

    const expand = useSelector(state => getExpand(state));
    const isDualMonitor = useSelector(state => getDualMonitor(state));
    const rightOpen = useSelector(state => isChatOpen(state) || isParticipantOpen(state));
    const mode = useSelector(state => getCurrentMode(state));
    const localID = useSelector(state => getLocalIDMember(state));
    
    const [ isMobile, setMobile ] = useState(false);
    const [ isLeft, setIsLeft ] = useState(true);
    const [ layoutStyle, setLayoutStyle ] = useState({ width: 0, height: 0 });

    const handlerExpand = useCallback(() => {
        dispatch(setExpand(!expand));
    }, [expand]);

    const setLayout = throttle(() => {
        const element = layoutContent?.current;

        if (element) {
            const { width, height } = element.getBoundingClientRect();
            dispatch(setLayoutContentStyle(width, height));
            setLayoutStyle({ width, height });

            if (getUserAgentType() === layoutType.mobile || width <= 450) {
                setMobile(true);
            } else {
                setMobile(false);
            }
        }
    }, 500);

    useEffect(() => {
        setLayout();
        window.addEventListener("resize", setLayout);

        return () => {
            window.removeEventListener("resize", setLayout);
        }
    }, [layoutContent?.current]);

    useEffect(() => {
        setLayout();
    }, [rightOpen, isDualMonitor]);

    useEffect(() => {
        dispatch(setIsMobile(isMobile));
    }, [isMobile]);

    const renderShareModeScreen = (mode) => {
        switch (mode) {
            case videoLayoutMode.document:
            case videoLayoutMode.white:
                return (
                    <ShareDocumentLayout mode={mode}
                        mobile={isDualMonitor ? true : isMobile}
                        expand={expand}
                        isLeft={isLeft}
                        setIsLeft={setIsLeft}
                    />
                )

            case videoLayoutMode.note:
                return (
                    <CommonNoteLayout
                        mobile={isDualMonitor ? true : isMobile}
                        expand={expand}
                        isLeft={isLeft}
                        setIsLeft={setIsLeft}
                        style={{flex: 1, width:"100%", height: "100%", border: "none"}} 
                    /> 
                );
            
            case videoLayoutMode.vod:
                return (
                    <VodLayout mode={mode}
                        mobile={isDualMonitor ? true : isMobile}
                        expand={expand}
                        isLeft={isLeft}
                        setIsLeft={setIsLeft}
                    /> 
                )
        }
    }

    const renderLayout = () => {
        switch (mode) {
            case videoLayoutMode.grid:                
                return (
                    <div className="gridFlex">
                        <MembersLayout className="gridFlex" noShowMember={true}
                            mode={mode} outerSize={layoutStyle}
                        />
                    </div>
                )                    

            case videoLayoutMode.pin:
                return (
                    <>
                        <div className="horizontal layout_center">
                            <MembersLayout noShowMember={true}
                                mode={mode} maxStyle={{ width: 172, height: 98 }}
                            />
                        </div>
                        <div className="mainFlex" >
                            <PinsLayout mode={mode} noPaging={true}
                                outerSize={{ 
                                    width: layoutStyle.width, height: layoutStyle.height - 98 
                                }} />
                        </div>
                    </>
                )

            case videoLayoutMode.voice:
                const outerSize = getFilmStyle(APP.store.getState, 2, { 
                    width: layoutStyle.width, height: layoutStyle.height - 98 
                }, true);

                return (
                    <>  
                        <div className="horizontal layout_center">
                            <MembersLayout className="gridFlex" noShowMember={true}
                                mode={mode} maxStyle={{ width: 172, height: 98 }}
                            />
                        </div>
                        
                        <div className="voice">
                            <div className="gridFlex" >
                                <PinsLayout noPaging={true}
                                    mode={mode} 
                                    outerSize={outerSize} />
                            </div>
                            <div className="gridFlex" >
                                <VoiceLayout outerSize={outerSize} noPaging={true} /> 
                            </div>
                        </div>
                    </>
                )

            case videoLayoutMode.seminar:
                return (
                    <PinsLayout className="gridFlex"
                        mode={mode} noPaging={true}
                        outerSize={layoutStyle} />
                )

            case videoLayoutMode.screen:
                return <ScreenScreen mode={mode} isFull={false} />

            case videoLayoutMode.white:
            case videoLayoutMode.document:
            case videoLayoutMode.note:
            case videoLayoutMode.vod:
                return (
                    <div className="share_content">
                        <ShareLayoutStyled className="share_video" expand={expand} mode={mode}>
                            { isLeft && <span className="document_expand" onClick={handlerExpand}>
                                <MdArrowRight size={32} color="#76acfb" />
                            </span> }
                            { expand ? 
                                <div className="horizontal">
                                    <PinsLayout 
                                        mode={mode} 
                                        noShowMember={true}
                                        maxStyle={{ width: 172, height: 98 }} />

                                    <MembersLayout noShowMember={true}
                                        mode={mode} maxStyle={{ width: 172, height: 98 }}
                                    />
                                </div> : <SeminarLayout className="pinFlex" noPaging={true}
                                    maxStyle={{ width: 172, height: 98 }} /> }                            
                        </ShareLayoutStyled>
                        { renderShareModeScreen(mode) }
                    </div>
                );
        }
    }

    const renderMobile = () => {
        switch(mode) {
            case videoLayoutMode.white:
            case videoLayoutMode.document:
            case videoLayoutMode.note:
            case videoLayoutMode.vod:
                return renderShareModeScreen(mode);

            case videoLayoutMode.screen:
                return <ScreenScreen mode={mode} isFull={true} />

            default:
                return (
                   <MobileScreen layoutStyle={layoutStyle} />
                )
        }
    }

    const renderDual = () => {
        switch (mode) {
            case videoLayoutMode.grid:
                return (
                    <div className="gridFlex">
                        <MemberScreen type="pin" mode={mode} 
                        members={[ localID ]} noPaging={true}
                        outerSize={layoutStyle} />
                    </div>
                );

            case videoLayoutMode.pin:
                return (
                    <div className="gridFlex">
                        <PinsLayout
                            mode={mode} noPaging={true}
                            outerSize={layoutStyle} 
                        />
                    </div>
                );
            
            case videoLayoutMode.voice:
                const outerSize = getFilmStyle(APP.store.getState, 1, layoutStyle, true);
                return (
                    <div className="voice">
                        <div className="gridFlex">
                            <PinsLayout
                                mode={mode} noPaging={true}
                                outerSize={outerSize} />
                        </div>
                        <div className="gridFlex">
                            <VoiceLayout outerSize={outerSize} /> 
                        </div>
                    </div>
                )
            
            case videoLayoutMode.seminar:
                return (
                    <div className="gridFlex">
                        <SeminarLayout
                            mode={mode} noPaging={true}
                            outerSize={layoutStyle} />
                    </div>
                )

            case videoLayoutMode.screen:
                return <ScreenScreen mode={mode} isFull={true} isDualMonitor={true} />

            case videoLayoutMode.white:
            case videoLayoutMode.document:
            case videoLayoutMode.note:
            case videoLayoutMode.vod:
                return renderShareModeScreen(mode);
        }
    }

    return (
        <LayoutContainerStyled ref={layoutContent} id="canvas_container" className="layout_wrapper"
            isGrid={VIDEO_MODE_LIST.includes(mode)} horizontal={HORIZONTAL_MODE.includes(mode)}
            isHide={screenType.includes(layoutType.mobile) && rightOpen}
            >
            { isDualMonitor ? 
            <>
               { renderDual() }
               <DualLayout mode={mode} />
            </> : isMobile ? renderMobile() : renderLayout() }

            <GroupDualLayout isMobile={isMobile} />
        </LayoutContainerStyled>
    )
});
