// import JitsiMeetJS from ".";
// @flow
const JitsiConferenceErrors = JitsiMeetJS.errors.conference;
const JitsiConnectionErrors = JitsiMeetJS.errors.connection;

/**
 * Creates a {@link JitsiLocalTrack} model from the given device id.
 */
export function createLocalTrack(type, deviceId) {
    return (
        JitsiMeetJS.createLocalTracks({
            cameraDeviceId: deviceId,
            devices: [ type ],

            // eslint-disable-next-line camelcase
            firefox_fake_device:
                window.config && window.config.firefox_fake_device,
            micDeviceId: deviceId
        }).then(([ jitsiLocalTrack ]) => jitsiLocalTrack));
}

export function createLocalTracks(devices: Array,  timeout: ?number, additionalOptions: ?Object) {
    return (
        JitsiMeetJS.createLocalTracks({
            cameraDeviceId: null,
            devices,
            firefox_fake_device:
                window.config && window.config.firefox_fake_device,
            micDeviceId: null,
            timeout,
            ...additionalOptions
        })
        .then((jitsiLocalTracks) => jitsiLocalTracks));
}

/**
 * Determines whether a specific {@link JitsiConferenceErrors} instance
 * indicates a fatal {@link JitsiConference} error.
 */
export function isFatalJitsiConferenceError(error) {
    if (typeof error !== 'string') {
        error = error.name;
    }

    return (
        error === JitsiConferenceErrors.FOCUS_DISCONNECTED
            || error === JitsiConferenceErrors.FOCUS_LEFT
            || error === JitsiConferenceErrors.ICE_FAILED
            || error === JitsiConferenceErrors.OFFER_ANSWER_FAILED
            || error === JitsiConferenceErrors.VIDEOBRIDGE_NOT_AVAILABLE);
}


export function isFatalJitsiConnectionError(error) {
    if (typeof error !== 'string') {
        error = error.name; 
    }

    return (
        error === JitsiConnectionErrors.CONNECTION_DROPPED_ERROR
            || error === JitsiConnectionErrors.OTHER_ERROR
            || error === JitsiConnectionErrors.SERVER_ERROR);
}

export async function loadConfig(url) { 
    return window.config;
}