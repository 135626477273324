import { CONFERENCE_JOINED, CONFERENCE_LEFT } from '../base/conference';
import { getLocalUserUUID, setPrecent } from '../base/localuser';
import { MEDIA_TYPE } from '../base/media';
import { StateListenerRegistry } from '../base/redux';
import MiddlewareRegistry from '../base/redux/MiddlewareRegistry';
import { getTracksByParticipantId } from '../base/tracks';
import { getLocalIDMember } from '../member';
import { ADD_LOCAL_MEMBER, SET_BRIDGE_ID } from '../member/actionType';

import statsEmitter from './statsEmitter';


let bridge_session_id;
/**
 * Implements the middleware of the feature connection-indicator.
 *
 * @param {Store} store - The redux store.
 * @returns {Function}
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
MiddlewareRegistry.register(store => next => action => {
    const _onStatsUpdated = (option) => {
        store.dispatch(setPrecent(option.connectionQuality));
    }

    switch (action.type) {
        case CONFERENCE_JOINED: {
            if (action.serverType === 'jitsi') {
                statsEmitter.startListeningForStats(action.conference.conference);
            }
            
            break;
        }

        case SET_BRIDGE_ID:
            const user_id = getLocalUserUUID(store.getState);
            if (action.user_uuid === user_id) {
                statsEmitter.subscribeToClientStats(action.bridge_session_id, _onStatsUpdated);
                bridge_session_id = action.bridge_session_id;
            }           

            break;

        case CONFERENCE_LEFT:
            bridge_session_id && statsEmitter.unsubscribeToClientStats(bridge_session_id, _onStatsUpdated);

            break;
    }

    return next(action);
});

