// @flow

import { hideNotification } from '../../notifications';
import {
    CAMERA_FACING_MODE,
    MEDIA_TYPE,
    SET_AUDIO_MUTED,
    SET_CAMERA_FACING_MODE,
    SET_VIDEO_MUTED,
    TOGGLE_CAMERA_FACING_MODE,
    setVideoMuted,
    toggleCameraFacingMode
} from '../media';
import { MiddlewareRegistry } from '../redux';

import { isPrejoinPageVisible } from '../conference';
import {
    TRACK_NO_DATA_FROM_SOURCE,
    TRACK_REMOVED,
    TRACK_UPDATED
} from './actionTypes';
import {
    showNoDataFromSourceVideoError,
    trackNoDataFromSourceNotificationInfoChanged
} from './actions';
import {
    getLocalTrack,
    isUserInteractionRequiredForUnmute,
    setTrackMuted
} from './functions';

let volume = 0;
MiddlewareRegistry.register(store => next => action => {
    const dispatch = store.dispatch;
    const state = store.getState();
    switch (action?.type) {
        case TRACK_NO_DATA_FROM_SOURCE: {
            const result = next(action);

            _handleNoDataFromSourceErrors(store, action);

            return result;
        }
        case TRACK_REMOVED: {
            _removeNoDataFromSourceNotification(store, action);
            break;
        }

        case SET_AUDIO_MUTED:
            if (!action.muted && isUserInteractionRequiredForUnmute(store.getState())) {
                return;
            }
            
            _setMuted(store, action, MEDIA_TYPE.AUDIO);
            break;

        case SET_VIDEO_MUTED:
            if (!action.muted
                    && isUserInteractionRequiredForUnmute(store.getState())) {
                return;
            }
            
            _setMuted(store, action, MEDIA_TYPE.VIDEO);
            break;

        case SET_CAMERA_FACING_MODE: {
            const localTrack = _getLocalTrack(store, MEDIA_TYPE.VIDEO);
            let jitsiTrack;

            if (localTrack
                    && (jitsiTrack = localTrack.jitsiTrack)
                    && jitsiTrack.getCameraFacingMode()
                        !== action.cameraFacingMode) {
                store.dispatch(toggleCameraFacingMode());
            }
            break;
        }

        case TOGGLE_CAMERA_FACING_MODE: {
            const localTrack = _getLocalTrack(store, MEDIA_TYPE.VIDEO);
            let jitsiTrack;

            if (localTrack && (jitsiTrack = localTrack.jitsiTrack)) {
                jitsiTrack._switchCamera();

                const mirror
                    = jitsiTrack.getCameraFacingMode() === CAMERA_FACING_MODE.USER;

                store.dispatch({
                    type: TRACK_UPDATED,
                    track: {
                        track,
                        mirror
                    }
                });
            }
            break;
        }

        case TRACK_UPDATED:
            if (typeof APP !== 'undefined') {
                const result = next(action);

                if (isPrejoinPageVisible(store.getState())) {
                    return result;
                }
                const { track } = action.track;
                const muted = track.isMuted();
                
                const isVideoTrack = track.type !== MEDIA_TYPE.AUDIO;

                if (isVideoTrack) {
                    if (track.isLocal()) {
                        store.dispatch(setVideoMuted(muted));
                    }
                } 

                return result;
            }
        }
        
        return next(action);
});

function _handleNoDataFromSourceErrors(store, action) {
    const { getState, dispatch } = store;

    const track = getState()['features/base/tracks'] === action.track;

    if (!track || !track.local) {
        return;
    }

    const { track : localTrack } = track;

    if (track.mediaType === MEDIA_TYPE.AUDIO && track.isReceivingData) {
        _removeNoDataFromSourceNotification(store, action.track);
    }

    if (track.mediaType === MEDIA_TYPE.VIDEO) {
        const { noDataFromSourceNotificationInfo = {} } = track;

        if (track.isReceivingData) {
            if (noDataFromSourceNotificationInfo.timeout) {
                clearTimeout(noDataFromSourceNotificationInfo.timeout);
                dispatch(trackNoDataFromSourceNotificationInfoChanged(localTrack, undefined));
            }

            // try to remove the notification if there is one.
            _removeNoDataFromSourceNotification(store, action.track);
        } else {
            if (noDataFromSourceNotificationInfo.timeout) {
                return;
            }

            const timeout = setTimeout(() => dispatch(showNoDataFromSourceVideoError(jitsiTrack)), 5000);

            dispatch(trackNoDataFromSourceNotificationInfoChanged(localTrack, { timeout }));
        }
    }
}

function _getLocalTrack(
        { getState },
        mediaType,
        includePending) {
    return (
        getLocalTrack(
            getState()['features/base/tracks'],
            mediaType,
            includePending));
}

function _removeNoDataFromSourceNotification({ getState, dispatch }, tracks) {
    const t = getState()['features/base/tracks'].find(t => t === tracks.track);
    const { track, noDataFromSourceNotificationInfo = {} } = t || {};

    if (noDataFromSourceNotificationInfo && noDataFromSourceNotificationInfo.uid) {
        dispatch(hideNotification(noDataFromSourceNotificationInfo.uid));
        dispatch(trackNoDataFromSourceNotificationInfoChanged(track, undefined));
    }
}

/**
 * 특정 미디어 유형의 로컬 트랙을 음소거 또는 음소거 해제합니다.
 */
function _setMuted(store, { ensureTrack, authority, muted }, mediaType) {
    const localTrack = _getLocalTrack(store, mediaType, true);
    
    if (localTrack) {
        // const isAudioOnly = authority === VIDEO_MUTISM_AUTHORITY.AUDIO_ONLY;
        localTrack && setTrackMuted(localTrack, muted);
    }
}
