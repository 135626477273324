// @flow

import { SET_AUDIO_ONLY } from '../audio-only';
import { MiddlewareRegistry } from '../redux';
import { setTrackMuted, TRACK_ADDED } from '../tracks';

import { setVideoMuted } from './actions';
import {
    MEDIA_TYPE,
    VIDEO_MUTISM_AUTHORITY
} from './constants';
import {
    _AUDIO_INITIAL_MEDIA_STATE,
    _VIDEO_INITIAL_MEDIA_STATE
} from './reducer';

MiddlewareRegistry.register(store => next => action => {
    switch (action?.type) {
        case SET_AUDIO_ONLY:
            return _setAudioOnly(store, next, action);

        case TRACK_ADDED: {
            const result = next(action);
            const { track } = action;

            track.local && track.videoType !== 'desktop' && _syncTrackMutedState(store, track);

            return result;
        }
    }

    return next(action);
});


function _setAudioOnly({ dispatch }, next, action) {
    const { audioOnly, ensureVideoTrack } = action;

    dispatch(setVideoMuted(audioOnly, MEDIA_TYPE.VIDEO, VIDEO_MUTISM_AUTHORITY.AUDIO_ONLY, ensureVideoTrack));

    if (navigator.product !== 'ReactNative') {
        dispatch(setVideoMuted(audioOnly, MEDIA_TYPE.PRESENTER, VIDEO_MUTISM_AUTHORITY.AUDIO_ONLY, ensureVideoTrack));
    }

    return next(action);
}

function _syncTrackMutedState({ getState }, track) {
    const state = getState()['features/base/media'];
    const mediaType = track.mediaType === MEDIA_TYPE.PRESENTER ? MEDIA_TYPE.VIDEO : track.mediaType;
    const muted = Boolean(state[mediaType].muted);

    if (track.muted !== muted) {
        track.muted = muted;
        setTrackMuted(track, muted);
    }
}
