import { toState } from "../base/redux";
import { getGroupMemberByUserId, getLocalIDMember, setGroupMembers } from "../member";
import { removeGroups, setGroup } from "./actions";

/**
 * 전체 그룹 가져오기 
 * @returns 
 */
export async function getGroups(dispatch, stateful) {
    const state = toState(stateful);
    const response = await APP.API.getGroupList();

    let members = new Map();
    if (response.complete) {
        const groups = response.message;

        const groupIds = _.cloneDeep(getGroupIdsList(state));
        Object.entries(groups).map(([group_id, group]) => {
            if (group_id === "lobby") return;

            groupIds.delete(group_id);
            dispatch(setGroup(group_id, group));
            group.members && group.members.map(g => {
                if (g.type === "") members.set(g.user_id, { ...g, group_id, group_name: group.name })
            });
        });

        dispatch(setGroupMembers(members));

        groupIds && groupIds.size > 0 && dispatch(removeGroups([...groupIds]));
    }
}

/**
 * 그룹 아이디 리스트 가져오기
 * @param {*} stateful 
 * @returns 
 */
export function getGroupIdsList(stateful) {
    const state = toState(stateful);

    return state["features/groups"].groupIds;
}

export function getGroupIdsArray(stateful) {
    const state = toState(stateful);

    return [...state["features/groups"].groupIds];
}

export function getStartGroupList(stateful) {
    const state = toState(stateful);

    const start_groups = state["features/groups"].start_groups;

    let groups = [];

    [...start_groups].map(uuid => {
        const group = getGroupById(stateful, uuid);
        groups.push({ ...group, uuid });
    });

    return groups;
}

/**
 * 
 * @param {*} stateful 
 * @returns 
 */
export function getGroupList(stateful) {
    const state = toState(stateful);
    const groupIds = getGroupIdsList(stateful);

    let list = [];
    const groups = state["features/groups"].groups;
    groupIds && [...groupIds].map(group_id => {
        const g = groups.get(group_id);

        g && list.push({ uuid: group_id, name: g.name })
    });

    return list;
}

/**
 * 그룹이름 존재 여부 확인
 * @param {} stateful 
 * @param {*} name 
 * @returns 
 */
export function isHaveGroupByName(stateful, name) {
    const state = toState(stateful);

    const groups = state["features/groups"].groups;

    return Boolean([...groups.values()].find(g => g.name === name));
}

/**
 * 그룹 아이디로 그룹 정보 가져오기
 * @param {} stateful 
 * @param {*} group_id 
 * @returns 
 */
export function getGroupById(stateful, group_id) {
    const state = toState(stateful);

    const groups = state["features/groups"].groups;

    return groups.get(group_id);
}

/**
 * 로컬 사용자 그룹 여부 
 * @param {Function} stateful 
 * @returns 
 */
export function startLocalGroup(stateful) {
    const state = toState(stateful);

    const local = getLocalIDMember(stateful);
    const member_group = getGroupMemberByUserId(stateful, local);

    if (member_group && member_group.group_id) {
        const start_groups = state["features/groups"].start_groups;
        const isStart = start_groups.has(member_group.group_id);

        return { start: isStart, group_id: member_group.group_id, role: member_group.role_name  };
    }

    return { start: false, group_id: null, member_group};
}

/**
 * 그룹 듀얼 모니터 open 
 * @param {Function} stateful 
 * @returns 
 */
export function getGroupDualMonitor(stateful) {
    const state = toState(stateful);
    return state["features/groups"].isDualMonitor;
}

/**
 * 그룹 듀얼 모니터 open 
 * @param {Function} stateful 
 * @returns 
 */
export function getStartGroup(stateful, isStatus = false) {
    const state = toState(stateful);

    if (isStatus) {
        const status = getGroupStatus(stateful);
        if (!status) return false;
    }
    return state["features/groups"].start_groups;
}


/**
 * 그룹 상태 가져오기
 * @param {Function} stateful 
 * @returns 
 */
export function getGroupStatus(stateful) {
    const state = toState(stateful);

    return state["features/groups"].status;
}

/**
 * 
 */
export function getStartGroupById(stateful, group_id) {
    const start_groups = getStartGroup(stateful);

    return start_groups.has(group_id);
}

