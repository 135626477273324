import styled from "styled-components";

export const ProgressBarStyled = styled.div`    
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    margin: 0;

    .value {
        font-size: 14px;
        color: #664AFF;
        font-weight: 600;
        margin: 0 8px;
    }

    input[type=range] { 
        -webkit-appearance: none; 
        overflow: hidden;
        width: 100%; 
        height: 10px;
        background: transparent; 
        cursor: pointer;
        background: #e5e4e3;
        border-radius: 0; /* iOS */
    }

    input {
        -webkit-appearance: none;
        width: 170px;
        height: 3px;
        background: #ddd;
        border-radius: 5px;
        outline: none;
        border: none;
        z-index: 2222;

        &::-webkit-slider-thumb{
            -webkit-appearance: none;
            width: 15px;
            height: 15px;
            background: red;
            border-radius: 50%;
            background: #664AFF;
            border: 1px solid #664AFF;
            cursor: pointer;
        }

        &::-moz-range-thumb{
            -webkit-appearance: none;
            width: 20px;
            height: 20px;
            background: red;
            border-radius: 50%;
            background: #664AFF;
            border: 1px solid #664AFF;
            cursor: pointer;
        }

        &::-moz-range-progress{
            background: #664AFF; //this progress background is only shown on Firefox
        }
    }
    label {
        position: absolute; top: 12px; color: #123;
    }
`;

export const Progressbar = styled.div`
    input[type=range] {
        -webkit-appearance: none; 
        width: 100%; 
        height: 6px;
        background: #d5d4d3; 
        cursor: pointer;
        border-radius: 0; /* iOS */
        transition: background 450ms ease-in;
    }

    input[type=range]:focus {
        outline: none; 
    }

    input[type=range]:not(.audio_level)::-webkit-slider-thumb{
        -webkit-appearance: none;
        width: 12px;
        height: 12px;
        background: #fff;
        border: 1px solid dodgerblue;
        border-radius:50%;
        cursor: pointer;
    }

    input[type=range].audio_level::-webkit-slider-thumb { display: none; }
    input[type=range].audio_level::-moz-range-thumb { display: none; }

    input[type=range]:not(.audio_level, .mute_vertical_range)::-moz-range-thumb{
        -webkit-appearance: none;
        width:10px;
        height:10px;
        background: #fff;
        border: 1px solid dodgerblue;
        border-radius:50%;
        cursor: pointer;
    }
`;