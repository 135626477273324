// // @flow

// export const SET_SCREEN_AUDIO_SHARE_STATE = 'SET_SCREEN_AUDIO_SHARE_STATE';

// export const SET_SCREEN_VIDEO_SHARE_STATE = 'SET_SCREEN_VIDEO_SHARE_STATE';

/**
 * 현재 녹화중인 사용자 아이디 
 * @param {string} userId 
 * @returns 
 */
export const SET_SHARE_SCREEN_ID = 'SET_SHARE_SCREEN_ID';

/**
 * 듀얼 모니터 on/off
 * @param {*} isDual 
 * @returns 
 */
export const SET_SCREEN_MONITOR = 'SET_SCREEN_MONITOR';