import React from "react";
import { getPinnedLayoutMembers, getShowMembers, getVoiceLayoutMember } from "../functions";
import { shallowEqual, useSelector } from "react-redux";
import MemberScreen from "./MemberScreen";
import { getLocalIDMember } from "../../member";
import { videoLayoutMode } from "../constant";
import { getScreenUserID } from "../../screen-share";

export const MembersLayout = React.memo(function MembersLayout({
    className, mode, noShowMember, maxStyle, outerSize, vertical, noPaging
}) {
    const members = useSelector(state => getShowMembers(state), shallowEqual);

    return (
        <MemberScreen className={className} 
            type="remote" mode={mode} 
            members={members} noShowMember={noShowMember} noPaging={noPaging}
            maxStyle={maxStyle}
            outerSize={outerSize} vertical={vertical} />
    )
});

export const PinsLayout = React.memo(function PinsLayout({
    className, mode, noShowMember, maxStyle, outerSize, vertical
}) {
    const pinMembers = useSelector(state => getPinnedLayoutMembers(state, mode), shallowEqual);

    return (
        <MemberScreen className={className} 
            type="pin" mode={mode} 
            members={pinMembers} noShowMember={noShowMember} noPaging={true}
            maxStyle={maxStyle}
            outerSize={outerSize} vertical={vertical} />
    )
});

export const VoiceLayout = React.memo(function VoiceLayout({
    className, mode, noShowMember, maxStyle, outerSize
}) {
    const voiceMember = useSelector(state => getVoiceLayoutMember(state), shallowEqual);

    return (
        <MemberScreen className={className} 
            type="voice" mode={mode} 
            members={voiceMember ? [voiceMember] : []}
            noShowMember={noShowMember} noPaging={true}
            maxStyle={maxStyle}
            outerSize={outerSize} />
    )
});

export const ScreenUserLayout = React.memo(function ScreenUserLayout({
    className, mode, noShowMember, maxStyle, outerSize
}) {
    const screenId = useSelector(state => getScreenUserID(state), shallowEqual);

    return (
        <MemberScreen className={className} 
            type="screen" mode={mode} 
            members={screenId ? [screenId] : []}
            noShowMember={noShowMember} noPaging={true}
            maxStyle={maxStyle}
            outerSize={outerSize} />
    )
});

export const SeminarLayout = React.memo(function VoiceLayout({
    className, noShowMember, maxStyle, outerSize
}) {
    const seminarMembers = useSelector(state => getPinnedLayoutMembers(state, videoLayoutMode.seminar, true), shallowEqual);

    return (
        <MemberScreen className={className} 
            type="seminar" mode={videoLayoutMode.seminar} 
            members={seminarMembers}
            noShowMember={noShowMember} noPaging={true}
            maxStyle={maxStyle}
            outerSize={outerSize} />
    )
});

export const LocalLayout = React.memo(function LocalLayout({
    className, mode, noShowMember, maxStyle, outerSize, vertical
}) {
    const local = useSelector(state => getLocalIDMember(state), shallowEqual);

    return (
        <MemberScreen className={className} 
            type="pin" mode={mode} 
            members={local ? [local]: []} noShowMember={noShowMember} noPaging={true}
            maxStyle={maxStyle}
            outerSize={outerSize} vertical={vertical} />
    )
});