import { CLEAR_ROOM } from "../base/conference";
import { ReducerRegistry } from "../base/redux";
import { videoLayoutMode } from "../video-layout";
import { ADD_SHARE_ITEM, ADD_SHARE_LIST, DELETE_SHARE_ITEM, SET_FOCUS_SHARE_ITEM, SET_VISIBLE_DOCUMENT_LIST } from "./actionTypes";
import { DEFAULT_DOCUMENT, DEFAULT_INDEX } from './constants';

const DEFAULT_STATE = {
    whiteboard: new Set(), 
    document: new Set(),
    vod: new Set(),
    focus: {
        whiteboard: { uuid: DEFAULT_DOCUMENT, index: DEFAULT_INDEX },
        document: { uuid: undefined, index: undefined },
        vod: { uuid: undefined, index: undefined }
    },
    visibleList: new Set()
};

ReducerRegistry.register("features/share-document", (state = DEFAULT_STATE, action) => {
    switch (action.type) { 
        case CLEAR_ROOM:
            return {
                ...DEFAULT_STATE
            }
        case SET_VISIBLE_DOCUMENT_LIST:
            state.visibleList = action.list;

            return {
                ...state
            }

        case ADD_SHARE_LIST:
            state.whiteboard = action.whiteboard_list;
            state.document = action.document_list;
            state.vod = action.vod_list;
            state.visibleList = action.visible_list;

            return {
                ...state
            }

        case ADD_SHARE_ITEM:
            if (state.whiteboard && action.data.config === videoLayoutMode.white) state.whiteboard.add(action.data.uuid);
            if (state.document && action.data.config === videoLayoutMode.document) state.document.add(action.data.uuid);
            if (state.vod && action.data.config === videoLayoutMode.vod) state.vod.add(action.data.uuid);

            if (action.prevDocument) state.visibleList.delete(action.prevDocument);

            state.visibleList.add(action.data);

            return {
                ...state
            }

        case DELETE_SHARE_ITEM:
            state.whiteboard.delete(action.uuid);
            state.document.delete(action.uuid);

            return {
                ...state
            }

        case SET_FOCUS_SHARE_ITEM:
            state.focus[action.mode] = { uuid: action.uuid, index: action.index };

            return {
                ...state
            }
    }
    
    return state;
});