// @flow

import React, { PureComponent } from 'react';
import Emoji from 'react-emoji-render';

import { smileys } from '../smileys';

class SmileysPanel extends PureComponent {
    constructor(props) {
        super(props);

        // Bind event handler so it is only bound once for every instance.
        this._onClick = this._onClick.bind(this);
        this._onKeyPress = this._onKeyPress.bind(this);
        this._onEscKey = this._onEscKey.bind(this);
    }

    _onEscKey(e) {
        if (e.key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            this.props.onSmileySelect();
        }
    }

    _onKeyPress(e) {
        if (e.key === ' ') {
            e.preventDefault();
            this.props.onSmileySelect(e.target.id && smileys[e.target.id]);
        }
    }

    _onClick(e) {
        e.preventDefault();
        this.props.onSmileySelect(e.currentTarget.id && smileys[e.currentTarget.id]);
    }

    render() {
        const smileyItems = Object.keys(smileys).map(smileyKey => (
            <div
                className = 'smileyContainer'
                id = { smileyKey }
                key = { smileyKey }
                onClick = { this._onClick }
                onKeyDown = { this._onEscKey }
                onKeyPress = { this._onKeyPress }
                role = 'option'
                style={{display: "inline", float: "left", transform: "scale(1.5)"}}
                tabIndex = { 0 }>
                <Emoji
                    onlyEmojiClassName = 'smiley'
                    text = { smileys[smileyKey] } />
            </div>
        ));

        return (
            <div
                aria-orientation = 'horizontal'
                id = 'smileysContainer'
                onKeyDown = { this._onEscKey }
                role = 'listbox'
                tabIndex = { -1 }>
                { smileyItems }
            </div>
        );
    }
}

export default SmileysPanel;
