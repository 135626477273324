import { DEL_VOD_LIST, SET_FOCUS_VOD_UUID, SET_UPLOAD_FILE, SET_UPLOAD_URL, SET_VOD_LAYOUT_OPEN, SET_VOD_LIST } from "./actionTypes";

export function setUploadFile(file) {
    return {
        type: SET_UPLOAD_FILE,
        file
    }
}

export function setUploadUrl(url) {
    return {
        type: SET_UPLOAD_URL,
        url
    }
}

export function setVodLayoutOpen(open) {
    return {
        type: SET_VOD_LAYOUT_OPEN,
        open
    }
}

export function setFocusVod(uuid, isRemote = false) {
    return {
        type: SET_FOCUS_VOD_UUID,
        uuid, isRemote
    }
}

export function delVodList(uuid, isRemote = false) {
    return {
        type: DEL_VOD_LIST,
        uuid, isRemote
    }
}

export function setVodItem(list) {
    return {
        type: SET_VOD_LIST,
        list
    } ;
}

