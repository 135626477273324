import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getMemberRecordList } from "../../member";
import { IconButton } from "../../base/buttons";
import { RECORD_MODAL_ID } from "../../options";

export default function RecordListButton() {
    const start_rec = useSelector(state => Boolean(getMemberRecordList(state)), shallowEqual);

    if (!start_rec) return null;

    const onClick = () => {
        APP.UI.openModal(RECORD_MODAL_ID);
    }

    return (
        <IconButton clsName="rec" mode="recording" onClick={onClick} />
    )
}