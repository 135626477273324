import React, { useEffect, useState } from "react";
import { PARTICIPANT_STATUS, ROLE, getMemberList } from "../../member";
import { useSelector } from "react-redux";
import { getGroupById } from "../functions";
import { G_DELET_POP, G_MODIFY_POP, HOST_NAME, PARTICIPANT_ALL_COUNT, PARTICIPANT_BELONG_COUNT, statusGroup } from "../constants";
import { GroupListStyled } from "./styles";
import { IconButton } from "../../base/buttons";
import GroupTable from "./GroupTable";

export default function GroupItem({
    t, uuid, detail, setPopup, setPopItem, setFocusItem
}) {
    const group = useSelector(state => getGroupById(state, uuid));
    const list = useSelector(state => getMemberList(state, { 
        status: [PARTICIPANT_STATUS.OCCUPIDE], groupIds: [ uuid ], excludeGroup: false
    }, false ));

    const focusItem = () => {
        setFocusItem(uuid);
    }

    const onModify = () => {
        setPopup(G_MODIFY_POP);

        setPopItem({ uuid, name: group.name });
    }

    const onDelete = () => {
        setPopup(G_DELET_POP);

        setPopItem({ uuid, name: group.name });
    }

    const renderItem = () => {        
        const groupMember = group.members ? group.members.filter(i => i.type === "hidden" ? false : true) : [];
        const all = groupMember ? groupMember.length : 0;

        if (detail) {
            const belong = list ? list.length : 0;
            const host = list.find(i => i.groupRole === ROLE.HOST)?.nickname || "-";

            return (
                <GroupTable tableConfig={statusGroup} heigtAuto={true} contentList={[{
                    [PARTICIPANT_BELONG_COUNT]: belong,
                    [PARTICIPANT_ALL_COUNT]: all,
                    [HOST_NAME]: host
                }]} height={35}>
                   <h2><p className="overText">{ group.name } </p></h2>
                   <IconButton mode="more" name="more" size={24} clsName="more" onClick={focusItem} />
                </GroupTable>  
            )
        } else {
            return (
                <GroupListStyled>
                    <div className="name" onClick={focusItem}>
                        <span className="overText"> {group.name} </span>
                        <span className="count">({ t("common.person", { count: all }) })</span>
                    </div>
                    <IconButton clsName="icon" size={16} mode="pen" onClick={onModify} />
                    <IconButton clsName="icon" size={16} mode="delete" onClick={onDelete} />
                </GroupListStyled>
            )
        }
    }
    
    return (
        <div style={{ padding: "8px 0 0 8px"}}>
            { renderItem() }
        </div>
    )
    // return (
    //     <div style={{ padding: "8px 0 0 8px"}}>
    //         { detail ? 
    //             <GroupTable tableConfig={statusGroup} contentList={[ contentList ]} height={35}>
    //                 <h2><p className="overText">{ item.name } </p></h2>
    //                 <IconButton mode="more" name="more" size={24} clsName="more" onClick={focusItem} />
    //             </GroupTable>  
    //             :
                
    //             <GroupListStyled>
    //                 <div className="name" onClick={focusItem}>
    //                     <span className="overText"> {item.name} </span>
    //                     <span className="count">({ t("common.person", { count: contentList.all }) })</span>
    //                 </div>
    //                 <IconButton clsName="icon" size={16} mode="pen" onClick={onModify} />
    //                 <IconButton clsName="icon" size={16} mode="delete" onClick={onDelete} />
    //             </GroupListStyled>
    //         }
    //     </div>
    // )
}