import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { AbstractDialog } from "../../base/dialog";
import { getHostMembers } from "../../member";
import { GroupAlertStyled } from "./styles";
import { ClickButton } from "../../base/buttons";
import { isNumber } from "lodash";

export default function RandomGroup({
    t, setPopup, alertMessage
}) {
    const groupNumRef = useRef();
    const hostIds = useSelector(state => getHostMembers(state));

    const handlerClose = () => setPopup(null);
    
    const makeRandomGroup = async () => {
        if (alertMessage(null, "group.confrimRandom")) {
            if (groupNumRef && groupNumRef.current) {
                const group_number = parseInt(groupNumRef.current.value);
                if (groupNumRef.current.value.trim() === "") {
                    alertMessage("group.inputErrEssential");
                    groupNumRef.current.value = 2;
                    return;
                }
                if (isNumber(group_number) === false) {
                    alertMessage("group.onlyNum");
                    groupNumRef.current.value = 1;
                    return;
                }

                if (group_number > 50) {
                    alertMessage("group.maxFifty");
                    groupNumRef.current.value = 50;
                    return 
                }
                const { complete, message } = await APP.API.randomizeGroup({
                    group_number,  exception_ids: [...hostIds]
                });
    
                if (complete) handlerClose();
                else alertMessage(message);
            }
        }        
    }


    return (
        <AbstractDialog name="randomGroup" width={500} handlerClose={handlerClose}>
            <GroupAlertStyled>
                <h4 className="guid">{ t("group.randomSetGuid") } </h4>
                <p className="guid">
                    { t("group.randomDeleteGuid") }
                </p>

                <div className="random_group_table">
                    <div className="tr">
                        <span className="th">{ t("group.randomCount") }</span>
                        <span className="td"><input ref={groupNumRef} type="number" min={1} placeholder={ t("group.inputRandomCount") } /></span>
                    </div>
                </div>

                <div className="g_button">
                    <ClickButton size={16} text={t("common.previous")} onClick={handlerClose} />
                    <ClickButton size={16} text={t("common.okay")} onClick={makeRandomGroup} />
                </div>
            </GroupAlertStyled>
        </AbstractDialog>
    )
}