import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Group, Rect, Text, Transformer } from "react-konva";
import { shallowEqual } from "react-redux";

const initShapeProps = {
    width: 0, height: 0, x: 0, y: 0
};

export default function TextCanvas({
    canvasTool, points, ratio, drawText, scale, stageWidth, stageHeight, setFocusTextarea,
    selected, handlerChangeCanvasPoint, handlerDeleteLine,
    handlerSelectedLine
}) {
    const { t } = useTranslation();
    const shapeRef = useRef();
    const trRef = useRef();
    const [shapeProps, setShapeProps] = useState(initShapeProps);

    const { thickness, color, opacity, tool } = canvasTool;

    useEffect(() => {
        if (selected) {
            if (trRef && trRef.current && shapeRef && shapeRef.current) {
                trRef.current.nodes([shapeRef.current]);
                trRef.current.getLayer().batchDraw();
            }
        }       
    }, [selected]);

    useEffect(() => {
        let [x, y] = points;

        setShapeProps({
            width: canvasTool.figureWidth,
            height: canvasTool.figureHeight,
            x,
            y,
            text: canvasTool.text
        });
    }, [points, canvasTool], shallowEqual);

    const handlerUpdatePoint = (option) => {
        const x = option?.x || shapeProps.x;
        const y = option?.y || shapeProps.y;

        handlerChangeCanvasPoint([ x, y ], {
            ...canvasTool,
            ...option
        });
    }

    const onDblClick = () => {
        setFocusTextarea(true);
        const textNode = shapeRef.current;
        const tr = trRef.current;
        textNode.hide();
        tr && tr.hide();

        var textPosition = textNode.absolutePosition();
        var areaPosition = {
            x: textPosition.x,
            y: textPosition.y
        };

        var rotation;
        var textarea = document.createElement('textarea');
        document.getElementById("canvas_wrapper").appendChild(textarea);

        textarea.value = shapeProps.text || '';
        textarea.style.position = 'absolute';
        textarea.style.top = areaPosition.y + 'px';
        textarea.style.left = areaPosition.x + 'px';
        textarea.style.width = shapeProps.width + 'px';
        textarea.style.height =  shapeProps.height + 'px';
        textarea.style.maxHeight = Math.max(stageHeight - shapeProps.y, textNode.height());
        textarea.style.fontSize = textNode.fontSize() + 'px';
        textarea.style.lineHeight = textNode.lineHeight();
        textarea.style.fontFamily = textNode.fontFamily();
        textarea.style.textAlign = textNode.align();
        textarea.style.color = textNode.fill();
        textarea.style.maxWidth = Math.max(stageWidth - shapeProps.x, 0) + 'px';
        textarea.style.maxHeight = Math.max(stageHeight - shapeProps.y, 0) + 'px';

        rotation = textNode.rotation();
        var transform = '';
        if (rotation) {
            transform += 'rotateZ(' + rotation + 'deg)';
        }

        var px = 0;
        var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if (isFirefox) {
            
            px += 2 + Math.round(textNode.fontSize() / 20);
        }
        transform += 'translateY(-' + px + 'px)';
        textarea.style.transform = transform;

        textarea.focus();

        function removeTextarea() {
            setFocusTextarea(false);
            textarea.parentNode.removeChild(textarea);
            window.removeEventListener('click', handleOutsideClick);
            textNode.show();
            tr && tr.show();
            try {
                tr && tr?.forceUpdate();
            } catch {

            }            
        }

        function setTextareaWidth(newWidth) {
            if (!newWidth) {
                newWidth = textNode.placeholder.length * textNode.fontSize();
            }

            var isSafari = /^((?!chrome|android).)*safari/i.test(
                navigator.userAgent
            );
            var isFirefox =
                navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
            if (isSafari || isFirefox) {
                newWidth = Math.ceil(newWidth);
            }

            var isEdge =
                document.documentMode || /Edge/.test(navigator.userAgent);
            if (isEdge) {
                newWidth += 1;
            }
        
            textarea.style.width = Math.min(Math.max(stageWidth - shapeProps.y, 0), newWidth) + 'px';
        }

        textarea.addEventListener('keydown', function (e) {
            // hide on enter
            // but don't hide on shift + enter
            if (e.keyCode === 13 && !e.shiftKey) {
                textNode.text(textarea.value);

                handlerUpdatePoint({ text: textarea.value, figureHeight: textarea.clientHeight, figureWidth: textarea.clientWidth  });
                removeTextarea();
            } else if (e.keyCode === 27) {
                removeTextarea();
            } else {
                // scale = textNode.getAbsoluteScale().x;
                // setTextareaWidth(textNode.width());
                textarea.style.height = '1px';
                textarea.style.height = textarea.scrollHeight + textNode.fontSize() + 'px';
                
                handlerUpdatePoint({ text: textarea.value, figureWidth: textarea.clientWidth, figureHeight: textarea.clientHeight });
            }           
        });

        function handleOutsideClick(e) {
            if (e.target !== textarea) {
                textNode.text(textarea.value);

                handlerUpdatePoint({ text: textarea.value, figureWidth: textarea.clientWidth, figureHeight: textarea.clientHeight });
                removeTextarea();
            }
        }
        setTimeout(() => {
            window.addEventListener('click', handleOutsideClick);
        });
    }

    const strokeWidth = (canvasTool.thickness + 10);

    const width = shapeProps.width ? shapeProps.width / window.devicePixelRatio : 0;
    const height = shapeProps.height ? shapeProps.height / window.devicePixelRatio : 0;
    return (
        <Group
            scaleX={ratio} scaleY={ratio}
            onDblClick={onDblClick}
            onDblTap={onDblClick}
            onClick={handlerSelectedLine} 
            onTap={handlerSelectedLine}
            onMouseOver={handlerDeleteLine}
            onMouseMove={handlerDeleteLine}
            onTouchMove={handlerDeleteLine}    
            width={width}
            height={height}
        >
            { drawText ? 
                <>
                    <Text 
                        ref={shapeRef}
                        fill={canvasTool.color}
                        fontSize={strokeWidth}
                        strokeWidth={strokeWidth}
                        hitStrokeWidth={strokeWidth}
                        opacity={canvasTool.opacity / 10}
                        width={shapeProps.width}
                        height={shapeProps.height}
                        x={shapeProps.x}
                        y={shapeProps.y}
                        text={shapeProps.text || t("options.dblInput")}
                        padding={8}
                        draggable={selected}                
                        onDragEnd={(e) => {
                            const data = {
                                ...shapeProps,
                                x: e.target.x(),
                                y: e.target.y()
                            };

                            setShapeProps(data);

                            handlerUpdatePoint({ x: data.x, y: data.y });
                        }}
                        onTransformEnd={(e) => {
                            if (!selected) return;
                            const node = shapeRef.current;
                            const scaleX = node.scaleX();
                            const scaleY = node.scaleY();
                            
                            node.scaleX(1);
                            node.scaleY(1);
                            const data = {
                                ...shapeProps,
                                x: node.x(),
                                y: node.y(),
                                // set minimal value
                                width: Math.max(5, node.width() * scaleX),
                                height: Math.max(node.height() * scaleY),
                            };
                            setShapeProps(data);
                            
                            handlerUpdatePoint({ x: data.x, y: data.y, figureWidth: data.width, figureHeight: data.height });
                        }}
                    />
                    { selected && <Transformer ref={trRef} 
                        keepRatio={false}
                        rotateEnabled={false}
                        centeredScaling={true}
                        enabledAnchors={['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right']}
                        boundBoxFunc={(oldBox, newBox) => {
                            if (newBox.width < 5 || newBox.height < 5) {
                                return oldBox;
                            }
                                
                            return newBox;
                        }}
                    /> }
                </> : <Rect width={shapeProps.width}
                        height={shapeProps.height}
                        x={shapeProps.x * ratio}
                        y={shapeProps.y * ratio}
                        strokeWidth={2}
                        lineJoin=""
                        dash={[10, 10]}
                        stroke={"#76acfb"} /> }
        </Group>
        
    )
}