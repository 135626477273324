import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { translate } from "../../base/i18n";

function DeviceSelector({ 
    t, id,
    hasPermission, devices, selectedDeviceId, error, onSelect
}) {
    const [list, setList] = useState([]);
    const [defaultSelected, setDefaultSelected] = useState();

    useEffect(() => {
        if (id === 'audioOutput' && devices.length === 1 && devices[0].deviceId === "") {
            const option = { label: t("device.default"), value: "default" };
            setList([ option ]);
            setDefaultSelected(option);
            return;
        }
        if (!(hasPermission || (!devices || !devices.length))) return;

        let items = [];

        devices.map(device => {
            const option = { label: device.label, value: device.deviceId };
    
            items.push(option);
            if (!error && device.deviceId === selectedDeviceId) setDefaultSelected(option);
        });

        if (error) {
            const option = { label: t(error), value: "error" };
            
            items.push(option);
            setDefaultSelected(option);
        }
        
        setList(items);
    }, [devices, error]);

    useEffect(() => {
        if (!selectedDeviceId || selectedDeviceId === defaultSelected?.value) return;

        const item = list.find(item => item.value === selectedDeviceId);
        if (item) {
            setDefaultSelected(item);
        }
    }, [selectedDeviceId]);

    /**
     * 두 개의 아이콘으로 둘러싸인 전달된 텍스트를 표시하기 위한 반응 요소를 만듭니다. 
     * 왼쪽 아이콘은 props를 통해 전달된 아이콘 클래스이고 오른쪽 아이콘은 AtlasKit ExpandIcon입니다.
     *
     * @param {string} triggerText - The text to display within the element.
     * @private
     * @returns {ReactElement}
     */
    const _createDropdownTrigger = (triggerText) => {
        return (
            <Select 
                className='device-select'
                placeholder={triggerText}
                isDisabled={true}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: 'red'
                    }),
                    option: (baseStyles, {isDisabled}) => ({
                        ...baseStyles,
                        cursor: isDisabled ? 'not-allowed' : 'pointer',
                    }),
                    placeholder: (baseStyles) => ({
                        ...baseStyles,
                        color: 'red'
                    })
                }}
            />
        );
    }

    /**
     * @param {Object} options - 표시를 위한 추가 구성입니다.
     * @param {Object} options.defaultSelected - 현재 선택된 것으로 설정해야 하는 옵션입니다.
     * @param {boolean} options.isDisabled - true인 경우 비활성화된 드롭다운을 시뮬레이션하기 위해 DropdownMenu 트리거 구성 요소만 반환됩니다.
     * @param {Array} options.items - 선택 가능한 모든 옵션을 표시합니다.
     * @param {string} options.placeholder - 선택하지 않았을 때 표시할 번역 키입니다.
     * @private
     * @returns {ReactElement}
     * 
     * 
        
     */
    const _createDropdown = (options) => {
        if (options?.isDisabled || options?.list.length < 1) {
            const triggerText = defaultSelected ? defaultSelected.label : options.placeholder;

            return _createDropdownTrigger(triggerText);
        }

        return (
            <Select 
                key={id}
                className='device-select'
                isSearchable={false}
                placeholder={options?.placeholder}
                value={defaultSelected}
                options={options?.list}
                onChange={device => {
                    setDefaultSelected(device);
                    onSelect(device.value)
                }}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: error ? "red" : state.isFocused ? 'grey' : 'green',
                    }),
                    option: (baseStyles, {isDisabled}) => ({
                        ...baseStyles,
                        color: "black",
                        cursor: isDisabled ? 'not-allowed' : 'pointer',
                    }),
                    placeholder: (baseStyles) => ({
                        ...baseStyles,
                        color: 'green'
                    }),
                }}
            />
            // <select key={id} defaultValue={options?.defaultSelected} onChange={onChange}>
            //     { options.list.map(item => {
            //         return (
            //             <option key={item.value} value={item.value}>
            //                 { item.label }
            //             </option>
            //         )
            //     })}
            // </select>
        );
    }

    /**
     * 비활성화되고 장치를 표시할 수 있는 권한이 없음을 나타내는 자리 표시자가 있는 DropdownMenu 구성 요소를 만듭니다.
     *
     * @private
     * @returns {ReactElement}
     */
    const _renderNoPermission = () => {
        return _createDropdown({
            isDisabled: true,
            placeholder: t('deviceSelection.noPermission')
        });
    }

    /**
     * 비활성화되고 선택할 장치가 없음을 나타내는 자리 표시자가 있는 구성 요소 선택을 만듭니다.
     *
     * @private
     * @returns {ReactElement}
     */
    const _renderNoDevices = () => {
        return _createDropdown({
            isDisabled: true,
            placeholder: t('deviceSelection.noDevice')
        });
    }

    if (!hasPermission) {
        return _renderNoPermission();
    }

    if (!devices || !devices.length) {
        return _renderNoDevices();
    }

    return _createDropdown({
        defaultSelected,
        isDisabled: false,
        list,
        placeholder: t('deviceSelection.selectADevice'),
        onSelect
    });
}

export default translate(DeviceSelector);