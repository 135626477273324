export const PERMISSION = {
    LAYOUT: 'layout',
    SHARE: 'share',
    NOTE: 'note',
    PIN: 'pin',
    CHAT: 'chat',
    KICK: 'kick',
    POLL: 'poll',
    DUAL: 'dual',
    SECURE: 'secure',
    SELF_RECORD: 'control member self record',
    SERVER_RECORD: 'server record',
    MEMBER_ROLE: 'control member role',
    MEMBER_CAMERA: 'control member camera',
    MEMBER_MIC: 'control member mic',
};

export const PARTICIPANT_STATUS = {
    OCCUPIDE: 'occupied',
    VACATED: 'vacated',
    BANNED: 'banned'
};

export const ROLE = {
    ADMIN: 'admin',
    HOST: 'host',
    MANAGER: 'manager',
    PRESENTER: 'presenter',
    PARTICIPANT: 'participant',
    VIEWER: 'viewer',
    OBSERVER: 'observer',
};

export const ROLE_LEVEL = [ROLE.ADMIN, ROLE.HOST, ROLE.MANAGER, ROLE.PRESENTER, ROLE.PARTICIPANT];
export const PARTICIPANT_STATUS_LEVEL = [PARTICIPANT_STATUS.OCCUPIDE, PARTICIPANT_STATUS.VACATED, PARTICIPANT_STATUS.BANNED];
export const CHANGE_ROLE_LIST = [ROLE.MANAGER, ROLE.PRESENTER, ROLE.PARTICIPANT];

export const LOCAL_PARTICIPANT_DEFAULT_ID = "local";