import React from 'react';
import styled from 'styled-components';
import { translate } from '../../../base/i18n';
import { ROLE, setMemberRole } from '../../../member';
import { removeParticipantNotification } from '../../../notifications';

const RaiseHandStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    .btn {
        border: none;
        cursor: pointer;
    }
`;
function PromptRaiseHand({t, dispatch, displayName, user_uuid}) {
    const onAccept = () => {
        dispatch(setMemberRole(user_uuid, ROLE.PRESENTER));
        APP.mateManagement.responsePresentation(user_uuid, true);

        dispatch(removeParticipantNotification(user_uuid));
    };

    const onRefuse = () => {
        APP.mateManagement.responsePresentation(user_uuid, false);
        dispatch(removeParticipantNotification(user_uuid));
    };

    return (
        <RaiseHandStyled>
            <span>{t('conference.requestPresenterInfo', {displayName})}</span>
            <div>
                <button className="btn btn_middle" onClick={onAccept}>
                    {t('conference.accept')}
                </button>
                <button className="btn btn_middle" onClick={onRefuse}>
                    {t('conference.refuse')}
                </button>
            </div>
        </RaiseHandStyled>
    );
}

export default translate(PromptRaiseHand);
