import { updateModalState } from '../base/modal';
import { MiddlewareRegistry } from '../base/redux';
import { getLocalIDMember } from '../member';
import { ADD_MESSAGE, OPEN_CHAT, SEND_MESSAGE, SET_SEND_FILE_LIST, UPDATE_MESSAGE } from './actionTypes';
import { addMessage, setUnreadCount, showChatNotification, updateMessage } from './actions';
import {
    CHAT_VIEW_MODAL_ID,
    MESSAGE_TYPE_LOCAL
} from './constants';
import { getUnreadCount } from './functions';

const PRIVACY_NOTICE_TIMEOUT = 20 * 1000;

MiddlewareRegistry.register(({ getState, dispatch }) => next => action => {
    const state = getState();

    switch (action?.type) {
        case ADD_MESSAGE:
            const unreadCount = action.hasRead ? 0 : getUnreadCount(state) + 1;
            var { isOpen, messages } = state['features/chat'];
            const chat = JSON.parse(action.message);
            if (!action.isMe && !isOpen) {
                dispatch(setUnreadCount());

                dispatch(showChatNotification(chat));
            }
            const { uuid } = chat;

            if (uuid) {
                // 이미 전송된 파일 처리 
                const isExist = messages.find(content => content.uuid === uuid);
                
                if (isExist) {
                    dispatch(updateMessage(action.message));
                    return;
                }
            }                
            break;
        
        case UPDATE_MESSAGE:
            var { messages } = state['features/chat'];

            messages.forEach((value, key) => {
                if (value.uuid === (action.uuid)) {
                    return next(action);
                }
            });
            break;

        case SEND_MESSAGE: {
            const { conference } = state['features/base/conference'];

            if (conference) {
                const shouldSendPrivateMessageTo = _shouldSendPrivateMessageTo(state, action);
                if (!shouldSendPrivateMessageTo) {
                    const { privateMessageRecipient } = state['features/chat'];
                    const privateMessage = privateMessageRecipient && privateMessageRecipient.length >= 1;
                    const localId = getLocalIDMember(state);
                    const time = new Date().getTime();
                    
                    dispatch(addMessage({
                        isMe: true,
                        hasRead: true,
                        from: localId,
                        message: JSON.stringify(action.message),
                        privateMessage,
                        timestamp: time
                    }));

                    if (privateMessage) {
                        APP.mateManagement.sendPrivateTextMessage(privateMessageRecipient, action.message);

                        _persistSentPrivateMessage({ dispatch, getState }, privateMessageRecipient, { ...action.message, time });
                    } else {
                        APP.mateManagement.sendTextMessage({ ...action.message, time });
                    }
                }
            }

            break;
        }
        
        case SET_SEND_FILE_LIST:
            dispatch(updateModalState(CHAT_VIEW_MODAL_ID));
            break;
        
        case OPEN_CHAT:
            dispatch(setUnreadCount(0)); 
            break;

    }

    return next(action);
});

/**
 * 개인 메시지 전송 
 */
function _persistSentPrivateMessage({ dispatch, getState }, recipientID, message) {
    const localParticipantId = getLocalIDMember(getState());
    dispatch(addMessage({
        isMe: true,
        hasRead: true,
        from: localParticipantId,
        message: JSON.stringify(message),
        privateMessage: true,
        timestamp: new Date().getTime(),
    }));
}


function _shouldSendPrivateMessageTo(state, action) {
    if (action.ignorePrivacy) {
        return undefined;
    }

    const { messages, privateMessageRecipient } = state['features/chat'];
    if (privateMessageRecipient) {
        return undefined;
    }

    if (!messages || (messages && !messages.length)) {
        return undefined;
    }

    const lastMessage = messages[messages.length - 1];
    
    if (lastMessage.messageType === MESSAGE_TYPE_LOCAL) {
        return undefined;
    }

    if (lastMessage.privateMessage) {
        return lastMessage.id;
    }

    const now = Date.now();
    const recentPrivateMessages = messages.filter(message =>
        message.messageType !== MESSAGE_TYPE_LOCAL
        && message.privateMessage
        && message.timestamp + PRIVACY_NOTICE_TIMEOUT > now);
    const recentPrivateMessage = recentPrivateMessages[recentPrivateMessages.length - 1];

    if (recentPrivateMessage) {
        return recentPrivateMessage.id;
    }

    return undefined;
}