import React, { useEffect, useState } from "react";
import MemberItem from "../../../member/components/MemberItem";
import { useSelector } from "react-redux";
import { PARTICIPANT_STATUS_LEVEL, ROLE_LEVEL, getLocalIDMember } from "../../../member";
import { ParticipantStyled } from "../../../member/components/styles";

export default function GroupMember({
    participant
}) {
    const [nMember, setNMeber] = useState([]);
    const permissions = useSelector(state => state['features/base/localuser'].permissions);
    const local_uuid = useSelector(state => getLocalIDMember(state));
    
    useEffect(() => {
        let members = [];
        [...participant].forEach(([user_id, p]) => {
            members.push(p);
        });

        members
            .sort((a, b) => a.pinned === b.pinned ? 0 : a.pinned ? -1 : 1 )
            .sort((a, b) => ROLE_LEVEL.indexOf(a.role) - (ROLE_LEVEL.indexOf(b.role)))
            .sort((a, b) => PARTICIPANT_STATUS_LEVEL.indexOf(a.status) - (PARTICIPANT_STATUS_LEVEL.indexOf(b.status)));

        setNMeber(members);
    }, [participant]);

    return (
        <ParticipantStyled>
            <div className="right_menu">
                <div className="item_content">
                {nMember.map(p => <MemberItem key={p.user_uuid} nMember={p} local_uuid={local_uuid} permissions={permissions} startGroup={[]} isMonitoring={true} />)}
                </div>
            </div>
        </ParticipantStyled>
    )
}