// @flow

export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const REMOVE_PARTICIPANT_NOTIFICATION = 'REMOVE_PARTICIPANT_NOTIFICATION';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';

export const SET_NOTIFICATIONS_ENABLED = 'SET_NOTIFICATIONS_ENABLED';
export const REMOVE_ATTENDANCES_NOTIFICATION = 'REMOVE_ATTENDANCES_NOTIFICATION';

export const ALERT_MESSAGE = 'ALERT_MESSAGE';
export const CLEAR_ALERT_MESSAGE = 'CLEAR_ALERT_MESSAGE';