import axios from "axios";

function objectToQueryString(obj) {    
    return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
}

export async function getGroupList({ aToken, data, offset = 0, limit = 20 }) {
    const url = objectToQueryString(Object.assign({}, data, {offset}, {limit}));

    const response = await axios({
        method: "GET",
        url: '/api/meeting/v1/meetings/by-group?' + url,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        }
    });

    return response;
}

export async function createGroup({ aToken, meeting_id, name }) {
    const response = await axios({
        method: "POST",
        url: `/api/meeting/v1/meetings/${meeting_id}/groups`,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        }, 
        data: {
            name
        }
    });

    return response;
}

export async function deleteGroup({ aToken, meeting_id, group_id }) {
    const response = await axios({
        method: "DELETE",
        url: `/api/meeting/v1/meetings/${meeting_id}/groups/${group_id}`,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        }
    });

    return response;
}

export async function assignGroup({ aToken, meeting_id, group_id = null, data }) {
    const response = await axios({
        method: "POST",
        url: `/api/meeting/v1/meetings/${meeting_id}/groups/${group_id}/assign`,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        }, 
        data: data
    });

    return response;
}

export async function updateGroup({ aToken, meeting_id, group_id = null, data }) {
    const response = await axios({
        method: "POST",
        url: `/api/meeting/v1/meetings/${meeting_id}/groups/${group_id}/update`,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        }, 
        data: data
    });

    return response;
}

export async function allUpdateGroup({ aToken, meeting_id, data }) {
    const response = await axios({
        method: "POST",
        url: `/api/meeting/v1/meetings/${meeting_id}/groups-update`,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        }, 
        data: data
    });

    return response;
}

export async function randomizeGroup({ aToken, meeting_id, data }) {
    const response = await axios({
        method: "POST",
        url: `/api/meeting/v1/meetings/${meeting_id}/groups/randomize`,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        }, 
        data: data
    });
    
    return response;
}