// import { debounce, throttle } from "lodash";
// import React, { useEffect, useRef, useState } from "react";
// import { Rect } from "react-konva/lib/ReactKonvaCore";

import { debounce, startsWith, throttle } from "lodash";
import React, { useEffect, useRef, useState } from "react"
import { Rect } from "react-konva"
import { shallowEqual } from "react-redux";

// let _scrollTimeout;
// const PADDING = 10;
// function ScrollCanvas({
//     elementWidth, elementHeight, canvasStyle, scale, scrollPosition, canvasX, canvasY,
//     focusIndex, number,
//     setCanvasX, setCanvasY, handlerFocusItem
// }) {
//     const verticalBarRef = useRef();
//     const horizontalBarRef = useRef();
    
//     const [ boxWidth, setBoxWidth ] = useState(0);
//     const [ boxHeight, setBoxHeight ] = useState(0);
//     const [ canvasWidth, setCanvasWidth ] = useState(0);
//     const [ canvasHeight, setCanvasHeight ] = useState(0);
//     const [ lastPointer, setLastPointer ] = useState(0);

//     useEffect(() => {
//         setCanvasX(0);
//         setCanvasY(0);

//         const horizontalBar = horizontalBarRef?.current;
//         const verticalBar = verticalBarRef?.current;

//         if (horizontalBar) horizontalBar.x(0);
//         if (verticalBar) verticalBar.y(0);
//     }, [scale]);

//     useEffect(() => {
//         const scaleUpWidth = canvasStyle.width * scale;
//         const scaleUpHeight = canvasStyle.height * scale;

//         setBoxWidth(Math.min(scaleUpWidth, elementWidth));
//         setBoxHeight(Math.min(scaleUpHeight, elementHeight));

//         setCanvasWidth(scaleUpWidth);
//         setCanvasHeight(scaleUpHeight);
//     }, [canvasStyle, elementWidth, elementHeight, scale, focusIndex]);

//     const handlerVertical = e => {
//         if (verticalBarRef && verticalBarRef.current) {
//             const verticalBar = verticalBarRef.current;

//             const availableHeight = boxHeight - PADDING * 2 - verticalBar.height();
//             var delta = (verticalBar.y() - PADDING) / availableHeight;
//             const canvasY = -(canvasHeight - boxHeight) * delta;

//             setCanvasY(canvasY);
//         }
//     }

//     const handlerHorizontal = e => {
//         if (horizontalBarRef && horizontalBarRef.current) {
//             const horizontalBar = horizontalBarRef.current;

//             const availableWidth = boxWidth - PADDING * 2 - horizontalBar.width();
//             var delta = (horizontalBar.x() - PADDING) / availableWidth;
//             const canvasX = -(canvasWidth - boxWidth) * delta;
            
//             setCanvasX(canvasX);
//         }
//     }

//     useEffect(() => {
//         if (!scrollPosition ) return;
//         const { type, pos } = scrollPosition;

//         let x, y;
//         if (type === "up") {
//             x = lastPointer.x - pos.x;
//             y = lastPointer.y - pos.y;
//         } else if (type === "wheel") {            
//             const dx = pos.x;
//             const dy = pos.y;
            
//             const minX = -(canvasWidth - elementWidth);
//             const maxX = 0;
//             x = Math.max(minX, Math.min(canvasX - dx, maxX));

//             const minY = -(canvasHeight - elementHeight);
//             const maxY = 0;
//             y = Math.max(minY, Math.min(canvasY - dy, maxY));         
//         }

//         if (type === "up") {
//             if (verticalBarRef && verticalBarRef.current) {
//                 const verticalBar = verticalBarRef.current;

//                 const vy = Math.max(Math.min(verticalBar.y() + y, boxHeight - verticalBar.height() - PADDING), 0);
//                 verticalBar.y(vy);
//             }

//             if (horizontalBarRef && horizontalBarRef.current ) {
//                 const horizontalBar = horizontalBarRef.current;
                
//                 const hx = Math.max(Math.min(horizontalBar.x() + x, boxWidth - horizontalBar.width() - PADDING), 0);
//                 horizontalBar.x(hx);                
//             }

//             handlerVertical();
//             handlerHorizontal();

//             const test = debounce(() => {
//                 const minY = -(canvasHeight - boxHeight);
//                 const maxY = 0;
//                 const canvasDeltaY = Math.max(minY, Math.min(canvasY - y, maxY));
                
//                 if (y < 0 && maxY === canvasDeltaY && focusIndex - 1 >= 0) handlerFocusItem(focusIndex - 1);
//                 else if (y > 0 && minY === canvasDeltaY && focusIndex + 1 < number) handlerFocusItem(focusIndex + 1);
//             }, 500);
//             test();
//             setLastPointer(null);
//             return;
//         } else if (type === "wheel") {
//             let vy1 = 0;
//             if (verticalBarRef && verticalBarRef.current) {
//                 const verticalBar = verticalBarRef.current;
    
//                 const availableHeight = elementHeight - PADDING - verticalBar.height();
//                 const vy = (canvasY / (-canvasHeight + elementHeight)) * availableHeight + 0;
//                 verticalBar.y(vy);

//                 vy1 = vy;
//             }
    
//             if (horizontalBarRef && horizontalBarRef.current ) {
//                 const horizontalBar = horizontalBarRef.current;
                
//                 const availableWidth = elementWidth - PADDING - horizontalBar.width();
//                 const hx = (canvasX / (-canvasWidth + elementWidth)) * availableWidth + 0;
//                 horizontalBar.x(hx);
//             }

            
//             if (horizontalBarRef.current) setCanvasX(isNaN(x) ? 0 : x);
//             if (verticalBarRef.current) setCanvasY(isNaN(y) ? 0 : y);  
            
//             const test = debounce(() => {
//                 if ((!verticalBarRef.current || (verticalBarRef.current && verticalBarRef.current.height() + vy1 + PADDING >= elementHeight )) && Math.abs(pos.y) > 40)  {
//                     if (pos.y < 0 && focusIndex - 1 >= 0) handlerFocusItem(focusIndex - 1);
//                     else if (pos.y > 0 && focusIndex + 1 < number) handlerFocusItem(focusIndex + 1);
//                     return;
//                 }
//             }, 800);
//             test();
//             return;
//         }
//         setLastPointer(pos);
//     }, [scrollPosition]);

//     const verticalHeight = boxHeight * (boxHeight / canvasHeight);
//     const horizontalWidth = boxWidth * (boxWidth / canvasWidth);
//     return (
//         <>
//             { boxHeight < canvasHeight &&
//                 <Rect ref={verticalBarRef}
//                     width={PADDING} height={verticalHeight}
//                     x={boxWidth - PADDING} y={0}
//                     fill={'grey'} opacity={0.5}

//                     draggable={true}
//                     onDragMove={handlerVertical}
//                     dragBoundFunc={(pos)=>{
//                         pos.x = boxWidth - PADDING;
//                         pos.y = Math.max(Math.min(pos.y, boxHeight - verticalHeight - PADDING), 0);

//                         return pos;
//                     }} /> 
//             }

//             { boxWidth < canvasWidth &&
//                 <Rect ref={horizontalBarRef}
//                     width={horizontalWidth} height={PADDING}
//                     x={0} y={boxHeight - PADDING}
//                     fill={'grey'} opacity={0.5}

//                     draggable={true}
//                     onDragMove={handlerHorizontal}
//                     dragBoundFunc={(pos)=>{
//                         pos.x = Math.max(Math.min(pos.x, boxWidth - horizontalWidth - PADDING), 0);
//                         pos.y = boxHeight - PADDING;

//                         return pos;
//                     }}
//                 />
//             }
//         </>
//     )
// }

// export default ScrollCanvas;
const PADDING = 10;
export default function ScrollCanvas({
    uuid, index,
    stageWidth, stageHeight, drawWidth, drawHeight,
    canvasPos, setCanvasPos, setStopDraw, line, wheel,
    page, setPage
}) {
    const verticalBarRef = useRef();
    const horizontalBarRef = useRef();
    const [ verticalHeight, setVerticalHeight ] = useState(0);
    const [ horizontalWidth, setHorizontalWidth ] = useState(0);

    useEffect(() => {
        if (horizontalBarRef && horizontalBarRef.current ) {
            const horizontalBar = horizontalBarRef.current; 
            horizontalBar.x(0);
        }

        if (verticalBarRef && verticalBarRef.current) {            
            const verticalBar = verticalBarRef.current;
            verticalBar.y(0);
        }
    }, [uuid, index]);

    useEffect(() => {
        const width = stageWidth * (stageWidth / drawWidth);
        const height = stageHeight * (stageHeight / drawHeight);

        setHorizontalWidth(width);
        setVerticalHeight(height);
    }, [stageWidth, stageHeight, drawWidth, drawHeight], shallowEqual);

    useEffect(() => {
        if (!line?.points) return;
        const { x: firstX, y: firstY } = line.canvasTool.points;
        const [ lastX, lastY ] = line.points;
        
        const moveX = firstX - lastX;
        const moveY = firstY - lastY;

        changeMouseMove(moveX, moveY);
    }, [line]);

    useEffect(() => {
        const dx = wheel.dx;
        const dy = wheel.dy;
        
        const minX = -(drawWidth - stageWidth);
        const maxX = 0;
        const moveX = Math.max(minX, Math.min(canvasPos.x - dx, maxX));

        const minY = -(drawHeight - stageHeight);
        const maxY = 0;
        const moveY = Math.max(minY, Math.min(canvasPos.y - dy, maxY)); 

        changeWheelMove(moveX, moveY, dy < 0 ? true : false);
    }, [wheel]);

    const changeMouseMove = (moveX, moveY) => {
        if (Math.abs(moveX) > 50) {   
            if (horizontalBarRef && horizontalBarRef.current ) {
                const horizontalBar = horizontalBarRef.current;                    
                
                const hx = Math.max(Math.min(horizontalBar.x() + moveX, stageWidth - horizontalBar.width() - PADDING), 0);
                horizontalBar.x(hx);  
                
                handlerHorizontal(hx);
            }     
        } 
        if (Math.abs(moveY) > 50) {
            if (verticalBarRef && verticalBarRef.current) {
                const verticalBar = verticalBarRef.current;
    
                const vy = Math.max(Math.min(verticalBar.y() + moveY, stageHeight - verticalBar.height() - PADDING), 0);
                verticalBar.y(vy);

                handlerVertical(vy);
            } 
        }
    }

    const changeWheelMove = (moveX, moveY, flipY) => {
        if (horizontalBarRef && horizontalBarRef.current ) {
            const horizontalBar = horizontalBarRef.current; 

            const availableWidth = stageWidth - PADDING - horizontalBar.width();
            const hx = (canvasPos.x / (-drawWidth + stageWidth)) * availableWidth + 0;
            horizontalBar.x(hx);

            setCanvasPos({ ...canvasPos, x: moveX });
        }

        if (verticalBarRef && verticalBarRef.current) {            
            const verticalBar = verticalBarRef.current;

            const availableHeight = stageHeight - PADDING - verticalBar.height();
            const vy = (canvasPos.y / (-drawHeight + stageHeight)) * availableHeight + 0;
            verticalBar.y(vy);
            
            if (vy === 0 && flipY) {
                changePage(false);
            } else if (!flipY && verticalBar.height() + vy + PADDING >= stageHeight) {
                changePage(true);
            } else {
                setCanvasPos({ ...canvasPos, y: moveY });
            }
        } else {
            if (moveY === 0) {
                if (flipY) changePage(true);
                else changePage(false);
            }
        }
    };

    const changePage = debounce((isPlus) => {        
        const plus = isPlus ? 1 : -1;
        setPage(page + plus);
    }, 500);

    const handlerVertical = (hy) => {
        if (verticalBarRef && verticalBarRef.current) {
            const verticalBar = verticalBarRef.current;

            const availableHeight = stageHeight - PADDING * 2 - verticalBar.height();
            var delta = (verticalBar.y() - PADDING) / availableHeight;
            const canvasY = -(drawHeight - stageHeight) * delta;

            setCanvasPos({ ...canvasPos, y: hy === 0 ? 0 : canvasY });
        }
    };

    const handlerHorizontal = (hx) => {
        if (horizontalBarRef && horizontalBarRef.current) {
            const horizontalBar = horizontalBarRef.current;

            const availableWidth = stageWidth - PADDING * 2 - horizontalBar.width();
            var delta = (horizontalBar.x() - PADDING) / availableWidth;
            const canvasX = -(drawWidth - stageWidth) * delta;
            
            setCanvasPos({ ...canvasPos, x: hx === 0 ? 0 : canvasX });
        }
    };

    const renderVerticalBar = () => {
        if (stageHeight < drawHeight && verticalHeight) {
            return (
                <Rect key="vertical" ref={verticalBarRef} 
                    width={PADDING} height={verticalHeight}
                    x={stageWidth - PADDING} y={0}
                    fill={'gray'} opacity={0.5}
                    onMouseDown={() => setStopDraw(true)}
                    draggable={true}
                    onDragMove={null}
                    dragBoundFunc={(pos)=>{
                        pos.x = stageWidth - PADDING;
                        pos.y = Math.max(Math.min(pos.y, stageHeight - verticalHeight - PADDING), 0);

                        setCanvasPos({ ...canvasPos, y: -pos.y });
                        return pos;
                    }} /> 
            )
        }
    }

    
    const renderHorizontal = () => {
        if (Math.ceil(stageWidth) +5 < drawWidth && horizontalWidth) {
            return (
                <Rect key="horizontal" ref={horizontalBarRef}
                    width={horizontalWidth} height={PADDING}
                    x={0} y={stageHeight - PADDING}
                    fill={'gray'} opacity={0.5}
                    onMouseDown={() => setStopDraw(true)}
                    draggable={true}
                    onDragMove={null}
                    dragBoundFunc={(pos)=>{
                        pos.x = Math.max(Math.min(pos.x, stageWidth  - horizontalWidth - PADDING), 0);
                        pos.y = stageHeight - PADDING;
                        
                        setCanvasPos({ ...canvasPos, x: -pos.x });
                        return pos;
                    }} /> 
            )
        }
    }

    const content = [
        renderVerticalBar(),
        renderHorizontal()
    ].filter(Boolean);

    return (
        content
    )
}