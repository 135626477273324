import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import media from "../../../../assets/media";
import { ClickButton } from "../../../base/buttons";
import { translate } from "../../../base/i18n";
import { getGridCount, setGridCount } from "../../../video-layout";
import { AbstractDialog } from "../../../base/dialog";

function SetGridCount({
    t, setOptionDrop, handlerHide
}) {
    const numRef = useRef();
    const dispatch = useDispatch();
    const gridCount = useSelector(state => getGridCount(state));

    const handlerGridCount = () => {
        const count = numRef.current?.value;

        if (count && count > 0 && count < 51 ) {
            dispatch(setGridCount(parseInt(count)));

            setOptionDrop ? setOptionDrop(false) : APP.UI.closeModal();
            
        }
    }

    return (
        <AbstractDialog name="gridCount" width={350} handlerClose={handlerHide}>
            <SetGridStyled>
                <span className="guid">
                    { t("conference.onlyfiftyVideo") }
                </span>

                <div className="grid_option">
                    <input type="number" ref={numRef} min={0} max={50} defaultValue={gridCount} />
            
                    <ClickButton size={15} text={t("common.add")} onClick={handlerGridCount} />
                </div>
            </SetGridStyled>            
        </AbstractDialog>
    )
}

const SetGridStyled = styled.div`   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 24px;

    span {
        font-size: 12px;
        word-break: keep-all;
    }

    .grid_option {
        display: inline-flex;
        gap: 15px;
        margin: 0;
    
        input {
            marign: 0px;
            flex: 1;
            background: transparent;
            border: 1px solid #b3b3b3;
            padding: 4px;
            border-radius: 4px;
            text-align: center;
            color: #fff;
        }
    }
`;

export default translate(SetGridCount);