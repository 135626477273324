import throttle from "lodash/throttle";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { getLocalMember } from "../../../member";
import { DocumentTool } from "../../../share-document";
import { getLayoutWidthAndHeight } from "../../../video-layout";
import { translate } from "../../i18n";
import { connect } from "../../redux";
import { setCanvasTool } from "../action";
import { PEN, pen_list } from "../constants";
import { getFitSize } from "../functions";
import CanvasDrawLayout from "./CanvasDrawLayout";
import CanvasTool from "./CanvasTool";
import { CanvasLayoutStyled } from "./styles";
import { isChatOpen } from "../../../chat";
import { isParticipantOpen } from "../../../participant-right";

function CanvasContainer({
    isVod, isWhiteBoard, vodStyle, isLeft, expand, setIsLeft, focusItem, mode,
    local, document, uuid, index, scale, isWidthFit, width, height, tool, option, rightOpen,
    children
}) {
    const dispatch = useDispatch();
    const fullCanvasRef = useRef();
    const [ focusTool, setFocusTool ] = useState();

    const [ drawCanvas, setDrawCanvas ] = useState({ width: 0, height: 0 });
    const [ fullCanvas, setFullCanvas ] = useState({ width: 0, height: 0 });
    const [ canvasPos, setCanvasPos ] = useState({ x: 0, y: 0 });
    
    useEffect(() => {
        updateCanvasStyle();
        window.addEventListener("resize", updateCanvasStyle);

        return () => {
            window.removeEventListener("resize", updateCanvasStyle);
        }
    }, []);

    useEffect(() => {
        updateCanvasStyle();
    }, [expand, isLeft, vodStyle, rightOpen]);

    useEffect(() => {
        return () => {
            if (pen_list.includes(focusTool?.name)) {
                setFocusTool(tool || PEN);
            }
        }
    }, [uuid, index], shallowEqual);

    useEffect(() => {
        // 화이트 보드일 경우 캔버스 스타일 16:9 비율 고정
        if (isWhiteBoard) {
            if (isVod) {
                setDrawCanvas(fullCanvas);
            } else {
                const canvasStyle = getFitSize(fullCanvas, { width: 16, height: 9 }, isWidthFit);

                setDrawCanvas(canvasStyle);
            }
        }
    }, [isWhiteBoard, fullCanvas, isWidthFit], shallowEqual);

    const updateCanvasStyle = throttle(() => {
        const element = fullCanvasRef?.current;
        if (element) {
            const style = element.getBoundingClientRect();

            if (isVod) {
                setFullCanvas({ width: style.width, height: style.height - 41})
            } else {
                setFullCanvas(style);
            }
        }
    }, 400);

    const handlerUpdateCanvasTool = (tool) => {
        dispatch(setCanvasTool(tool));
    }

    return (
        <>
            <DocumentTool isLeft={isLeft} setIsLeft={setIsLeft}>
                <CanvasTool isMobile={false} 
                    tool={tool} option={option} role={local?.role}
                    focusTool={focusTool} fullCanvas={fullCanvas} 
                    setFocusTool={setFocusTool}
                    handlerUpdateCanvasTool={handlerUpdateCanvasTool}
                />
            </DocumentTool>
            <CanvasLayoutStyled ref={fullCanvasRef} isVod={isVod}>
                <CanvasDrawLayout 
                    isVod={isVod}
                    local={local}
                    scale={scale}
                    tool={tool}
                    mode={mode}
                    focusTool={focusTool}
                    canvasOption={option}
                    focusItem={focusItem}
                    isWhiteBoard={isWhiteBoard}
                    canvasPos={canvasPos}
                    drawCanvas={drawCanvas}
                    fullCanvas={fullCanvas}
                    setDrawCanvas={setDrawCanvas}
                    setCanvasPos={setCanvasPos}
                    setFocusTool={setFocusTool}
                    handlerUpdateCanvasTool={handlerUpdateCanvasTool}
                >
                    { children }
                </CanvasDrawLayout>                
            </CanvasLayoutStyled>
        </>
    )
}

function _mapStateToProps(state, ownProps) {
    const local = getLocalMember(state);
    const { width, height } = getLayoutWidthAndHeight(state);
    const { tool, option } = state['features/base/canvas'];
    const rightOpen = isChatOpen(state) || isParticipantOpen(state);

    return {
        local,
        tool,
        option,
        scale: option.scale / 100, 
        isWidthFit: option.isWidthFit,
        width, height,
        rightOpen
    }
}

export default translate(connect(_mapStateToProps)(CanvasContainer));