import { APP_MOUNT, SET_APP_ENABLE_PAGE, SET_APP_PAGE, SET_ROOM_OPTION } from "./actionType";

export function appMount(isLoad) {
    return {
        type: APP_MOUNT,
        isLoad
    }
}

export function setAppEnablePage(data) {
    return {
        type: SET_APP_ENABLE_PAGE,
        data
    }
}
export function setAppPage(page) {
    return {
        type: SET_APP_PAGE,
        page
    }
}

export function setRoomOption(option) {
    return {
        type: SET_ROOM_OPTION,
        option
    }
}