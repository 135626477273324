import { MiddlewareRegistry } from "../base/redux";
import { SET_GRID_COUNT } from "./actionType";
import { setShowCount } from "./actions";
import { videoLayoutMode } from "./constant";
import { getCurrentMode, getIsMobile } from "./functions";

MiddlewareRegistry.register(store => next => action => {
    let mode;
    switch (action.type) {        
        case SET_GRID_COUNT:
            mode = getCurrentMode(store.getState);

            if (mode === videoLayoutMode.grid && getIsMobile(store.getState)) {
                store.dispatch(setShowCount(action.gridCount));
            }
            break;
    } 

    return next(action);
});