import React, { useEffect, useRef, useState } from "react";
import { Layer, Stage } from "react-konva";
import { shallowEqual, useDispatch } from "react-redux";
import { connect } from "../../redux";
import { deleteCanvasDraw, setCanvasPoint } from "../action";
import { CHOICE, CIRCLE, FIGURE_LIST, F_CIRCLE, F_TRIANGLE, HANDLER, LINE_ERASER, PART_ERASER, PEN, TEXT, TRIANGLE, pen_list } from "../constants";
import { getPdfDrawByUUIDAndIndex } from "../functions";
import FigureCanvas from "./FigureCanvas";
import LineCanvas from "./LineCanvas";
import { debounce, throttle } from "lodash";
import CanvasDrawItem from "./CanvasDrawItem";
import ScrollCanvas from "./ScrollCanvas";
import { changeFocusPage } from "../../../share-document";
import { videoLayoutMode } from "../../../video-layout";


function CanvasDraw({
    drawList,
    local, document, uuid, index, isWhiteBoard, mode,
    drawCanvas, fullCanvas,
    canvasOption, scale,
    tool, focusTool, setFocusTool, 
    canvasPos, setCanvasPos,
    handlerUpdateCanvasTool
}) {
    const drawRef = useRef();
    const dispatch = useDispatch();

    const [ stopDraw, setStopDraw ] = useState(false);
    const [ stageStyle, setStageStyle ] = useState({ x: 0, y: 0 });
    const [ down, setDown ] = useState(false);
    const [ line, setLine ] = useState();
    const [ selected, setSelected ] = useState(null);
    const [ focusTextarea, setFocusTextarea ] = useState(false);
    const [ wheel, setWheel ] = useState({ dx: 0, dy: 0 });
    const [ page, setPage ] = useState(index);

    useEffect(() => {
        const handler = () => {
            setDown(false);
            setStopDraw(false);
        }
        window.addEventListener("mouseup", handler);

        return () => {
            window.removeEventListener("mouseup", handler);
        }
    }, []);

    useEffect(() => {
        setPage(index);
    }, [index]);

    useEffect(() => {
        if (mode === videoLayoutMode.document) {
            if (page === index) return;

            const changePage = async () => {
                const response = await dispatch(changeFocusPage(uuid, page, mode));
                if (!response) setPage(index);
            }

            changePage();
        }
    }, [page]);

    useEffect(() => {
        if (!line) return;
        
        if (line && !down) setLine(null);
        if (!stopDraw && down) {
            if (line.canvasTool.tool !== HANDLER.name) {
                dispatch(setCanvasPoint(
                    uuid, index, line.canvasTool, line.points, down, 
                    fullCanvas.width, fullCanvas.height, false
                ));
            }            
        } 
    }, [line]);

    useEffect(() => {
        setLine(null);
        setCanvasPos({ x: 0, y: 0 });
        setFocusTool(tool);
    }, [uuid, index, canvasOption.isWidthFit]);

    // useEffect(() => {
    //     !down && setLine(false);
    // }, [down]);

    useEffect(() => {
        setDown(null);
    }, [stopDraw]);

    useEffect(() => {
        setStageStyle({ 
            width: Math.min(fullCanvas.width, drawCanvas.width * scale), 
            height: Math.min(fullCanvas.height, drawCanvas.height * scale)
        });
    }, [drawCanvas, fullCanvas, scale], shallowEqual);

    const onMouseDown = canvas => {
        if (focusTextarea) return;
        if (stopDraw) return;

        const points = getPointerPosition();
        const intersection = canvas.currentTarget.getIntersection(points);
        
        if (!intersection) setSelected(null);
        if (intersection && intersection._partialText && TEXT.name === tool) return;

        const key = Date.now() + '_' + local.user_uuid;
        setDown(key);

        if (pen_list.includes(focusTool?.name)) setFocusTool(PEN.name);
        if (CHOICE.name === tool) return;

        const newLine = {
            canvasTool: {
                ...drawCanvas,
                ...canvasOption,
                tool,
                points
            },
            points: [points.x, points.y]
        };

        setLine(newLine);
    }

    const onMouseMove = canvas => {
        if (stopDraw) return;
        if (tool === HANDLER.name) return;
        canvas.evt.preventDefault();

        if (!down) return;        
        
        setDrawLine();
    }

    const onMouseUp = canvas => {
        if (stopDraw) {
            setStopDraw(false);
            setDown(false);
            return;
        }
        if (!down) return;        
        
        setDown(false);
        setDrawLine();
        

        // setTimeout(() => setDown(false), 00);       
    };

    const onWheel = canvas => {
        const dx = canvas.evt.deltaX;
        const dy = canvas.evt.deltaY;

        setWheel({ dx, dy });
    };

    const setDrawLine = throttle(() => {        
        const { x, y } = getPointerPosition();
        let newLine = line;
        if (tool === PEN.name || tool === PART_ERASER.name) {
            if (!line?.points) return;
            newLine = {
                ...line,
                points: line.points.concat([x, y])
            };            
        } else if (FIGURE_LIST.includes(tool) || tool === TEXT.name) {
            if (!line?.canvasTool?.points) return;
            
            let deltaX = x - line.canvasTool.points.x;
            let deltaY = y - line.canvasTool.points.y;

            let pX = deltaX < 0 ? x : line.points[0];
            let pY = deltaY < 0 ? y : line.points[1];
            
            newLine = {
                ...line,
                canvasTool: {
                    ...line.canvasTool,
                    flipX: deltaX < 0 ? true : false,
                    flipY: deltaY < 0 ? true : false,
                    figureWidth: Math.max(Math.abs(deltaX), 10), 
                    figureHeight: Math.max(Math.abs(deltaY), 10)
                }, points: [
                    pX, pY
                ]
            };
        } else if ( tool === HANDLER.name) {
            if (!line?.canvasTool?.points) return;
            
            newLine = {
                ...line,
                points: [
                    x, y
                ]
            };
        }

        setLine(newLine); 
    }, 5000, { trailing: false });

    const getPointerPosition = pos => {
        const pointer = pos ? pos : drawRef.current.getRelativePointerPosition();
        if (tool === HANDLER.name) {
            return pointer;
        } else {
            const x = (pointer.x - canvasPos.x) / scale;
            const y = (pointer.y - canvasPos.y) / scale;

            return { x, y };
        }
    }

    const handlerDeleteCanvasPoint = throttle((uuid, index, id) => {
        dispatch(deleteCanvasDraw(uuid, index, id));
    }, 1000, { trailing: false });

    const handlerDeleteLine = (id) => {
        if (!down) return;
        if (tool === LINE_ERASER.name) {
            handlerDeleteCanvasPoint(uuid, index, id)
        }
    }

    const handlerSelectedLine = (id) => {
        // if (canvasTool === TEXT.name) {
        //     const onClick = debounce(() => {
                
        //     }, 500);

        //     onClick();
        // } else {
        if (tool === CHOICE.name) {
            const sID = id === selected ? null : id;
            setSelected(sID);
        }
        // }
    };

    const handlerChangeCanvasPoint = (id, points, canvasTool) => {
        dispatch(setCanvasPoint(
            uuid, index, canvasTool, points, id, canvasTool.width, canvasTool.height, true
        ));
    };
    
    return (
        <>
            <div id="canvas_wrapper" className="stage canvas_input" />
            <Stage className={`stage ${isWhiteBoard ? 'white' : ''}`} ref={drawRef}
                width={stageStyle.width} height={stageStyle.height}

                onMouseDown={onMouseDown} onTouchStart={onMouseDown}
                onMouseMove={onMouseMove} onTouchMove={onMouseMove}
                onMouseUp={onMouseUp} onTouchEnd={onMouseUp}
                onWheel={focusTextarea ? null : onWheel}
            >
                <Layer x={canvasPos.x} y={canvasPos.y} id="focus">
                    { drawList && drawList.map(key => {
                        if (!key) return null;  
                        if (key === down) return null;
                        
                        return (
                            <CanvasDrawItem key={key} id={key} scale={scale}
                                uuid={uuid} index={index} 
                                stageStyle={stageStyle}
                                drawCanvas={drawCanvas} selected={selected}
                                setFocusTextarea={setFocusTextarea}
                                handlerDeleteLine={handlerDeleteLine}
                                handlerSelectedLine={handlerSelectedLine}
                                handlerChangeCanvasPoint={handlerChangeCanvasPoint}
                            />
                        ) } )
                    }

                    { line && <CanvasDrawItem key={down} id={down} line={line} scale={scale}
                            stageStyle={stageStyle}              
                            uuid={uuid} index={index} 
                            drawCanvas={drawCanvas} selected={selected}
                            setFocusTextarea={setFocusTextarea}
                            handlerDeleteLine={handlerDeleteLine}
                            handlerSelectedLine={handlerSelectedLine}
                            handlerChangeCanvasPoint={handlerChangeCanvasPoint}
                        />
                    }
                </Layer>
                <Layer>
                    <ScrollCanvas 
                        uuid={uuid} index={index}
                        stageWidth={stageStyle.width}
                        stageHeight={stageStyle.height}
                        drawWidth={drawCanvas.width * scale}
                        drawHeight={drawCanvas.height * scale}
                        canvasPos={canvasPos} setCanvasPos={setCanvasPos}
                        wheel={wheel} page={page} setPage={setPage}
                        setStopDraw={setStopDraw} line={line && line.canvasTool.tool === HANDLER.name ? line : null} />
                </Layer>
            </Stage>
        </>
    )
}


function _mapStateToProps(state, ownProps) {    
    const drawList = getPdfDrawByUUIDAndIndex(state, ownProps.uuid, ownProps.index);

    return {
        drawList
    }
}

export default connect(_mapStateToProps)(CanvasDraw);