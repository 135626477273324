import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { ChatLayout, isChatOpen } from "../../../chat";
import { MembersContainer } from "../../../member";
import { layoutType } from "../../../video-layout";

function ConferenceRightMeun({
    screenType, isDualMonitor,
    isChat, isParticipant, isShow,
    onClickInvite, onClickChat
}) {
    const isAllOpen = isChat && isParticipant;
    const [ chatOpen, setChatOpen ] = useState(false);
    const [ ppOpen, setPPOpen ] = useState(false);

    const handlerChatOpen = () => { 
        if (chatOpen && !ppOpen ) return; 
        setChatOpen(!chatOpen); 
    }
    const handlerPPOpen = () => {
        if (!chatOpen && ppOpen ) return; 
        setPPOpen(!ppOpen);
    }

    useEffect(() => {
        setChatOpen(isChat);
        setPPOpen(isParticipant);
    }, [isChat, isParticipant]);

    return (
        isShow && 
            <RightMenuStyled isDualMonitor={isDualMonitor} chatOpen={chatOpen} ppOpen={ppOpen} screenType={screenType}>
                { isParticipant && 
                    <div className="participant" style={{ maxHeight: ppOpen ? chatOpen ? "30%" : isChat ? "calc(100% - 35px)" : "100%" : "35px" }}>
                        <MembersContainer
                            onClickInvite={onClickInvite}
                            isParticipant={isParticipant}
                            open={ppOpen}
                            handlerOpen={handlerPPOpen}
                        />
                    </div>
                }
                { isChat && 
                    <div className="chat" style={{ maxHeight: chatOpen ? ppOpen ? "70%" : isParticipant ? "calc(100% - 35px)" : "100%" : "35px" }}>
                        <ChatLayout isChat={isChat} open={chatOpen} handlerOpen={handlerChatOpen}
                            onClickChat={onClickChat}
                        />
                    </div>
                }                    
            </RightMenuStyled>
    )
}

const RightMenuStyled = styled.div`
    min-width: 330px;
    width: ${props => props.screenType === layoutType.mobile || props.isDualMonitor ? "100%" : "330px"};
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-left: 1px solid;

    & > * {
        background: #fff;
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
    }
    
    .participant {
        height: ${props => props.chatOpen && props.ppOpen ? "30%" : !props.chatOpen && props.ppOpen ? "100%" : "auto"}
    }

    .chat {
        height: ${props => props.chatOpen && props.ppOpen ? "70%" : props.chatOpen && !props.ppOpen ? "100%" : "auto"}
    }
`;

function _mapStateToProps(state, ownProps) {
    const isSharingVideo = state['features/screen-share'].sharingVideo;
    const { screenModal } = false;// state['features/video-layout-mode'];

    let isShow = ownProps.isShow || !(isSharingVideo && APP.mateManagement.getScreenSharing()) || !screenModal;
    const isChat = isChatOpen(state);
    const {isOpen: isParticipant} = state["features/participant-right"];
    
    isShow = isShow && (isChat || isParticipant);
    return {
        isChat,
        isParticipant,
        isShow
    }
}

export default connect(_mapStateToProps)(ConferenceRightMeun);
