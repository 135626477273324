import React from "react";
import { AbstractDialog } from "../../base/dialog";
import prevRecordInfo from "./prevRecordInfo.png";
import { useTranslation } from "react-i18next";
import { ClickButton } from "../../base/buttons";
import { useDispatch, useSelector } from "react-redux";
import { setRecordingEnabled, startServerRecord } from "../actions";
import { RECORDING_TYPE } from "../constants";

export default function PrevRecordPopup({
    type
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isRecord = useSelector(state => state['features/recording'][type].enabled);

    const onClick = () => {
        if (!isRecord === true) {
            dispatch(startServerRecord());

            APP.UI.closeModal();
            return;
        }
        


        dispatch(setRecordingEnabled(type, !isRecord));
        APP.UI.closeModal();
    }
    return (
        <AbstractDialog t={t} name={ "record" } width={800}>
            <img src={prevRecordInfo} />

            <ClickButton clsName="record" text={type === RECORDING_TYPE.PERSONAL ? t(`recording.${isRecord}Personal`) : t(`recording.${isRecord}Server`)} 
                size={30}
                onClick={onClick}/>
        </AbstractDialog>
    )
}