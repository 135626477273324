import { v4 as uuidv4 } from 'uuid';
import { getDisplayName } from '../../features/base/settings';
import { command } from './comand';
import { getLocalIDMember, getLocalMember } from '../../features/member';


export default class TempMate {
    constructor(group_id, handler) {
        this.connecting = false;
        this.group_id = group_id;

        this.connection = new TempConnection(group_id);
        this.events = new TempEvent(group_id, handler);
    }

    connectionAndJoin() {
        return this.connection.connectionAndJoin(this.events);
    }

    leave() {
        return this.events.leave();
    }
}

class TempEvent {
    constructor(group_id, handler) {
        this.onMessage = [];
        this.nextId = 0;
        this.websocket = null;
        this.group_id = group_id;
        this.handler = handler;
    }

    setWebocket(websocket) {
        this.websocket = websocket;
    }

    setAuth() {
        const access_token = APP.mateManagement.getAccessToken();

        return this.sendCommand(command['auth-setting'], { access_token });
    }

    join() {
        const nickname = getDisplayName(APP.store.getState()) || "test";
        const password = APP.store.getState()['features/room-lock'].password;

        return this.sendCommand(command['join-room'], { 
            password,
            nickname,
            type: "hidden",
            role_name_forced: null
        });
    }

    leave() {
        return this.sendCommand(command['left-room']);
    }

    reciveMessage(data) {
        this.handler.reciveMessage(data);
    }
    
    reciveCommand(message) {
        let data = JSON.parse(message?.data);
        // 이벤트 응답
        this.onMessage.forEach((handle) => {
            handle(message.data);
        });

        switch (data.class) {
            case `Event.${command['join-room']}ed`:
                this.handler.handlerJoinParticipant(data.payload);
                break;

            case `Event.${command['left-member-room']}`:
                this.handler.handlerLeftParticipant(data.payload);
                break;

            case `Event.${command['send-text']}`:
            case `Event.${command['send-private-text']}`:
                this.reciveMessage(data.payload);
                break;

            case `Event.${command['set-role']}`:
                this.handler.handlerChangeParticipantRole(data.payload);
                break;
            
           // 핀 모드 옵션 
            case `Event.${command['member-add-pin']}ed`:
                this.handler.handlerPin(data.payload, true);
                break;
            case `Event.${command['member-del-pin']}d`:
                this.handler.handlerPin(data.payload, false);
                break;
            /**
             * 손들기 
             */
            case `Event.${command['set-handler-up']}`:
                this.handler.handlerHandsup(data.payload, true);
                break;
            case `Event.${command['set-handler-down']}`:
                this.handler.handlerHandsup(data.payload, false);
                break;
        }
    }
    

    sendCommand(clazz, payload, timeout = 5000) {    
        try {
            const websocket = this.websocket;
            if (!websocket || websocket.readyState !== WebSocket.OPEN ) {
                console.log("웹소켓 연결 안됨.", payload)
                return Promise.resolve();
            }
    
            const meeting_id = APP.mateManagement.getMeetingId();
            const requestID = `${Date.now()}-${++this.nextId}`;

            websocket.send( 
                JSON.stringify({ 
                    class: clazz, 
                    id: requestID, 
                    payload: {
                        ...payload,
                        group_id: this.group_id,
                        meeting_id 
                    } 
                }) 
            );
    
            return this._waitFor((data) => JSON.parse(data), (res) => res?.payload?.id === requestID || res?.id === requestID, timeout, clazz);
        } catch (err) {
            console.log(err);
        }
    }

    _subscribe(handle) {
        this.onMessage.push(handle);
    
        return () => {
            const index = this.onMessage.findIndex((element) => {
                return element == handle;
            });
            
            if (index >= 0) {
                this.onMessage.splice(index, 1);
            }
        };
    }

    _waitFor(hook, check, timeout, clazz) {
        return new Promise((resolve, reject) => {
            let timerID;
            let unsubcribe;
            
            const cleanup = () => {
                clearTimeout(timerID);
    
                unsubcribe();
            };
    
            unsubcribe = this._subscribe((data) => {
                try {
                    const result = hook(data);
                    if (!check(result)) return;
              
                    cleanup();
                    resolve(result);
                } catch (err) {
                    cleanup();
                    reject(err);
                }
            });
            
            timerID = setTimeout(() => {
                cleanup();
                reject('time out' + clazz);
            }, timeout);
        });
    }
}

class TempConnection {
    constructor(group_id) {
        this.connect = null;
        this.connecting = false;

        this.groupId = group_id;
    }

    disconnect() {
        if (this.connect) this.connect.close();
    }

    connectionAndJoin(events) {
        if (this.connect) {
            return Promise.reject("연결이 되어있음.");
        }
    
        if (this.connecting) {
            return Promise.reject("이미 연결 중");
        }

        const websocket = new WebSocket(`wss://${location.host}/svc/room/ws${this.groupId ? `?${this.groupId}hidden` : ``}`);

        this.connecting = true;

        return new Promise((resolve, reject) => {
            this.connecting = false;
            this.connect = websocket;

            events.setWebocket(websocket);

            websocket.onopen = async () => {
                websocket.onclose = () => {
                    this.connect = null;
                    this.connecting = false;

                    this.disconnection();
                }

                websocket.onmessage = (message) => {                    
                    events.reciveCommand(message);
                }

                // 웹 소켓 연결이 성공 한 경우 (auth 세팅)
                const response = await events.setAuth();
                if (response.status === 200) {
                    const joinResponse = await events.join();

                    if (joinResponse.status === 200) {
                        resolve(joinResponse.payload);
                    } else {
                        reject("방 입장 오류");
                    }
                } else {
                    reject("인증 오류");
                }
            }

            websocket.onerror = (err) => {
                this.connect = null;
                reject(err);
            }
        });
    }
}

// TempMate.prototype.constructor = TempMate;

// TempMate.prototype.setHandler = function(handler) {
//     this.handler = handler;

//     // if (!this.connecting) this.connection();
// }

// TempMate.prototype.setWebsocket = function(websocket, connecting) {
//     this.websocket = websocket;
//     this.connecting = connecting;

//     if (connecting === false) {
//         this.group_id = null;
//     }
// }
// // 입장 
// TempMate.prototype.join = async function(group_id) {
//     if (!group_id) return;
//     this.group_id = group_id;
//     const nickname = getDisplayName(APP.store.getState());
//     const password = APP.store.getState()['features/room-lock'].password;

//     this.sendCommand(command['join-room'], { 
//         password,
//         nickname,
//         type: "hidden",
//         role_name_forced: null
// });
// }
// // 채팅 
// TempMate.prototype.sendTextMessage = function(message) {
//     this.sendCommand(command['send-text'], { message: JSON.stringify(message) });
// }
// TempMate.prototype.sendPrivateTextMessage = function(participants, message) {
//     participants.map(p => {
//         this.sendCommand(command['send-private-text'], { message: JSON.stringify(message), 
//             user_uuid: p.id });
//     });
// }

// // 연결 해제
// TempMate.prototype.leave =  function() {
//     this.sendCommand(command['left-room'], null);
// }
// // 연결
// TempMate.prototype.connection = function() {
//     const websocket = new WebSocket(`wss://${location.host}/svc/room/ws?group_${new Date().getTime()}`);

//     const handlerWebsocket = (websocket, connecting) => this.setWebsocket(websocket, connecting);
//     websocket.addEventListener('open', () => {
        
//         handlerWebsocket(websocket, true);

//         // set auth
//         const access_token = APP.mateManagement.getAccessToken();
        
//         this.sendCommand(command['auth-setting'], { access_token });
//     });

//     websocket.addEventListener('close', () => {
//         console.log("close")
//         handlerWebsocket(websocket, false);
//     });
    
//     websocket.addEventListener('error', ev => {
//         console.log(ev)
//         handlerWebsocket(websocket, false);
//     });

//     websocket.addEventListener('message', (event) => {
//         this.reciveCommand(event);   
//     }); 
// } 
// // 연결 끊기
// TempMate.prototype.disconnection = function() {

// }

// TempMate.prototype.sendCommand = function(name, payload) {
//     const websocket = this.websocket;
//     if (!websocket) return;
//     try {
//         const uuid = uuidv4();
//         const cmdReqData = Object.assign({
//             class: name,
//             id: uuid,
//             payload
//         });

//         const meeting_id = APP.mateManagement.getMeetingId();
        
//         cmdReqData.payload = {
//             ...payload,
//             meeting_id,
//             group_id: this.group_id
//         };
//         websocket.send(JSON.stringify(cmdReqData));
//     } catch (err) {
//         console.log(err);
//     }
// }

// TempMate.prototype.reciveCommand = function(event) {
//     let { data } = event;            

//     try {
//         data = JSON.parse(data);
//     } catch (err) {
//         console.log(err);
//         data = JSON.parse(data);
//     }

//     if (data.status && data.status !== 200 ) {
//         switch (data.class) {
//             case command['left-room']:
//                 return;

//             default:
//                 const message = data.payload?.message?.message?.format;
//                 if (message) alert(message);
//                 return;
//         }
//         return;
//     }
    
//     switch (data.class) {
//         case command['auth-setting']:
//             this.handler.showLoading(false);
//             break;

//         case command['join-room']:
//             this.handler.showLoading(false);
//             this.handler.setParticipant(data.payload.members, data.payload.group_id);
//             break;
        
//         case command['left-room']:
//             break;

//         case `Event.${command['send-text']}`:
//         case `Event.${command['send-private-text']}`:
//             this.handler.setChat(data.payload);
//             break;

//         case `Event.${command['join-room']}ed`:
//             this.handler.joinParticipant(data.payload);
//             break;
//         case `Event.${command['left-member-room']}`:
//             this.handler.leftParticipant(data.payload);

//         case `Event.${command['set-role']}`:
//             this.handler.changeParticipantRole(data.payload);
//     }
// }

