import styled from "styled-components";

export const DeviceStyled = styled.ul`
    text-align: center;
    .guid {
        // color: red;
        word-break: keep-all;
        letter-spacing: 1px;
        line-height: 23px;
        margin-bottom: 20px;
    }

    .device_wrapper {
        display: flex;        
        .item {
            flex: 1;
            .icon {
                margin: 12px 0;
                gap: 15px;
            }
        }
    }
`;

export const SettingStyled = styled.div`
    .titleGuid {
        color: #fff;
        text-align: center;
    }

    .setting {
        display: flex;
        margin: 12px 0;
    }
    .tap {
        display: flex;
        min-width: 25%;
        flex-direction: column;

        &_item {
            padding: 8px 12px;
            margin: 0;
            cursor: pointer;

            &:hover, &.on {
                background: #76acfb;
                color: #fff;
            }
        }
    }

    .setting_content {
        flex: 1;
        padding: 0 8px;
        box-sizing: border-box;
        border-left: 2px solid #fff;
    }

    .fadeout_text {
        display: flex;
        margin-bottom: 12px;
        justify-content: center;
        align-items: center;

        input {
            text-align: center;
            border: none;
            border-bottom: 2px solid #fff;
            background: transparent;
            padding: 6px;
            box-sizing: border-box;
            margin: auto;
            width: 50%;
            color: #fff;
        }
    }
`;