import { isCheckPermission } from "../base/localuser";
import { toState } from "../base/redux";
import { PERMISSION } from "../member";
import { SHARE_MODE_LIST, VIDEO_MODE_LIST, getCurrentMode, videoLayoutMode } from "../video-layout";

export function preventHWP(ext) {
    if (ext.toUpperCase().indexOf("HWP") !== -1) {
        if (!APP.UI.confirmMessage("share.hwpError")) {
            return true;
        }
    }

    return false;
}

export function getShareState(stateful: Function) {
    const state = toState(stateful);
    return state['features/share-document'];
}

/**
 * 문서 리스트 가져오기 
 * 
 * @param {Function} getState 
 * @param {String} mode 
 * @returns 
 */
export function getDocumentList(stateful: Function, mode) {
    const state = toState(stateful);
    if (mode === videoLayoutMode.document) return new Set([...state['features/share-document'].document]);
    else return new Set([...state['features/share-document'].whiteboard]);
}

/**
 * 문서 리스트 가져오기 
 * 
 * @param {Function} getState 
 * @param {String} mode 
 * @returns 
 */
 export function getVisibleDocumentList(stateful: Function) {
    const state = toState(stateful);
    const visibleList = state['features/share-document'].visibleList;

    return visibleList;
}

/**
 * 방 설정 권한 확인 (레이아웃에서만 사용)
 * @param {Function} stateful 
 * @param {String} type 
 * @returns Boolean
 */
export function checkSharePermission(stateful: Object, type: String) {
    return isCheckPermission(stateful, type);
}

/**
 * 문서 포커스 uuid
 * @param {Function} stateful 
 * @param {String} uuid 
 * @returns Boolean
 */
export function isFocusItemByUUID(stateful: Object, uuid: String) {
    const state = toState(stateful);

    const focusList = Object.values(state['features/share-document'].focus);
    return Boolean(focusList.find(i => i.uuid === uuid));
}

/**
 * 문서 포커스 
 * @param {Function} stateful 
 * @param {String} mode 
 * @returns Boolean
 */
export function getFocusItemByMode(stateful: Object, mode: String = 'document') {
    const state = toState(stateful);

    return state['features/share-document'].focus[mode];    
}

/**
 * uuid로 문서 아이템 가져오기 
 * @param {Function} stateful 
 * @param {String} uuid 
 * @returns 
 */
export function getDocumentItemByUUID(stateful: Object, uuid: String) {
    const state = toState(stateful);
    const visible_list = state['features/share-document'].visibleList;

    const list = [...visible_list].find(item => item.uuid === uuid);
    return list;
}



/**
 * 캔버스 리스트 파싱
 * @param {Array} canvasList
 * @returns
 */
export function parseCanvaseLine(canvasList) {
    let list = new Map();
    
    canvasList && Object.keys(canvasList).forEach(index => {
        const value = new Map();
        Object.keys(canvasList[index]).map(key => {
            value.set(key, canvasList[index][key]);
        });
        
        list.set(Number(index), value);
    });

    return list;
}

/**
 * 
 */
export function isFocusAblePage(stateful, uuid, page) {
    const document = getDocumentItemByUUID(stateful, uuid);

    if (page < 0) return false;
    if (document.list.length < page + 1) return false;
    return true;
}