export const initialAsyncState = {
    loading: false,
    data: null,
    error: null
}; 

export const loadingState = {
    loading: true,
    data: null,
    error: null
};
  
const success = data => ({
    loading: false,
    data
});
  
const error = error => ({
    loading: false,
    data: error
});

async function feedback(promiseFn, ...rest) {    
    let response;
    
    try {
        const data = await promiseFn(...rest);

        response = success(data);
    } catch (e) {
        console.log(e)
        if (e.response) {
            const data = {
                data: e.response.statusText,
                status: e.response.status
            }
            response = error(data);
        } else {
            response = error(e?.response?.status);
        } 
    }

    return response;
}


export default feedback;