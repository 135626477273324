import { MiddlewareRegistry } from "../base/redux";
import { SET_VIDEO_LAYOUT, videoLayoutMode } from "../video-layout";
import { stopScreenShare } from "./action";
import { getLocalSharingStatus, getStartScreenShare } from "./function";

MiddlewareRegistry.register(store => next => action => {
    switch (action.type) {
        // case SET_SHARE_SCREEN_ID:
        //     if (!action.isRemote) {
        //         if (action.userId) store.dispatch(setVideoLayoutMode(videoLayoutMode.screen));
        //     }
        //     break;

        case SET_VIDEO_LAYOUT:
            if (action.mode !== videoLayoutMode.screen && !action.isRemote) {
                if (getStartScreenShare(store.getState)) {
                    APP.store.dispatch(stopScreenShare());
                }
            }
            break;

    }

    return next(action);
});