import React from 'react';
import { Video } from '../../base/media';


/**
 * 비디오를 표시하기 위한 React 컴포넌트입니다. 이 구성 요소는 비디오 렌더링을 위한 lib-jitsi-meet 논리를 따릅니다.
 * @param {Object} 
 * {
 *  error: ?string,
 *  track: Object
 * }
 * @returns 
 */
export default function VideoInputPreview({
    error, track
}) {
    return (
        <div className='video_input'>
            { !error && track && <Video
                className = 'video-input-preview-display flipVideoX'
                playsinline = { true }
                videoTrack = {{ track }} /> }  
        </div>
    )
}