// @flow
import {
    SET_CONFERENCE_ERROR,

    CONFERENCE_WILL_JOIN,

    CONFERENCE_FAILED,
    CONFERENCE_JOINED,
    CONFERENCE_LEFT,
    CONFERENCE_SUBJECT_CHANGED,
    CONFERENCE_TIMESTAMP_CHANGED,
    
    KICKED_OUT,
    SET_ROOM,
    CLEAR_ROOM,
    SET_GROUP_UUID,
    SET_MAIN_ROLE,
    SET_INIT_ROOM,
    INIT_ROOM,
    CONFERENCE_ALL_JOINED
} from './actionTypes';

// 그룹 입장
export function setGroupId(groupId) {
    return {
        type: SET_GROUP_UUID,
        groupId
    }
}

// 회의실 참여 예정
export function conferenceWillJoin(conference, serverType) {
    return {
        type: CONFERENCE_WILL_JOIN,
        conference,
        serverType        
    };
}

// 방 입장 
export function conferenceJoined(conference, serverType) {
    return {
        type: CONFERENCE_JOINED,
        conference,
        serverType
    };
}

// 회의실 나가기
export function conferenceLeft(conference) {
    return {
        type: CONFERENCE_LEFT,
        conference
    };
}


export function setConferenceError(error) {
    return {
        type: SET_CONFERENCE_ERROR,
        error
    }
}

// 회의실 입장 시간 변경
export function conferenceTimestampChanged(conferenceTimestamp) {
    return {
        type: CONFERENCE_TIMESTAMP_CHANGED,
        conferenceTimestamp
    };
}
// 방 이름 변경
export function conferenceSubjectChanged(subject) {
    return {
        type: CONFERENCE_SUBJECT_CHANGED,
        subject
    };
}

// 회의실 입장 실패
export function conferenceFailed(conference, error, ...params) {
    return {
        type: CONFERENCE_FAILED,
        conference,
        error: {
            name: error,
            params,
            recoverable: undefined
        }
    };
}

// 방 이름 설정 
export function setRoom(room, actionType) {
    return {
        type: SET_ROOM,
        room,
        actionType
    };
}

export function kickedOut(conference, participant) {
    return {
        type: KICKED_OUT,
        conference,
        participant
    };
}
export function clearRoom() {
    return {
        type: CLEAR_ROOM
    }
}

export function setMainRole(role) {
    return {
        type: SET_MAIN_ROLE,
        role
    }
}

export function initRoom(data, user_uuid) {
    return {
        type: INIT_ROOM,
        data, user_uuid
    }
}

export function setRoomJoined(joined) {
    return {
        type: CONFERENCE_ALL_JOINED,
        joined
    }
}