// @flow
import { toState } from '../redux';

import { DEFAULT_SERVER_URL } from './constants';

export function getPropertyValue(state, propertyName, sources) {
    sources = {
        config: true,
        settings: true,
        urlParams: true,

        ...sources
    };
    
    if (sources.settings) {
        const value = state['features/base/settings'][propertyName];
        if (typeof value !== 'undefined') {
            return value;
        }
    }


    return undefined;
}

export function getServerURL(stateful) {
    const state = toState(stateful);

    return state['features/base/settings'].serverURL || DEFAULT_SERVER_URL;
}

export function getUserSelectedCameraDeviceId(stateful) {
    const state = toState(stateful);
    const {
        userSelectedCameraDeviceId,
        userSelectedCameraDeviceLabel
    } = state['features/base/settings'];
    const { videoInput } = state['features/base/devices'].availableDevices;

    return _getUserSelectedDeviceId({
        availableDevices: videoInput,
        matchRegex: /\s#\d*(?!.*\s#\d*)/,
        userSelectedDeviceId: userSelectedCameraDeviceId,
        userSelectedDeviceLabel: userSelectedCameraDeviceLabel,
        replacement: ''
    });
}

export function getUserSelectedMicDeviceId(stateful) {
    const state = toState(stateful);
    const {
        userSelectedMicDeviceId,
        userSelectedMicDeviceLabel
    } = state['features/base/settings'];
    const { audioInput } = state['features/base/devices'].availableDevices;

    return _getUserSelectedDeviceId({
        availableDevices: audioInput,
        matchRegex: /\s\(\d*-\s(?!.*\s\(\d*-\s)/,
        userSelectedDeviceId: userSelectedMicDeviceId,
        userSelectedDeviceLabel: userSelectedMicDeviceLabel,
        replacement: ' ('
    });
}

export function getUserSelectedOutputDeviceId(stateful) {
    const state = toState(stateful);
    const {
        userSelectedAudioOutputDeviceId,
        userSelectedAudioOutputDeviceLabel
    } = state['features/base/settings'];
    const { audioOutput } = state['features/base/devices'].availableDevices;

    return _getUserSelectedDeviceId({
        availableDevices: audioOutput,
        matchRegex: undefined,
        userSelectedDeviceId: userSelectedAudioOutputDeviceId,
        userSelectedDeviceLabel: userSelectedAudioOutputDeviceLabel,
        replacement: undefined
    });
}

function _getUserSelectedDeviceId(options) {
    const {
        availableDevices,
        matchRegex,
        userSelectedDeviceId,
        userSelectedDeviceLabel,
        replacement
    } = options;

    if (!userSelectedDeviceLabel || !userSelectedDeviceId) {
        return userSelectedDeviceId;
    }

    const foundMatchingBasedonDeviceId = availableDevices.find(
        candidate => candidate.deviceId === userSelectedDeviceId);

    if (foundMatchingBasedonDeviceId) {
        return userSelectedDeviceId;
    }

    const strippedDeviceLabel
        = matchRegex ? userSelectedDeviceLabel.replace(matchRegex, replacement)
            : userSelectedDeviceLabel;
    const foundMatchBasedOnLabel = availableDevices.find(candidate => {
        const { label } = candidate;

        if (!label) {
            return false;
        } else if (strippedDeviceLabel === label) {
            return true;
        }

        const strippedCandidateLabel
            = label.replace(matchRegex, replacement);

        return strippedDeviceLabel === strippedCandidateLabel;
    });

    return foundMatchBasedOnLabel
        ? foundMatchBasedOnLabel.deviceId : userSelectedDeviceId;
}

export function getCurrentCameraDeviceId(state) {
    return getDeviceIdByType(state, 'isVideoTrack');
}

export function getCurrentMicDeviceId(state) {
    return getDeviceIdByType(state, 'isAudioTrack');
}

export function getCurrentOutputDeviceId(state) {
    return state['features/base/settings'].audioOutputDeviceId;
}

function getDeviceIdByType(state, isType) {
    const [ deviceId ] = state['features/base/tracks']
        .map(t => t.track)
        .filter(t => t && t.isLocal() && t[isType]())
        .map(t => t.getDeviceId());

    return deviceId || '';
}


export function getDisplayName(stateful) {
    const state = toState(stateful);
    return state['features/base/settings'].displayName || '';
}

export function handleCallIntegrationChange(disabled) { // eslint-disable-line no-unused-vars
}
export function handleCrashReportingChange(disabled) { // eslint-disable-line no-unused-vars
}