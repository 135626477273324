const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validPwdRegx = RegExp(/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{6,16}$/i);
const validIdRegex = RegExp(/^[A-Za-z0-9+]{2,20}$/i);

/**
 * 유효성 검사 
 * @param {string} name 
 * @param {string} value 
 * @returns 
 */
export function formValidation(t, name, value, state, isShowEmail) {
    let message = "";

    
    if (value === "" && name !== "nickname" && name !== "profile") message = t("common.inputErrEssential");
    
    else {
        switch (name) {
            case "id":
                if (!validIdRegex.test(value)) {
                    message = "2~20자의 영문 대소문자, 숫자만 사용 가능합니다.";
                }
                break;
            case "password":
                if (!validPwdRegx.test(value)) {
                    message = t("account.inputErrPasswordRule");
                }
                break;  
                
            case "rePassword":
                const password = state.password;
                if (password !== value) {
                    message = t("account.inputErrDifferentPassword");
                }    
                break;                    

            case "email":
                if (isShowEmail && !validEmailRegex.test(value)) {
                    message = t("account.inputErrNotCorretEmail");
                } 
                break;
        
            default:
                break;
        }
    }

    return message;
}

export function getRemainingTime(expirationUnixTime) {
    const curUnixTime = Math.floor(new Date().getTime() / 1000);
    const remainingTime = expirationUnixTime - curUnixTime;

    return remainingTime;
}

export function formatTime(time) {    
    if (time === 0) return 0;

    let hours = parseInt(time / 60);
    let minutes = time % 60;

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return `${hours} : ${minutes}`;    
}