import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClickButton } from "../../base/buttons";
import { getGroupIdsList } from "../functions";
import { setGroupStatus } from "../actions";

export default function StatusGroupButton({
    clsName, t, alertMessage, type
}) {
    const dispatch = useDispatch();
    const list = useSelector(state => getGroupIdsList(state));

    const onUpdateGroup = async () => {
        dispatch(setGroupStatus(type === 'open' ? true : false, alertMessage));        
    }

    return (
        <ClickButton clsName={clsName} text={t(`group.${type}`)} line={true} size={24} 
            background="transparent" color="#ff4040" 
            hBackground="#ff4040" hColor="#fff" 
            onClick={onUpdateGroup}/>
    )
}