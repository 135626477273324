import React from "react";

export default class AbstractSwitchButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: null
        };

        this._onClick = this._onClick.bind(this);
    }

    load(mode) {
        this.setState({
            mode: mode
        });
    }

    _onClick() {
        APP.UI.openModal(this.state.mode);
    }
}

