import React from "react";
import styled from "styled-components";
import { translate } from "../../../base/i18n";
import { removeAttendancesNotification } from "../../../notifications";

const CheckStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    .guid {
        margin: 0 auto;
        white-space: pre-line;
        text-align: center;
    }

    .btn {margin: 0 auto;}

    .btn_bundle {
        display: inline-flex;
        flex-direction: rows;
        gap: 8px;
    }
`;

function PromptAlertAttendances({ t, dispatch, uuid, name }) {
    const onClick = () => {
        APP.API.checkedAttendances(uuid)
            .then(({ complete, message }) => {
                
                if (!complete) {
                    alert(t(message));
                    return;
                }                
                dispatch(removeAttendancesNotification());
            });
    }

    return (
        <>
            <CheckStyled>
                <span className="guid">{ t("attendances.requestGuid", { name: t(`attendances.${name}`)}) }</span>
                <button className="btn btn_middle btn_bundle" onClick={onClick}>{ t("common.okay") }</button>
            </CheckStyled>
        </>
    )
}

export default translate(PromptAlertAttendances);