import { PERMISSION } from '../../member';

import { addDocumentItem } from '../../share-document';
import { isCheckPermission } from '../localuser';
import { setFocusVod } from './action';
import { getMediaItem } from './function';
// import watchMate from '../../../server/mate/WatchMate';
// import { getRoomOption } from '../conference';
// import { getLocalUserUUID, getUserAccessToken, isCheckPermission } from '../localuser';
// import { getLocalParticipantId, PERMISSION } from '../participants';
// import { MiddlewareRegistry } from '../redux';
// import { setFocusVod, setVodList } from './action';
// import {
//     DEL_VOD_LIST,
//     SET_FOCUS_VOD_UUID,
//     SET_UPLOAD_FILE,
//     SET_UPLOAD_URL,
//     SET_VOD_LAYOUT_OPEN
// } from './actionTypes';
// import { PLAYBACK_STATUSES, VOD_FILE, VOD_URL } from './constants';
// import { getChannelID, getMediaItem } from './function';

const { MiddlewareRegistry } = require("../redux");
const { SET_VOD_LIST, SET_FOCUS_VOD_UUID } = require("./actionTypes");

MiddlewareRegistry.register(store => next => async action => {
    const { dispatch, getState } = store;
    const state = getState();

    switch (action.type) {
        case SET_VOD_LIST:
            console.log(action)
            const document_data = {
                uuid: action.list.uuid,
                file: { name: action.list.name },
                shared: true,
                complete: false,
                config: "vod"
            };

            dispatch(addDocumentItem(document_data));
            break;

        case SET_FOCUS_VOD_UUID:
            if (!action.isRemote) {
                const item = getMediaItem(state, action.uuid);
                
                if (!item && action.uuid) {
                    dispatch(setFocusVod(null));
                    return;
                }
                if (isCheckPermission(state, PERMISSION.SHARE)) APP.mateManagement.selectVodItem(action.uuid);
            }

            break;
//         // case SET_VOD_LAYOUT_OPEN:
//         //     const open = action.open;
//         //     if (open === false) {
//         //         watchMate.disconnect();
//         //         break;
//         //     }

//         //     const userAToken = getUserAccessToken(state);
//         //     watchMate.connect(userAToken)
//         //         .then(async () => {
//         //             if (isCheckPermission(state, PERMISSION.SHARE)) {
//         //                 const { uuid: roomUuid } = getRoomOption(state);
//         //                 watchMate.getMediaChannel(roomUuid);

//         //                 // 미디어 목록 불러오기
//         //                 // const list = await watchMate.getMediaList();
//         //                 // dispatch set media list (merge);
//         //             }
//         //         });
//         //     break;

//         // case SET_UPLOAD_FILE:
//         //     if (action.file) {
//         //         APP.UI.showLoading(true);
//         //         APP.UI.closeModal();

//         //         watchMate.uploadMedia(action.file)
//         //             .then(data => {
//         //                 // 변환 전 업로드
//         //                 const mediaIDs = data.result.id;

//         //                 const newFile = {
//         //                     uuid: mediaIDs,
//         //                     name: action.file.name,
//         //                     channel_id: null,
//         //                     type: VOD_FILE,
//         //                 };
//         //                 dispatch(setVodList(newFile));
//         //                 APP.UI.showLoading(false); 

//         //                 setTimeout(() => {
//         //                     watchMate.changeMediaList(mediaIDs)
//         //                         .then(item => {
//         //                             const newFile = {
//         //                                 uuid: mediaIDs,
//         //                                 name: action.file.name,
//         //                                 channel_id: item.channelID,
//         //                                 type: VOD_FILE,
//         //                             };

//         //                             // check permission
//         //                             if (isCheckPermission(state, PERMISSION.SHARE)) APP.mateManagement.setVodList(newFile);
//         //                             dispatch(setVodList(newFile));
//         //                         });
//         //                 }, 100000);
//         //                 return;


//         //                 // watchMate
//         //                     // .changeMediaList(mediaIDs)
//         //                 //     .then(item => {
//         //                 //         const newFile = {
//         //                 //             uuid: mediaIDs,
//         //                 //             name: action.file.name,
//         //                 //             channel_id: item.channelID,
//         //                 //             type: VOD_FILE,
//         //                 //         };

//         //                 //         // check permission
//         //                 //         if (
//         //                 //             isCheckPermission(
//         //                 //                 state,
//         //                 //                 PERMISSION.SHARE,
//         //                 //             )
//         //                 //         )
//         //                 //             APP.mateManagement.setVodList(newFile);
//         //                 //         dispatch(setVodList(newFile));

//         //                 //         APP.UI.showLoading(false);
//         //                 //         f;
//         //                 //         return;
//         //                 //     })
//         //                 //     .catch(err => {
//         //                 //         console.log(err);

//         //                 //         APP.UI.showLoading(false);
//         //                 //         return;
//         //                 //     });
//         //             })
//         //             .catch(err => {
//         //                 console.log(err);

//         //                 APP.UI.showLoading(false);
//         //                 return;
//         //             });
//         //     }
//         //     break;

//         // case SET_UPLOAD_URL:
//         //         const user_uuid = getLocalUserUUID(state);

//         //         const uuid = v4();
//         //         const newFile = { 
//         //             uuid,
//         //             url: action.url,
//         //             muted: false,
//         //             user_uuid,
//         //             time: 0,
//         //             volume: 0,
//         //             status: PLAYBACK_STATUSES.STOPPED,
//         //             type: VOD_URL
//         //         }

//         //         dispatch(setVodList(newFile));
//         //         if (isCheckPermission(state, PERMISSION.SHARE)) APP.mateManagement.setVodList(newFile);
//         //     break;
            
//         case SET_FOCUS_VOD_UUID:
//             if (!action.isRemote) {
//                 const item = getMediaItem(state, action.uuid);
                
//                 if (!item && action.uuid) {
//                     dispatch(setFocusVod(null));
//                     return;
//                 }
//                 // if (item) {
//                 //     if (item.type === VOD_FILE) {
//                 //         const channelID = item.channel_id;
    
//                 //         const { status, message } = await watchMate.selectMedia(channelID, action.uuid);
    
//                 //         if (status === false) return;
//                 //     }
//                 // }                

//                 if (isCheckPermission(state, PERMISSION.SHARE)) APP.mateManagement.selectVodItem(action.uuid);
//             }

//             break;

//         // case DEL_VOD_LIST:
//         //     if (!action.isRemote) {
//         //         if (isCheckPermission(state, PERMISSION.SHARE)) APP.mateManagement.deleteVodItem(action.uuid);
//         //     }
//         //     break;
    }

    return next(action);
});
