import type { Dispatch } from 'redux';
import { addDocumentFile, addDocumentItem, removeShareItem, setFocusShareItem, updateShareItem } from './actions';
import { getDocumentItemByUUID, getDocumentList, preventHWP } from "./functions";
import { videoLayoutMode } from '../video-layout';

export default {
    addDoumentFile: async (dispatch: Dispatch, file: Object) => {
        return new Promise(async (resolve, reject) => {
            if (!file) return reject({ message: 'noFile', uuid: null });
    
            const extArr = file.name.split("\.");
            const ext = extArr[extArr.length - 1];
    
            if (preventHWP(ext)) return reject({ message: 'hwpfile', uuid: null });
    
            // 문서 저장 
            const response = await dispatch(addDocumentFile(file));

            if (response === true) {
                resolve(response);
            } else {
                reject(response);
            }            
        })
    }, 
    addDocoumetItem: (dispatch: Dispatch) => {
        dispatch(addDocumentItem({ config: videoLayoutMode.white }));
    },
    deleteShareItem: (dispatch: Dispatch, uuid: String) => {
        dispatch(removeShareItem(uuid));
    },
    focusShareItem: (dispatch: Dispatch, uuid: String, index: String, mode: String) => {
        dispatch(setFocusShareItem(uuid, index, mode));
    },
    currentShareItem: (dispatch: Dispatch, uuid: String, isWhiteBoard: Boolean) => {
        dispatch(updateShareItem(uuid, { uuid, shared: true, isWhiteBoard }));    
    }
}
