import { CHECKING_ROOM_PASSWORD_MODAL_ID, DEVICE_SETTING_MODAL_ID, SET_SCREEN_TYPE_MODAL_ID } from "../../options";
import { setRoomPassword } from "../../room-lock";
import { CLEAR_ROOM, getCurrentConference } from "../conference";
import { ReducerRegistry, set, StateListenerRegistry } from "../redux";
import { CLOSE_MODAL, SET_MODAL_NAME } from "./actionTypes";

const DEFAULT_STATE = {
    name: null
};

ReducerRegistry.register("features/base/modal", (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_MODAL_NAME:
            return set(state, "name", action.name);

        case CLEAR_ROOM:
        case CLOSE_MODAL:
            return {
                name: null
            }
    }
    return state;
});

StateListenerRegistry.register(state => state['features/base/modal'].name,
    (name, { dispatch, getState }, prevName) => {
        if (prevName === CHECKING_ROOM_PASSWORD_MODAL_ID && name === null) {
            const conferenceJoined = getCurrentConference(getState());
            if (!conferenceJoined) dispatch(setRoomPassword());
        }
});