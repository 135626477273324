import React, { useCallback, useState } from "react";
import "../../../../assets/css/account.scss";
import background from "../../../../assets/images/bg/bg_login.jpg";
import { translate } from "../../../base/i18n";
import { setLocalUserProperty } from "../../../base/localuser/action";
import { updateModalState } from "../../../base/modal/actions";
import { connect } from "../../../base/redux";
import { SIGN_UP_MODAL_ID } from "../../../options";
import { formValidation } from "../function";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

function Login({ 
    isModal, onClose 
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const style = !isModal ? {
        background: `url(${background}) no-repeat 65% center/cover`,
        width: "100%", height: "100%"
    } : {width: "380px", height: "auto"};  

    const [error, setError] = useState({id: "", password: ""});
    const [data, setData] = useState({id: "", password: ""});

    const setLoginError = (type, value) => setError({
        ...error, 
        [type]: value
    });

    const setLoginData = (type, value) => setData({
        ...data, 
        [type]: value
    });

    const login = useCallback(async () => {
        let isLogin = true;

        for (const [ key, value ] of Object.entries(data)) {                
            const message = formValidation(t, key, value);
    
            if (message !== "") isLogin = false;
            if (error[key] !== message) setLoginError(key, message);
        }  

        if (isLogin) {
            const { key, complete, message } = await APP.API.login(data);
            
            if (key !== "all") setLoginError(key, message);
            else {
                if (complete) {
                    const token = message;
                    dispatch(setLocalUserProperty({ token }));

                    if (isModal) onClose();
                    else location.href = `/${location.search}`; 
                    return;  
                }
                alert(t(message));
            }
        }
    }, [ data, error ]);

    const signup = () => {
        if (isModal) dispatch(updateModalState(SIGN_UP_MODAL_ID));
        else location.href = `/signup${location.search}`;
    }

    const onChange = useCallback(e => {
        const { name, value } = e.target;
        setLoginData(name, value);

        const message = formValidation(t, name, value);
        setLoginError(name, message);
    }, [ data, error ]);

    const onEnter = e => {
        e.preventDefault();
        if (e.keyCode == 13) {
            login(e);
            return true;
        }
        
        return false;
    };

    return (
        <>
            <div style={style}>
                <div className="account login center">
                    <div className="option_header">
                        <h3 className="title">{ t("account.login") }</h3>
                        <ul>
                            {isModal ?
                                <li onClick={onClose}>{ t("dialog.close") }</li>
                            : null}
                        </ul>
                    </div>
                    <div className="option_content">
                        <p className="guid">{ t("account.loginGuid") }</p>
                                            
                        <form>
                            <table className="nonSelect">
                                <tbody>
                                    <tr>
                                        <th>{ t("account.id") }</th>
                                        <td>
                                            <input
                                                type="text"
                                                name="id"
                                                placeholder={ t("account.placeholderId") }
                                                onChange={onChange}
                                                onKeyUp={onEnter}
                                            />
                                            <p>{t(error.id)}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{ t("account.password") }</th>
                                        <td>
                                            <input
                                                type="password"
                                                name="password"
                                                placeholder={ t("account.placeholderInputPassword") }
                                                autoComplete="off"
                                                onChange={onChange}
                                                onKeyUp={onEnter}
                                            />
                                            <p>{t(error.password)}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                    <div className="option_footer">
                        <p className="btn btn_large" onClick={login} >
                            { t("account.login") }
                        </p>
                
                        <ul className="sub_area">
                            {/* <li>비밀번호 찾기</li> */}
                            <li onClick={signup}>{ t("account.signup") }</li>
                        </ul>
                    </div>
                </div>
            </div>
        </> 
    )
}

export default Login;