// @flow
import React from 'react';
import { MdInfo, MdWarning, MdAddCircleOutline } from 'react-icons/md';

import { translate } from '../../../base/i18n';
import { NOTIFICATION_TYPE } from '../../constants';
import AbstractNotification from '../AbstractNotification';

/**
 * 알림 아이콘의 보조 색상입니다
 */
const ICON_COLOR = {
    error: '#000',
    info: '#000',
    normal: '#000',
    success: '#000',
    warning: '#fff'
};

class Notification extends AbstractNotification {
    render() {
        const {t} = this.props;
        const {
            appearance,
            component,
            title,
            titleArguments,
            titleKey,
            uid,
            order
        } = this.props.data;
        
        const appear = appearance === undefined ? 'normal' : appearance;
        const secIconColor = ICON_COLOR[appearance];
        return (
            <div className={`item ${appear}`} style={{ order: `${order ? order : 1}` }}>
                 <div className="title">
                    <span style ={{ display: "inline-flex", color: `'${secIconColor}'`}}> { this._mapAppearanceToIcon() }</span>
                    <span>{ title || t(titleKey, titleArguments) }</span>
                </div>
                
                {component && <>                        
                        {component} 
                    </>                   
                }
                { this._renderDescription() }
            </div>
        );
    }

    _renderDescription() {
        const description = this._getDescription();
        
        return (
            <div className="description">
                {description.map((value, index) => {
                    return (
                        <span key={index}>{ value }</span>
                    )
                })
                }
            </div>
        );
    }

    _onOpenSupportLink() {
        window.open(interfaceConfig.SUPPORT_URL, '_blank', 'noopener');
    }

    _mapAppearanceToIcon() {
        const appearance = this.props.appearance;
        const iconSize = 20;

        switch (appearance) {
            case NOTIFICATION_TYPE.ERROR:
                return (
                    <MdAddCircleOutline
                        label = { appearance }                        
                        size = { iconSize } />
                );

            case NOTIFICATION_TYPE.WARNING:
                return (
                    <MdWarning
                        label = { appearance }
                        size = { iconSize } />
                );

            default:
                return (
                    <MdInfo
                        label = { appearance }
                        size = { iconSize } />
                );
            }
    }
}

export default translate(Notification);
