import { CLEAR_ROOM, CONFERENCE_LEFT } from "../base/conference";
import { ReducerRegistry, StateListenerRegistry } from "../base/redux";
import { ADD_LOCAL_MEMBER, ADD_MEMBER, ADD_PINNED, ADD_RECORD, DELETE_MEMBER, DELETE_PINNED, DELETE_RECORD, LEFT_MEMBER, SET_BRIDGE_ID, SET_GROUP_MEMBERS, SET_MEMBER_VOICE, UPDATE_MEMBER } from "./actionType";
import { setStatusList } from "./actions";
import { PARTICIPANT_STATUS, ROLE } from "./constants";
import { getMemberList } from "./functions";

const DEFAULT_STATE = {
    local: null,
    remote: new Set(),
    pinList: new Set(),
    hostList: new Set(),
    recordList: new Set(),
    voice: null,

    members: new Map(),
    bridgeList: new Map(),
    memberIDs: new Map(),
    group: new Map()
};

ReducerRegistry.register("features/memeber", (state = DEFAULT_STATE, action) => {
    let member;
    let memberState = state;
    switch (action.type) {
        case ADD_LOCAL_MEMBER:
            member = action.member;
            memberState.local = member.user_uuid;
            memberState.members.set(member.user_uuid, member);
            memberState.memberIDs.set(member.member_uuid, member.user_uuid);
            if (member.role === ROLE.HOST) state.hostList.add(member.user_uuid);

            return {
                ...memberState 
            };

        case ADD_MEMBER:
            member = action.member;
            
            memberState.members.set(member.user_uuid, member);
            memberState.memberIDs.set(member.member_uuid, member.user_uuid);
            memberState.remote.add(member.user_uuid);

            if (member?.jitsi_id && member?.jitsi_id !== "") memberState.bridgeList.set(member.user_uuid, member.jitsi_id);
            if (member.role === ROLE.HOST) memberState.hostList.add(member.user_uuid);

            return {
                ...memberState 
            }

        case LEFT_MEMBER:
            member = action.member;

            memberState.memberIDs.delete(member.member_uuid);
            memberState.hostList.delete(member.user_uuid);
            memberState.pinList.delete(member.user_uuid);
            if (memberState.voice === member.user_uuid) memberState.voice = null;
            return {
                ...memberState
            }

        case DELETE_MEMBER:
            member = action.member;

            memberState.memberIDs.delete(member.member_uuid);
            memberState.hostList.delete(member.user_uuid);
            memberState.pinList.delete(member.user_uuid);
            if (memberState.voice === member.user_uuid) memberState.voice = null;
            return {
                ...memberState
            }
    
        case ADD_PINNED:
            memberState.pinList.add(action.user_uuid);
            return {
                ...memberState 
            };
        
        case DELETE_PINNED:
            memberState.pinList.delete(action.user_uuid);
            return {
                ...memberState 
            };

        case ADD_RECORD:
            memberState.recordList.add(action.user_uuid);
            return {
                ...memberState
            };

        case DELETE_RECORD:
            memberState.recordList.delete(action.user_uuid);
            return {
                ...memberState
            };

        case UPDATE_MEMBER:
            member = action.member;

            if (!member.user_uuid) break;
            if (member.role === ROLE.HOST) memberState.hostList.add(member.user_uuid);

            memberState.members.set(member.user_uuid, member);
            return {
                ...memberState 
            };

        case SET_BRIDGE_ID: 
            memberState.bridgeList.set(action.user_uuid, action.bridge_session_id);
            return {
                ...memberState 
            };

        case SET_MEMBER_VOICE:
            memberState.voice = action.user_uuid;
            return {
                ...memberState 
            };

        case SET_GROUP_MEMBERS:
            memberState.group = action.members;

            return {
                ...memberState 
            };

        case CONFERENCE_LEFT:
            return {
                local: null,
                remote: new Set(),
                pinList: new Set(),
                hostList: new Set(),
                recordList: new Set(),
                voice: null,

                members: new Map(),
                bridgeList: new Map(),
                memberIDs: new Map(),
                group: new Map()
            }
    }

    return state;
});