import axios from "axios";

export async function fileConvertUrl(file) {
    const response = await axios({
        method: "POST",
        url: "/fileshare/",
        data: file
    });

    return {
        status: response.status,
        data: response.data,
    };
}

export async function getDownloadFile({ id, name }) {
    fetch(`/fileshare/download/${id}/${name}`)
        .then(response => response.blob())
        .then(result => {
            var uri = URL.createObjectURL(result);
            var link = document.createElement("a");
            link.setAttribute("href", uri);
            link.download = name;  
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(error => alert("파일 다운로드에 실패했습니다"));
}