export const PART_ERASER = { name: 'part_eraser' };
export const LINE_ERASER = { name: 'line_eraser' };

export const RECT = { name : 'rectangle' };
export const F_RECT = { name : 'fill_rectangle' };
export const CIRCLE = { name : 'circle' };
export const F_CIRCLE = { name : 'fill_circle' };
export const TRIANGLE = { name : 'triangle' };
export const F_TRIANGLE = { name : 'fill_triangle' };

export const HANDLER = { name: 'handler' };
export const CHOICE = { name: 'choice' };
export const PEN = { name: 'pen' };
export const THICKNESS = { name: 'thickness', children: true };
export const OPACITY = { name: 'opacity', children: true };
export const COLOR = { name: 'color', children: true }; 
export const ERASER = { name: 'eraser', children: [PART_ERASER, LINE_ERASER]};
export const ALL_ERASER = { name: 'all_eraser' };
export const FIGURE =  { name: 'figure', children: [RECT, F_RECT, CIRCLE, F_CIRCLE, TRIANGLE, F_TRIANGLE]};
export const TEXT = { name: 'text' };
export const ZOOM = { name: 'zoom', children: true };

export const canvas_tool_list = [HANDLER, CHOICE, PEN, THICKNESS, OPACITY, COLOR, ERASER, ALL_ERASER, FIGURE, TEXT, ZOOM];

export const canvas_tool_mobile_list = [{
    name: `${HANDLER.name}`,
    children: [HANDLER, CHOICE]
}, {
    name: `${PEN.name}`,
    children: [PEN, THICKNESS, OPACITY, COLOR, FIGURE]
}, ERASER, ALL_ERASER, TEXT, ZOOM];

export const pen_list = [
    THICKNESS.name, OPACITY.name, COLOR.name, ZOOM.name
];

export const FIGURE_LIST = [
    RECT.name, F_RECT.name, CIRCLE.name, F_CIRCLE.name, TRIANGLE.name, F_TRIANGLE.name
];
  
export const p_canvas_tool_list = [HANDLER, CHOICE, PEN, THICKNESS, OPACITY, COLOR, LINE_ERASER, FIGURE, TEXT, ZOOM];
export const p_canvas_tool_mobile_list = [{
    name: `${HANDLER.name}`,
    children: [HANDLER, CHOICE]
}, {
    name: `${PEN.name}`,
    children: [PEN, THICKNESS, OPACITY, COLOR, FIGURE]
}, ERASER,  TEXT, ZOOM];