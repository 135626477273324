import React, { useEffect, useState } from 'react';
import { connect } from '../../base/redux';
import { getDocumentList, getFocusItemByMode } from '../functions';
import DocumentItem from './ShareDocumentItem';

function ShareDocumentList({
    sharePermission, mode,
    share_list, focusItem
}) {
    const [focus, setFocus] = useState(null);

    useEffect(() => {
        if (focus && !share_list.find(uuid => uuid === focus)) {
            setFocus(null);
        }
    }, [share_list]);
    return (    
        <div style={{ width: "100%", height: "100%", overflow: "auto" }}> 
            { share_list && share_list.map(uuid => {                
                return <DocumentItem key={uuid} uuid={uuid} mode={mode}
                    focusUuid={focusItem.uuid}    
                    focusIndex={focusItem.index}
                    focus={focus}
                    isFocusItem={focusItem.uuid === uuid}
                    isFocus={uuid === focus} setFocus={setFocus} 
                    isSharedPermission={sharePermission} /> 
            })}
        </div>
    );
}

function _mapStateToProps(state: Object, ownProps) {
    const share_list = getDocumentList(state, ownProps.mode);
    const focusItem = getFocusItemByMode(state, ownProps.mode);

    return {
        share_list: [...share_list] || [],
        focusItem
    }
}

export default connect(_mapStateToProps)(ShareDocumentList);