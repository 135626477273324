import { toState } from '../redux';

// (get) 회의 시작 시간
export function getConferenceTimestamp(stateful) {
    const state = toState(stateful);
    
    const { conferenceTimestamp } = getConferenceState(state);

    return conferenceTimestamp;
}
export function getConferenceError(state) {
    const { error } = getConferenceState(state);

    return error;
}

export const getConferenceState = (state) => state['features/base/conference'];

// 회의 가져오기
export const getJitsiRoom = (state) => {
    try {
        const conference = getConferenceState(state).conference[APP.mateOption.mediaServer];
        return conference;
    } catch {
        return null;
    }
};

// 회의 가져오기
export const getMateRoom = (state) => {
    try {
        const conference = getConferenceState(state).conference[APP.mateOption.mateServer];
        return conference;
    } catch {
        return null;
    }
};

// 회의 이름 url통해서 가져오기
export function getRoomNameFromUrl(pathname) {
    try {
        const list = pathname.split('/');
        return list[2];
    } catch {
        return false;
    }
}

/**
 * 현재 방 이름 가져오기
 */
export function getRoomOption(state) {
    const { options } = state["features/base/conference"];

    return options;
}

export function getMediaServerInfo(state) {
    const { mediaSeverInfo } = state["features/base/conference"];

    return mediaSeverInfo;
}

/**
 * 현재 conference 상태 가져오기 
 */
export function getCurrentConference(statful) {
    const state = toState(statful);

    const conference = state['features/base/conference'];
    return conference && conference.joined;
}

/* 방 이름 유효 */
export function isRoomValid(room) {
    return typeof room === 'string' && room !== '';
}

/* 회의실 전페이지  */
export function isPrejoinPageVisible(stateful) {
    const conference = getJitsiRoom(stateful);
    
    return conference ? false : true;
}

export function getGroupId(stateful) {
    const state = toState(stateful);
    const groupId = state["features/base/conference"].groupId;

    return groupId || '';
}

export function getMainRoomRole(state) {
    return state["features/base/conference"].mainRole;
}