import React, { useEffect, useState } from "react";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import media from "../../../../assets/media";
import IconButton from "../../../base/buttons/IconButton";
import { translate } from "../../../base/i18n";
import { isCheckPermission } from "../../../base/localuser";
import { SHARE_MODE_LIST, setVideoLayoutMode, videoLayoutMode } from "../../../video-layout";
import { PERMISSION } from "../../../member";

function LayoutIconButton({
    t, list, mode, isMobile, optionDrop, setOptionDrop, children
}) {
    const dispatch = useDispatch();
    const sharedPremission = useSelector(state => isCheckPermission(state, PERMISSION.SHARE));
    const [ isMobileDropDown, setIsMobileDropDown ] = useState(false);

    const hideDropDown = e => {
        if (e && (e.target.closest('.layout_icon') || e.target.parentNode &&  e.target.parentNode.closest && e.target.parentNode.closest('.layout_icon'))) return;
        if (isMobileDropDown) setIsMobileDropDown(false);
        if (optionDrop) setOptionDrop(false);
    } 

    const onChangeMode = changeMode => {
        if (changeMode === mode && videoLayoutMode.grid === mode) {
            list && list.length > 1 && setOptionDrop(!optionDrop);
            return;
        }    
        
        dispatch(setVideoLayoutMode(changeMode));
        list && list.length > 1 && hideDropDown();
    } 

    useEffect(() => {
        window.addEventListener("click", hideDropDown);

        return (() => {
            window.removeEventListener("click", hideDropDown);
        });
    }, [hideDropDown]);

    useEffect(() => {
        if (optionDrop) setIsMobileDropDown(false);
    }, [optionDrop]);

    useEffect(() => {
        if (optionDrop && isMobileDropDown) setOptionDrop(false);
    }, [isMobileDropDown]);

    return (
        <>
            { sharedPremission && 
                <LayoutIconStyled mode={mode} className="layout_icon">
                    {/* option */}
                    { children }
                    
                    {/* (모바일만) 선택한 경우 */}
                    { isMobile && <div className="choice_mobile" onClick={() => list && list.length > 1 && setIsMobileDropDown(!isMobileDropDown)}>
                        <IconButton mode={mode} />
                        { list && list.length > 1 ? isMobileDropDown ? <MdArrowDropDown size={28} /> : <MdArrowDropUp size={28} />  : null }
                    </div> }

                    {/* 레이아웃 버튼  */}
                    { ((isMobile && isMobileDropDown) || !isMobile) && <div id={`${isMobile && 'option_button'}`} className={`${isMobile ? `drop_menu drop_menu_${isMobileDropDown}` : 'cover_menu'}`}>
                        { list && list.map((name, index) => {
                            if (!APP.layout.mode[name]) return null;

                            return (
                                <IconButton key={index}
                                    clsName={`button button_${name === mode ? 'on' : 'off'}`}
                                    mode={name} 
                                    name={t(`layout.${name}`)} 
                                    onClick={() => onChangeMode(name)}
                                />
                            );
                        })}
                    </div> }
                </LayoutIconStyled>
            }
        </>
    )
}

const LayoutIconStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 114px!important;
    margin: 0;
    ${props => SHARE_MODE_LIST.indexOf(props.mode) !== -1 && `position: relative;`}
    

    .option {
        position: absolute;
        left: 0;

        &:after {
            content: "";
            border-right: 1px solid;
            height: 34px;
            width: 1px;
            display: inline-flex;
            color: #fff!important;
        }
    }

    .choice_mobile {
        display: inline-flex;
    }

    .cover_menu {
        display: inline-flex;
        gap: 15px;
    }

    .drop_menu {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 52px;
        padding: 8px 14px;
        z-index: 2;
        background: #0c0e11;
        gap: 20px;

        &_false {
            display: none;
        }
    }

    .icon {
        gap: 8px;
        cursor: pointer;

        span {
            font-size: 11px;
            text-align: center;
        }

        .overText {
            overflow: visible;
            text-overflow: unset;
            white-space: initial;
        }

        &.button:hover {
            color: rgb(72, 162, 241);
            border: 1
        }
    }

    ${media.tabletV`
        position: initial;
        padding-left: 0px!important;

        .option {
            position: relative;
            padding-right: 12px;
        }
    `} 
`;

export default translate(LayoutIconButton);
