import { isAudioMuted, isVideoMutedByUser } from "../base/media";
import { _disposeAndRemoveTracks, disposeAndRemoveTracks, getLocalAudioTrack, getLocalVideoTrack, replaceLocalTrack } from "../base/tracks";
import { SET_DEVICE_STATUS, SET_PREJOIN_DEVICE_ERRORS } from "./actionTypes";

export function setDeviceStatusWarning(kind, deviceStatusText) {
    return {
        type: SET_DEVICE_STATUS,
        value : {
            kind,
            deviceStatusText,
            deviceStatusType: 'warning'
        }
    };
}
export function setDeviceStatusOk(kind, deviceStatusText) {
    return {
        type: SET_DEVICE_STATUS,
        value : {
            kind,
            deviceStatusText,
            deviceStatusType: 'ok'
        }
    };
}

export function setPrejoinDeviceErrors(value) {
    return {
        type: SET_PREJOIN_DEVICE_ERRORS,
        value
    };
}

export function replaceAudioTrackById(deviceId) {
    return async (dispatch, getState) => {
        const oldTrack = getLocalAudioTrack(getState()['features/base/tracks'])?.track;
        try {
            const wasAudioMuted = isAudioMuted(getState());
            const [ newTrack ] = await APP.mateManagement.createLocalTracks({
                micDeviceId: deviceId, devices: [ 'audio' ] 
            });
            
            wasAudioMuted && newTrack.mute();

            dispatch(replaceLocalTrack(oldTrack, newTrack));
            dispatch(setDeviceStatusOk('audioInput',  {titleKey: 'device.microphoneOk'}));
        } catch (err) {
            dispatch(_disposeAndRemoveTracks([ oldTrack ]));
            dispatch(setDeviceStatusWarning('audioInput', {titleKey: 'device.audioTrackError'}));

            console.log(err);
        }
    };
}

export function replaceVideoTrackById(deviceId) {
    return async (dispatch, getState) => {
        
        const oldTrack = getLocalVideoTrack(getState()['features/base/tracks'])?.track;
        try {
            const wasVideoMuted = isVideoMutedByUser(getState());
            const [ newTrack ] = await APP.mateManagement.createLocalTracks({
                cameraDeviceId: deviceId, devices: [ 'video' ] 
            });         
            
            wasVideoMuted && newTrack.mute();
            
            dispatch(replaceLocalTrack(oldTrack, newTrack));
            
            dispatch(setDeviceStatusOk('videoInput', {titleKey: 'device.cameraOk'}));
        } catch (err) {            
            dispatch(_disposeAndRemoveTracks([ oldTrack ]));

            dispatch(setDeviceStatusWarning('videoInput', {titleKey: 'device.videoTrackError'}));
            console.log('Error replacing video track', err);
        }
    };
}