import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux"
import { bytesToSize, downloadFile } from "../../functions";
import { MESSAGE_TYPE_LOCAL, MESSAGE_TYPE_REMOTE } from "../../constants";
import { getFormatter } from "../../../base/i18n";
import { useTranslation } from "react-i18next";
import { ChatBodyStyled } from "./styles";

export default React.memo(function ChatBodyContainer({
    prevMessage
}) {
    const { t } = useTranslation();
    
    const chatBodyRef = useRef();
    const messages = prevMessage ? prevMessage : useSelector(state => state['features/chat'].messages);

    const scrollToBottom = (withAnimation) => {
        if (chatBodyRef) {
            chatBodyRef.current.scrollIntoView({
                behavior: 'auto',
                block: 'end'
            });
        }  
    }

    const _onChatScroll = () => {
        const element = chatBodyRef.current;

        this._isScrolledToBottom
            = element.scrollHeight - element.scrollTop === element.clientHeight;
    };

    useEffect(() => {
        scrollToBottom(true);
    }, [messages]);

    return (
        <ChatBodyStyled className="chat_body">
            { messages.map((item, index) => {
                if (item.error) {
                    return <ChatError key={index} t={t} message={item.message} />
                } else {
                    return (
                        <ChatBodyItem
                            key={index}
                            t={t}
                            index={index}
                            isMe={item.isMe}
                            recipient={item.recipient}
                            displayName={item.displayName}
                            messageType={item.messageType}
                            message={item.message}
                            timestamp={item.timestamp}
                        />
                   )
                }
            })}
            <div ref={ chatBodyRef }/>
        </ChatBodyStyled>
    )
});

function ChatError({ t, message }) {
    return (
        <p key={index} 
            className="message_context" style={{
            float: "right", color: "red", marginRight: "15px;"
        }}> 
            { t("chat.sendFail") }: {message} 
        </p>
    );
}

function ChatBodyItem({
    t, isMe, index, recipient, displayName,
    messageType, message, timestamp
}) {
    
    const [recipientName, setRecipientName] = useState();
    const [ senderName, setSenderName ] = useState();
    const [ isPrivate, setPrivate ] = useState(false);
    const [ time, setTime ] = useState(0);

    useEffect(() => {
        const count = recipient?.length;

        let name;
        if (count === 1) name = recipient[0].name;
        else if (count > 1) name = recipient[0].name + t('chat.etc') + (count - 1) + t('chat.person');
        else name = t('chat.all');

        setRecipientName(name);

        if (count === 0) setPrivate(false);
        else setPrivate(true);
    }, [recipient]);

    useEffect(() => {
        let name;
    
        if (isMe) {
            name = t("chat.messageFrom", { recipient: recipientName });
        } else {
            name = t('chat.messageTo', { sender: displayName, recipient: recipientName });
        }

        setSenderName(name);
    }, [isMe, displayName, recipientName]);

    useEffect(() => {
        setTime(getFormatter(timestamp));
    }, [timestamp]);

    const renderText = () => {
        return (
            <>
                <p className="sender"> { senderName } </p>
                <p className="message_context"> 
                    <span className="time">{ time }</span>
                    <span className="message">
                        { message } 
                    </span> 
                </p> 
            </>
        )
    }

    const renderFile = () => {
        const { name, size } = message;
        const downloadLink = message?.downloadLink;

        const handlerDownloadFile = () => {
            const file = downloadFile(downloadLink, name);
            if (!file) alert(t("common.error"));
        };
        
        return (
            <>  
                <div className="message_text">
                    <p className="sender">
                        { senderName }
                        <span style={{margin: "0 6px", fontSize: "10px"}}>
                            {time}
                        </span>
                    </p>
                    <p className="message_context"> {name} </p> 
                </div>
                <div className="message_file" onClick={handlerDownloadFile}>
                    <span className="icoImg message_download">
                        <span className="blind">{ t("chat.download") }</span>
                    </span>
                    <span>{downloadLink ? `${bytesToSize(size)}` : t("chat.sending")}</span>
                </div>
            </>
        )
    }
    
    return (
        <div  key={timestamp}
            className={`message message_${isPrivate} 
            ${messageType} 
            ${isMe ? MESSAGE_TYPE_LOCAL : MESSAGE_TYPE_REMOTE}`}> 
            { messageType === 'text' ? renderText() : renderFile() }
        </div>
    )
}