import type { Dispatch } from 'react';
import { getLocalIDMember } from '../../member';
import { ADD_CANVAS_LIST, SET_CANVAS_DRAW, SET_CANVAS_OPTION, SET_CANVAS_PDF, SET_CANVAS_TOOL } from './actionTypes';
import { checkCanvasPermission, getPdfDrawByUUID, getPdfItemByUUID } from './functions';

/**
 * 캔버스 현재 사용하는 툴 수정 
 * 
 * {
 *      type: SET_CANVAS_TOOL,
 *      tool: String
 * }
 */
export function setCanvasTool(tool: String) {
    return {
        type: SET_CANVAS_TOOL,
        tool
    }
}

/**
 * 캔버스 현재 사용하는 옵션 수정 
 * 
 * {
 *      type: SET_CANVAS_OPTION,
 *      option: {
 *          thickness?: Number,
 *          scale?: Number,
 *          opacity?: Number
 *          isWidthFit?: Boolean|undefined,
 *      }
 * }
 */
export function setCanvasOption(option: Object) {
    return {
        type: SET_CANVAS_OPTION,
        option
    }
}

/**
 * 캔버스 포인트 업데이트 
 * @param {String} uuid 
 * @param {String} index 
 * @param {Object} canvasTool 
 * @param {Array} points 
 * @param {String} date 
 * @param {width} width 
 * @param {height} height 
 * @param {Boolean} isUpdate 
 * @param {Boolean} isRemote 
 * @returns 
 */
export function setCanvasPoint(uuid: String, index: String, canvasTool = null, points: Array, 
    key: String, width: Number, height: Number, isUpdate = false, isRemote = false) {
    return async (dispatch: Dispatch, getState: Function) => {    
        let property = { canvasTool, points };
        if (!canvasTool) {
            const canvasState = getState()['features/base/canvas'];
            const { option, tool } = canvasState;
            property = {
                canvasTool: {
                    tool,
                    opacity: option.opacity,
                    thickness: option.thickness,
                    color: option.color,
                    width, height
                },
                points
            }
        }

        if (!key) {
            const user_id = getLocalIDMember(getState);
            key = `${Date.now()}_${user_id}`;
        }

        if (!isRemote) {
            const response = await APP.mateManagement.sendSharePoints(uuid, index, key, property);
            if (response.status !== 200) return;
        }
       
        dispatch(setCanvasDraw(uuid, index, key, property));
    }   
}

/**
 * 캔버스 리스트 데이터 업데이트 (한줄)
 * @param {String} uuid 
 * @param {Array} data 
 * @returns 
 */
export function setCanvasListData(uuid, data) {
    return {
        type: SET_CANVAS_DRAW,
        uuid, data
    }
}

/**
 * 캔버스 remote 업데이트 
 * 
 * @param {Stirng} uuid 
 * @param {Stirng} index 
 * @param {Stirng} key 
 * @param {Object} property 
 * @returns 
 */
export function setCanvasDraw(uuid: String, index: String, key: String, property: Object) {
    return (dispatch: Dispatch, getState: Function) => {
        const draw = getPdfDrawByUUID(getState, uuid);
        
        let newDraw = draw || new Map();
        let data = new Map();

        newDraw.set(index, draw && draw.get(index) ? draw.get(index).set(key, property) : data.set(key, property));
        dispatch(setCanvasListData(uuid, newDraw));
    }
}

/**
 * 캔버스 객체 삭제 
 * @param {Sting} uuid 
 * @param {Sting} index 
 * @param {Sting} key 
 * @param {Boolean} isRemote 
 * @returns 
 */
export function deleteCanvasDraw(uuid: String, index: String, key: String, isRemote: Boolean = false) {
    return async (dispatch: Dispatch, getState: Function) => {
        if (!isRemote) {
            if (!checkCanvasPermission(getState, 'share')) {  
                const local_uuid = getLocalIDMember(getState);
                if (!key.includes(local_uuid)) return;
            }

            const response = await APP.mateManagement.deleteShareLine(uuid, index, key, false);
            if (response.status !== 200) return;
        }
        
        const draw = getPdfDrawByUUID(getState, uuid);
        let newDraw = draw;
        const item = draw.get(index);

        item && item.delete(key);
        newDraw.set(index, item);

        dispatch(setCanvasListData(uuid, newDraw));
    };
}

/**
 * 초기값 설정(PDF 인덱스 리스트 ) 
 * 
 * @param {Array} list 
 * @returns 
 */
export function addCanvasList(list: Array) {
    return {
        type: ADD_CANVAS_LIST,
        list
    }
}

/**
 * 캔버스 리스트 삭제
 * 
 * @param {String} uuid 
 * @param {Number} index 
 * @returns 
 */
export function deleteCanvasIndex(uuid: String, index: Number, isRemote: Boolean = false) {
    return (dispatch: Dispatch, getState: Function) => {
        if (!isRemote) {
            if (checkCanvasPermission(getState, 'share') ) {      
                APP.mateManagement.deleteShareCanvasIndex(uuid, index);
            } else return;
        }

        const draw = getPdfDrawByUUID(getState, uuid);

        let newDraw = draw;
        draw && draw.delete(index);

        dispatch(setCanvasListData(uuid, draw));
    }
}