import React from "react";
import styled from "styled-components";
import loading from "../../../../assets/images/loading.gif";

export default function LoadImage({
    text, isLoad
}){
    return (
        <LoadStyled isLoad={isLoad} text={text}>
            <div className="load_cover center nonSelect">
                    { text && <dl>
                    <span className="text overText">{ text }</span>
                    <span className="text">이동 중 입니다.</span>
                </dl> }
                <dl style={{
                    background: `url(${loading}) no-repeat`,
                    width: "65px",
                    height: "65px"
                }}></dl>
                <span className="text">LOADING</span>
            </div> 
        </LoadStyled>
    )
}

const LoadStyled = styled.div`
    position: absolute;
    width: 100%; height: 100%;
    min-width: 350px; min-height: 350px;
    top: 0;
    left: 0;
    background: ${props => props.text ? "rgba(0,0,0, 1)": "rgba(0,0,0, 0.5)"};
    display: ${props => props.isLoad ? "block" : "none"};
    z-index: 4;

    .load_cover {
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: center;
        justify-content: center;
        width: 100%;

        dl { 
            display: flex;
            flex-direction: column;
            width: 100%;
            text-align: center;
            justify-content: center;
            align-items: center;
        }

        .text {
            text-align: center;
            max-width: 50%;
            font-size: 30px;
            color: #fff;
            font-weight: 400;
            letter-spacing: 6px;
            padding-left: 10px;
            margin: 0;
        }
    }
`;