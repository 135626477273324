import React from "react";
import { Line } from "react-konva";
import { PEN } from "../constants";

export default function LineCanvas({
    canvasTool, points, ratio, 
    handlerDeleteLine
}) {    
    const { thickness, color, opacity, tool } = canvasTool;

    let nThickness = tool === PEN.name ? thickness : thickness + 20;
    const hitStrokeWidth = (nThickness + 5) / ratio;
    const strokeWidth = (nThickness) / window.devicePixelRatio / ratio;

    return (
        <Line 
            scaleX={ratio} scaleY={ratio}
            points={points}
            strokeWidth={strokeWidth === Infinity  ? 0 : strokeWidth}
            hitStrokeWidth={hitStrokeWidth === Infinity ? 0 : hitStrokeWidth}
            opacity={tool === PEN.name ? canvasTool.opacity / 10 : 1}
            lineJoin='round'
            lineCap='round'
            globalCompositeOperation={tool === PEN.name ? 'source-over' : 'destination-out'}
            bezier={true}
            stroke={canvasTool.color}
            onMouseOver={handlerDeleteLine}
            onTouchMove={handlerDeleteLine}    
        />
    )
}