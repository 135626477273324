import axios from "axios";

export async function getSurvey({ meeting_uuid, survey_uuid }) {
    const response = await axios.get(`/survey/${meeting_uuid}/${survey_uuid}/info`);

    return  response;
}

export async function getAllSurvey(meeting_uuid) {
    const response = await axios.get(`/survey/${meeting_uuid}`);

    return  response;
}