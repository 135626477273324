// @flow

import React from 'react';
import { equals } from '../../../redux';

import AbstractAudio from '../AbstractAudio';

export default class Audio extends AbstractAudio {
    constructor(props) {
        super(props);

        this._onCanPlayThrough = this._onCanPlayThrough.bind(this);
        this._setRef = this._setRef.bind(this);
    }

    componentDidMount() {
        if (this._ref) {
            const { volume } = this.props;

            if (typeof volume === 'number') {
                this._ref.volume = volume;
            }
        }
    }

    shouldComponentUpdate(nextProps) {
        if (this._ref) {
            const currentVolume = this._ref.volume;
            const nextVolume = nextProps.volume;

            if (typeof nextVolume === 'number' && !isNaN(nextVolume) && currentVolume !== nextVolume) {
                this._ref.volume = nextVolume;
            }
        } 

        return false;
    }

    render() {
        return (
            <audio
                loop = { true }
                onCanPlayThrough = { this._onCanPlayThrough }
                preload = 'auto'
                autoPlay= { true }

                // $FlowFixMe
                ref = { this._setRef }
                src = { this.props.src } />
        );
    }

    stop() {
        if (this._ref) {
            this._ref.pause();

            // $FlowFixMe
            this._ref.currentTime = 0;
        }
    }

    _maybeSetAudioElementImpl() {
        if (this._ref && this._audioFileLoaded) {
            this.setAudioElementImpl(this._ref);
        }
    }

    _onCanPlayThrough() {
        this._audioFileLoaded = true;
        this._maybeSetAudioElementImpl();
    }

    _setRef(audioElement) {
        this._ref = audioElement;

        if (audioElement) {
            this._maybeSetAudioElementImpl();
        } else {
            this.setAudioElementImpl(null);

            this._audioFileLoaded = false;
        }
    }
}
