import axios from "axios";

// 이메일 전송
export async function ujVerfiyEmail(email) {
    const response = await axios({
        method: "POST",
        url: "/svc/user/email-challenges",
        data: {
            email
        }
    });

    return {
        status: response.status,
        data: response.data,
    };
}

// 인증 코드 확인
export async function ujCheckEmail({ uuid, code }) {
    const response = await axios({
        method: "PUT",
        url: `/svc/user/email-challenges/${uuid}/checking`,
        data: {
            code
        },
    });

    return {
        status: response.status,
        data: response.data
    };
}

// 회원가입
export async function ujJoinUser({ data: { data, email_challenge_uuid }, uuid, access_token }) {
    const response = await axios({
        method: "PUT",
        url: `/svc/user/users/${uuid}/joining`,
        headers: {
            'Authorization': 'Bearer ' + access_token,
            'Content-Type': 'application/json; charset=UTF-8',
        },
        data: {
            uuid,
            email_challenge_uuid,
            auth_name: data.id,
            auth_password: data.password,
        },
    });

    return {
        status: response.status,
        data: response.data
    };
}

// 임시 사용자 생성 
export async function uTempLoginUser() {
    const response = await axios({
        method: "POST",
        url: "/svc/user/users"
    });

    return {
        status: response.status,
        data: response.data
    };
}

// 로그인
export async function uLoginUser({ id, password }) {
    const response = await axios({
        method: "POST",
        url: "/svc/user/issue-auth-token/by-password",
        data: {
            auth_name: id,
            auth_password: btoa(password),
            enc_type: "base64"
        },
    });
    
    return {
        status: response.status,
        data: response.data,
    };
}

// 로그인 유저 정보 가져오기 
export async function getUserSelf( access_token ) {
    const response = await axios({
        method: "POST",
        url: "/svc/user/verify-auth-token/by-input",
        data: {access_token}
    });
    
    return {
        status: response.status,
        data: response.data
    };
}

export async function checkRequireEmailVerification() {
    const response = await axios({
        method: "GET",
        url: "/api/user/v1/settings/option",
    });

    return response;
}