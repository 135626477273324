import { ROLE } from "../member";

export const SHOW_GROUP_ROLE_LIST = [ROLE.HOST, ROLE.MANAGER];

export const G_GUIDE = 'group_guide';
export const G_PP_LIST = 'group_participant_list';
export const G_STATUS = 'group_status';
export const G_FOCUS_ITEM = 'group_focus_item';
export const G_FOCUS_PP_LIST = 'group_item_participant_list';

export const main_list = [G_GUIDE, G_PP_LIST, G_STATUS];

export const G_MODIFY_POP = 'modify_group_popup';
export const G_DELET_POP = 'del_group_popup';
export const G_RANDOM_MAKE = 'addRandomGroup';
export const G_ADD_POP = 'addGroup';

export const STATUS = "status";
export const ROLE_NAME = "role_name";
export const HOST_NAME = "host_name";
export const NICKNAME ="nickname";
export const GROUP_ID = "group_id";
export const SELECT = "select";
export const PARTICIPANT_BELONG_COUNT = "belong";
export const PARTICIPANT_ALL_COUNT = "all";
export const G_LOADING = 'loading';

export const GROUP_DEFAULT_NAME = 'lobby';

export const focusGroup = [
    {name: STATUS, sort: false, change: false},
    {name: NICKNAME, sort: true, change: false},
    {name: ROLE_NAME, sort: true, change: true},
];

export const participantGroup = [
    {name: STATUS, sort: false, change: false},
    {name: NICKNAME, sort: true, change: false},
    {name: ROLE_NAME, sort: true, change: true},
    {name: GROUP_ID, sort: true, change: true},
];

export const startMemberGroup = [
    {name: STATUS, sort: false, change: false},
    {name: NICKNAME, sort: true, change: false},
    {name: ROLE_NAME, sort: true, change: true},
    {name: GROUP_ID, sort: true, change: true},
];

export const addParticipantGroup = [
    {name: STATUS, sort: false, change: false},
    {name: SELECT, sort: true, change: false},
    {name: NICKNAME, sort: true, change: false},
    {name: ROLE_NAME, sort: true, change: false},
    {name: GROUP_ID, sort: true, change: false},
];

export const statusGroup = [
    {name: PARTICIPANT_BELONG_COUNT, sort: false, change: false},
    {name: PARTICIPANT_ALL_COUNT, sort: false, change: false},
    {name: HOST_NAME, sort: false, change: false},
];

export const participantInnerGroup = [
    {name: STATUS, sort: false, change: false},
    {name: NICKNAME, sort: true, change: false},
    {name: ROLE_NAME, sort: true, change: true},
    {name: GROUP_ID, sort: true, change: true},
];
