import React from "react";
import styled from "styled-components";
import ModalPortal from "../../../../ModalPortal";
import { Login } from "../../../account/login";
import { Signup } from "../../../account/signup";
import { Device } from "../../../device/components";
import {
    ALERT_ATTENDANCES_MODAL_ID, CHECKING_ROOM_PASSWORD_MODAL_ID, CLOSE_MODAL_ID, CREATE_ATTENDANCES_MODAL_ID, CheckAttendances, ClosePopup,
    DEVICE_SETTING_MODAL_ID,
    GRID_COUNT,
    INVITE_GUEST_MODAL_ID, InviteGuest, LOGIN_MODAL_ID,
    OPTION_ETC_MODAL_ID,
    OptionMenuLayout, RECORD_MODAL_ID, ROOM_LOCK_MODAL_ID, ROOM_MODAL_ID, SETTING_MODAL_ID, SET_SCREEN_TYPE_MODAL_ID,
    SIGN_UP_MODAL_ID, SURVEY_MODAL_ID, SettingPopup
} from "../../../options";
import SetGridCount from "../../../options/components/etc/SetGridCount";
import MyPage from "../../../options/components/room/MyPage";
import { RecordList } from "../../../record";
import { CheckPassword, SetPasswordLayout } from "../../../room-lock";
import { ChoiceScreenShareTypePopup } from "../../../screen-share";
import { SurveyIFrame } from "../../../survey";
import { connect } from '../../redux';
import { RECORDING_TYPE } from "../../../recording";
import PrevRecordPopup from "../../../recording/components/PrevRecordPopup";


const ModalStyled = styled.div`
    ${props => props.back && `
        position: absolute;
        width: 100%; height: 100%;
        top: 0; left: 0; z-index: 2;
        background: rgba(0,0,0,0.5);
    `}
`;

const myComponent = (name) => {
    switch (name) {
        case SIGN_UP_MODAL_ID:
            return <Signup isModal={true} onClose={() => APP.UI.closeModal()} />

        case LOGIN_MODAL_ID:
            return <Login isModal={true} onClose={() => APP.UI.closeModal()} />

        case ROOM_LOCK_MODAL_ID:
            return <SetPasswordLayout />

        case CHECKING_ROOM_PASSWORD_MODAL_ID:
            return <CheckPassword />

        case SETTING_MODAL_ID:
            return <SettingPopup />

        case INVITE_GUEST_MODAL_ID:
            return <InviteGuest />

        case OPTION_ETC_MODAL_ID:
            return <OptionMenuLayout isOption={true} />

        case DEVICE_SETTING_MODAL_ID:
            return <Device isRoom={true} />

        case SURVEY_MODAL_ID:
            return <SurveyIFrame />

        case CLOSE_MODAL_ID:
            return <ClosePopup />

        case CREATE_ATTENDANCES_MODAL_ID:
            return <CheckAttendances type="create" />

        case ALERT_ATTENDANCES_MODAL_ID:
            return <CheckAttendances type="alert" />    

        // case SET_SPEAKER_VOLUME_MODAL_ID:
        //     return <SpeackerLevel />

        case RECORD_MODAL_ID:
            return <RecordList />

        case SET_SCREEN_TYPE_MODAL_ID:
            return <ChoiceScreenShareTypePopup />

        case ROOM_MODAL_ID:
            return <MyPage isModal={true} />

        // case SET_VOD_ATT_TYPE:
        //     return <ChoiceVodType />

        case GRID_COUNT:
            return <SetGridCount />

        case RECORDING_TYPE.PERSONAL:
        case RECORDING_TYPE.SERVER:
            return <PrevRecordPopup type={name} />


        default:
            return null;
    }
}

const excludeModal = [];

function Modal({
    name
}) {
    const onClick = e => {
        const { id } = e.target;
        
        if (!name) return;
        if (name === "password-required") return;
        if (name === SET_SCREEN_TYPE_MODAL_ID) return;
        if (id === "modalBack") APP.UI.closeModal();
    }

    return (
        <ModalPortal>
            {name && <ModalStyled id="modalBack"
                className={`modal ${name}`}
                back={excludeModal.includes(name) ? false : true} 
                onClick={onClick}>
                { myComponent(name) }
            </ModalStyled> }
        </ModalPortal>
    )
}

function _mapStateToProps(state) {
    const { name } = state["features/base/modal"];
    return {
        name
    }
}

export default connect(_mapStateToProps)(Modal);