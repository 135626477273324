/* @flow */

import React, { Component } from 'react';

import { trackVideoStarted } from '../../tracks';
import { shouldRenderVideoTrack } from '../functions';
import { Video } from './web';

export default class AbstractVideoTrack extends Component {
    constructor(props) {
        super(props);

        this._onVideoPlaying = this._onVideoPlaying.bind(this);
    }

    render() {
        const videoTrack = _falsy2null(this.props.videoTrack);
        let render;

        if (this.props.waitForVideoStarted && videoTrack) {
            if (shouldRenderVideoTrack(videoTrack, true)) {
                render = true;
            } else if (shouldRenderVideoTrack(videoTrack, false)
                    && !videoTrack.videoStarted) {
                render = true;
            }
        } else {
            render = shouldRenderVideoTrack(videoTrack, false);
        }

        const stream = render && videoTrack
            ? videoTrack.track.getOriginalStream() : null;

        const zoomEnabled
            = this.props.zoomEnabled
                && stream
                && videoTrack
                && videoTrack.videoType === 'desktop';

        return (
            <Video
                mirror = { videoTrack && videoTrack.mirror }
                onPlaying = { this._onVideoPlaying }
                onPress = { this.props.onPress }
                stream = { stream }
                zOrder = { this.props.zOrder }
                zoomEnabled = { zoomEnabled } />
        );
    }

    _onVideoPlaying() {
        const { videoTrack } = this.props;
        
        if (videoTrack && !videoTrack.videoStarted) {
            this.props.dispatch(trackVideoStarted(videoTrack.track));
        }
    }
}

function _falsy2null(value) {
    return value || null;
}
