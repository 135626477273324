import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getPage } from "./functions";
import { setPage } from "./actions";

export default function PageButton({
    type, show, renderButton, setPrev, setNext
}) {
    const dispatch = useDispatch();
    const { page, maxPage } = useSelector(state => getPage(state), shallowEqual);

    useEffect(() => {        
        if (page > 1) setPrev(true);
        else setPrev(false);

        if (maxPage > page) setNext(true);
        else setNext(false);
    }, [ page, maxPage]);

    const prevPage = () => {
        dispatch(setPage(page - 1));
    }

    const nextPage = () => {
        dispatch(setPage(page + 1));
    }

    const onClick = type === 'next' ? nextPage : prevPage;

    if (!show) return null;
    return renderButton(type, onClick);
}