/* @flow */

import { toState } from '../redux';
import { getPropertyValue } from '../settings';

import { VIDEO_MUTISM_AUTHORITY } from './constants';

const START_WITH_AUDIO_VIDEO_MUTED_SOURCES = {
    config: true,
    settings: true,
    urlParams: true,
    jwt: false
};

export function isAudioMuted(stateful) {
    const { muted } = toState(stateful)['features/base/media'].audio;
    
    return muted === "on" || muted ? true : false;
}

export function isVideoMutedByAudioOnly(stateful) {
    return (
        _isVideoMutedByAuthority(stateful, VIDEO_MUTISM_AUTHORITY.AUDIO_ONLY));
}

function _isVideoMutedByAuthority(
        stateful,
        videoMutismAuthority) {
    const { muted } = toState(stateful)['features/base/media'].video;
    
    // eslint-disable-next-line no-bitwise
    return muted;
}

export function getStartWithAudioMuted(stateful) {
    return Boolean(getPropertyValue(stateful, 'startWithAudioMuted', START_WITH_AUDIO_VIDEO_MUTED_SOURCES));
}

export function getStartWithVideoMuted(stateful) {
    return Boolean(getPropertyValue(stateful, 'startWithVideoMuted', START_WITH_AUDIO_VIDEO_MUTED_SOURCES));
}

export function isVideoMutedByUser(stateful) {
    return _isVideoMutedByAuthority(stateful, VIDEO_MUTISM_AUTHORITY.USER);
}

export function shouldRenderVideoTrack(
        videoTrack,
        waitForVideoStarted) {

        return (
            videoTrack
                && !videoTrack.muted
                && (!waitForVideoStarted || videoTrack.videoStarted));
}
