import React, { useEffect, useState } from "react";
import { BsCameraVideoOff, BsFillMicFill, BsFillMicMuteFill } from "react-icons/bs";
import { shallowEqual, useSelector } from "react-redux";
import { AudioLevelIndicator, VideoTrack } from "../../base/media";
import ConnectionIndicatorContent from "../../connection-indicator/components/ConnectionIndicatorContent";
import { getMemberTrackByUserID } from "../../member";
import IconPinMember from "./IconPinMember";

function MemberFilm({
    user_uuid, isMaxSize, styles, vertical = false
}) {
    const member = useSelector(state => getMemberTrackByUserID(state, user_uuid, true), shallowEqual);
    const [audioMuted, setAudioMuted] = useState(false);
    
    useEffect(() => {
        if (!member) return;
        const muted = member.audioTrack && !member.audioTrack.muted ? false : true;
        setAudioMuted(muted);
    }, [member?.audioTrack]);

    /**
     * 사용자 비디오 UI
     * @returns 
     */
    const renderVideo = () => {
        const show = member.videoTrack && !member.videoTrack.muted;

        return (
            show 
                ? <VideoTrack videoTrack={member.videoTrack} />
                : renderMutedVideo()
        )
    }

    /**
     * 비디오 음소거 기본 UI
     * @returns 
     */
    const renderMutedVideo = () => {
        return (
            <div className="default"> 
                <dl className="center">
                    <BsCameraVideoOff color={"#fff"} />
                </dl>
            </div>
        )
    }
    if (!member) return null;
    return (
        <div className={`item ${member.local ? 'mirror' : 'no_mirror'}`}
            style={isMaxSize 
                ? vertical 
                    ? { width: `${styles.width}px`, maxHeight: `${styles.height}px`, height: '100%' } 
                    : { maxWidth: `${styles.width}px`, height: `${styles.height}px`, width: '100%' }
                : styles ? { width: `${styles.width}px`, height: `${styles.height}px` } : null} >
            <div className="videoWrap">
                { renderVideo() }
            </div>

            <div className="namePlate">
                <ConnectionIndicatorContent  
                    local={member.local}
                    memberId={member.jitsi_id}
                    videoTrack={member.videoTrack}
                    audioTrack={member.audioTrack} />
                <div style={{ flex: 1, display: "flex", textAlign: "center", justifyContent: "center", alignItems: "center", gap: "4px", overflow: "hidden" }}>
                    <span className="overText"> {member.nickname} </span>
                    <span className={"mike"}>
                        { audioMuted 
                            ? <BsFillMicMuteFill size={12} color={"red"} /> 
                            : <BsFillMicFill size={12} color={"#128ed7"} stroke={"#fff"} /> }
                    </span>  
                </div>

                
            </div>

            <IconPinMember user_uuid={user_uuid} pinned={member.pinned}/>
            <AudioLevelIndicator audioTrack={member.audioTrack} />
            
            
        </div>
    )
}

export default React.memo(MemberFilm);