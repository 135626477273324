import styled from "styled-components";

export const IconStyled = styled.span`
    margin: 0 4px;
    cursor: ${(props) => props.isPointer ? "pointer" : "auto"};

    &.icon {
        svg {
            width: 18px;
            height: 18px;
            color: ${(props) => props.isOn ? "#a5a5a5!important":  "#0e95e6!important"};
        }
    } 

    &.pinIcon {
        svg {
            width: 16px; height: 15px;
            color: ${(props) => props.isOn ? "#0e95e6!important" : "#a5a5a5!important"};
        }
    } 

    &.rec {
        display: inline-flex;
        margin: 0;

        svg {
            width: 24px;
            height: 24px;
            color: ${(props) => props.isOn ? "#e60e0e!important":  "#a5a5a5!important"};
        }
    }
`;

export const IconPinStyle = styled.span`
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: ${(props) => props.isPointer ? "pointer" : "auto"};

    svg {
        width: 20px;
        height: 20px;
    }
`;

export const ParticipantStyled = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .search_bar {
        width: 100%; background: #123; color: white; 

        input {
            border: none; background: none; color: #fff;
            width: 100%;
            box-sizing: border-box;
            padding: 12px;
        }
    }
`;