import { deleteCookie, getCookieValue } from "../../../base/util";

export function setMiddleAttendancesCookies() {
    const hour = getCookieValue("check_hour");
    const minute = getCookieValue("check_min");

    if (hour && minute) {
        let today = new Date();
        today.setHours(hour);
        today.setMinutes(minute);
        today.setSeconds(0);
        const start = today.toJSON();
        const check_duration = 600000;

        APP.API.createAttendancesCheck({
            name: "middle", 
            check_start_time: start,
            check_duration
        }).then(() => {
            deleteCookie("check_hour");
            deleteCookie("check_min");
        });
    }
}