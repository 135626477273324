// import React, { useEffect, useState } from "react";
// function MobileOption({
//     dispatch, list, tool, onChangeTool
// }) {
//     return (
//         <>
//             <div className="option_box mobile noClick" style={{ display: "flex", flexDirection: "column" }}>
//                 { list.map((value, key) => {
//                     return (
//                         <React.Fragment key={key}>
//                             <dl onClick={() => onChangeTool(value, true)} 
//                             className={`boardImg ${value} use_${value === tool}`} />

//                             { value === tool && <ToolOptionBox choiceTool={tool} />}
//                         </React.Fragment>
//                     )
//                 })}
//             </div>
//         </>
//     )
// }

// function CanvasTool({ 
//     dispatch, tool, showOption, layoutHeight, role, isVod 
// }) {
//     const [toolList, setToolList] = useState(CANVAS_TOOL_LIST);
//     const [isShort, setIsShort] = useState(false);

//     useEffect(() => {
//         const userAgent = getUserAgentType();
//         if (layoutHeight < 600 || userAgent === layoutType.mobile) {
//             setIsShort(true);
//             setToolList(getMobileToolList(tool));
//             return;
//         }
//         setIsShort(false);
//         setToolList(CANVAS_TOOL_LIST);
//     }, [layoutHeight]);

//     const onChangeTool = (tool, showOption) => {  
//         if (tool === CANVAS_TOOL.eraser && role === ROLE.PARTICIPANT) {
//             dispatch(setCanvasProperty({ tool, showOption: false, isPartEraser: false, isVod }));
//             return;
//         }
//         dispatch(setCanvasProperty({ tool, showOption, isVod }));
//     }

//     return (    
//         <div>
//             { toolList.map((value, key) => {
//                 if (isVod && value === CANVAS_TOOL.zoom) return null;
//                 return (
//                     <React.Fragment key={key}>
//                         <dl onClick={() => onChangeTool(value, tool !== value ? true : !showOption)} 
//                             className={`boardImg ${value} use_${tool === value}`} />

//                         { showOption ? 
//                             isShort && pen_list.indexOf(tool) !== -1 && pen_list.indexOf(value) !== -1 ? <MobileOption list={pen_list} tool={tool} onChangeTool={onChangeTool} />
//                             : isShort && handler_list.indexOf(tool) !== -1 && handler_list.indexOf(value) !== -1 ? <MobileOption list={handler_list} tool={tool} onChangeTool={onChangeTool} />
//                             : tool === value && <ToolOptionBox choiceTool={tool} />
//                             : null }
//                     </React.Fragment>
//                 )
//             }) }
//         </div>            
//     )
// }

// function _mapStateToProps(state) {
//     const { tool, showOption } = state["features/base/canvas"];
//     const { layoutHeight } = getLayoutWidthAndHeight(state);
//     const role = getLocalMember(state).role;
    
//     return {
//         tool, layoutHeight, showOption, role
//     }
// }

// export default connect(_mapStateToProps)(CanvasTool);

import React, { useEffect, useState } from 'react';

import { canvas_tool_list, canvas_tool_mobile_list, p_canvas_tool_list, p_canvas_tool_mobile_list } from '../constants';
import ToolButton from './ToolButton';
import { ROLE } from '../../../member';

export default function CanvasTool({
    focusTool, setFocusTool,
    isMobile, fullCanvas, tool, option, role,
    handlerUpdateCanvasTool
}) {
    const [list, setList] = useState([]);

    useEffect(() => {
    
        if (isMobile || fullCanvas.height < 624) {
            setList((role === ROLE.PARTICIPANT) ? p_canvas_tool_mobile_list : canvas_tool_mobile_list);
        } else {
            setList((role === ROLE.PARTICIPANT) ? p_canvas_tool_list : canvas_tool_list);
        }
    }, [isMobile, fullCanvas, role]);

    return (
        <div className="tool_container">
            { list.map(item => {
                return (
                    <ToolButton
                        key={item.name}
                        role={role}
                        item={item}
                        tool={tool}
                        option={option}
                        focusTool={focusTool}
                        isMobile={isMobile}
                        setFocusTool={setFocusTool}
                        handlerUpdateCanvasTool={handlerUpdateCanvasTool}
                    />
                )
            })}
        </div>
    );
}
