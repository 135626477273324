import React, { useRef } from "react";
import { AbstractDialog } from "../../base/dialog";
import { GroupAlertStyled } from "./styles";
import { ClickButton } from "../../base/buttons";
import { isHaveGroupByName } from "../functions";

export default function ModifyGroup({
    t, popItem, setPopup, alertMessage
}) {
    const nameRef = useRef();
    const handlerClose = () => setPopup(null);

    const onUpdate = async () => {
        let message;

        const element = nameRef.current;
        if (element) {
            const name = element.value;
            
            if (!name) message = "group.requiredGroup";
            else {
                const isHave = isHaveGroupByName(APP.store.getState, name);
                if (isHave) message = "group.alreadyGroup";
                else {
                    const response = await APP.API.updateGroup({ name }, popItem.uuid);
                    
                    if (!response.complete) {
                        message = response.message;
                    } else {
                        handlerClose();
                    }
                }

                message && alertMessage(message);
            }
        }
    }

    const onKeyDown = e => {
        if (e.keyCode != 13) return true;

        onUpdate();
        return false;
    }
    
    return (
        <AbstractDialog name="modifyGroup" width={400} handlerClose={handlerClose}>
            <GroupAlertStyled>
                <h3 className="guid">{ t("group.modifyGuid") }</h3>
                <input type="text" ref={nameRef} onKeyDown={onKeyDown} />
                <div className="g_button">
                    <ClickButton size={16} text={t("common.previous")} onClick={handlerClose} />
                    <ClickButton size={16} text={t("common.modify")} onClick={onUpdate} />
                </div>
            </GroupAlertStyled>
        </AbstractDialog>
    )
}