import React, { useEffect, useState } from "react";
import { translate } from "../../../base/i18n";
import { isCheckPermission } from "../../../base/localuser";
import { connect } from "../../../base/redux";
import { PERMISSION } from "../../../member";
import { SHARE_MODE_LIST, VIDEO_MODE_LIST, getCurrentMode, layoutType } from "../../../video-layout";
import LayoutIconButton from "./LayoutIconButton";

function SharingButton({ 
    t, dispatch, screenType, 
    mode,
    isSharePermission, isLayoutPermission
}) {
    const [ list, setList ] = useState([]); 
    const [ isMobileDropDown, setIsMobileDropDown ] = useState(false);
    const [ isMobile, setIsMobile ] = useState(false);
    const [ optionDrop, setOptionDrop ] = useState(false);

    useEffect(() => {
        const mobile = screenType === layoutType.desktop ? false : true;

        setIsMobileDropDown(false);
        setIsMobile(mobile);
    }, [screenType]);

    useEffect(() => {
        if (isSharePermission && SHARE_MODE_LIST.includes(mode)) setList(SHARE_MODE_LIST);
        else if (isLayoutPermission && VIDEO_MODE_LIST.includes(mode)) setList(VIDEO_MODE_LIST);
        else setList(null);
    }, [mode, isSharePermission, isLayoutPermission]);

    const onShowOption = () => {
        setOptionDrop(!optionDrop);
    }

    return (
        <LayoutIconButton list={list} mode={mode} isMobile={isMobile} optionDrop={optionDrop} setOptionDrop={setOptionDrop} />         
    )
}

function _mapStateToProps(state) {
    const mode = getCurrentMode(state);
    const isSharePermission = isCheckPermission(state, PERMISSION.SHARE);
    const isLayoutPermission = isCheckPermission(state, PERMISSION.LAYOUT);

    return {
        mode, 
        isSharePermission,
        isLayoutPermission
    };
}


export default translate(connect(_mapStateToProps)(SharingButton));