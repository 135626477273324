import React, { useEffect, useState } from "react";
import { FooterIconButton } from "../../../base/buttons";
import { translate } from "../../../base/i18n";
import { connect } from "../../../base/redux";

import { isChatOpen, SwitchChatButton } from "../../../chat";
import { SwitchParticipantRight } from "../../../participant-right";
import { getCurrentMode, setVideoLayoutMode, VIDEO_MODE_LIST, videoLayoutMode } from "../../../video-layout";
import { useDispatch, useSelector } from "react-redux";

let prevMode = videoLayoutMode.grid;

function OpenVideoButton({ t, isMobile, chatOpen, participantOpen }) {
    const dispatch = useDispatch();
    const mode = useSelector(state => getCurrentMode(state));

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (VIDEO_MODE_LIST.includes(mode)) setChecked(true);
        else setChecked(false);

        return () => {
            if (VIDEO_MODE_LIST.includes(mode)) prevMode = mode;
        }
    }, [mode]);

    const onClick = () => {
        if (VIDEO_MODE_LIST.includes(mode)) return;
        dispatch(setVideoLayoutMode(prevMode));
    }

    return (
        <>
            { isMobile && chatOpen ? <SwitchChatButton /> 
            : isMobile && participantOpen ? <SwitchParticipantRight />
            : <FooterIconButton mode="video" name={t("device.video")} 
                checked={checked} onClick={onClick} /> }
        </>
    )
}

export default translate(OpenVideoButton);