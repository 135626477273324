import React, { useEffect } from "react";
import { translate } from "../../base/i18n";
import { getMemberList } from "./../functions";
import { getGroupId } from "../../base/conference";
import { getStartGroup } from "../../groups";
import { useSelector } from "react-redux";
import { PARTICIPANT_STATUS } from "../constants";

export default function ChatMemberList({ 
    t, currentGropuId, recipientList, 
    handlerToggleRList,
    setOpenMember
}) {   
    const member = useSelector(state => {
        let groupIds = null;
        let excludeGroup = false;
        currentGropuId = currentGropuId ? currentGropuId : getGroupId(state);
        const startGroup = getStartGroup(state, true);
        if (currentGropuId) {
            // 그룹이 시작 
            if (startGroup && startGroup.has(currentGropuId)) {
                // 그룹에 속한 경우 : 그룹에 속한 사용자 
                groupIds = [currentGropuId];
                excludeGroup = false;
            }      
        } else {
            // 그룹에 속하지 않은 경우 : 그룹이 시작된 사용자 제외 
            if (startGroup && startGroup.size > 0) {
                groupIds = [...startGroup];
                excludeGroup = true;
            }   
        }    

        return getMemberList(state, { status: [PARTICIPANT_STATUS.OCCUPIDE], excludeLocal: true, groupIds, excludeGroup}, false, false, true);
    });

    useEffect(() => {  
        recipientList.forEach((name, user_id) => {
            const prevP = member && member.find(p => p.user_uuid === user_id);

            if (!prevP) handlerToggleRList(user_id);
            
        });
    }, [member]);

    return (
        <>
            <dl className="close nonSelect" onClick={() => setOpenMember(false)}> {t("dialog.close")} </dl>
            <div className="list">
                { member && member.map(p =>  {
                    const isCheck = recipientList.has(p.user_uuid);
                    const handlerChoice = () => handlerToggleRList(p.user_uuid, p.nickname);

                    return (
                        <dl key={p.user_uuid} className="nonSelect" onClick={handlerChoice}>
                            <span className={`icoImg check check_${isCheck}`}></span>
                            <span>{p.nickname}</span>
                        </dl>
                    );
                }) }
            </div>
        </>
    )
}
