import { getDeviceLabelById } from "../../features/base/devices";
import { devicePermissionsChanged, notifyCameraError, notifyMicError, updateDeviceList } from "../../features/base/devices/actions";
import JitsiMeetJS, { JitsiMediaDevicesEvents, JitsiTrackErrors, JitsiTrackEvents, createLocalTracks } from "../../features/base/lib-jitsi-meet";
import { MEDIA_TYPE, VIDEO_TYPE, setAudioMuted, setVideoMuted } from "../../features/base/media";
import {
    getUserSelectedCameraDeviceId,
    getUserSelectedMicDeviceId,
    updateSettings
} from "../../features/base/settings";
import {
    getLocalAudioTrack,
    getLocalTracks,
    getLocalVideoTrack,
    isLocalTrackMuted,
    isUserInteractionRequiredForUnmute,
    noDataFromSource,
    replaceLocalTrack,
    trackAdded,
    trackMutedChanged,
    trackStopped
} from "../../features/base/tracks";
import loadEffects from "../../features/base/tracks/loadEffects";
import { setDeviceStatusOk } from "../../features/device/actions";
import { LOCAL_PARTICIPANT_DEFAULT_ID } from "../../features/member";
import { createTaskQueue } from "../../modules/util/helpers";

const _replaceLocalVideoTrackQueue = createTaskQueue();
const _replaceLocalAudioTrackQueue = createTaskQueue();

const JITSI_TRACK_ERROR_TO_MESSAGE_KEY_MAP = {
    microphone: {
        [JitsiTrackErrors.CONSTRAINT_FAILED]: 'dialog.micConstraintFailedError',
        [JitsiTrackErrors.GENERAL]: 'dialog.micUnknownError',
        [JitsiTrackErrors.NOT_FOUND]: 'dialog.micNotFoundError',
        [JitsiTrackErrors.PERMISSION_DENIED]: 'dialog.micPermissionDeniedError'
    },
    camera: {
        [JitsiTrackErrors.CONSTRAINT_FAILED]: 'dialog.cameraConstraintFailedError',
        [JitsiTrackErrors.GENERAL]: 'dialog.cameraUnknownError',
        [JitsiTrackErrors.NOT_FOUND]: 'dialog.cameraNotFoundError',
        [JitsiTrackErrors.PERMISSION_DENIED]: 'dialog.cameraPermissionDeniedError',
        [JitsiTrackErrors.UNSUPPORTED_RESOLUTION]: 'dialog.cameraUnsupportedResolutionError'
    }
};


let _initDeviceStatus = false;

class Device {
    constructor() {
        this.handler;
        this.localVideo = null;
        this.localPresenterVideo = null;

        this._untoggleScreenSharing = null;

        this.handlerUpdateAudioLevel = this.updateAudioLevel.bind(this);
    }

    init(handler) {
        this.handler = handler;
    }

    unload() {
        this.connection && this.connection.disconnect();

        JitsiMeetJS.mediaDevices.removeEventListener(
            JitsiMediaDevicesEvents.DEVICE_LIST_CHANGED,
            this.deviceChangeListener);
    }

    /**
     * 특정 유형의 로컬 트랙을 만듭니다.
     */
    createLocalTracksF(options = {}) {
        const state = APP.store.getState();

        let { cameraDeviceId, micDeviceId, devices } = options;
        
        cameraDeviceId = (cameraDeviceId === undefined) ? getUserSelectedCameraDeviceId(state) : options.cameraDeviceId;
        micDeviceId = (micDeviceId === undefined) ? getUserSelectedMicDeviceId(state) : options.micDeviceId;
           
        return (
            loadEffects(APP.store)
                .then(effectsArray => {
                    const effects = effectsArray.filter(effect => Boolean(effect));
                
                    return createLocalTracks(devices, 5000, {
                        cameraDeviceId,
                        micDeviceId,
                        effects,
                        facingMode: options.facingMode,
                        desktopSharingFrameRate: 30,
                        constraints: {
                            video: {
                                height: { ideal: 720 },
                                frameRate: { ideal: 30 }
                            }
                        },
                    }).catch(err => {
                        return Promise.reject(err);
                    });
                }
            ));
    } 

    // // audio stream 사용 
    // useAudioStream(newTrack) {
    //     const { getState, dispatch } = APP.store;

    //     return new Promise((resolve, reject) => {
    //         _replaceLocalAudioTrackQueue.enqueue(onFinish => {
    //             const state = getState();   
    //             const oldTrack = getLocalAudioTrack(state['features/base/tracks'])?.track;
                
    //             if (oldTrack === newTrack) {
    //                 resolve();
    //                 onFinish();
    //             }

    //             const conference = this.handler.getRoom();
    //             dispatch(replaceLocalTrack(oldTrack, newTrack, conference))
    //                 .then(resolve)
    //                 .catch(reject)
    //                 .then(onFinish)
    //         });
    //     });
    // }
    // // video stream 사용 
    // useVideoStream(newTrack) {
    //     const { getState, dispatch } = APP.store;
        
    //     return new Promise((resolve, reject) => {
    //         _replaceLocalVideoTrackQueue.enqueue(onFinish => {
    //             const state = getState();       
    //             const oldTrack = getLocalVideoTrack(state['features/base/tracks'])?.track;

    //             if (oldTrack === newTrack) {
    //                 resolve();
    //                 onFinish();
    //                 return;
    //             }

    //             const conference = this.handler.getRoom();
    //             dispatch(replaceLocalTrack(oldTrack, newTrack, conference))
    //                 .then(resolve)
    //                 .catch(reject)
    //                 .then(onFinish)
    //         });
    //     });
    // }

    // /**
    //  * TRACK
    //  */
    // // TRACK 저장 
    // trackAdded(track) {
    //     track.on(JitsiTrackEvents.TRACK_MUTE_CHANGED,
    //         () => APP.store.dispatch(trackMutedChanged(track)));

    //     // track.on(JitsiTrackEvents.TRACK_VIDEOTYPE_CHANGED,
    //     //     type =>  APP.store.dispatch(trackVideoTypeChanged(track, type)));

    //     const local = track.isLocal();
    //     const mediaType = track.getType();
    //     let participantId;
    //     let trackId;
        
    //     if (local) { 
    //         trackId = LOCAL_PARTICIPANT_DEFAULT_ID;
    //         participantId = LOCAL_PARTICIPANT_DEFAULT_ID;  
    //         track.on(JitsiTrackEvents.NO_DATA_FROM_SOURCE, () => APP.store.dispatch(noDataFromSource({ track })));

    //         track.on(JitsiTrackEvents.LOCAL_TRACK_STOPPED,
    //             () => APP.store.dispatch(trackStopped(track)));
    //     } else {
    //         trackId = track.getParticipantId();
    //     }

    //     APP.store.dispatch(trackAdded({
    //         track,
    //         participantId: trackId,
    //         muted: track.isMuted(),
    //         local,
    //         mediaType,
    //         videoStarted: false,
    //         videoType: track.type
    //     }));
    // }
    
    /**
     * TRACK 음소거
     */
    setTrackMuted(track, muted) {
        return track.track[muted]()
            .then(() => {
                // console.log(" track muted ", muted)
            }).catch(error => {
                setNotifyCameraError(error);
                if (error.name !== JitsiTrackErrors.TRACK_IS_DISPOSED) {
                    console.error(`set track ${muted} failed`, error);
                }
            });
    }
    // 오디오 muted
    isLocalAudioMuted() {
        const tracks = APP.store.getState()['features/base/tracks'];
        return isLocalTrackMuted(tracks, MEDIA_TYPE.AUDIO);
    }
    // 비디오 muted 
    isLocalVideoMuted() {
        const tracks = APP.store.getState()['features/base/tracks'];
        return isLocalTrackMuted(tracks, MEDIA_TYPE.VIDEO);
    }

    // 오디오만 사용 하는가
    isAudioOnly() {
        return Boolean(APP.store.getState()['features/base/audio-only'].enabled);
    }

    /**
     * 화면 공유
     */
    // 화면 공유 track 생성
    createDesktopTrack(options = {}) {
        const didHaveVideo = !this.isLocalVideoMuted();

        const getDesktopStreamPromise = this.createLocalTracksF({
                desktopSharingFrameRate: 25,
                devices: [ VIDEO_TYPE.DESKTOP ],
                resolution: 720,
                desktopSharingSourceDevice: ['screen', 'window', 'tab']
            });

        return getDesktopStreamPromise.then(desktopStreams => {
            this._untoggleScreenSharing = options.unToggleScreenSharing.bind(this, didHaveVideo);

            const desktopVideoStream = desktopStreams.find(stream => stream.getType() === MEDIA_TYPE.VIDEO);
            const dekstopAudioStream = desktopStreams.find(stream => stream.getType() === MEDIA_TYPE.AUDIO);

            if (dekstopAudioStream) {
                dekstopAudioStream.on(JitsiTrackEvents.LOCAL_TRACK_STOPPED, () => {
                    this._untoggleScreenSharing();
                });
            }
            
            if (desktopVideoStream) {
                desktopVideoStream.on(JitsiTrackEvents.LOCAL_TRACK_STOPPED, () => { 
                    this._untoggleScreenSharing && this._untoggleScreenSharing();
                });
            }

            return desktopStreams;
        }, (error) => {
            throw error;
        });
    }

    // conference track 재배치
    async replaceConferenceTrack(oldTrack, newTrack) {
        const conference = this.handler.getRoom();
        if (conference) {
            return await conference.replaceTrack(oldTrack, newTrack);
        }
    }

    /**
     * Video Input
     */
    toggleVideoMuted(showUI) {
        this.muteVideo(!this.isLocalVideoMuted(), showUI);
    }

    toggleAudioMuted(showUI) {
        this.muteAudio(!this.isLocalAudioMuted(), showUI);
    }

    muteVideo(mute, showUI = true) {
        if (!mute && isUserInteractionRequiredForUnmute(APP.store.getState())) {
            console.error('Unmuting video requires user interaction');

            return;
        }

        if (!this._localTracksInitialized) {
            muteLocalVideo(mute);
            return;
        } else if (this.isLocalVideoMuted() === mute) return;
    }

    muteAudio(mute, showUI = true) {
        if (!mute && isUserInteractionRequiredForUnmute(APP.store.getState())) {
            console.log('Unmuting audio requires user interaction');

            return;
        }
        if (!this._localTracksInitialized) {
            muteLocalAudio(mute);
            return;
        } else if (this.isLocalVideoMuted() === mute) return;
    }
    
    /**
     * Audio Input 
     */
    // audio level event listener 저장 
    listenForAudioUpdates({ track }) {
        this._stopListenForAudioUpdates();

        track && track.on(
            JitsiTrackEvents.TRACK_AUDIO_LEVEL_CHANGED, 
            this.handlerUpdateAudioLevel
        );
    }
    // audio level event listener 삭제
    _stopListenForAudioUpdates(track) {
        const localAudio = track ? track.track : getLocalAudioTrack(APP.store.getState()['features/base/tracks'])?.track;
        
        localAudio && localAudio.off(
            JitsiTrackEvents.TRACK_AUDIO_LEVEL_CHANGED, 
            this.handlerUpdateAudioLevel
        );
    }
    // UI
    updateAudioLevel(level) {
        APP.UI.updateAudioLevel(level);
    }

    /**
     * Audio Output
     */
    getAudioOutputDeviceId() {
        return JitsiMeetJS.mediaDevices.getAudioOutputDevice();   
    }
    setAudioOutputDevice(newId) {
        return JitsiMeetJS.mediaDevices.setAudioOutputDevice(newId);        
    }
}

function muteLocalAudio(muted) {
    APP.store.dispatch(setAudioMuted(muted));
}

function muteLocalVideo(muted) {
    APP.store.dispatch(setVideoMuted(muted));
}

// 마이크 오류 표시 
function setNotifyMicError(error) {
   const message = getErrorMicMsg(error);
    
    APP.store.dispatch(notifyMicError(message));
}

// 비디오 오류 표시 
 function setNotifyCameraError(error) {
    const message = getErrorCameraMsg(error);

    APP.store.dispatch(notifyCameraError(message));
}


const deviceManage = new Device();
export default deviceManage;