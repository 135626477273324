// // @flow

import { isCheckPermission } from "../base/localuser";
import { PERMISSION, getLocalIDMember } from "../member";
import { SET_SCREEN_TYPE_MODAL_ID } from "../options";
import { setVideoLayoutMode, videoLayoutMode } from "../video-layout";
import { SET_SCREEN_MONITOR, SET_SHARE_SCREEN_ID } from "./actionTypes";
import { getLocalSharingStatus, getScreenSharingId, getStartScreenShare } from "./function";

/**
 * 듀얼 모니터 on/off
 * @param {*} isDual 
 * @returns 
 */
export function setScreenMonitor(isDual) {
    return {
        type: SET_SCREEN_MONITOR,
        isDual
    }
}

export function toggleScreenShare(status = null, isButton = false) {
    return (dispatch, getState) => {    
        const change = status === null ? !getStartScreenShare(getState) : status;
        
        const onChange = change ? startScreenShare : stopScreenShare;
    
        dispatch(onChange(isButton));
    }
}


export function startScreenShare(openModalType) {
    return async (dispatch, getState) => {        
        // dispatch(setScreenMonitor(openModalType));
        // jitsi join 
        APP.UI.closeModal();

        APP.UI.showLoading(true);
        APP.mateManagement.startScreenShare(true)
            .then(conference => {                
                const jitsi_id = conference.myUserId();
                const user_id = getLocalIDMember(getState);
                dispatch(setShareScreenUserId(jitsi_id, user_id));

                APP.UI.showLoading(false);
            })
            .catch(err => {
                console.log(err);
                // err && APP.UI.messageHandler.showError({
                //     descriptionKey,
                //     titleKey
                // });

                APP.UI.showLoading(false);
            });
    }
}

/**
 * 현재 녹화중인 사용자 아이디 
 * @param {string} userId 
 * @returns 
 */
export function setShareScreenUserId(jitsi_id, user_id, isRemote = false) {
    return async (dispatch, getState) => {
        if (!isRemote) {
            
            const response = await APP.mateManagement.setScreenOption(jitsi_id);
            if (response.status !== 200) {
                APP.mateManagement.stopScreenShare();
                return;
            } else {
                if (jitsi_id) dispatch(setVideoLayoutMode(videoLayoutMode.screen));
                else dispatch(setVideoLayoutMode(videoLayoutMode.grid, false, true));
            }
        }
        
        dispatch({
            type: SET_SHARE_SCREEN_ID,
            jitsi_id,
            user_id,
            isLocal: isRemote ? false : true && jitsi_id,
            isRemote
        });
    }    
}


export function stopScreenShare(isButton = false) {
    return async (dispatch, getState) => {
        dispatch(setScreenMonitor(false));
        const localStatus = getLocalSharingStatus(getState);

        if (localStatus) {
            // 내가 녹화 중인 경우  -> 화면 공유 취소 
            APP.mateManagement.stopScreenShare();
            dispatch(setShareScreenUserId(null, null));
        } else {
            // 내가 녹화중이 아닌 경우 
            const response = await APP.mateManagement.setScreenOption(null);
            if (response.status !== 200) {
                console.log("error");
                return;
            } else {
                // if ( isButton ) {
                //     openScreenTypeModal(true);
                // }
            }
        }
    }
}

function openScreenTypeModal(isButton) {
    return (dispatch, getState) => {
        console.log(" start Screen share ")

        if (!isCheckPermission(getState, PERMISSION.SHARE)) {
            // 화면 공유 권한이 없스빈다.
            alert('화면 공유 권한이 없스빈다.');
            return;
        }

        APP.UI.openModal(SET_SCREEN_TYPE_MODAL_ID);   
    }
}