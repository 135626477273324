import React from "react";
import styled from 'styled-components';
import media from '../../../assets/media';
import { setAppPage } from "../../base/app/action";
import { enableByPage } from "../../base/app";
import { connect } from "react-redux";
import { translate } from "../../base/i18n";
import { MdChevronLeft } from "react-icons/md";

const PreviousButtonStyled = styled.div`
    margin: 20px 0 0 25px; position: absolute; bottom: 20px;
    display: inline-flex; align-items: center; gap: 4px;
    cursor: pointer;

    .button {
        display: flex;
        span {--icon-primary-color: #29abe2!important; }
    }
    
    .text {
        font-size: 14px; color: #1a1d25;
    }  
    
    ${media.desktop`
        right: 20px;
    `};

    ${media.tabletH`
        max-width: 90%;
    `};
`;

function PreviousButton({ t, dispatch, isEnable, previousRoute }) {
    const onClick = () => {
        dispatch(setAppPage(previousRoute));
    }

    return (
        <>
        { isEnable && <PreviousButtonStyled onClick={onClick}>
            <span className="button" style={{background: '#1FACFF', borderRadius: "10px", color: "#fff"}}> <MdChevronLeft size={20} /> </span>
            <span className="text">{ t("common.back") }</span>
        </PreviousButtonStyled> }
        </>
    );
}

function _mapStateToProps(state, ownProps) {
    return {
        isEnable: enableByPage(state, ownProps.previousRoute)
    };
}

export default translate(connect(_mapStateToProps)(PreviousButton));