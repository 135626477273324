import React from "react";
import { ChatBodyContainer } from "../../../chat";

function GroupChat({
    messages
}) {    
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column",     overflow: "hidden" }}>
            <ChatBodyContainer prevMessage={messages} />
        </div>
    )
}

export default GroupChat;