import { getCurrentConference, getGroupId } from "../base/conference";
import { toState } from "../base/redux";
import { getStartGroup } from "../groups";
import { PARTICIPANT_STATUS, getMemberList } from "../member";

export function getAudioTrackInRoom(stateful, filter ) {
    const state = toState(stateful);

    const isJoined = getCurrentConference(state);
    if (!isJoined) return false;

    let groupIds = false;
    let excludeGroup = false;
    const currentGropuId = getGroupId(state);
    const startGroup = getStartGroup(state, true);
    if (currentGropuId) {
        // 그룹이 시작 
        if (startGroup && startGroup.has(currentGropuId)) {
            // 그룹에 속한 경우 : 그룹에 속한 사용자 
            groupIds = [currentGropuId];
            excludeGroup = false;
        }      
    } else {
        // 그룹에 속하지 않은 경우 : 그룹이 시작된 사용자 제외 
        if (startGroup && startGroup.size > 0) {
            groupIds = [...startGroup];
            excludeGroup = true;
        }   
    } 

    const mebmers = getMemberList(state, { groupIds, excludeGroup, status: PARTICIPANT_STATUS.OCCUPIDE }, false, false, true);
    
    let audioTracks = new Map();
    mebmers.map(m => {
        const t = m.audioTrack;
        
        
        if (t) {
            audioTracks.set(t.track.stream.id, t.track);
        }        
    });

    return audioTracks;
}