import React from "react";
import { getMemberByUserID, getMemberRecordList } from "../../member";
import { shallowEqual, useSelector } from "react-redux";
import { AbstractDialog } from "../../base/dialog";
import { useTranslation } from "react-i18next";

function RecordItem({t, index, uuid}) {
    const member = useSelector(state => getMemberByUserID(state, uuid));
    
    if (!member) return null;
    return (
        <tr>
            <td>{ index + 1} </td>
            <td>{t(`role.${member.role}`)} </td>
            <td>{member.nickname} </td>
        </tr>
    )
}

export default function RecordList() {
    const { t } = useTranslation();
    const list = useSelector(state => getMemberRecordList(state), shallowEqual);

    return (
        <AbstractDialog t={t} name={"recording"} width={520}>
            <table cellPadding={"8px"}>
                <tbody>
                    { list && list.map((item, index) => <RecordItem key={item} t={t} index={index} uuid={item} /> )}
                </tbody>
            </table>
        </AbstractDialog>
    )
}