import { ReducerRegistry } from '../redux';

import {
    ADD_PENDING_DEVICE_REQUEST,
    DEVICE_PERMISSIONS_CHANGED,
    REMOVE_PENDING_DEVICE_REQUESTS,
    SET_AUDIO_INPUT_DEVICE,
    SET_VIDEO_INPUT_DEVICE,
    UPDATE_DEVICE_LIST
} from './actionTypes';
import { groupDevicesByKind } from './functions';

const DEFAULT_STATE = {
    availableDevices: {
        audioInput: [],
        audioOutput: [],
        videoInput: []
    },
    pendingRequests: [],
    permissions: {
        audio: false,
        video: false
    }
};

ReducerRegistry.register('features/base/devices', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case UPDATE_DEVICE_LIST: {
            const deviceList = groupDevicesByKind(action.devices);

            return {
                ...state,
                availableDevices: deviceList
            };
        }

        case ADD_PENDING_DEVICE_REQUEST:
            return {
                ...state,
                pendingRequests: [
                    ...state.pendingRequests,
                    action.request
                ]
            };

        case REMOVE_PENDING_DEVICE_REQUESTS:
            return {
                ...state,
                pendingRequests: [ ]
            };

        case SET_AUDIO_INPUT_DEVICE: {
            return state;
        }
        case SET_VIDEO_INPUT_DEVICE: {
            return state;
        }
        case DEVICE_PERMISSIONS_CHANGED: {
            return {
                ...state,
                permissions: action.permissions
            };
        }
        
        default:
            return state;
        }
    }
);

