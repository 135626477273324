import React from "react";
import { MdOutlinePushPin, MdPushPin } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { PERMISSION, setMemberPinned } from "../../member";
import { isCheckPermission } from "../../base/localuser";
import { IconPinStyle } from "../../member/components/styles";


export default function IconPinMember({
    user_uuid, pinned
}) {
    if (!APP.function.pin) return null;
    const dispatch = useDispatch();
    const isPinPermission = useSelector(state => isCheckPermission(state, PERMISSION.PIN));
    
    const setPinned = () => {        
        dispatch(setMemberPinned(user_uuid, !pinned));
    }

    return (
        <span>
            <IconPinStyle isPointer={isPinPermission} onClick={setPinned}>
                { pinned 
                    ? <MdPushPin color="#29abe2" /> 
                    : <MdOutlinePushPin color="#d5d5d5"/> }                        
            </IconPinStyle>
        </span>
    )
}