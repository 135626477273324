import React from "react";
import { MdMenu, MdMenuOpen } from "react-icons/md";

export default function DocumentTool({
    isLeft, setIsLeft, children
}) {
    const onClick = () => setIsLeft(!isLeft);

    return (
        <div className={`canvas_tool`}>
            <div className="switch_canvas_list noClick" onClick={onClick}>
                { isLeft 
                    ? <MdMenu className="noClick" size={28} /> 
                    : <MdMenuOpen className="noClick" size={28} /> }
            </div>

            { children }
        </div>
    )
}