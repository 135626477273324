/**
 * 문서 파일 저장 
 * {
 *     type: ADD_SHARE_ITEM,
 *     data: {
 *          file: Object,
 *          isWhiteBoard: Boolean,
 *          shared: Boolean,
 *          page: String,
 *          list: Array,
 *          count: Number,
 *          complete: Boolean,
 *          user_uuid: String
 *      };
*  }
 */
export const ADD_SHARE_ITEM = 'ADD_SHARE_ITEM';

/**
 * 문서 파일 삭제 
 * {
 *      type: DELETE_SHARE_ITEM,
 *      uuid: String
 * }
 */
export const DELETE_SHARE_ITEM = 'DELETE_SHARE_ITEM';

/**
 * 문서 포커스 
 * {
 *      type: SET_FOCUS_SHARE_ITEM,
 *      uuid, index, mode
 * }
 */
export const SET_FOCUS_SHARE_ITEM = 'SET_FOCUS_SHARE_ITEM';

/**
 * 문서 파일 리스트 저장
 * {
 *      type: ADD_SHARE_LIST,
 *      whiteboard_list, document_list * 
 * }
 */
export const ADD_SHARE_LIST = 'ADD_SHARE_LIST';

export const SET_VISIBLE_DOCUMENT_LIST = 'SET_VISIBLE_DOCUMENT_LIST';
