import { connectionDisconnect, connectionWillConnect } from "../../features/base/connection";

export default function MateConnection(mate) {
    this.mate = mate;

    this.connect = null;
    this.connecting = false;
}

MateConnection.prototype.constructor = MateConnection;

MateConnection.prototype.connectAndJoin = async function(result_only, change_role) {
    if (this.connect) {
        return Promise.reject("연결이 되어있음.");
    }

    if (this.connecting) {
        return Promise.reject("이미 연결 중");
    }

    const websocket = new WebSocket(`wss://${location.host}/svc/room/ws${this.mate.groupId ? `?${this.mate.groupId}` : ``}`);

    APP.store.dispatch(connectionWillConnect(websocket, this.mate.type));
    this.connecting = true;

    return new Promise((resolve, reject) => {
        this.connect = websocket;
        this.mate.setWebsocket(websocket);

        websocket.onopen = async () => {
            websocket.onclose = () => {
                this.connect = null;

                APP.mateManagement.disconnect();
            }

            websocket.onmessage = (message) => {
                this.mate.events.reciveCommand(event);
            }

            // 웹 소켓 연결이 성공 한 경우 (auth 세팅)
            const response = await this.mate.events.setAuth();
            if (response.status === 200) {
                const joinResponse = await this.mate.events.join(result_only, change_role);

                if (joinResponse.status === 200) {
                    resolve(true);
                } else {
                    reject("방 입장 오류 - " + joinResponse.payload.message.message.format);
                }
            } else {
                reject("인증 오류");
            }
        }

        websocket.onerror = (err) => {
            this.connect = null;
            reject(err);
        }
    });
}

MateConnection.prototype.disconnect = function() {
    if (this.mate.groupId === "") APP.store.dispatch(connectionDisconnect(this.mate.type));
    if (this.mate.websocket) this.mate.websocket.close();
}

MateConnection.prototype.unsetConnect = function() {
    this.connect && this.connect.close();
    this.connect = null;
    this.connecting = false;
    this.mate.setWebsocket(null);
}