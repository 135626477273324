import React from "react";
import { BsFillFileMinusFill, BsFillGridFill, BsFillPencilFill, BsFillPersonLinesFill, BsFillPlusSquareFill, BsFillTrashFill, BsGearFill, BsHouseFill, BsPeople, BsSearch } from "react-icons/bs";
import { MdAccessTime, MdAddBox, MdAppRegistration, MdAssignmentInd, MdAutoAwesome, MdBackHand, MdCenterFocusStrong, MdCoPresent, MdFiberPin, MdFilterNone, MdGroups, MdHowToVote, MdInterpreterMode, MdKeyboardArrowDown, MdKeyboardArrowUp, MdLockOutline, MdMenu, MdMenuOpen, MdMic, MdMicOff, MdOndemandVideo, MdOutlineAttachFile, MdOutlineChatBubble, MdOutlineClose, MdOutlineEmojiEmotions, MdOutlineLibraryBooks, MdOutlinePermContactCalendar, MdOutlinePersonAddAlt1, MdOutlinePushPin, MdOutlineScreenShare, MdOutlineSettings, MdOutlineSettingsRemote, MdOutlineVoiceOverOff, MdOutlineWavingHand, MdPeople, MdReadMore, MdRecordVoiceOver, MdSaveAlt, MdSettings, MdSkipPrevious, MdStorage, MdVideocam, MdVideocamOff, MdZoomOutMap } from "react-icons/md";
import styled from "styled-components";
import { PERMISSION } from "../../member";
import { videoLayoutMode } from "../../video-layout";
import { RECORDING_TYPE } from "../../recording/constants";

function getIcon(mode, size=16) {
    switch (mode) {
        case "current":
            return <MdOutlineScreenShare size={28} />

        case "option":
            return <MdSettings size={24} />

        case videoLayoutMode.white:
            return <MdFilterNone className="layout_button" size={24} />

        case videoLayoutMode.document:
            return <MdOutlineLibraryBooks className="layout_button" size={28} />

        case videoLayoutMode.note:
            return <MdAppRegistration className="layout_button" size={28} />

        case videoLayoutMode.vod:
            return <MdOndemandVideo  className="layout_button"size={24} />
        
        case videoLayoutMode.grid:
            return <BsFillGridFill className="layout_button" size={24} />

        case videoLayoutMode.pin:
            return <MdCoPresent className="layout_button" size={24} />

        case videoLayoutMode.seminar:
            return <MdAssignmentInd className="layout_button" size={26} />

        case videoLayoutMode.voice:
            return <MdInterpreterMode className="layout_button" size={22} />

        case 'pin':
            return <MdOutlinePushPin size={size} />
            
        case 'voice':
            return <MdOutlineVoiceOverOff size={size} />

        case "fullScreen":
            return <MdZoomOutMap size={size} />
        
        case "fullScreenExit":
            return <MdZoomOutMap size={size} />
        
        case "headInvite":
            return <BsPeople size={size} />

        case "workTime":
            return <MdAccessTime size={size} />

        case "recording":
            return (
                <span className="icoImg rec"></span>
            );

        case "participant":
            return <MdPeople size={size} />

        case "chat":
            return <MdOutlineChatBubble size={size} />

        case "survey": 
            return <MdHowToVote size={size} />

        case "blur":
            return <MdAutoAwesome size={size} />

        case "dual":
            return <MdOutlineScreenShare size={size} />

        case PERMISSION.SELF_RECORD:
        case RECORDING_TYPE.PERSONAL:
            return <MdRecordVoiceOver size={size} />

        case PERMISSION.SERVER_RECORD:
        case RECORDING_TYPE.SERVER:
            return <MdStorage size={size} />

        case "lock":
            return <MdLockOutline size={size} />

        case "deviceManager":
            return <MdOutlineSettings size={size} />

        case "setDevice":
            return <MdOutlineSettingsRemote size={size} />

        case "fillAdd":
            return <MdAddBox size={size} />
        
        case "delete":
            return <BsFillTrashFill size={size} />

        case "setUser":
            return <BsFillPersonLinesFill size={size} />

        case "home":
            return <BsHouseFill size={size} />

        case "search": 
            return <BsSearch size={size} />

        case "setting":
            return <BsGearFill size={size} />

        case "save":
            return <MdSaveAlt size={size} />

        case "previous":
            return <MdSkipPrevious size={size} />

        case "close":
            return <MdOutlineClose size={size} />

        case "group":
            return <MdGroups size={size} />

        case "more":
            return <MdReadMore size={size} />

        case "asc":
            return <MdKeyboardArrowUp size={size} /> // <BsSortAlphaUpAlt size={size} />

        case "desc":
            return <MdKeyboardArrowDown size={size} /> // <BsSortAlphaDown size={size} />
            
        case "pen":
            return <BsFillPencilFill size={size} />

        case "emoji":
            return <MdOutlineEmojiEmotions size={size} />

        case "file":
            return <MdOutlineAttachFile size={size} />

        case "inivite":
            return <MdOutlinePersonAddAlt1 size={size} />

        case "canvasRec":
            return <MdCenterFocusStrong size={size} />
            
        case 'menu':
            return <MdMenu size={size} />

        case 'menu_open':
            return <MdMenuOpen size={size} />
        
        case 'minus':
            return <BsFillFileMinusFill size={size} />

        case 'plus':
            return <BsFillPlusSquareFill size={size} /> 
        
        case "mic_on":
            return <MdMic size={size} />
        
        case "mic_off":
            return <MdMicOff size={size} />
        
        case "camera_on":
            return <MdVideocam size={size} />
        
        case "camera_off":
            return <MdVideocamOff size={size} />

        case "keep_hand":
            return <MdBackHand size={size} />

        case "fadeout_hand":
            return <MdOutlineWavingHand size={size} />
        

        default:
            return null;
    }
}

export default function IconButton({
    id, clsName, mode, name, size, hover=true, children, checked, onClick
}) {
    return (
        <IconButtonStyled id={id} className={`icon ${clsName}`} mode={mode} hover={hover} checked={checked} onClick={onClick}>
            { getIcon(mode, size) }

            { name && <span >
                { name }
                { children }
            </span> }
        </IconButtonStyled>
    )
}

const IconButtonStyled = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &.button {
        &_on { 
            color: rgb(72, 162, 241)!important;
            .voice {
                background: rgb(72, 162, 241);
            }
        }
    }

    ${props => props.checked && `
        color: rgb(72, 162, 241)!important;
    `}

    span { word-break: ${props => props.mode === "current" ? "break-all" : "keep-all"}; display: flex; width: ${props => props.mode === "current" ? "45px" : "auto"};

        align-items: center;
    }

    .layout_button {
        min-width: 22px;
    }  

    ${props => props.hover && `
        cursor: pointer;
        &:hover {
            color: rgb(72, 162, 241)!important;
        }
    `}
`;