import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getRoomOption } from "../../base/conference";
import { translate } from "../../base/i18n";
import { isCheckPermission } from "../../base/localuser/functions";
import { connect } from "../../base/redux";
import { deleteCookie, setCookie } from "../../base/util/cookie";
import { PERMISSION, getLocalIDMember } from "../../member";
import AbstractPopup from "../../options/components/AbstractPopup";
import { SURVEY_MODAL_ID } from "../../options/constants";
import { addSharedSurvey, setSurveyReplied } from "../action";
import { getSharedSurvey } from "../functions";

const SurveyStyled = styled.ul`
    display: flex;
    margin: 12px 0;
    li { width: 25%; text-align: center; word-break: keep-all; white-space: normal; }
    li:nth-child(2) { width: 30%;}
`;

const pageTypeList = {
    init: "init",
    add: "add",
    edit: "edit",
    vote: "vote"
};

function Survey({
    t, dispatch, isVotePermission, list, localId, roomUUID, lastRepliedUUID
}) {
    const [pageType, setPageType] = useState(pageTypeList.init);
    const [src, setSrc] = useState(null);
    const surveyframe = useRef();

    useEffect(() => {
        if (pageType === pageTypeList.edit) {
            if (surveyframe) surveyframe.current.contentWindow.location.reload();
        }
    }, [lastRepliedUUID]);

    const frameLoad = () => {
        const message = {roomID: roomUUID, memberID: localId};
        setTimeout(() => surveyframe.current.contentWindow.postMessage(message, "*"), 200);
    }

    useEffect(() => {
        return (() => {
            if (surveyframe && surveyframe.current) {
                surveyframe.current.removeEventListener("load", frameLoad);                
            }
        })
    }, []);

    useEffect(() => {
        if (surveyframe && surveyframe.current) {
            surveyframe.current.addEventListener("load", frameLoad);                
        }
    }, [surveyframe]);
    
    useState(async() => {
        const getIFrameMessage = async (receiveMessage) => {
            const type = receiveMessage?.data?.type;
            const uuid = receiveMessage?.data?.uuid;
    
            if (type && type === "SHARE_SURVEY") {
                if (uuid) {
                    const { complete, message } = await APP.API.getSurveyInfo(uuid);
                    if (complete) {
                        // 설문 조사 저장 후 
                        dispatch(addSharedSurvey(uuid, message));
                        
                        // send websocket 저장 했으니 설문조사 보세요.라고 알림
                        APP.mateManagement.notifySurvey(uuid);
    
                        // 설문 조사 결과 페이지로 이동 
                        goToPage(null, pageTypeList.init);
                    }
                }
            } else if (type && type === "REPLY_SURVEY") {
                APP.mateManagement.replySurvey(uuid);
    
                dispatch(setSurveyReplied(uuid, localId));
                goToPage(null, pageTypeList.init);
            }
        }

        window.addEventListener("message", getIFrameMessage, false);
        
        return (() => {
            window.removeEventListener("message", getIFrameMessage, false);
            setSrc(null);
        });
    }, []);

    const goToPage = (key, type) => {
        if (type === pageTypeList.edit) {
            setCookie("survAuth", key, null, '/');
            setSrc(`/survey/${roomUUID}/${key}/chart?memberID=${localId}`);
        } else if (type === pageTypeList.vote) {
            deleteCookie("survAuth"); 
            setSrc(`/survey/${roomUUID}/${key}?memberID=${localId}`);           
        } else if (type === pageTypeList.add) {
            setSrc(`/survey?roomID=${roomUUID}&memberID=${localId}`);
        } else if (type === pageTypeList.init) {
            setSrc(null);
        }

        setPageType(type);     
    };

    return (
        <>
            { pageType === pageTypeList.init ?
                isVotePermission ? 
                    <>
                        <button className="btn btn_middle" onClick={() => goToPage(null, pageTypeList.add)}> {t("survey.add")} </button>
                        {
                            list.length > 0 ?
                                list.map(([ uuid, value ], index) => {
                                    return (
                                        <SurveyStyled key={uuid}>
                                            <li> { index + 1 } </li>
                                            <li> { value?.title } </li>
                                            <li> { value?.deadline || "-" } </li>
                                            <li>
                                                <span className="btn btn_middle btn_true" onClick={() => goToPage(uuid, pageTypeList.edit)}>
                                                    {t("survey.result")} 
                                                </span> 
                                            </li>
                                        </SurveyStyled>
                                    );                                        
                                })
                            : <div> {t("survey.noSurvey")} </div>
                        }
                    </>
                    :
                    <>
                    {
                        list.length > 0 ?
                            [...list].map(([ uuid, value ], index) => {
                                return (
                                    <SurveyStyled key={uuid}>
                                        <li> { index + 1 } </li>
                                        <li> { value?.title } </li>
                                        <li> { value?.deadline } </li>
                                        <li>
                                        { value?.deadline && Date.parse(value.deadline) < Date.parse(new Date) ? 
                                            <div> {t("survey.expired")} </div> : 
                                            value.replied.has(localId) 
                                                ? <div> {t("survey.complete")} </div> 
                                                : <p className="btn btn_middle btn_true" onClick={() => goToPage(uuid, pageTypeList.vote)}> 
                                                    {t("survey.participation")}
                                                </p>
                                        }
                                        </li>
                                    </SurveyStyled>
                                );                                        
                            })
                        : <div> {t("survey.noSurvey")} </div>
                    }
                    </>
                : 
                <>
                    <button className="btn btn_middle btn_true" onClick={() => goToPage(null, pageTypeList.init)}> {t("common.previous")} </button>
                </> 
            }

            <iframe ref={surveyframe} src={src} style={src !== null ? {width: "100%", height: "100%" } : {display: "none"}} />
        </>
    )
}

class SurveyIFrame extends AbstractPopup {
    componentDidMount() {
        this._setModalID(SURVEY_MODAL_ID);
    }

    render() {
        const contentStyle = { height: "90%" };

        return (
            <div className="option_pop center survey" style={{ height: "90%" }}>
                { this._renderPopupHeader(this.props.t, "options.survey") }
                { this._renderPopupBody(
                    <Survey   
                        t={this.props.t}
                        dispatch={this.props.dispatch}
                        isVotePermission={this.props.isVotePermission} 
                        list={this.props.list} 
                        localId={this.props.localId}
                        roomUUID={this.props.roomUuid}
                        lastRepliedUUID={this.props.lastRepliedUUID}
                    />,
                    contentStyle) 
                }
            </div>
        )
    }
}
 
function _mapStateToProps(state) {
    const { uuid: roomUuid } = getRoomOption(state);
    const isVotePermission = isCheckPermission(state, PERMISSION.POLL);
    const localId = getLocalIDMember(state);
    const { lastRepliedUUID } = state["features/survey"];

    console.log(localId, getSharedSurvey(state))
    return {
        isVotePermission,
        list: getSharedSurvey(state),
        localId,
        roomUuid,
        lastRepliedUUID
    }
}

export default translate(connect(_mapStateToProps)(SurveyIFrame));