import React, { useRef, useState } from "react";
import { ClickButton, IconButton } from "../../base/buttons";
import { useDispatch, useSelector } from "react-redux";
import { createGroup } from "../actions";
import { isHaveGroupByName } from "../functions";
import { ROLE, getLocalIDMember } from "../../member";
import { AbstractDialog } from "../../base/dialog";

export default function AddGroupButton({
    t, popup, handlerClose, alertMessage
}) {
    const dispatch = useDispatch();
    const local_uuid = useSelector(state => getLocalIDMember(state));
    const nameRef = useRef();

    const onClick = () => saveGroup();

    const onKeyDown = e => {
        if (e.keyCode != 13) {
            return true;
        }
        e.preventDefault();

        saveGroup();
        return false;
    }

    const saveGroup = async () => {
        try {
            let message;

            const name = nameRef.current.value;
            if (!name) message = "group.requiredGroup";
            else {
                const isHave = isHaveGroupByName(APP.store.getState, name);
            
                if (isHave) message = "group.alreadyGroup";
                else {
                    const data = { members: [{ id: local_uuid, role_name: ROLE.HOST }] };
                    const response = await APP.API.createGroup(name, data);

                    if (!response.complete) {
                        message = response.message;
                    } else {
                        nameRef.current.value = "";
                        popup && handlerClose && handlerClose();
                    }
                }
            }
            
            message && alertMessage(message);
        } catch (err) {
            console.log(err)
        }        
    }

    const renderButton = () => {
        return (
            <div className="section section_add">
                <input ref={nameRef} type="text" placeholder={t("group.requiredGroup")} onKeyDown={onKeyDown} />
                { popup 
                    ? <ClickButton text={t("group.add")} size={20} onClick={onClick} /> 
                    : <IconButton size={24} mode={"fillAdd"} onClick={onClick} /> }
            </div>
        )
    }

    if (popup) {
        return (
            <AbstractDialog name="addGroup" width={400} handlerClose={handlerClose}>
                { renderButton() }
            </AbstractDialog>
        )
    } else {
        return renderButton();
    }
}