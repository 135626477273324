// @flow

import COUNTRIES_RESOURCES from 'i18n-iso-countries/langs/ko.json';
import i18next from 'i18next';
import I18nextXHRBackend from 'i18next-xhr-backend';

import LANGUAGES_RESOURCES from '../../../lang/languages.json';
import MAIN_RESOURCES from '../../../lang/main.json';

import languageDetector from './languageDetector';

export const LANGUAGES = Object.keys(LANGUAGES_RESOURCES);

export const DEFAULT_LANGUAGE = LANGUAGES[0];

const options = {
    backend: {
        loadPath: 'lang/{{ns}}-{{lng}}.json'
    },
    defaultNS: 'main',
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
        escapeValue: false // not needed for react as it escapes by default
    },
    load: 'languageOnly',
    ns: [ 'main', 'languages', 'countries' ],
    react: {
        useSuspense: false
    },
    returnEmptyString: false,
    returnNull: false,
    whitelist: LANGUAGES.slice()
};

i18next
    .use(navigator.product === 'ReactNative' ? {} : I18nextXHRBackend)
    .use(languageDetector)
    .init(options);

i18next.addResourceBundle(
    DEFAULT_LANGUAGE,
    'countries',
    COUNTRIES_RESOURCES,
    /* deep */ true,
    /* overwrite */ true);
i18next.addResourceBundle(
    DEFAULT_LANGUAGE,
    'languages',
    LANGUAGES_RESOURCES,
    /* deep */ true,
    /* overwrite */ true);
i18next.addResourceBundle(
    DEFAULT_LANGUAGE,
    'main',
    MAIN_RESOURCES,
    /* deep */ true,
    /* overwrite */ true);

require('./BuiltinLanguages');




export default i18next;

