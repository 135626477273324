import React from "react";
import DocumentLeft from "../../../share-document/components/DocumentLeft";
import { CanvasTool } from "../../canvas";
import { translate } from "../../i18n";

import { DocumentTool } from "../../../share-document";
import VodList from "./VodList";
import VodPlayer from "./VodPlayer";

function VodLayout({
    t, mobile, expand, isLeft, setIsLeft
}) {

    const onClick = () => {
        setIsLeft(!isLeft);
    };

    return (
        <div className={`addition_layout share_document_layout left_${expand}`} >
            <DocumentLeft mobile={mobile} expand={expand} isLeft={isLeft} 
                name={t("layout.vod")}>
                <VodList />
            </DocumentLeft>

            <VodPlayer expand={expand} isLeft={isLeft} setIsLeft={setIsLeft} />
        </div>
    );
}

export default translate(VodLayout);