import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setPage } from "../actions";
import { getPage, getShowMembers } from "../functions";
import MemberFilm from "./MemberFilm";
import { PagingStyled, SliderStyled } from "./styles";

let touch_x = 0;
let touch_start = false;
export default function MobileScreen({
    layoutStyle
}) {
    const dispatch = useDispatch();
    const members = useSelector(state => getShowMembers(state), shallowEqual);
    const { page, maxPage } = useSelector(state => getPage(state));

    const [columns, setColumns] = useState(1);
    const [rows, setRows] = useState(1);
    const [isVertical, setIsVertical] = useState(false);
    const [prev, setPrev] = useState(false);
    const [next, setNext] = useState(false);

    useEffect(() => {
        change();
    }, []);
    
    useEffect(() => {
        change();
    }, [layoutStyle]);

    useEffect(() => {
        if (page > 1) setPrev(true);
        else setPrev(false);

        if (maxPage > page) setNext(true);
        else setNext(false);
    }, [page, maxPage]);

    useEffect(() => {
        const count = members.length;
        
        if (count < 3) {
            setColumns(1);
            setRows(count);
        } else {
            const rows = Math.round(count / 2);
            setColumns(2);
            setRows(rows);
        }
    }, [members.length]);

    const prevPage = () => {
        dispatch(setPage(page - 1));
    }
    const nextPage = () => {
        dispatch(setPage(page + 1));
    }

    const change = () => {
        if (window.orientation == 0) {
            setIsVertical(true);
        } else {
            setIsVertical(false);
        }
    }

    const onTouchStart = e => {
        touch_start = true;
        touch_x = e.changedTouches[event.changedTouches.length - 1].clientX;
    };
    const onTouchEnd = e => {
        if (!touch_start) return;
        touch_start = false;
        const moveX = touch_x - e.changedTouches[event.changedTouches.length - 1].clientX;
        if (
            e.type == 'touchend' && (Math.abs(moveX) > 40)
        ) {
            if (moveX > 0) {
                if (next) nextPage();
            } else {
                if (prev) prevPage();
            }
        }
    };
    return (
        <>
            <SliderStyled
                onTouchStart={onTouchStart}
                onClick={onTouchEnd}
                onTouchEnd={onTouchEnd}
                columns={isVertical ? columns : rows}
                rows={isVertical ? rows : columns}
                maxPage={maxPage}>
                { members && members.map(m => {
                    if (!m) return null;
                    return <MemberFilm key={m} user_uuid={m} /> 
                }) }
            </SliderStyled>

            { maxPage > 1 &&
                <PagingStyled
                    width={100 / maxPage}
                    margin={(100 / maxPage) * (page - 1)}>
                    <span className="bar"></span>
                </PagingStyled> }
        </>
    )
}