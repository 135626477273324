import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FooterIconButton } from "../../../base/buttons";
import { translate } from "../../../base/i18n";
import { getStartScreenShare, stopScreenShare } from "../../../screen-share";
import { SHARE_MODE_LIST, getCurrentMode, setVideoLayoutMode, videoLayoutMode } from "../../../video-layout";

let prevVMode = videoLayoutMode.grid;
let prevSMode = videoLayoutMode.document;

function SwitchShareDocumentButton({ t }) {
    const dispatch = useDispatch();
    const mode = useSelector(state => getCurrentMode(state));
    const start_screen = useSelector(state => getStartScreenShare(state));
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (SHARE_MODE_LIST.includes(mode)) setChecked(true);
        else setChecked(false);

        return () => {
            if (SHARE_MODE_LIST.includes(mode)) prevSMode = mode;
            else if (videoLayoutMode.screen !== mode) prevVMode = mode;
        }
    }, [mode]);

    const startDocumentShare = () => {
        // 화면 공유중인경우 무시 
        if (start_screen) {
            dispatch(stopScreenShare());
            return;
        };
        APP.UI.closeModal();        

        let mode = checked ? prevVMode : prevSMode;
        dispatch(setVideoLayoutMode(mode));
    }

    return (
        <FooterIconButton mode="document" name={t("conference.documentShare")} checked={checked} onClick={startDocumentShare} />
    )
}

export default translate(SwitchShareDocumentButton);