import React from "react";

import LoadingPortal from "../../../../LoadingPortal";
import { connect } from "../../redux";
import LoadImage from "./LoadImage";

class Loading extends React.Component {
    constructor(props) {
        super(props);
    }    
    
    render() {
        return (
            <LoadingPortal>
                <LoadImage isLoad={this.props.isLoad} text={this.props.text} /> 
            </LoadingPortal>
        );
    }    
}

export function _mapStateToProps(state) {
    const { isLoad, text } = state['features/base/loading'];
    return {
        isLoad, text
    }
}

export default connect(_mapStateToProps)(Loading);

