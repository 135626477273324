import React from "react";

import { IconButton } from "../buttons";
import { translate } from "../i18n";
import RightLabel from "./RightLabel";

function RightHeader({
    t, name, open, children, handlerOpen, handlerClose
}) {
    return (
        <RightLabel>
            <li>
                <IconButton clsName="arrow" mode={open ? "desc" : "asc"} size={24} onClick={handlerOpen} />
                <span className="title">{ t(`common.${name}`) }</span>
            </li>
            <li>
                { children }
                { APP.layout[name].close && <IconButton mode="close" onClick={handlerClose} /> }
            </li>
        </RightLabel>
    )
}

export default translate(RightHeader);
