import React, { useRef, useState } from "react";
import { toArray } from "react-emoji-render";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { IconButton } from "../../../base/buttons";
import RightLabel from "../../../base/right-menu/RightLabel";
import { ChatMemberList } from "../../../member";
import { sendMessage, setPrivateMessageRecipient } from "../../actions";
import SmileysPanel from "../SmileysPanel";
import { ChatFooterStyled } from "./styles";

function getRecipient(list) {
    let recipients = [];
    try {
        list.forEach((name, id) => {
            recipients.push({ id, name });
        });

        return recipients;
    } catch {
        return recipients;
    }
}

export default function ChatFooterContainer({
    handlerFileMessage, displayName
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const chatTextArea = useRef();
    const [ message, setMessage ] = useState();
    const [ list, setList ] = useState(new Map());
    const [ openMember, setOpenMember ] = useState(false);
    const [ showEmoji, setShowEmoji ] = useState(false);

    const handlerChatMessage = () => {
        const content = message && message.trim() !== '' ? message : null;

        if (content) {
            const recipient = getRecipient(list);
            dispatch(setPrivateMessageRecipient(recipient));

            const data = {
                type: 'text',
                uuid: uuidv4(),
                content: message,
                recipient,
                displayName
            };

            dispatch(sendMessage(data));

            setMessage();
        }
    }

    const handlerToggleRList = (user_id, nickname) => {
        const nList = _.cloneDeep(list);

        if (nList.has(user_id)) nList.delete(user_id);
        else nList.set(user_id, nickname);
        setList(nList);
    }

    const renderShowChoiceMember = () => {
        const isPrivateMsg = list.size > 0;
        if (!isPrivateMsg) return null;
        return (
            <div className="choice_box">
                { [...list].map(([user_id, name]) => {
                    return (
                        <IconButton key={user_id} clsName="item"
                            mode="close" name={name} hover={false}
                            onClick={() => handlerToggleRList(user_id)} />
                    )
                })}
            </div> 
        )
    }

    const onChangeMessage = e => {
        const message = e.target.value;

        setMessage(message);
    }

    const renderTextArea = () => {
        const onDetectSubmit = e => {
            if (e.keyCode === 13 && !e.shiftKey) {
                e.preventDefault();
    
                handlerChatMessage();
                chatTextArea.current.value = '';
            }
        }
        
        return (
            <textarea ref={chatTextArea} onKeyDown={onDetectSubmit}
                onChange={onChangeMessage} value={message}
                placeholder={t('chat.fieldPlaceHolder')} />
        )
    }

    const renderEmoji = () => {
        if (!showEmoji) return null;

        const handlerSmileySelect = smileyText => {
            if (smileyText) {
                let processedMessage = '';
                const content = toArray(smileyText, {className: 'smiley'});
    
                let k = 0;
                content.forEach(i => {
                    k++;
                    if (typeof i === 'string') processedMessage = {i};
                    else processedMessage = i;
                });
                
                const icon = processedMessage.props.children;
                setMessage(message ? message + icon : icon);
            }
    
            chatTextArea && chatTextArea.current.focus();
        };

        return (
            <SmileysPanel onSmileySelect={handlerSmileySelect} />
        )
    }

    const renderMemberList = () => {
        if (!openMember) return null;        

        return (
            <div className={`chat_participant`}>
                <ChatMemberList t={t}
                    recipientList={list}
                    setOpenMember={setOpenMember}
                    handlerToggleRList={handlerToggleRList}
                />
            </div> 
        )
    }

    const renderRightLabel = () => {
        const choiceMember = (type) => {
            const isPrivateMsg = list.size > 0;
            
            const onClick = () => {
                if (type) {
                    setOpenMember(false);
                    setList(new Map());
                } else {
                    setOpenMember(!openMember);
                }
            }

            return (
                <span className={`text_${isPrivateMsg === !type ? 'on' : 'off'}`}
                    onClick={onClick}>
                    {t(`chat.${type}SelectPartner`)}
                </span>
            )
        }

        const renderEmotionBtn = () => {
            if (!APP.layout.chat.emotion) return null;

            const onClick = () => setShowEmoji(!showEmoji);
            return (
                <IconButton clsName={`button button_${showEmoji ? 'on' : 'off'}`} 
                    mode="emoji" size={20} 
                    onClick={onClick} />
            )   
        }

        const renderFile = () => {
            if (!APP.layout.chat.file) return null;
            const handlerChatFileSend = ({ target }) => {
                const { files } = target;
        
                handlerFileMessage(files, getRecipient(list));
        
                target.value = null;
            }

            return (
                <>
                    <input type="file" id="chatFile" className="chatFile" 
                        onChange={handlerChatFileSend} />
                    <label htmlFor="chatFile">
                        <IconButton clsName={"chat_file_icon"} mode="file" size={20} />
                    </label>
                </>
            )
        }

        return (
            <RightLabel>
                <li>
                    <span className="title">{ t("chat.dialog") }</span>
                    <div className={`select_member`}>
                        { choiceMember(true) }
                        { choiceMember(false) }
                    </div>
                </li>
                <li>
                    { renderEmotionBtn() }
                    { renderFile() }
                </li>
            </RightLabel>
        )
    }

    return (
        <ChatFooterStyled>
            { renderMemberList() }
            { renderShowChoiceMember() }
            { renderEmoji() }
            { renderRightLabel() }
            { renderTextArea() }
        </ChatFooterStyled>
    )
}