import { connectionFailed, connectionSuccess, connectionWillConnect, getConnection } from "../../features/base/connection";
import JitsiMeetJS, { JitsiConnectionEvents } from "../../features/base/lib-jitsi-meet";

const type = "jitsi";
class Connection {
    constructor() {
        this.connection = null;
        this.connecting = null;
        this.handler = null;
    }

    init(handler) {
        this.handler = handler;
    }

    connect(config, isAddEvent) {
        if (this.connection) {
            // 이미 연결 되어있는 상태
            return Promise.reject("연결이 되어있음.");
        }

        if (this.connecting) {
            // 연결 중인 상태 
            return Promise.reject("이미 연결 중");
        }
        
        const connection = new JitsiMeetJS.JitsiConnection(null, null, config);
        return new Promise((resolve, reject) => {
            const dispatch = APP.store.dispatch;
            connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, handleConnectionEstablished);
            connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, handleConnectionFailed);

            // 연결 시도
            !isAddEvent && dispatch(connectionWillConnect(connection, type));

            function handleConnectionEstablished() {
                // connection 
                this.connection = connection;
                this.connecting = false;

                !isAddEvent && dispatch(connectionSuccess(connection, type));
                // 이벤트 삭제 
                unsubscribe();

                resolve(connection);
            }

            function handleConnectionFailed(error, message, credentials, details) {
                this.connection = null;
                this.connecting = false;
                // 연결 실패
                connection.removeEventListener(JitsiConnectionEvents.CONNECTION_FAILED, handleConnectionFailed);

                !isAddEvent && dispatch(connectionFailed(message, type));

                reject(error);
            }

            function unsubscribe() {
                connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, handleConnectionEstablished);

                connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, handleConnectionFailed);
            }

            this.connection = connection;
            connection.connect();
        });

        // 연결 시작 
        connection.connect();
    }

    disconnect() {
        if (this.connection) {
            this.connection.disconnect();

            this.connection = null;
            this.connecting = false;    
        }
    }

}

export default Connection;