/* global APP */

import { MiddlewareRegistry } from '../redux';
import { updateSettings } from '../settings';

import {
    CHECK_AND_NOTIFY_FOR_NEW_DEVICE,
    NOTIFY_CAMERA_ERROR,
    NOTIFY_MIC_ERROR,
    SET_AUDIO_INPUT_DEVICE,
    SET_VIDEO_INPUT_DEVICE,
    UPDATE_DEVICE_LIST
} from './actionTypes';
import {
    configureInitialDevices,
    devicePermissionsChanged,
    removePendingDeviceRequests,
    setAudioInputDevice,
    setVideoInputDevice
} from './actions';
import {
    areDeviceLabelsInitialized,
    formatDeviceLabel,
    setAudioOutputDeviceId
} from './functions';
import { showNotification, showWarningNotification } from '../../notifications/actions';
    
import { replaceAudioTrackById, replaceVideoTrackById, setDeviceStatusWarning } from '../../device/actions';
import { isPrejoinPageVisible } from '../conference';
import { NOTIFICATION_TIMEOUT } from '../../notifications';
import { APP_MOUNT } from "./../app";
import { JitsiMediaDevicesEvents } from '../lib-jitsi-meet';

let permissionsListener;

MiddlewareRegistry.register(store => next => action => {

    switch (action.type) {
        case UPDATE_DEVICE_LIST:
            if (areDeviceLabelsInitialized(store.getState())) {
                
                return _processPendingRequests(store, next, action);
            }
            break;

        case NOTIFY_CAMERA_ERROR: {
            const error = action.error;
            
            store.dispatch(setDeviceStatusWarning('videoInput', {
                titleKey: error?.titleKey,
                message: error?.additionalCameraErrorMsg
            }));

            if (!isPrejoinPageVisible(store.getState())) {
                store.dispatch(showWarningNotification({
                    description: error?.additionalCameraErrorMsg,
                    descriptionKey: error?.cameraErrorMsg,
                    titleKey: error?.titleKey
                }));
            }        

            break;
        }

        case NOTIFY_MIC_ERROR: {
            const { additionalMicErrorMsg, micErrorMsg, titleKey } = action.error;

            store.dispatch(setDeviceStatusWarning('audioInput', {
                titleKey,
                message: additionalMicErrorMsg
            }));

            if (!isPrejoinPageVisible(store.getState())) {
                store.dispatch(showWarningNotification({
                    description: additionalMicErrorMsg,
                    descriptionKey: micErrorMsg,
                    titleKey
                }));
            }

            break;
        }
        case SET_AUDIO_INPUT_DEVICE:            
            store.dispatch(replaceAudioTrackById(action.deviceId));
 
            break;

        case SET_VIDEO_INPUT_DEVICE:
            store.dispatch(replaceVideoTrackById(action.deviceId));

            break;
        
        case CHECK_AND_NOTIFY_FOR_NEW_DEVICE:
            _checkAndNotifyForNewDevice(store, action.newDevices, action.oldDevices);
            break;

        case APP_MOUNT:
            if (!action.isLoad) break;
            // 장치에 대한 권한 확인 + 권한 업데이트 이벤트 추가 
            const _permissionsListener = permissions => {
                store.dispatch(devicePermissionsChanged(permissions));
                store.dispatch(configureInitialDevices());
            }
            
            const { mediaDevices } = JitsiMeetJS;

            permissionsListener = _permissionsListener;
            mediaDevices.addEventListener(JitsiMediaDevicesEvents.PERMISSIONS_CHANGED, permissionsListener);
            Promise.all([
                mediaDevices.isDevicePermissionGranted('audio'),
                mediaDevices.isDevicePermissionGranted('video')
            ]).then(results => {
                _permissionsListener({
                    audio: results[0],
                    video: results[1]
                });
            }).catch(() => {

            });
            break;
        }

        return next(action);
    });


function _processPendingRequests({ dispatch, getState }, next, action) {
    const result = next(action);
    const state = getState();
    const { pendingRequests } = state['features/base/devices'];

    if (!pendingRequests || pendingRequests.length === 0) {
        return result;
    }

    pendingRequests.forEach(request => {
        processExternalDeviceRequest(
            dispatch,
            getState,
            request,
            request.responseCallback);
    });
    dispatch(removePendingDeviceRequests());

    return result;
}

function _checkAndNotifyForNewDevice(store, newDevices, oldDevices) {
    const { dispatch } = store;

    const onlyNewDevices = newDevices.filter(
        nDevice => !oldDevices.find(
            device => device.deviceId === nDevice.deviceId));

    const devicesGroupBy = onlyNewDevices.reduce((accumulated, value) => {
        accumulated[value.groupId] = accumulated[value.groupId] || [];
        accumulated[value.groupId].push(value);

        return accumulated;
    }, {});

    Object.values(devicesGroupBy).forEach(devicesArray => {
        if (devicesArray.length < 1) {
            return;
        }
        const newDevice = devicesArray[0];
        const description = formatDeviceLabel(newDevice.label);

        let titleKey;

        switch (newDevice.kind) {
            case 'videoinput': {
                titleKey = 'notify.newDeviceCameraTitle';
                break;
            }
            case 'audioinput' :
            case 'audiooutput': {
                titleKey = 'notify.newDeviceAudioTitle';
                break;
            }
        }

        dispatch(showNotification({
            description,
            titleKey,
            customActionNameKey: 'notify.newDeviceAction',
            customActionHandler: _useDevice.bind(undefined, store, devicesArray)
        }), NOTIFICATION_TIMEOUT);

        // _useDevice(store, devicesArray);
    });
}

function _useDevice({ dispatch }, devices) {
    devices.forEach(device => {
        switch (device.kind) {
            case 'videoinput': {
                dispatch(updateSettings({
                    userSelectedCameraDeviceId: device.deviceId,
                    userSelectedCameraDeviceLabel: device.label
                }));

                dispatch(setVideoInputDevice(device.deviceId));
                break;
            }
            case 'audioinput': {
                dispatch(updateSettings({
                    userSelectedMicDeviceId: device.deviceId,
                    userSelectedMicDeviceLabel: device.label
                }));

                dispatch(setAudioInputDevice(device.deviceId));
                break;
            }
            case 'audiooutput': {
                setAudioOutputDeviceId(
                    device.deviceId,
                    dispatch,
                    true,
                    device.label)
                    .then(() => console.log('changed audio output device'))
                    .catch(err => {
                        console.log(
                            'Failed to change audio output device.',
                            'Default or previously set audio output device will',
                            ' be used instead.',
                            err);
                    });
                break;
            }
        }
    });

    return true;
}
