import { getFocusItemByMode } from "../../share-document";
import { getCurrentMode } from "../../video-layout";
import { MiddlewareRegistry } from "../redux";
import { deleteCanvasIndex } from "./action";
import { SET_CANVAS_TOOL } from "./actionTypes";
import { ALL_ERASER } from "./constants";

MiddlewareRegistry.register(store => next => async action => {
    switch (action.type) {
        case SET_CANVAS_TOOL:
            if (action.tool === ALL_ERASER.name) {
                const focusItem = getFocusItemByMode(store.getState, getCurrentMode(store.getState));
                focusItem.uuid && store.dispatch(deleteCanvasIndex(focusItem.uuid, focusItem.index));
            }
            break;
    }
    return next(action);
});