// @flow

import { Component } from 'react';

import { hideNotification } from '../actions';
import { areThereNotifications } from '../functions';

export default class AbstractNotificationsContainer extends Component {
    constructor(props) {
        super(props);

        this._notificationDismissTimeout = null;

        this._onDismissed = this._onDismissed.bind(this);
    }

    componentDidMount() {
        this._manageDismissTimeout();
    }

    componentDidUpdate(prevProps) {
        this._manageDismissTimeout(prevProps);
    }

    _manageDismissTimeout(prevProps) {
        const { _notifications, autoDismissTimeout } = this.props;

        if (_notifications.length) {
            const notification = _notifications[0];
            const previousNotification
                = prevProps && prevProps._notifications.length
                    ? prevProps._notifications[0]
                    : undefined;

            if (notification !== previousNotification) {
                this._clearNotificationDismissTimeout();

                if (notification
                        && notification.props.isDismissAllowed !== false) {
                    const {
                        timeout = autoDismissTimeout,
                        uid
                    } = notification;

                    this._notificationDismissTimeout = setTimeout(() => {
                        this._onDismissed(uid);
                    }, timeout || 2500);
                }
            }
        } else if (this._notificationDismissTimeout) {
            this._clearNotificationDismissTimeout();
        }
    }

    componentWillUnmount() {
        this._clearNotificationDismissTimeout();
    }

    _clearNotificationDismissTimeout() {
        this._notificationDismissTimeout
            && clearTimeout(this._notificationDismissTimeout);

        this._notificationDismissTimeout = null;
    }

    _onDismissed(uid) {
        const { _notifications } = this.props;

        if (!_notifications.length || _notifications[0].uid === uid) {
            this._clearNotificationDismissTimeout();
        }

        this.props.dispatch(hideNotification(uid));
    }
}

export function _abstractMapStateToProps(state) {
    const { notifications } = state['features/notifications'];
    const _visible = areThereNotifications(state);

    return {
        _notifications: _visible ? notifications : [],
        autoDismissTimeout: typeof interfaceConfig === 'undefined'
            ? undefined // Ignore for the case of mobile
            : interfaceConfig.ENFORCE_NOTIFICATION_AUTO_DISMISS_TIMEOUT
    };
}
