// @flow

import { ALERT_MESSAGE, REMOVE_ATTENDANCES_NOTIFICATION } from '.';
import { CLEAR_ROOM, CONFERENCE_LEFT } from '../base/conference';
import { ReducerRegistry } from '../base/redux';

import {
    CLEAR_ALERT_MESSAGE, CLEAR_NOTIFICATIONS,
    HIDE_NOTIFICATION, REMOVE_PARTICIPANT_NOTIFICATION, SET_NOTIFICATIONS_ENABLED,
    SHOW_NOTIFICATION
} from './actionTypes';
import { NOTIFICATION_TYPE_PRIORITIES } from './constants';

const DEFAULT_STATE = {
    enabled: true,
    notifications: [],
    message: null
};

ReducerRegistry.register('features/notifications', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case CONFERENCE_LEFT:
            return {
                ...state,
                notifications: []
            };

        case REMOVE_PARTICIPANT_NOTIFICATION:
            var notifications = state.notifications.filter(
                notification => notification?.props?.id !== action?.id
            );

            return {
                ...state,
                notifications
            };
        case REMOVE_ATTENDANCES_NOTIFICATION:
            var notifications = state.notifications.filter(notification => !notification.props.isAttendances);

            return {
                ...state,
                notifications
            };

        case HIDE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.uid !== action.uid)
            };

        case SET_NOTIFICATIONS_ENABLED:
            return {
                ...state,
                enabled: action.enabled
            };

        case SHOW_NOTIFICATION:
            return {
                ...state,
                notifications:
                    _insertNotificationByPriority(state.notifications, {
                        component: action.component,
                        props: action.props,
                        timeout: action.timeout,
                        uid: action.uid
                    })
            };
        
        case ALERT_MESSAGE:
            const { title, props } = action;
            return {
                ...state,
                message: { title, props }
            }

        case CLEAR_ALERT_MESSAGE:
            return {
                ...state, 
                message: null
            }
    }    

    return state;
});

function _insertNotificationByPriority(notifications, notification) {
    const newNotificationPriority
        = NOTIFICATION_TYPE_PRIORITIES[notification.props.appearance] || 0;

    let insertAtLocation = notifications.length;
    for (let i = 1; i < notifications.length; i++) {
        const queuedNotification = notifications[i];
        const queuedNotificationPriority
            = NOTIFICATION_TYPE_PRIORITIES[queuedNotification.props.appearance]
                || 0;

        if (queuedNotificationPriority < newNotificationPriority) {
            insertAtLocation = i;
            break;
        }
    }

    const copyOfNotifications = notifications.slice();

    copyOfNotifications.splice(insertAtLocation, 0, notification);
    
    return copyOfNotifications;
}
