// @flow
import {
    CONNECTION_DISCONNECTED,
    CONNECTION_ESTABLISHED,
    CONNECTION_FAILED,
    CONNECTION_WILL_CONNECT,
    SET_IS_CONNECTED
} from './actionTypes';

// conneting
export function connectionWillConnect(connection, serverType) {
    return {
        type: CONNECTION_WILL_CONNECT,
        data: {
            serverType, 
            connection
        }
    };
}

// 연결 성공 시 action 
export function connectionSuccess(connection, serverType) {
    return (dispatch) => {
        try {
            const timeEstablished = Date.now();

            // 연결 성공 시간 update
            dispatch(_setConnectionEstablished(connection, timeEstablished, serverType));
        } catch (err) {
            console.log(err)
        } 
    };
}

// 연결 끊기는 경우 action
export function connectionDisconnect(serverType) {
    return {
        type: CONNECTION_DISCONNECTED,
        serverType
    }
}

// 연결 실패 시 action 
export function connectionFailed(error, serverType) {
    return {
        type: CONNECTION_FAILED,
        serverType,
        error
    }
}

export function setIsConnection(connected) {
    return {
        type: SET_IS_CONNECTED,
        connected        
    }    
}

// connection 성공 
function _setConnectionEstablished(connection, timeEstablished, serverType) {
    return {
        type: CONNECTION_ESTABLISHED,
        connection,
        timeEstablished,
        serverType
    };
}