// @flow
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const ENDPOINT_MESSAGE_RECEIVED = 'ENDPOINT_MESSAGE_RECEIVED';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const CLOSE_CHAT = 'CLOSE_CHAT';
export const OPEN_CHAT = 'OPEN_CHAT';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SET_PRIVATE_MESSAGE_RECIPIENT = 'SET_PRIVATE_MESSAGE_RECIPIENT';

export const SET_SEND_FILE_LIST = 'SET_SEND_FILE_LIST';
export const RESET_SEND_FILE_LIST = 'RESET_SEND_FILE_LIST';
export const SET_UNREAD_COUNT = 'SET_UNREAD_COUNT';
 