import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '../../base/buttons';
import { translate } from '../../base/i18n';
import { isCheckPermission } from '../../base/localuser/functions';
import { PERMISSION } from '../../member';
import { setRecordingEnabled, stopServerRecord, stopTempServerRecord } from '../actions';
import { RECORDING_TYPE } from '../constants';

function SwitchRecordButton({ t, type }) {
    if (!APP.layout.option.recorder) return null;
    else {
        if (type === RECORDING_TYPE.PERSONAL && !APP.function.record.local) return null;
        if (type === RECORDING_TYPE.SERVER && !APP.function.record.server) return null;
    }

    const permission = useSelector(state => isCheckPermission(state, RECORDING_TYPE.PERSONAL === type ? PERMISSION.SELF_RECORD : PERMISSION.SERVER_RECORD));
    if (!permission) return null;

    const dispatch = useDispatch();
    const isRecord = useSelector(state => state['features/recording'][type].enabled);

    const onClick = () => {
        if (type === RECORDING_TYPE.SERVER) {
            if (!isRecord) APP.UI.openModal(type);
            else {
                dispatch(stopTempServerRecord());
            }
        }
        
    };

    return (
        <IconButton mode={type} 
            name={ type === RECORDING_TYPE.PERSONAL ? t(`recording.${isRecord}Personal`) : t(`recording.${isRecord}Server`) }
            size={22}
            onClick={onClick} />

    );
}

export default translate(SwitchRecordButton);
