// @flow

import React, { Component } from 'react';


export default class AudioTrack extends Component {
    static defaultProps = {
        autoPlay: true,
        id: ''
    };
    
    constructor(props) {
        super(props);

        this._setRef = this._setRef.bind(this);
        this._play = this._play.bind(this);
    }

    componentDidMount() {
        this._attachTrack(this.props.audioTrack);

        if (this._ref) {
            const { muted, volume } = this.props;

            if (typeof volume === 'number') {
                this._ref.volume = volume;
            }

            if (typeof muted === 'boolean') {
                this._ref.muted = muted;
            }
        }
    }

    componentWillUnmount() {
        this._detachTrack(this.props.audioTrack);
    }

    shouldComponentUpdate(nextProps) {
        const currentJitsiTrack = this.props.audioTrack?.track;
        const nextJitsiTrack = nextProps.audioTrack?.track;

        if (currentJitsiTrack !== nextJitsiTrack) {
            this._detachTrack(this.props.audioTrack);
            this._attachTrack(nextProps.audioTrack);
        }

        if (this._ref) {
            const currentVolume = this._ref.volume;
            const nextVolume = nextProps.volume;

            if (typeof nextVolume === 'number' && !isNaN(nextVolume) && currentVolume !== nextVolume) {
                this._ref.volume = nextVolume;
            }

            const currentMuted = this._ref.muted;
            const nextMuted = nextProps.muted;

            if (typeof nextMuted === 'boolean' && currentMuted !== nextVolume) {
                this._ref.muted = nextMuted;
            }
        }

        return false;
    }

    render() {
        const { autoPlay, id } = this.props;

        return (
            <audio
                autoPlay = { autoPlay }
                id = { id }
                ref = { this._setRef } />
        );
    }

    _attachTrack(track) {
        if (!track || !track.track) {
            return;
        }

        track.track.attach(this._ref);
        this._play();
    }

    _detachTrack(track) {
        if (this._ref && track && track.track) {
            clearTimeout(this._playTimeout);
            this._playTimeout = undefined;
            track.track.detach(this._ref);
        }
    }

    _play(retries = 0) {
        if (!this._ref) {
            return;
        }
        const { autoPlay, id } = this.props;

        if (autoPlay) {
            this._ref.play()
            .then(() => {
                if (retries !== 0) {
                    this._playTimeout = undefined;
                }
            }, e => {
                if (retries < 3) {
                    this._playTimeout = setTimeout(() => this._play(retries + 1), 1000);
                } else {
                    this._playTimeout = undefined;
                }
            });
        }
    }

    _setRef(audioElement) {
        this._ref = audioElement;
        const { onInitialVolumeSet } = this.props;

        if (this._ref && onInitialVolumeSet) {
            onInitialVolumeSet(this._ref.volume);
        }
    }
}
