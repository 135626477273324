import { ENTER_ROOM } from "../../lobby/constants";
import { getRoomNameFromUrl, setRoom } from "../conference";
import { storage } from "../localStorage";
import { setUserToken } from "../localuser";
import { getUserAccessToken } from "../localuser/functions";
import { MiddlewareRegistry } from "../redux";
import { getDisplayName, updateSettings } from "../settings";
import { deleteCookie, getCookieValue, getSearchData } from "../util";
import { setAppEnablePage, setAppPage } from "./action";
import { APP_MOUNT, SET_APP_PAGE } from "./actionType";
import { PAGE_TYPE } from "./constants";
import { checkEnabaledPage, getCurrentPage } from "./functions";

MiddlewareRegistry.register(store => next => action => {
    const { dispatch, getState } = store;
    const state = getState();

    switch(action.type) {
        case APP_MOUNT:
            const InitMount = async () => {
                setUIOption(dispatch);

                APP.mateManagement.init();
                
                let authAToken = getCookieValue('access_token');
                if (authAToken) {
                    // cookie를 받아온 경우
                    await setInitDataByCookie(dispatch, authAToken);
                    dispatch(setAppPage(PAGE_TYPE.DEVICE));
                } else {
                    // url로 입장 한 경우
                    authAToken = getUserAccessToken(state);
                    setInitDataByURL(dispatch, authAToken);
                }
            }

            InitMount();
            
            break;
        
        case SET_APP_PAGE:
            const nextPage = action.page;
            const currentPage = getCurrentPage(state);
            if (nextPage === null || nextPage === currentPage) return;

            const isEnable = checkEnabaledPage(store, nextPage);

            if (isEnable) {
                if (nextPage === PAGE_TYPE.DEVICE || nextPage === PAGE_TYPE.MAIN) {
                    const nickname = getDisplayName(state);
                    if (!nickname || nickname === '') {
                        let nickname = getCookieValue('nickname');
                        if (nickname) {
                            nickname = decodeURIComponent(nickname);
                            dispatch(updateSettings({ displayName: nickname }));
                        } else {
                            action = {
                                ...action,
                                page: PAGE_TYPE.NICKNAME
                            };
                        }
                    } else {                        
                        if (currentPage === PAGE_TYPE.LOBBY) {
                            action = {
                                ...action,
                                page: PAGE_TYPE.NICKNAME
                            };
                        }
                    }                    
                }  
            } else {
                return next({
                    ...action, 
                    page: PAGE_TYPE.ERROR
                });
            }

            if (nextPage === PAGE_TYPE.LOBBY) {
                dispatch(setRoom());
            }
            break;
    }

    return next(action);
});

async function setUIOption(dispatch) {
    const storage_key = getSearchData('storage_key');
    
    if (storage_key) {
        const storage_data = storage.get(storage_key);
        if (storage_data && storage_data.ui_option) {
            storage_data.ui_option?.defaultNickname && await dispatch(updateSettings({ displayName: storage_data.ui_option.defaultNickname }));

            const data = {
                [PAGE_TYPE.LOBBY]: storage_data.ui_option?.enableLobbyPage && true,
                [PAGE_TYPE.NICKNAME]: storage_data.ui_option?.enableNicknamePage && true,
            }

            await dispatch(setAppEnablePage(data));
        }
    }        
}

async function setInitDataByCookie(dispatch, authAToken) {
    const token = {
        access_token: authAToken,
        refresh_token: null,
    };

    await dispatch(setUserToken(token));

    const roomUUID = getCookieValue('room_uuid');
    let nickname = getCookieValue('nickname');

    if (roomUUID) {
        await dispatch(setRoom({ name: roomUUID }));
        if (nickname) {
            try {
                nickname = decodeURIComponent(nickname);
            } catch {
                
            }
            
            await dispatch(updateSettings({ displayName: nickname }));
        }
    }

    deleteCookie('access_token');
    deleteCookie('room_uuid');
    // deleteCookie('nickname');
}

async function setInitDataByURL(dispatch, authAToken) {
    // localstorage get access token 
    if (!authAToken) {
        let localToken = storage.get('token');
        
        const storage_key = getSearchData('storage_key');
        if (storage_key) {
            const data = storage.get(storage_key);

            if (data && data.token?.access_token) localToken = data.token;
            else localToken = null;
        }

        localToken && await dispatch(setUserToken(localToken));
    }

    // const urlRoomName = getRoomNameFromUrl(location.pathname);
    
    // if (urlRoomName) {    
    //     await dispatch(setRoom({ name: urlRoomName }, ENTER_ROOM));
    // }
}