// @flow

import { isAudioMuted, isVideoMutedByUser } from '../base/media';

/**
 * Selector for determining if the device status strip is visible or not.
 *
 * @param {Object} state - The state of the app.
 * @returns {boolean}
 */
 export function isDeviceStatusVisible(state) {
    return !(isAudioMuted(state) && isVideoMutedByUser(state))
    && !state['features/base/config'].startSilent;
}
/**
 * Returns the text for the prejoin status bar.
 *
 * @param {Object} state - The state of the app.
 * @returns {string}
 */
export function getDeviceStatusText(state) {
    return state['features/device']?.deviceStatusText;
}

/**
 * Returns the type of the prejoin status bar: 'ok'|'warning'.
 *
 * @param {Object} state - The state of the app.
 * @returns {string}
 */
export function getDeviceStatusType(state) {
    return state['features/device']?.deviceStatusType;
}


/**
 * Selector for getting the error if any while creating streams.
 *
 * @param {Object} state - The state of the app.
 * @returns {string}
 */
export function getRawError(state) {
    return state['features/device']?.rawError;
}

export function getVdoErrorMsg(error) {
    if (!error) return "deviceSelection.cameraOk";
    let message = "deviceSelection.cameraError";
    const { name } = error;
    
    switch (name) {
        case "NotReadableError":
            message = "deviceSelection.cameraError";    
            break;
        case "AbortError":
            message = "deviceSelection.cameraAbort";    
            break;
        case "NotAllowedError":
            message = "deviceError.cameraPermission";    
            break;
        // case "NotFoundError":
        // case "OverconstrainedError":
        // case "SecurityError":
        // case "TypeError":
        //     message = "deviceError.cameraError";
        //     break;
    }

    return message;
}