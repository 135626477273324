import React from "react";
import { connect } from "react-redux";
import { ClickButton } from "../../../base/buttons";
import { getGroupId, getMainRoomRole } from "../../../base/conference";
import { AbstractDialog } from "../../../base/dialog";
import { translate } from "../../../base/i18n";
import { ROLE, getLocalMember } from "../../../member";

function ClosePopup({
    t, dispatch, local, isPermission, currentGroupId
}) {
    const exitRoom = async () => {
        APP.UI.closeModal();
        
        const { complete, message } = await APP.API.assignGroup({ 
            members: [{user_id: local.user_uuid, role_name: ROLE.ADMIN}], method: "unset" 
        }, currentGroupId);
            
        if (complete) {
            APP.mateManagement.changeMeetingRoom(currentGroupId, false);
        } else {
            alert(message);
        }
    }

    const endRoom = async () => {
        // 호스트 or 매니저
        if (isPermission) {
            if (currentGroupId) {
                const { complete, message } = await APP.API.allUpdateGroup({ groups: {[currentGroupId]: {switch: "close"}} });
                if (complete) {
                    await exitRoom();
                    APP.UI.closeModal();
                }
                return;
            } 
        }

        APP.mateManagement.unload();
    }

    const closeRoom = async () => {
        await APP.API.closeRoom();

        APP.mateManagement.unload();
    }

    return (
        <AbstractDialog t={t} name={ currentGroupId ? "exit" : "close" } width={460}>
            <div style={{display: "flex", flexDirection: "column", gap: "12px"}}>
                <p className="guid">{ currentGroupId ? t("conference.groupExitGuid") :  t("conference.exitGuid") }</p>
                { currentGroupId ?  
                    <div style={{ margin: "16px auto"}}>
                        <p>{t("conference.outGroup")}</p>
                        <p>{t("conference.closeGroup")}</p>
                    </div>  
                    : <div style={{ margin: "16px auto"}}>
                        { isPermission && <p>{t("conference.forceQuit")}</p> }
                        <p>{t("conference.OutQuit")}</p> 
                    </div>                   
                }

                <div style={{display: "flex", gap: "12px"}}>
                    { isPermission && currentGroupId && <ClickButton text={t("conference.exit")} size={20} onClick={exitRoom} /> }
                    { isPermission && !currentGroupId && <ClickButton text={t("conference.close")} size={20} onClick={closeRoom} /> }
                    <ClickButton text={t("conference.end")} size={16} onClick={endRoom} />
                </div>
            </div>
        </AbstractDialog>
    )
}

function _mapStateToProps(state) {
    const local = getLocalMember(state);
    const mainRole = getMainRoomRole(state);
    const isPermission = [ROLE.HOST, ROLE.MANAGER].includes(mainRole);
    
    return {
        local,
        isPermission,
        currentGroupId: getGroupId(state)
    }
}

export default translate(connect(_mapStateToProps)(ClosePopup));

