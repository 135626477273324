import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FooterIconButton, IconButton } from "../../base/buttons";
import { translate } from "../../base/i18n";
import { isChatOpen, toggleChat, unReadChat } from "..";
import { connect } from "../../base/redux";

function SwitchChatButton({ t, dispatch, clsName, isOption, openChat, unRChat }) {
    if (!APP.function.chat) return null;

    const onClick = () => {
        dispatch(toggleChat());
        APP.UI.closeModal();
    };
    
    return (
        <>  
            { isOption ? 
                <IconButton clsName={clsName} mode={"chat"} 
                    name={`${ t(`options.${openChat}Chat`) } `} 
                    size={20}
                    onClick={onClick} />
                :
                <FooterIconButton mode={`${unRChat === 'waiting' ? 'chat_waiting': 'chat'}`} 
                    name={ t("chat.title") } 
                    checked={openChat}
                    onClick={onClick} 
                />
            }
             
        </>
    )
}

function _mapStateToProps(state) {
    const openChat = isChatOpen(state);
    const unRChat = unReadChat(state);

    return {
        openChat, unRChat
    }
}

export default translate(connect(_mapStateToProps)(SwitchChatButton));