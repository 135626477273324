export const CONFERENCE_FAILED = 'CONFERENCE_FAILED';
export const CONFERENCE_JOINED = 'CONFERENCE_JOINED';
export const CONFERENCE_LEFT = 'CONFERENCE_LEFT';
export const CONFERENCE_SUBJECT_CHANGED = 'CONFERENCE_SUBJECT_CHANGED';
export const CONFERENCE_TIMESTAMP_CHANGED = 'CONFERENCE_TIMESTAMP_CHANGED';
export const CONFERENCE_UNIQUE_ID_SET = 'CONFERENCE_UNIQUE_ID_SET';
export const KICKED_OUT = 'KICKED_OUT';
export const SET_ROOM = 'SET_ROOM';
export const CLEAR_ROOM = 'CLEAR_ROOM';
export const SET_CONFERENCE_ERROR = 'SET_CONFERENCE_ERROR';

export const CONFERENCE_WILL_JOIN = 'CONFERENCE_WILL_JOIN';
export const SET_IS_COFERENCE_JOIN = 'SET_IS_COFERENCE_JOIN';

export const SET_GROUP_UUID = 'SET_GROUP_UUID';
export const SET_MAIN_ROLE = 'SET_MAIN_ROLE';

export const INIT_ROOM = 'INIT_ROOM';

export const CONFERENCE_ALL_JOINED = 'CONFERENCE_ALL_JOINED';