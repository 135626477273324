import { getLocalUserUUID, getUserAccessToken } from "../../features/base/localuser/functions";
import UpdateAPIStatus from "./APIStatus";
import feedback from "./asycUtils";
import { fileConvertUrl, getDownloadFile } from "./external_api/chat";
import { allUpdateGroup, assignGroup, createGroup, deleteGroup, getGroupList, randomizeGroup, updateGroup } from "./external_api/group";
import { checkedAttendances, closeRoom, createAttendances, createConference, deleteConfernce, getAllParticipant, getAttendancesCheck, holdConference, invitationsConference, inviteGuest, nVacatedMember, notifyConference, notifyInvitationsConference, reservationConference, searchConference, setPropertyConference, umCheckUserId, umKickUser } from "./external_api/meeting";
import { addRecordResource, getSharedDocument, shareDocumentConvert } from "./external_api/share";
import { getAllSurvey, getSurvey } from "./external_api/survey";
import { checkRequireEmailVerification, getUserSelf, ujCheckEmail, ujJoinUser, ujVerfiyEmail, uLoginUser, uTempLoginUser } from "./external_api/user";
import { getVodChannel, getVodList } from "./external_api/vod";

const APIStatus = new UpdateAPIStatus();
class API {
    async sendData(promiseFn, data) {
        const response = await feedback(promiseFn, data);

        return response;
    }
     /**
      * 미참석 알림
      */
    async notifyVacatedMember() {
        const meeting_uuid = APP.mateManagement.getMeetingId();
        const aToken = APP.mateManagement.getAccessToken();
        const response = await this.sendData(nVacatedMember, {meeting_uuid, aToken});
        return response;
    }
    /**
     * 로그인 
     * @param {object} data = {id, password}
     */
    async login(data) {
        const response = await this.sendData(uLoginUser, data);
        return APIStatus.login(response.data);
    }
    async tempLogin() {
        let status = await this.tempSignup();

        if (status.complete) {
            const { auth_name, auth_password, uuid} = status.message;

            status = await this.login({ id: auth_name, password: auth_password });
        }
        
        return status;
    }
    /**
     * 인증 코드 전송 
     * @param {String} email 
     */
    async sendToEmail(email) {   
        const response = await this.sendData(ujVerfiyEmail, email);
        return APIStatus.sendToEmail(response.data);
    }
    /**
     * 인증 코드 유효성 검사 
     * @param {Object} data = {uuid, code}
     */
    async checkCertificationCode(data) {
        const response = await this.sendData(ujCheckEmail, data);

        return APIStatus.checkCertificationCode(response.data);
    }
    // 임시 사용자 생성 
    async tempSignup() {
        const response = await this.sendData(uTempLoginUser, null);
        return APIStatus.tempSignup(response.data);
    }
    // 회원 가입 
    async signup(data) { 
        let status = await this.tempSignup();

        // 임시 로그인 
        if (status.complete) {
            const { auth_name, auth_password, uuid} = status.message;

            status = await this.login({ id: auth_name, password: auth_password });
            if (status.complete) {
                const response = await this.sendData(ujJoinUser, { 
                    data, uuid, access_token: status.message.access_token 
                });

                status = APIStatus.signup(response.data); 
            }
        }

        return status;
    }
    /**
     * 사용자 정보 검색 
     * @param {*} name 
     */
    async getUser(data) {
        const response = await this.sendData(getUserSelf, data);
        return APIStatus.getUser(response.data);
    }

    /**
     * conference
     */
    async closeRoom() {
        const meeting_uuid = APP.mateManagement.getMeetingId();
        const aToken = APP.mateManagement.getAccessToken();

        const response = await this.sendData(closeRoom, { meeting_uuid, aToken });

        return response;
    }
    // 방 초대 
    async invitationsRoom(data) {
        const response = await this.sendData(invitationsConference, data); 
        const status = APIStatus.invitationsRoom(response.data);
        
        if (status.complete) {
            const response = await this.sendData(notifyInvitationsConference, data); 
            return APIStatus.invitationsRoom(response.data);
        }

        return status;
    }
    // 방 검색 
    async searchRoom(data) {
        const response = await this.sendData(searchConference, data); 
        return response.data ? APIStatus.searchRoom(response.data) : false;
    }

    // 방 객체 생성
    async createObjectRoom(aToken) {
        const response = await this.sendData(createConference, aToken);
        return APIStatus.createObjectRoom(response.data);
    }
    // 중복 로그인 확인 
    async checkOccupiedUser() {
        const state = APP.store.getState();

        const meeting_uuid = APP.mateManagement.getMeetingId();
        const user_id = getLocalUserUUID(state);

        const response = await this.sendData(umCheckUserId, { meeting_uuid, user_id });

        return APIStatus.checkOccupiedUser(response.data);
    }
    // 중복 로그인 강퇴 
    async kickOccupiedUser(kick_user_id) {
        const meeting_uuid = APP.mateManagement.getMeetingId();
        const aToken = APP.mateManagement.getAccessToken();
        const response = await this.sendData(umKickUser, { meeting_uuid, aToken, kick_user_id });

        return APIStatus.kickOccupiedUser(response.data);
    }
    // 방 시작
    async createRoom({ aToken, name }) {
        let status = await this.createObjectRoom(aToken);

        if (status.complete) {
            const { uuid: meeting_uuid } = status.message;
            const response = await this.sendData(holdConference, {
                meeting_uuid, aToken, name 
            });

            status = APIStatus.createRoom(response.data);
            status.message = { text: status.message, meeting_uuid };
        }

        return status;
    }
    async deleteRoom(data) {
        const response = await this.sendData(deleteConfernce, data);
        return APIStatus.deleteRoom(response.data);
    }

    // convert document
    async convertShareDocument(file) {
        const formData = new FormData();
        formData.append("file", file);

        const meeting_uuid = APP.mateManagement.getMeetingId();

        const response = await this.sendData(shareDocumentConvert, {file: formData, meeting_uuid}, false);
        
        return APIStatus.convertShareDocument(response.data);
    }
    // 방 예약
    async reservationRoom({ aToken, data }) {
        let status = await this.createObjectRoom(aToken);

        if (status.complete) {
            const { uuid: meeting_uuid } = status.message;
            const response = await this.sendData(reservationConference, {
                meeting_uuid, aToken, data 
            });

            status = APIStatus.reservationRoom(response.data);
            status.meeting_uuid = meeting_uuid;
        }
        
        return status;
    }
    // 방 속성 변경 
    async setPropertyRoom({ meeting_uuid, aToken, data}) {
        const response =  await this.sendData(setPropertyConference, {
            meeting_uuid,
            aToken,
            data
        });

        return APIStatus.setPropertyRoom(response.data);
    }

    // 출석 체크 생성
    async createAttendancesCheck(data) {
        const meeting_id = APP.mateManagement.getMeetingId();
        const aToken = APP.mateManagement.getAccessToken();

        const response = await this.sendData(createAttendances, {
            meeting_uuid: meeting_id, 
            aToken,
            ...data
        });

        return APIStatus.createAttendancesCheck(response.data);
    }

    // 전체 출석 기록 (get)
    async getAttendancesCheck() {
        const meeting_id = APP.mateManagement.getMeetingId();
        const aToken = APP.mateManagement.getAccessToken();

        const response = await this.sendData(getAttendancesCheck, {
            data: {
                meeting_uuid: meeting_id
            }, 
            aToken
        });
        
        return APIStatus.getAttendancesCheck(response.data);
    }
    // 출석 체크 확인
    async checkedAttendances(attendance_id) {
        const aToken = getUserAccessToken(APP.store.getState());

        const response = await this.sendData(checkedAttendances, {
            attendance_id,
            aToken
        });
        
        return APIStatus.checkedAttendances(response.data);
    }


    // 방 예약 초대 
    inviteGuest(uuid) {
        return this.sendData(inviteGuest, uuid);
    }
    // 초대 알림 
    notifyRoom(data) {
        return this.sendData(notifyConference, data);
    }
    // 설문 조사 개별 
    async getSurveyInfo(uuid) {
        const state = APP.store.getState();

        const meeting_id = APP.mateManagement.getMeetingId();
        if (!meeting_id) return { complete: false, message: "api.errorNoRoom"};

        const response = await this.sendData(getSurvey, {
            meeting_uuid: meeting_id,
            survey_uuid: uuid
        });

        return APIStatus.getSurveyInfo(response.data);
    }
    // 설문 조사 전체 
    async getAllSurvey(meeting_uuid) {
        const state = APP.store.getState();

        const meeting_id = APP.mateManagement.getMeetingId();
        if (!meeting_id) return { complete: false, message: "api.errorNoRoom"};

        const response = await this.sendData(getAllSurvey, meeting_uuid);

        return APIStatus.getAllSurvey(response.data);
    }


    /**
     * use only mate server
     * @param {Object} response 
     */
    reciveCommandMessage(response) {        
        server.recivedCommand(response);
    }

    // send file 
    async convertFile(formData) {
        const convertResponse = await feedback(fileConvertUrl, formData);

        return convertResponse;
    }
    async downloadFile(id, name) {
        await feedback(getDownloadFile({ id, name }));
    }
    async getDocumentInformaiton(uuid) {
        return this.sendData(getSharedDocument, uuid, false);
    }

    // VOD
    async getMediaChannel(data) {
        const response = await this.sendData(getVodChannel, data);

        return APIStatus.getMediaChannel(response.data);
    }

    async getMediaList(data) {
        const response = await this.sendData(getVodList, data);

        return APIStatus.getMediaChannel(response.data);
    }

    // group
    async getAllParticipant(data) {
        const meeting_id = APP.mateManagement.getMeetingId();
        const aToken = APP.mateManagement.getAccessToken();

        const response = await this.sendData(getAllParticipant, { meeting_id, aToken, data });
        return APIStatus.getAllParticipant(response.data);
    }
    async getGroupList(data) {
        const meeting_id = APP.mateManagement.getMeetingId();
        const aToken = APP.mateManagement.getAccessToken();

        const response = await this.sendData(getGroupList, { data: { ...data, meeting_id }, aToken });

        return APIStatus.getGroupList(response.data);
    }

    async createGroup(name) {
        const meeting_id = APP.mateManagement.getMeetingId();
        const aToken = APP.mateManagement.getAccessToken();

        const response = await this.sendData(createGroup, { meeting_id, aToken, name });
        return APIStatus.createGroup(response.data);
    }

    async deleteGroup(group_id) {
        const meeting_id = APP.mateManagement.getMeetingId();
        const aToken = APP.mateManagement.getAccessToken();

        const response = await this.sendData(deleteGroup, { meeting_id, aToken, group_id});
        return APIStatus.deleteGroup(response.data);
    }

    async assignGroup(data, group_id) {
        const meeting_id = APP.mateManagement.getMeetingId();
        const aToken = APP.mateManagement.getAccessToken();

        const response = await this.sendData(assignGroup, { meeting_id, aToken, group_id, data });
        
        return APIStatus.assignGroup(response.data);
    }

    async updateGroup(data, group_id) {
        const meeting_id = APP.mateManagement.getMeetingId();
        const aToken = APP.mateManagement.getAccessToken();

        const response = await this.sendData(updateGroup, { meeting_id, aToken, group_id, data });
        
        return APIStatus.assignGroup(response.data);
    }

    async allUpdateGroup(data) {
        const meeting_id = APP.mateManagement.getMeetingId();
        const aToken = APP.mateManagement.getAccessToken();

        const response = await this.sendData(allUpdateGroup, { meeting_id, aToken, data });
        
        return APIStatus.assignGroup(response.data);
    }

    async randomizeGroup(data) {
        const meeting_id = APP.mateManagement.getMeetingId();
        const aToken = APP.mateManagement.getAccessToken();

        const response = await this.sendData(randomizeGroup, { meeting_id, aToken, data });

        return APIStatus.randomizeGroup(response.data);
    }

    async addRecordResource(group_id) {
        const meeting_id = APP.mateManagement.getMeetingId();
        const aToken = APP.mateManagement.getAccessToken();

        const response = await this.sendData(addRecordResource, { meeting_id, aToken, group_id });
        return APIStatus.addRecordResource(response.data);        
    }

    /*
     * ACTION 
     */
    executeCommand(name, ...args) {
        this._transport.sendEvent({
            data: args,
            name: name
        });
    }

    //tes
    async requireEmailVerification() {
        const response = await this.sendData(checkRequireEmailVerification);

        return APIStatus.requireEmailVerification(response.data);
    }
}

export default new API();
