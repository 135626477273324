import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { MdArrowLeft, MdArrowRight } from "react-icons/md";
import { ReactNewWindowStyles } from "react-new-window-styles";
import { useDispatch, useSelector } from "react-redux";
import { getPropertyValue, setDualOption, updateSettings } from "../../base/settings";
import { SwitchChatButton } from "../../chat";
import ConferenceRightMeun from "../../conference/components/web/ConferenceRightMeun";
import SwitchGridOption from "../../options/components/etc/SwitchGridOption";
import { SwitchParticipantRight } from "../../participant-right";
import { setDualMonitor } from "../actions";
import { layoutType } from "../constant";
import { MembersLayout } from "./MembersLayout";
import ScreenScreen from "./ScreenScreen";
import { DualStyled, LayoutContainerStyled } from "./styles";
import SetGridCount from "../../options/components/etc/SetGridCount";
import { ClickButton } from "../../base/buttons";
import PageButton from "../PageButton";
import { InviteGuest } from "../../options";

const SEND_CHAT_POP = 'SEND_CHAT_POP';
const GRID_COUNT_POP = 'GRID_COUNT_POP';
const INVITE_POP = 'INVITE_POP';

export default function DualLayout({
    mode
}) {
    const newWindowNode = useRef();
    const dispatch = useDispatch();
    const dualOption = useSelector(state => getPropertyValue(state, 'dualOption'));
    const [ popup, setPopup ] = useState(null);
    const [ outerStyle, setOuterStyle ] =  useState({ width: 960, height: 960 });
    const [ windowOpen, setWindowOpen ] = useState(false);
    const [prev, setPrev] = useState(false);
    const [next, setNext] = useState(false);

    const setContainerStyle = (e) => {
        const { innerWidth, innerHeight } = e.target;
        
        setOuterStyle({ width: innerWidth, height: innerHeight });
    }

    const unload = () => {
        dispatch(updateSettings({ 'dualOption': false }));
        dispatch(setDualMonitor(false));
        setWindowOpen(false);
    }

    const open = e => {
        e.addEventListener("resize", setContainerStyle);
    }

    useEffect(debounce(() => {
        setWindowOpen(true);
    }, [], 1000));

    useEffect(() => {
        if (windowOpen && newWindowNode) {
            const element = newWindowNode.current.state.externalWindow;
            if (element && element.window) {
                element.window.addEventListener('resize', setContainerStyle);
            }
        } 
    }, [windowOpen]);

    const onClick = () => {
        dispatch(setDualOption(!dualOption));
    }

    const hidePop = () => setPopup(null);

    const getPopup = () => {
        switch (popup) {
            case SEND_CHAT_POP:
                break;
            case INVITE_POP:
                return <InviteGuest onClose={hidePop} />
            case GRID_COUNT_POP:
                return <SetGridCount setOptionDrop={hidePop} handlerHide={hidePop} />
        }
    }

    const renderButton = (type, onClick) => {
        return (
            <ClickButton text={type} onClick={onClick} size={26} />
        )
    }

    const onClickInvite = (type) => {
        setPopup(INVITE_POP);
    }

    const onClickChat = () => {
        setPopup(SEND_CHAT_POP);
    }

    return (
        <ReactNewWindowStyles 
            ref={newWindowNode}
            title="Meemate2"
            windowProps={{width: '960px', height: '960px'}} 
            onClose={unload}
            copyStyles={true}
            autoClose={true}>
            <DualStyled>         
                { dualOption && <div className="dualOption">
                    <ScreenScreen isDual={true} />
                    <div className="buttons">
                        <SwitchChatButton isOption={true} clsName="chatBtn" />
                        <SwitchParticipantRight isOption={true} clsName="chatBtn" />
                    </div>
                    <div className="right_wrapper">
                        <ConferenceRightMeun isDualMonitor={true} screenType={layoutType.desktop} 
                            isChat={true} isParticipant={true}
                            onClickInvite={onClickInvite}
                            onClickChat={onClickChat}  />
                    </div>
                </div> }
                <div className="right">
                    <div className="top_label">
                        <div className="toggleSide" onClick={onClick}>
                            { dualOption ? <MdArrowLeft size={32} color="#d5d5d5"/> : <MdArrowRight size={32} color="#d5d5d5" /> }
                        </div>

                        {/* <SwitchGridOption handlerClick={() => setPopup(GRID_COUNT_POP)}/> */}

                        <PageButton show={prev} type='prev' setPrev={setPrev} setNext={setNext} renderButton={renderButton} />
                        <PageButton show={next} type='next' setPrev={setPrev} setNext={setNext} renderButton={renderButton} />
                    </div>
                    
                    <LayoutContainerStyled className="layout_wrapper"
                        isGrid={true}
                        horizontal={false}
                    >      
                        <div className="gridFlex">
                            <MembersLayout noShowMember={true}
                                mode={mode} 
                                noPaging={true}
                                outerSize={{ 
                                    width: dualOption ? outerStyle.width - 350 : outerStyle.width, 
                                    height: outerStyle.height - 38
                                }}
                            />
                        </div>
                    </LayoutContainerStyled>
                </div>                
                { popup && 
                    <>
                        <div style={{position: "absolute", top: "0", left: "0", background: "rgba(0, 0, 0, 0.2)",  width: "100%", height: "100%", zIndex: "2"}}> </div> 
                        { getPopup() }
                    </>
                }
            </DualStyled>
        </ReactNewWindowStyles>
    )
}

