import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdBackHand, MdGavel, MdGrade, MdMic, MdMoreHoriz, MdOutlineWavingHand, MdPushPin, MdVideocam } from "react-icons/md";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { MEDIA_TYPE } from "../../base/media";
import { NOTIFICATION_TIMEOUT, showNotification } from "../../notifications";
import { muteRemote } from "../../video-menu";
import { kickMemeber, setHandlerMember, setMemberPinned, setMemberRole } from "../actions";
import { CHANGE_ROLE_LIST, PARTICIPANT_STATUS, PERMISSION, ROLE, ROLE_LEVEL } from "../constants";
import { checkRoleLevel, getMemberTrackByUserID, getRoleByUserID } from "../functions";
import { IconStyled } from "./styles";
import IcPresenter from './ic_presenter.svg';


const DEFAULT_CONTROL = ['pin', 'camera', 'mic'];
function MemberItem({
    nMember, user_uuid, local_uuid, permissions, currentGropuId, startGroup, isMonitoring
}) { 
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isRolePermission, setIsRolePermission] = useState(false);
    const [controlList, setControlList] = useState(DEFAULT_CONTROL); 
    const [slider, setSlider] = useState(false);
    const [kickPermission, setKickPermission] = useState(false);
    const [rolePermission, setRolePermission] = useState(false);
    
    const member = nMember ? nMember : useSelector(state => getMemberTrackByUserID(state, user_uuid), shallowEqual);
    const local_role = useSelector(state => getRoleByUserID(state, local_uuid));
    const hands_up = useSelector(state => state["features/base/settings"].hands_up);
     
    useEffect(() => {
        if (!currentGropuId && startGroup.includes(currentGropuId) && member.groupName) {
            return;
        }
        
        const presenterPermission = member.local && !isRolePermission;
        const kick = permissions.indexOf(PERMISSION.KICK) !== -1;
        const role = (permissions.indexOf(PERMISSION.MEMBER_ROLE) !== -1 && checkRoleLevel(local_role, member.role));

        setKickPermission(kick);
        setRolePermission(role);

        const list = [
            (member.local || (hands_up === 'keep' ? true : member.hands_up)) && ROLE_LEVEL.indexOf(member.role) > 2 && 'handler',
            APP.layout.participant.presenter && presenterPermission && 'presenter', 
            ...DEFAULT_CONTROL,
            !isMonitoring && !member.local && (kick || role) && "etc"
        ];

        setControlList(list);
    }, [permissions, isRolePermission, currentGropuId, member.group_id, member.hands_up,  member.role, hands_up]);

    useEffect(() => {
        if (member && member.role) {
            const roleLevel = checkRoleLevel(local_role, ROLE.PRESENTER);

            setIsRolePermission(roleLevel);
        }
    }, [local_role]);

    useEffect(() => {
        setSlider(false);
    }, [member.groupId]);

    const getConfig = (icon) => {
        // { member.group ? member.group.name :
        let config, icon_permissions, muted = false;

        switch (icon) {
            case "handler":
                const handlerPermission = [ROLE.ADMIN, ROLE.HOST].includes(local_role);
                config = {
                    icon: MdOutlineWavingHand,
                    className: "pinIcon",
                    permissions: APP.layout.participant.handler && (member.local || handlerPermission),
                    isOn: member.hands_up,
                    onClick: () => {
                        if (member.local || handlerPermission) {
                            if (handlerPermission && member.hands_up === false) return;
                            dispatch(setHandlerMember(member.user_uuid, !member.hands_up));
                        }
                    }
                }
                break;

            case "presenter":            
                config = {
                    icon: MdGavel,
                    className: "pinIcon",
                    permissions: APP.layout.participant.presenter && isRolePermission,
                    isOn: member.role === ROLE.PRESENTER,
                    onClick: () => {
                        if (member.local) {
                            if (member.role === ROLE.PRESENTER) return;
                            if (!isRolePermission) {
                                dispatch(showNotification({
                                    titleKey: 'notify.requestPresentation', isRequestPresent: true, isMe: true
                                }, NOTIFICATION_TIMEOUT));
                                APP.mateManagement.requestPresentation();
                            }
                        } else {
                            if (isRolePermission) {
                                let role = ROLE.PRESENTER;
                                if (member.role === ROLE.PRESENTER) role = ROLE.PARTICIPANT;
                                
                                dispatch(setMemberRole(user_uuid, role));
                            }
                        }
            
                    } 
                };
                break;

            case "pin":
                icon_permissions = permissions.indexOf(PERMISSION.PIN) !== -1;
                config = {
                    icon: MdPushPin,
                    className: "pinIcon",
                    permissions: icon_permissions,
                    isOn: member.pinned,
                    onClick: () => { 
                        if (icon_permissions) dispatch(setMemberPinned(user_uuid, !member.pinned));
                    } 
                }
                break;

            case "camera":
                muted = member.videoTrack?.muted; 
                icon_permissions = permissions.indexOf(PERMISSION.MEMBER_CAMERA) !== -1;
                config = {
                    icon: MdVideocam,
                    className: "icon",
                    permissions: member.local || icon_permissions,
                    isOn: muted,
                    onClick: () => { 
                        if (member.local) {
                            APP.UI.toggleVideo(!muted);
                        } else {
                            if (icon_permissions) dispatch(muteRemote(user_uuid, MEDIA_TYPE.VIDEO, !muted));
                        }
                    } 
                }
                break;

            case "mic":
                muted = member.audioTrack?.muted; 
                icon_permissions = permissions.indexOf(PERMISSION.MEMBER_MIC) !== -1;
                config = {
                    icon: MdMic,
                    className: "icon",
                    permissions: member.local || icon_permissions,
                    isOn: muted,
                    onClick: () => { 
                        if (member.local) {
                            APP.UI.toggleAudio(!muted);
                        } else {
                            if (icon_permissions) dispatch(muteRemote(user_uuid, MEDIA_TYPE.AUDIO, !muted));
                        }
                    } 
                }
                break;

            case "etc": 
                icon_permissions = kickPermission && rolePermission;

                config = {
                    icon: MdMoreHoriz,
                    className: "pinIcon",
                    permissions: icon_permissions,
                    isOn: slider,
                    onClick: () => { 
                        setSlider(!slider);
                    } 
                }
                break;                
        }

        return config;
    }

    const renderItem = (icon) => {
        const config = getConfig(icon);

        if (!config) return null;        
        return (
            <IconStyled key={icon} className={config.className} 
                isPointer={!isMonitoring ? config.permissions : null} isOn={config.isOn} onClick={!isMonitoring ? config.onClick : null} >
                <config.icon color={config.isOn ? "#29abe2" : "#d5d5d5"} /> 
            </IconStyled>
        )
    }

    const renderGroup = () => {
        return (
            <li style={{ color: "red", width: "30%" }}>  
                <span className="overText"> { member.groupName } </span>
            </li>
        )
    }

    const renderNoAttend = () => {
        return (
            <li style={{ color: "red", width: "30%" }}>  
                <span className="overText"> 미 참 석 </span>
            </li>
        )
    }

    const onKickUser = () => {
        dispatch(kickMemeber(user_uuid));

        setSlider(false);
    }

    return (
        <div key={user_uuid} className={`item_participant item_${member.local} nonSelect`}>
            

            <ul>
                <li className="role">{ t(`role.${member?.role}`) }</li>
                <li className="nickname tooltip">
                    <span className="tooltip-text">{member.nickname}</span>
                    <span className="overText">{member.nickname}</span>
                    { member.local && t("notify.me") } 
                </li>

                { member.status === PARTICIPANT_STATUS.OCCUPIDE ? 
                    currentGropuId === '' && startGroup.includes(member.groupId) && member.groupName ? renderGroup() 
                    : <li>
                        { controlList.map(c => renderItem(c)) }
                    </li>
                    : renderNoAttend() }
            </ul>
            { slider && 
                <div className="control control_true nonSelect">
                    { rolePermission && isRolePermission && 
                        CHANGE_ROLE_LIST.map((r, index) => ( 
                            <span key={index} onClick={() => {
                                dispatch(setMemberRole(user_uuid, r));

                                setSlider(false);
                            }}>
                                { t(`role.${r}`) }
                            </span> 
                        ))}
                    { kickPermission && isRolePermission &&
                        <span onClick={onKickUser}>
                            { t("chat.kick") }
                        </span>}
                </div> }
        </div>
    )
}
export default React.memo(MemberItem);