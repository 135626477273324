import { combineReducers } from 'redux';

import { CLEAR_ROOM, CONFERENCE_FAILED, CONFERENCE_LEFT } from '../conference/actionTypes';
import { ReducerRegistry } from '../redux';
import { TRACK_REMOVED } from '../tracks/actionTypes';

import {
    SET_AUDIO_AVAILABLE,
    SET_AUDIO_MUTED,
    SET_CAMERA_FACING_MODE,
    SET_VIDEO_AVAILABLE,
    SET_VIDEO_MUTED,
    STORE_VIDEO_TRANSFORM,
    TOGGLE_CAMERA_FACING_MODE
} from './actionTypes';
import { CAMERA_FACING_MODE } from './constants';

export const _AUDIO_INITIAL_MEDIA_STATE = {
    available: true,
    muted: false
};

export const _VIDEO_INITIAL_MEDIA_STATE = {
    available: true,
    facingMode: CAMERA_FACING_MODE.USER,
    muted: false,
    transforms: {}
};

function _audio(state = _AUDIO_INITIAL_MEDIA_STATE, action) {
    switch (action.type) {
        case SET_AUDIO_AVAILABLE:
            return {
                ...state,
                available: action.available
            };

        case SET_AUDIO_MUTED:
            return {
                ...state,
                muted: action.muted
            };

        case CONFERENCE_LEFT:
            return _AUDIO_INITIAL_MEDIA_STATE;

        default:
            return state;
    }
}



function _video(state = _VIDEO_INITIAL_MEDIA_STATE, action) {
    switch (action.type) {
        case CONFERENCE_FAILED:
        case CONFERENCE_LEFT:
            return _clearAllVideoTransforms(state);

        case SET_CAMERA_FACING_MODE:
            return {
                ...state,
                facingMode: action.cameraFacingMode
            };

        case SET_VIDEO_AVAILABLE:
            return {
                ...state,
                available: action.available
            };

        case SET_VIDEO_MUTED:
            return {
                ...state,
                muted: action.muted
            };

        case STORE_VIDEO_TRANSFORM:
            return _storeVideoTransform(state, action);

        case TOGGLE_CAMERA_FACING_MODE: {
            let cameraFacingMode = state.facingMode;

            cameraFacingMode
                = cameraFacingMode === CAMERA_FACING_MODE.USER
                    ? CAMERA_FACING_MODE.ENVIRONMENT
                    : CAMERA_FACING_MODE.USER;

            return {
                ...state,
                facingMode: cameraFacingMode
            };
        }

        case TRACK_REMOVED:
            return _trackRemoved(state, action);
        
        default:
            return state;
    }
}

ReducerRegistry.register('features/base/media', combineReducers({
    audio: _audio,
    video: _video
}));

function _clearAllVideoTransforms(state) {
    return _VIDEO_INITIAL_MEDIA_STATE;
}

function _storeVideoTransform(state, { streamId, transform }) {
    return {
        ...state,
        transforms: {
            ...state.transforms,
            [streamId]: transform
        }
    };
}

function _trackRemoved(state, { track }) {
    if (track && track.track && typeof track.track.getStreamId === "Function") {
        const streamId = track.track.getStreamId();

        if (streamId && streamId in state.transforms) {
            const nextTransforms = {
                ...state.transforms
            };

            delete nextTransforms[streamId];

            return {
                ...state,
                transforms: nextTransforms
            };
        }
    }

    return state;
}
