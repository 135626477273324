/* @flow */

import { combineReducers } from 'redux';
import { Reducer } from 'redux';

class ReducerRegistry {
    _elements;
    constructor() {
        this._elements = {};
    }
    combineReducers(additional) {
        // $FlowExpectedError
        return combineReducers({
            ...this._elements,
            ...additional
        });
    }

    register(name, reducer) {
        this._elements[name] = reducer;
    }
}

/**
 * The public singleton instance of the ReducerRegistry class.
 */
export default new ReducerRegistry();
