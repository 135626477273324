import {  CLOSE_MODAL, SET_MODAL_NAME } from "./actionTypes";

export function updateModalState(name) {
    return (dispatch, getState) => {
        let modalName;
        if (getState()['features/base/modal'].name !== name) {
            modalName = name;
        } else {
            modalName = null;
        }

        dispatch(setModalName(modalName));
    }
}

export function closeModal() {
    return {
        type: CLOSE_MODAL
    }
}

export function setModalName(name) {
    return {
        type: SET_MODAL_NAME,
        name
    };
}