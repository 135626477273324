import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { IconButton } from "../../base/buttons";
import { RightHeader } from "../../base/right-menu";
import { InviteMenuButton } from "../../options";
import { toggleParticipantRight } from "../../participant-right";
import { getLocalIDMember, getMemberList } from "../functions";
import MemberItem from "./MemberItem";
import "../../../assets/css/rightMenu.scss";
import { getGroupId } from "../../base/conference";
import { getStartGroup } from "../../groups";
import { PARTICIPANT_STATUS } from "../constants";
import { ParticipantStyled } from "./styles";

function MembersContainer({
    onClickInvite, isParticipant, participant, open, handlerOpen, 
    isMonitoring = false, focusGroupId = null
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [ search, setSearch ] = useState();
    const [ nickname, setNickname ] = useState();
    const permissions = useSelector(state => state['features/base/localuser'].permissions);
    const local_uuid = useSelector(state => getLocalIDMember(state));
    const { member, currentGropuId, startGroup } = participant ? participant : 
        useSelector(state => {
            let data = { excludeLocal: true, nickname };    
            const currentGropuId = focusGroupId || getGroupId(state);
            const startGroup = getStartGroup(state, true);

            if (currentGropuId) {
                // 그룹이 시작 
                if (startGroup && startGroup.has(currentGropuId)) {
                    // 그룹에 속한 경우 : 그룹에 속한 사용자 
                    data = { ...data, groupIds: [currentGropuId], excludeGroup: false};
                }      
            }

            return {
                member: getMemberList(state, data),
                currentGropuId, startGroup
            }
        }, shallowEqual);
        
    const onSwitchSearh = () => setSearch(!search);

    const handlerSearchText = e => {
        const text = e.target.value;
        
        setNickname(text);
    };

    const handlerClose = () => {
        dispatch(toggleParticipantRight(false));
    }

    return (
        <ParticipantStyled>
            { !isMonitoring && <>
                <RightHeader name="participant" open={open} handlerOpen={handlerOpen} handlerClose={handlerClose}>
                    { open && APP.layout.participant.invite && <InviteMenuButton onClickInvite={onClickInvite} /> }
                    { open && APP.layout.participant.search && 
                        <IconButton clsName={`button button_${search ? 'on': 'off'}`}
                            mode="search" size={14} onClick={onSwitchSearh} /> }
                </RightHeader> 
                { search && <div className="search_bar">
                    <input type="text" placeholder={ t("conference.inputParticipant") }
                        onChange={handlerSearchText} />
                </div> }
            </> }

            <div className="right_menu">
                { !isMonitoring && <MemberItem user_uuid={local_uuid} local_uuid={local_uuid} permissions={permissions} 
                    currentGropuId={currentGropuId} startGroup={ startGroup ? [...startGroup] : []} /> }
                <div className="item_content">
                    { member.map(user_uuid => {
                        return <MemberItem key={user_uuid} user_uuid={user_uuid}
                            local_uuid={local_uuid} permissions={permissions} isMonitoring={isMonitoring}
                            currentGropuId={currentGropuId} startGroup={startGroup ? [...startGroup] : []} />
                    })}
                </div>
            </div>
        </ParticipantStyled>
        
    )
}

export default React.memo(MembersContainer);