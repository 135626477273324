import React from "react";
import { useSelector } from "react-redux";
import { IconButton } from '../../base/buttons';
import { getGroupId } from "../../base/conference";
import { translate } from "../../base/i18n";
import { isCheckPermission } from "../../base/localuser";
import { PERMISSION } from "../../member";
import { ROOM_LOCK_MODAL_ID } from "../../options";
import { startLocalGroup } from "../../groups";

function SwitchRoomLockButton({ t }) {
    const { start, group_id } = useSelector(state => startLocalGroup(state));
    const permission = useSelector(state => isCheckPermission(state, PERMISSION.SECURE));

    if (!APP.layout.option.security || !permission || (start && group_id)) return null;
    const onClick = () => APP.UI.openModal(ROOM_LOCK_MODAL_ID);

    return (
        <IconButton mode="lock" name={t('options.security')} size={22} onClick={onClick} />
    )
}
export default translate(SwitchRoomLockButton);