import React from "react";
import { getGroupById, getGroupIdsList } from "../../functions";
import { useSelector } from "react-redux";
import { PARTICIPANT_STATUS, ROLE, getMemberList } from "../../../member";
import ConferenceTimer from "../../../conference/components/ConferenceTimer";
import { useTranslation } from "react-i18next";
import { ClickButton } from "../../../base/buttons";

export default function GroupCard({
    setPopup, handlerChangeConference, handlerChangeGroupStatus
}) {
    const list = useSelector(state => getGroupIdsList(state));

    return (
        <div className="group_list">
            { [...list].map(uuid => {
                return (
                    <CardItem key={uuid} uuid={uuid} setPopup={setPopup}
                        handlerChangeConference={handlerChangeConference}
                        handlerChangeGroupStatus={handlerChangeGroupStatus} />
                )
            })}
        </div>
        
    )
}

function CardItem({
    uuid, setPopup, handlerChangeConference, handlerChangeGroupStatus
}) {
    const { t } = useTranslation();
    const group = useSelector(state => getGroupById(state, uuid));
    const list = useSelector(state => getMemberList(state, { 
        status: [PARTICIPANT_STATUS.OCCUPIDE], groupIds: [ uuid ],
        excludeGroup: false
    }, false ));

    const groupMember = group.members ? group.members.filter(i => i.type === "hidden" ? false : true) : [];
    const all = groupMember ? groupMember.length : 0;
    const belong = list ? list.length : 0;
    const host = list.find(i => i.role === ROLE.HOST)?.nickname || "-";

    const onEnterRoom = () => {
        handlerChangeConference(uuid);
    }

    return (
        <div className="group_item" >
            <div className="layout_name" style={{ borderBottom: "2px solid"}}>
                { group.name }
            </div>
            <div className="item_body">
                <div className="table">
                    <ul>
                        <li>{t("group.time")}</li>
                        <li>
                            { group.status === "opened" ? 
                                <ConferenceTimer currentGroupId={uuid} /> : "closed"}
                        </li>
                    </ul>
                    <ul>
                        <li>{t("group.allCount")}</li>
                        <li>{ t("common.person", { count: all }) }</li>
                    </ul>
                    <ul style={{ borderBottom: "none"}}>
                        <li>{t("group.attendCount")}</li>
                        <li>{ t("common.person", { count: belong }) }</li>
                    </ul>
                </div>
                <div className={`${belong ? 'button_right' : 'button_center'}`}>
                    { group.status === "opened" && <>
                        <ClickButton text={t("group.enter")} size={16} 
                            onClick={onEnterRoom} />
                        { belong > 0 && <ClickButton text={t("group.sendMessage")} size={16} 
                            onClick={() => setPopup(uuid) } /> }
                    </> }
                </div>
                <ClickButton text={ group.status === "opened" ? t("group.close") : t("group.open") }
                    size={16}
                    onClick={() => handlerChangeGroupStatus(uuid, group.status)}  />
            </div>            
        </div>
    )
}