import React from "react";
import { connect } from "react-redux";
import { translate } from "../../../base/i18n";
import { DEVICE_SETTING_MODAL_ID } from "../../constants";
import AbstractSwitchButton from "../AbstractSwitchButton";

class SwitchSettingButton extends AbstractSwitchButton {
    constructor(props) {
        super(props);
    }

    componentDidMount() {        
        this.load(DEVICE_SETTING_MODAL_ID, this.props.dispatch);
    }

    render() {
        return (
            <>
                <dl onClick={this._onClick}>
                    <dt className="icoImg setupSwitch nonSelect" />
                    <dd> {this.props.t("common.setting")} </dd>
                </dl>
            </>
        )
    }
}

export default translate(connect()(SwitchSettingButton));