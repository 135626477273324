import React from "react";
import { connect } from "react-redux";
import { FooterIconButton } from "../../../base/buttons";
import { translate } from "../../../base/i18n";
import { CLOSE_MODAL_ID } from "../../constants";

function SwitchCloseButton({ t }) {
    const onClick = () => APP.UI.openModal(CLOSE_MODAL_ID);
    
    return (
        <FooterIconButton mode="close" name={t("conference.end")} onClick={onClick} />
    )
}

export default translate(SwitchCloseButton);

