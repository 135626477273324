export const CONNECTION_DISCONNECTED = 'CONNECTION_DISCONNECTED';

export const CONNECTION_ESTABLISHED = 'CONNECTION_ESTABLISHED';

export const CONNECTION_FAILED = 'CONNECTION_FAILED';

export const CONNECTION_WILL_CONNECT = 'CONNECTION_WILL_CONNECT';

export const SET_IS_CONNECTED = 'SET_IS_CONNECTED';

export const SET_CONNECTION = 'SET_CONNECTION';

export const CLEAR_CONNECTION = 'CLEAR_CONNECTION';