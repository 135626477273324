import styled from "styled-components";
import background from "../../../../assets/images/bg/bg_login.jpg";
import media from "../../../../assets/media";

export const RoomOptionStyle = styled.div`
    background: ${(props) => !props.isModal ? `url(${background}) no-repeat 65% center/cover` : null };
    width: ${(props) => props.isModal === 'section' ? "auto" : "100%"};
    height: ${(props) => props.isModal === 'section' ? "auto" : "100%"};

    max-width: 100%;
    max-height: 100%;
    margin-left: 0;
    min-width: 300px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3em;
    flex: 1;


    .room-container {
        display: inline-flex;
        justify-content: ${(props) => !props.isModal ? "center" : "flex-start"};
        align-items: flex-start;

        min-width: 100%;
        max-height: ${(props) => !props.isModal ? "50%" : "80%"};
        height: ${(props) => !props.isModal ? "50%" : "80%"};
    }

    .modal_content {
        padding: 20px;
        background: #fff;
        border: 2px solid #d3d3d3;
        box-sizing: border-box;
        width: 100%; max-width: 100%;
        height: 100%; max-height: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;        
        
        .option_header {
            display: flex;
            align-items: flex-end;
            margin: 0;

            h3 { margin: 0 auto 4px 10px;}
            li { cursor: pointer; margin: 0 10px 4px auto; }
        }
    }

    ${media.tabletV` 
        min-width: 100%; max-height: 100%;
        position: relative;
        overflow: hidden;

        .option_content {
            height: 73%;
            overflow: auto;
        }
    `};
`;