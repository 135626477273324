import React, { useEffect, useState } from "react";
import { MdArrowLeft, MdArrowRight } from "react-icons/md";
import PageButton from "../PageButton";
import { getFilmStyle } from "../functions";
import MemberFilm from "./MemberFilm";
import { PagingDesktopStyled } from "./styles";
import { IconButton } from "../../base/buttons";

function MemberScreen({
    className, type, members, mode, noShowMember = false, vertical = false,
    noPaging = false, maxStyle = null, outerSize = null
}) {    
    const [styles, setStyles] = useState({ width: 0, height: 0 });
    const [prev, setPrev] = useState(false);
    const [next, setNext] = useState(false);

    useEffect(() => {

        const count = members.length || 1;
        
        if (maxStyle) {
            setStyles(maxStyle);
        } else {
            let style = _.cloneDeep(outerSize);
            if (!noPaging && (prev || next)) style.width = style.width - 60;
            const newStyle = getFilmStyle(APP.store.getState, count, style);

            setStyles(newStyle);
        }  
    }, [members, maxStyle, outerSize, prev, next, noPaging], {
        deepEquls: true
    });

    useEffect(() => {
        if (noPaging) {
            setPrev(false);
            setNext(false);
        }
    }, [noPaging]);

    const renderButton = (type, onClick) => {
        return (
            <PagingDesktopStyled vertical={vertical} isMaxSize={Boolean(maxStyle)} type={type} onClick={onClick}>
                { type === 'next'
                    ? <MdArrowRight size={32} color="#d5d5d5"/>
                    : <MdArrowLeft size={32} color="#d5d5d5"/> }
            </PagingDesktopStyled>
        )
    }

    if (members && members.length > 0) {
        return (
            <>
                { !noPaging && <PageButton show={prev} type='prev' setPrev={setPrev} setNext={setNext} renderButton={renderButton} /> }
                    { members.map(m => {
                        if (!m) return null;
                        return <MemberFilm key={m} 
                            user_uuid={m} styles={styles} 
                            isMaxSize={Boolean(maxStyle)} vertical={vertical} /> 
                    }) }
                { !noPaging && <PageButton show={next} type='next' setPrev={setPrev} setNext={setNext} renderButton={renderButton} /> }
            </>
        )
    } else {
        if (noShowMember) return null;
        return (
            <div className={className} 
                style={{
                    width: `${styles.width}px`, height: `${styles.height}px`,
                    backgroundColor: "#000"
                }}>
                <IconButton clsName='noMember' mode={type} size={24} name={`no ${type}`} hover={false} />
            </div>
        )
    }
}

export default React.memo(MemberScreen);