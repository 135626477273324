import { SET_ROOM_PASSWORD, SET_ROOM_LOCKED } from './actionType';

export function setRoomPassword(password) {
    return {
        type: SET_ROOM_PASSWORD,
        password
    }
}

export function setRoomLocked(locked) {
    return {
        type: SET_ROOM_LOCKED,
        locked
    }
}
