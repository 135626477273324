import React, { useEffect, useState } from 'react';
import { BsFillCircleFill } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { getJitsiRoom } from '../../base/conference';
import { translate } from '../../base/i18n';
import { connect } from '../../base/redux';
import { isTrackStreamingStatusInactive, isTrackStreamingStatusInterrupted } from '../functions';
import statsEmitter from '../statsEmitter';
const INDICATOR_DISPLAY_THRESHOLD = 30;

const CONNECTION_QUALITY = [

    // Full (3 bars)
    {
        msg: 'connectionindicator.quality.good',
        percent: 30 // INDICATOR_DISPLAY_THRESHOLD
    },

    // 2 bars.
    {
        msg: 'connectionindicator.quality.nonoptimal',
        percent: 10
    },

    // 1 bar.
    {
        msg: 'connectionindicator.quality.poor',
        percent: 0
    }
];

const QUALITY_TO_WIDTH: Array<Object> = [

    // Full (3 bars)
    {
        colorClass: 'status-high',
        percent: INDICATOR_DISPLAY_THRESHOLD,
        tip: 'connectionindicator.quality.good',
        width: '100%',
        color: 'green',
        icon: <BsFillCircleFill color="#00e700" size={13} />
    },

    // 2 bars
    {
        colorClass: 'status-med',
        percent: 10,
        tip: 'connectionindicator.quality.nonoptimal',
        width: '66%',
        icon: <BsFillCircleFill color="orange" size={13} />,
        color: 'orange'
    },

    // 1 bar
    {
        colorClass: 'status-low',
        percent: 0,
        tip: 'connectionindicator.quality.poor',
        width: '33%',
        icon: <BsFillCircleFill color="red" size={13} />
    }

    // Note: we never show 0 bars as long as there is a connection.
];

let autoHideTimeout;
let prevStats;
let prevTipKey;
function ConnectionIndicatorContent({
    t, local,
    memberId,
    _isConnectionStatusInactive,
    _isConnectionStatusInterrupted,
    _videoSsrc,
    _audioSsrc,
    _isVirtualScreenshareParticipant
}) {
    const dispatch = useDispatch();
    const [stats, setStats] = useState({});
    
    const [showIndicator, setShowIndicator] = useState(false);

    useEffect(() => {
        memberId && statsEmitter.subscribeToClientStats(memberId, _onStatsUpdated);

        return () => {
            memberId && statsEmitter.unsubscribeToClientStats(memberId, _onStatsUpdated);

            clearTimeout(autoHideTimeout);
        }
    }, [memberId]);


    /**
     * 전달된 사용자 ID와 연결된 새 연결 통계를 사용할 수 있을 때 호출되는 콜백입니다. 
     * 구성 요소의 현재 통계 표시를 업데이트합니다.
     *
     * @param {Object} stats - Connection stats from the library.
     * @private
     * @returns {void}
     */
    const _onStatsUpdated = (option = {}) => {
        const connectionQuality = stats?.connectionQuality;
        const newPercentageState = typeof connectionQuality === 'undefined' ? {} : { percent: connectionQuality };
        const newStats = Object.assign(
            {},
            prevStats,
            option,
            newPercentageState);
        setStats(newStats);

        _updateIndicatorAutoHide(newStats.percent);

        prevStats = newStats;
    }

    /**
     * 표시기를 자동으로 숨기기 위한 내부 상태를 업데이트합니다.
     *
     * @param {number} percent - 값 0과 100 사이의 현재 연결 품질 백분율입니다.
     * @private
     * @returns {void}
     */
    const _updateIndicatorAutoHide = (percent) => {
        if (percent < INDICATOR_DISPLAY_THRESHOLD) {
            clearTimeout(autoHideTimeout);
            autoHideTimeout = undefined;
            setShowIndicator(true);
        } else if (autoHideTimeout) {
            // 백분율이 임계값 미만이고 autoHideTimeout이 설정된 경우 추가 조치가 필요하지 않기 때문에 이 절은 의도적으로 비워 둡니다.
        } else {
            autoHideTimeout = setTimeout(() => {
                setShowIndicator(false);
            }, 2000);
        }
    }

    const _onToggleShowMore = () => {
        setShowIndicator(!showIndicator);
    }

    /**
     * Gets the "real" participant ID. FOr a virtual screenshare participant, that is its "owner".
     *
     * @param {Props} props - The props where to extract the data from.
     * @returns {string | undefined } The resolved participant ID.
     */
    const _getRealParticipantId = (props: Props) => {
        if (_isVirtualScreenshareParticipant) {
            return getVirtualScreenshareParticipantOwnerId(memberId);
        }

        return memberId;
    }

    
    const _getConnectionStatusColor = () => {
        let icon;
        let percent;
        switch (true) {
            case _isConnectionStatusInterrupted:
                icon = <BsFillCircleFill color="red" size={13} />;
                break;

            case _isConnectionStatusInactive:
                icon = <BsFillCircleFill color="red" size={13} />;
                break;

            default: {
                percent = stats?.connectionQuality;
                if (typeof percent === 'undefined') {
                    icon = <BsFillCircleFill color="#00e700" size={13} />;
                } else {
                    const config = _getDisplayConfiguration(percent);

                    icon = config.icon;
                }
            }
        }

        return icon;
    }

    const _getDisplayConfiguration = (percent) => {
        return QUALITY_TO_WIDTH.find(x => percent >= x.percent) || {};
    }

    const _renderShowMoreLink = ()  => {
        
        return (
            <div style={{ left: 0, padding: "5px", boxSizing: "border-box", color: "#f2f2f2" }}>
                { _getConnectionStatusColor() }
            </div>
        )
    }

    return (
        <>
            { _renderShowMoreLink() }
            {/* { showIndicator && <ConnectionStatsTable 
                connectionSummary={ _getConnectionStatusTip() }
                bitrate={stats?.bitrate}
                packetLoss={stats?.packetLoss}
                maxEnabledResolution={stats?.maxEnabledResolution}
                resolution={stats?.resolution}
                framerate={stats?.framerate}
                codec={stats?.codec}
                videoSsrc={_videoSsrc}
                audioSsrc={_audioSsrc}
            /> } */}
        </>
    );
}

function _mapStateToProps(state, ownProps) {
    const { videoTrack, audioTrack } = ownProps;
    const conference = getJitsiRoom(state)?.conference?.conference;

    const _isConnectionStatusInactive = isTrackStreamingStatusInactive(videoTrack);
    const _isConnectionStatusInterrupted = isTrackStreamingStatusInterrupted(videoTrack);

    return {
        _isConnectionStatusInactive,
        _isConnectionStatusInterrupted,
        _isLocalVideo: ownProps.local,
        _videoSsrc: videoTrack ? conference?.getSsrcByTrack(videoTrack.track) : undefined,
        _audioSsrc: audioTrack ? conference?.getSsrcByTrack(audioTrack.track) : undefined,
    }
}

export default translate(connect(_mapStateToProps)(ConnectionIndicatorContent));