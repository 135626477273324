// @flow

export const CHAT_VIEW_MODAL_ID = 'chatView';

export const CHAT_SIZE = 315;
export const MESSAGE_TYPE_ERROR = 'error';
export const MESSAGE_TYPE_LOCAL = 'local';
export const MESSAGE_TYPE_REMOTE = 'remote';

export const SMALL_WIDTH_THRESHOLD = 580;

export const EXT_STRING = "jpg, jpeg, gif, bmp, png, mpg, mpeg, mp4, mp3, wav, doc, docx, hwp, txt, pdf, csv, xls, xlsx, ppt, pptx, zip, svg";
export const POSSIBLE_EXT_LIST = ["jpg", "jpeg", "gif", "bmp", "png", "mpg", "mpeg", "mp4", "mp3", "wav", "doc", "docx", "hwp", "txt", "pdf", "csv", "xls", "xlsx", "ppt", "pptx", "zip", "svg"];
export const FILE_MAX_SIZE = 100;

export const NO_FILE = "noFile";
export const NO_EXT_FILE = "noExtFile";
export const EMPTY_FILE = "emptyFile";
export const MAX_FILE = "maxFile";
export const OK_FILE = "okFile";



