import React from "react";
import { connect } from "react-redux";
import { SETTING_MODAL_ID } from "../../constants";
import AbstractSwitchButton from "../AbstractSwitchButton";

class DevicePermissionRightMenutButton extends AbstractSwitchButton {
    constructor(props) {
        super(props);
    }
    
    componentDidMount() {        
        this.load(SETTING_MODAL_ID, this.props.dispatch);
    }

    render() {
       return (
            <span 
                className={`icoImg permission_manage permission_manage_${this.props._isDeviceOpen}`}
                onClick={this._onClick} 
            ></span>
       ) ;
    }
}

function _mapStateToProps(state) {
    // option item 
    const _isDeviceOpen = state["features/base/modal"].name === SETTING_MODAL_ID ? true : false;

    return {
        _isDeviceOpen
    }
}

export default connect(_mapStateToProps)(DevicePermissionRightMenutButton);