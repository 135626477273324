import { throttle } from 'lodash';
import { clearRoom, conferenceLeft, getGroupId, getRoomOption, setGroupId } from './features/base/conference';
import { getLocalUserUUID } from './features/base/localuser';
import { getGroupById } from './features/groups';
import { ROLE, getLocalIDMember, setBridgeId } from './features/member';
import { clearNotifications } from './features/notifications';
import { CHECKING_ROOM_PASSWORD_MODAL_ID } from './features/options';
import { setRoomLocked } from './features/room-lock';
import { getLocalSharingStatus, setShareScreenUserId, stopScreenShare } from './features/screen-share';
import { createTaskQueue } from './modules/util/helpers';
import jitsiServer from './server/jitsi';
import ScreenShare from './server/jitsi/screenSharing';
import TempMate from "./server/mate/TempMate";
import { default as Mate } from './server/mate/index';

const task = createTaskQueue();
let goToGroup;
let isLeft = false;
class MateManagement {
    constructor() {
        this.mateServer = new Mate('');
        this.mediaServer = new jitsiServer();
        this.tempServer = new TempMate();
        this.screenServer = new ScreenShare();
        this.accessToken = null;
        this.meeting_uuid = null;
        this.group_id = null;

        this.groupWebsocket = new Map();

        this.currentGroupId = null;

        this.connectionGroupId = null;
    }

    init() {
        this.mateServer.init();
        this.mediaServer.init();
    }

    getTempMate() {
        return this.tempServer;
    }

    async unload() {
        if (isLeft) return;

        isLeft = true;
        if (this.screenServer) this.stopScreenShare();        

        let groupCount = this.groupWebsocket.size;
        this.groupWebsocket.forEach((g) => {
            this.endMeetingRoom(g.groupId);
        });

        this.disconnect();

        this.groupWebsocket = new Map();

        APP.store.dispatch(conferenceLeft());
        window.close();

        APP.canvasList = new Map();
    }

    // room option 
    getRoom(type) {
        const room = getRoomOption(APP.store.getState());
        
        if (type === 'uuid') return room.uuid;
        if (type === 'code') return room.code;
        return room.name;
    }

    getGroupWebsocket(groupId) {
        if (!groupId) return false;
        let group = this.groupWebsocket.get(groupId);

        if (!group) {
            const newGroup = new Mate(groupId);
            group = newGroup;

            this.groupWebsocket.set(groupId, group);
        }

        return group;
    }

    handlerGroupJoin(groupId, change, role) {
        APP.store.dispatch(setGroupId(groupId));
        const group = !groupId ? this.mateServer : this.getGroupWebsocket(groupId);

        if (change) {
            group.events.join(true, role);
            APP.UI.showLoading(false); 
        } else {
            if (!groupId) {
                goToGroup = setTimeout(() => {
                    group.events.join(true);
                
                    APP.UI.showLoading(false);
                }, 500);
            } else {                        
                goToGroup = setTimeout(() => {                
                    group.init();
                    group.connection.connectAndJoin(change, role)
                        .then(() => {
                            console.log(" group connection join ");

                            APP.UI.showLoading(false); 
                        });
                               
                }, 2000);
            }     
        }
          
    }

    startGroupMeeting(groupId, change, role) { 
        if (groupId) {
            const group = getGroupById(APP.store.getState, groupId);

            APP.UI.showLoading(true, `${group.name}`);
        } else {
            APP.UI.showLoading(true, `lobby`);
        }

        if (change) {
            clearTimeout(goToGroup);
            APP.store.dispatch(clearNotifications());     
            APP.store.dispatch(clearRoom());    
        }

        this.handlerGroupJoin(groupId, change, role);
    }

    changeMeetingRoom(currentGroupId = false, groupId = false, role) {
        clearTimeout(goToGroup);

        // 입장 중인 그룹 left 
        this.endMeetingRoom(currentGroupId); 

        // 현재 할당된 그룹 
        if (currentGroupId !== groupId) {
            // 초기화 
            APP.store.dispatch(clearNotifications());     
            APP.store.dispatch(clearRoom());

            if (getLocalSharingStatus(APP.store.getState)) APP.store.dispatch(stopScreenShare());
        } 
        
        // 새로운 그룹 시작
        this.startGroupMeeting(groupId, false, role);         
    }

    endMeetingRoom(groupId) {
        // 종료된 그룹 
        if (groupId) {
            const group = this.getGroupWebsocket(groupId);
            // 그룹 나가기
            if (group) {
                group.events.leave()
                    .then(() => {                        
                        this.groupWebsocket.delete(groupId);
                    });
            }
        }      
    }

    /**
     * CONNECTION
     */
    // 연결
    async connection() {
        isLeft = false;
        // 방 잠금 여부 확인
        this.mediaServer.connect(false)
            .then(conference => {
                this.mateServer.connection.connectAndJoin()
                    .then(async () => {
                        const userId = conference.myUserId();
                        await this.setJitsiId(userId); 

                        return;
                    }).catch(err => {
                        alert(err);
                        APP.UI.showLoading(false);
                        APP.mateManagement.disconnect();
                    })
            }).catch(err => {
                console.log(err);
                APP.mateManagement.disconnect();
                APP.UI.showLoading(false);
            });
        
            // 내가 속한 그룹 사용자 확인 
            // const { message, complete } = await checkStartGroup(this.group_id);
            // if (complete) {
            
            // } else {
            //     console.log(message);
            //     APP.mateManagement.disconnect();
            // }            
        

        
    }

    // set 영상 session id 
    setJitsiId(jitsi_id) {
        this.mateServer.events.setJitsiId(jitsi_id)
            .then(response => {
                if (response.status === 200) {
                    // const local_uuid = getLocalUserUUID(APP.store.getState);
                    // APP.store.dispatch(setBridgeId(local_uuid, jitsi_id));
                    console.log(" dispatch update birdge session id");
                } else {
                    console.log(" 브릿지 세션 세팅 실패 했습니다. ");
                }
            });
    }

    // 연결 종료 
    disconnect() {
        this.mateServer.leave()
            .then(response => {
                this.mediaServer.disconnect();
                APP.store.dispatch(conferenceLeft());
                this.mateServer.connection.unsetConnect();
            });
        ;
    }

    getAccessToken() {
        return this.accessToken;
    }
    setAccessToken(accessToken) {
        this.accessToken = accessToken;
    }
    getMeetingId() {
        return this.meeting_uuid;
    }
    setMeetingId(meeting_uuid) {
        this.meeting_uuid = meeting_uuid;
    }
    setCurGroupId(group_id) {
        this.currentGroupId = group_id;
    }
    getCurGroupId() {
        return this.currentGroupId;
    }

    // device 
    // 장치 초기 설정
    loadDevice() {
        return this.mateServer.track.loadDevice();
    }
    listenForAudioUpdates(stream) {
        this.mateServer.track.listenForAudioUpdates(stream);
    }
    stopListenForAudioUpdates() {
        this.mateServer.track.stopListenForAudioUpdates();
    }
    addDeviceListChange(remoteDeviceChagne) {
        this.mateServer.track.addDeviceListChange(remoteDeviceChagne);
    }
    removeDeviceListChange() {
        this.mateServer.track.removeDeviceListChange();
    }
    getSpeakerStats() {
        return this.mediaServer.getSpeakerStats();
    }
    
    /**
     * DEVICE 장치 설정
     */    
    getVdoErrorMsg(err) {
        return this.mateServer.track.getVdoErrorMsg(err);
    }   
    getMicErrorMsg(err) {
        return this.mateServer.track.getMicErrorMsg(err);
    }   

    getLocalTracksInitialized() {
        return this.mediaServer.getLocalTracksInitialized();
    }
    // conference 입장 전 device list
    initDevice() {
        return this.mediaServer.initDevice(true);
    }
    
    // LOCAL TRACK
    createLocalTracks(options) {
        return this.mediaServer.createLocalTracks(options);
    }
    // 실패시 LOCAL TRACK 재시도
    createLocalTrackA(options) {
        return this.mediaServer.createLocalTrackA(options);
    }

    // TRACK 음소거
    setTrackMuted(track, muted) {
        return this.mediaServer.setTrackMuted(track, muted);
    }

    /**
     * Mute
     */
    toggleVideoMuted(showUI) {
        try {
            this.mediaServer.toggleVideoMuted((showUI = true));
        } catch (e) {
            console.log(e);
        }
    }
    toggleAudioMuted(showUI) {
        try {
            this.mediaServer.toggleAudioMuted((showUI = true));
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * CONFERENCE TRACK
     */
    addConferenceTrack(track) {
        return this.mediaServer.addLocalTracksToConference(
            track,
        );
    }
    replaceConferenceTrack(oldTrack, newTrack) {
        return this.mediaServer.replaceConferenceTrack( oldTrack, newTrack);
    }
    removeConferenceTrack(track) {
        return this.serverList[
            this.mediaServer
        ].removeLocalTracksFromConference(track);
    }
    startScreenShare() {
        return this.screenServer.startScreenShare();
    }
    stopScreenShare() {
        return this.screenServer.stopScreenShare();
    }
    
    listenForAudioLevelUpdates(track) {
        return this.mediaServer.listenForAudioLevelUpdates(
            track,
        );
    }
    removeForAudioLevelUpdates(track) {
        return this.mediaServer.removeForAudioLevelUpdates(
            track,
        );
    }
    getAudioOutputDeviceId() {
        try {
            return this.mediaServer.getAudioOutputDeviceId();
        } catch (e) {
            console.log(e);
        }
    }
    setAudioOutputDevice(newId) {
        try {
            return this.mediaServer.setAudioOutputDevice(
                newId,
            );
        } catch (e) {
            return true;
        }
    }

    // send end point (use websocket)
    sendActionMessage(data) {
        try {
            this.mediaServer.sendActionMessage(data);
        } catch (e) {
            console.log(e);
            // this.serverList[this.actionServer].sendActionMessage(data);
        }
    }

    /**
     * START YOUTUBE STREAMING
     */
    toggleYoutubeStream(toggle, streamKey) {
        return this.mediaServer.toggleYoutubeStreaming(
            toggle,
            streamKey,
        );
    }

    /**
     * MATE2 ACTION
     */
    getEventServer() {
        let server = this.mateServer;

        const currentGroupId = getGroupId(APP.store.getState());
        if (currentGroupId && currentGroupId !== "") {
            const websocket = this.getGroupWebsocket(currentGroupId);
            if (websocket) server = websocket;
        }

        return server;
    }
    
    
    // 현재 레이아웃 모드 변경
    setLayoutMode(mode, viewVisible) {
        const server = this.getEventServer();
        return server.events.setLayoutMode(mode, viewVisible);       
    }
    // 그리드 모드 default count 
    requestSetGridCount(grid_count) {
        const server = this.getEventServer();
        return server.events.setGridCount(grid_count);
    }
    // pin 알림
    setMemberPinned(user_uuid, pinned) {
        const server = this.getEventServer();
        return server.events.setMemberPinned({ user_uuid, pinned });
    }
    // recording 알림
    setRecording(user_uuid, recording) {
        const server = this.getEventServer();
        return server.events.setRecording(user_uuid, recording);
    }
    // 사용자 역할 변경
    async setMemberRole(user_uuid, role) {
        const server = this.getEventServer();
        return server.events.setParticipantRole(user_uuid, role);
    }

    // 화면 공유 종료 요청e
    setScreenOption(jitsi_id) {
        const server = this.getEventServer();
        return server.events.setScreenOption(jitsi_id);
    }

    // 문서 
    // 문서 저장 
    shareItem(data) {
        const server = this.getEventServer();
        return server.events.shareItem(data);
    }
    // 문서 삭제 
    deleteShareItem(uuid) {
        const server = this.getEventServer();
        return server.events.deleteShareItem(uuid);
    }
    // 문서 공유 focus item 
    focusShareItem(uuid, index, mode) {
        const server = this.getEventServer();
        return server.events.focusShareItem(uuid, index, mode);
    }
    // 문서 캔버스 
    sendSharePoints(uuid, index, key, property) {
        const server = this.getEventServer();
        return server.events.sendSharePoints(uuid, index, key, property);
    }
    // 캔버스 업데이트 
    deleteShareLine(uuid, index, key, isAll) {
        const server = this.getEventServer();
        return server.events.deleteShareLine(uuid, index, key, isAll);
    }
    // 문서 캔버스 삭제
    deleteShareCanvasIndex(uuid, index) {
        const server = this.getEventServer();
        return server.events.deleteShareCanvasIndex(uuid, index);
    }

    // 손들기 
    raiseHand(user_uuid, handler) {
        const server = this.getEventServer();
        return server.events.raiseHand(user_uuid, handler);
    }

    // 손들기 (발표자 요청)
    requestPresentation() {
        const server = this.getEventServer();
        return server.events.requestPresentation();
    }
    // 발표자 응답
    responsePresentation(member_uuid, accepted) {
        const server = this.getEventServer();
        server.events.responsePresentation(member_uuid, accepted);
    }

    // 사용자 강퇴
    kickMemeber(user_uuid) {
        const server = this.getEventServer();
        return server.events.kickMemeber(user_uuid);
    }
    // 사용자의 카메라 on/off 알림
    setMemberCamera(member_uuid, muted) {
        const server = this.getEventServer();
        server.events.setMemberCamera(member_uuid, muted);
    }
    // 사용자의 마이크 on/off 알림
    setMemberMic(member_uuid, muted) {
        const server = this.getEventServer();
        server.events.setMemberMic(member_uuid, muted);
    }
    
    /**
     * vod 
     */
    // vod 등록
    setVodList(file) {
        const server = this.getEventServer();
        server.events.setVodList(file);
    }
    // vod 삭제
    deleteVodItem(uuid) {
        const server = this.getEventServer();
        server.events.deleteVodItem(uuid);
    }
    // focus vod
    selectVodItem(uuid) {
        const server = this.getEventServer();
        server.events.selectVodItem(uuid);
    }

    /**
     * 설문 조사 
     */
    notifySurvey(uuid) {
        const server = this.getEventServer();
        server.events.notifySurvey(uuid);
    }
    replySurvey(uuid) {
        const server = this.getEventServer();
        server.events.replySurvey(uuid);
    }
    
    /**
     * CHAT (채팅)
     */
    sendTextMessage(message) {
        if (message.type === 'file' && !message.content.downloadLink) return;
        const server = this.getEventServer();
        server.events.sendTextMessage(message);
    }
    sendPrivateTextMessage(participants, message) {
        const server = this.getEventServer();
        server.events.sendPrivateTextMessage(participants, message );
    }

    // /**
    //  * 알림
    //  */
    sendNotification(message, group_id) {
        const server = this.getEventServer();
        // const content = {
        //     message, 
        //     group_id
        // };
        return server.events.sendNotification(message, group_id);
    }

    /**
     * 그룹
     */
    setGroupRunnigStatus(running) {
        const server = this.getEventServer();

        return this.mateServer.events.setGroupRunnigStatus(running);
    }
    initJoin() {
        this.mateServer.events.join(true);
    }

    /**
     * 방 설정
     */
    updatePolicy(policy) {
        this.mateServer.events.updatePolicy({ policy });
    }
}

// async function checkStartGroup(groupId) {
//     if (groupId) {
//         return { complete: true, message: true }
//     }
//     // 그룹 조회 
//     const { dispatch, getState } = APP.store;
//     const state = getState();

//     const localId = getLocalUserUUID(state);
//     const main_role = await checkMeetingRole(localId);
//     dispatch(setMainRole(main_role));
    
//     const response = await APP.API.getGroupList();

//     let groupStart = false;
//     let group_id = null;
//     if (response.complete) {
//         const groups = response.message;
        
//         Object.keys(groups).map(uuid => {
//             if (uuid === GROUP_DEFAULT_NAME) return;
//             const group = groups[uuid];
//             if (group.members && group.members.find(i => i.user_id === localId)) {
//                 dispatch(setAssignGroupId(uuid)); // 내가 지금 현재 할당 되어있는 그룹 
//                 if (group.status === 'opened') group_id = uuid;
//             }

//             if (group.status === 'opened') {
//                 groupStart = true;               
//                 return;
//             }
//         });
//     }

//     // 그룹 실행 여부 
//     dispatch(setGroupStatus(groupStart));
    
//     return response;
// }

export default new MateManagement();
