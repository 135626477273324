// @flow
import { getCurrentPage, setAppPage } from '../app';
import { PAGE_TYPE } from '../app/constants';
import { ReducerRegistry, StateListenerRegistry, assign, set } from '../redux';
import { getBackendSafeRoomName } from '../util';

import {
    CONFERENCE_ALL_JOINED,
    CONFERENCE_JOINED,
    CONFERENCE_LEFT,
    CONFERENCE_TIMESTAMP_CHANGED, CONFERENCE_WILL_JOIN, SET_CONFERENCE_ERROR, SET_GROUP_UUID, SET_MAIN_ROLE, SET_ROOM
} from './actionTypes';
import { getRoomOption, isRoomValid } from './functions';

const DEFAULT_STATE = {
    options: {
        room: null,
        code: null,
        uuid: null
    },
    conference: {},
    groupId: null,
    error: null,
    conferenceTimestamp: 0,
    mainRole: null,
    mediaSeverInfo: null,
    joined: false
};

ReducerRegistry.register('features/base/conference', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case CONFERENCE_ALL_JOINED:
            return {
                ...state,
                joined: action.joined
            }
            
        case CONFERENCE_WILL_JOIN:
            return _conferenceWillJoin(state, action);

        case CONFERENCE_JOINED:
            return _conferenceJoined(state, action);

        case CONFERENCE_TIMESTAMP_CHANGED:
            return set(state, 'conferenceTimestamp', action.conferenceTimestamp);

        case CONFERENCE_LEFT:
            return _conferenceLeftOrWillLeave(state, action);

        case SET_ROOM:
            return _setRoom(state, action);

        case SET_CONFERENCE_ERROR:
            return {
                ...state,
                options: action.error ? DEFAULT_STATE.options : state.options,
                error: action.error
            }

        case SET_GROUP_UUID:
            return {
                ...state,
                groupId: action.groupId
            }

        case SET_MAIN_ROLE: 
            return {
                ...state,
                mainRole: action.role
            }
    }
    return state;
    
});

// 방 입장 중 
function _conferenceWillJoin(state, { conference, serverType }) {
    return assign(state, {
        conference: {
            ...state.conference,
            [serverType]: {
                conference,
                joined: 'loading'
            }
        }
    });
}

// 방 입장
function _conferenceJoined(state, { conference, serverType }) {  
    return assign(state, {
        conference: {
            ...state.conference,
            [serverType]: {
                conference,
                joined: true
            }
        }
    });
}

function _conferenceLeftOrWillLeave(state) {
    return assign(state, {
        conference: undefined,
        joined: false,
        groupId: null,
    });
}

function _p2pStatusChanged(state, action) {
    return set(state, 'p2p', action.p2p);
}

// 방 이름 
function _setRoom(state, action) {
    let { room, media_server_info } = action;
    if (room) {
        if (!isRoomValid(room.name)) {
            room.name = undefined;
        }

        room.name = getBackendSafeRoomName(room.name);
        return {
            ...state,
            options: room,
            mediaSeverInfo: media_server_info
        };
    } else {
        return assign(state, {
            error: undefined,
            room: undefined
        });
    }
}

StateListenerRegistry.register(state => getRoomOption(state),
    ({ uuid }, { dispatch, getState }) => {
        if (uuid) {
            APP.mateManagement.setMeetingId(uuid);

            if (PAGE_TYPE.LOBBY === getCurrentPage(getState())) {
                dispatch(setAppPage(PAGE_TYPE.MAIN));
            }            
        }
    }
);

StateListenerRegistry.register(state => state['features/base/conference'].joined,
    (joined, store, prevJoined) => {
        if (joined) store.dispatch(setAppPage(PAGE_TYPE.MAIN));
        else if (prevJoined && !joined) store.dispatch(setAppPage(PAGE_TYPE.LOBBY));
    });