function getSearchData(key) {
    const searchParams = new URLSearchParams(location.search);

    return searchParams.get(key);
}

export const JitsiOptions = {
    connectOptions: {
        serviceUrl: `wss://${location.host}/xmpp-websocket`,
        hosts: {
            domain: "mate.net",
            muc: "muc.mate.net",
        },
    },
    conferenceOptions: {
        createVADProcessor: null,
        p2p: false,
        enabled : true,
        enableTalkWhileMuted: true,  
        enableUnifiedOnChrome: true,
        enableNoisyMicDetection: true,
        openBridgeChannel: 'websocket',
        disableSimulcast: getSearchData('disableSimulcast') || false,
        enableLayerSuspension: true,
        // desktopSharingFrameRate: {
        //     min: 5, 
        //     max: 30
        // },
        videoQuality: {
            maxBitratesVideo: {
                low: Number(getSearchData('low')) ||  100000,
                standard: Number(getSearchData('standard')) || 500000,
                high: Number(getSearchData('high')) || 2000000
            }
        }, 
        testing: {
            capScreenshareBitrate: 1
        }
    }
};