import bgDevice from "/libs/assets/bg_device.jpg";
import bgLobby from "/libs/assets/bg_lobby.jpg";
import bgNickname from "/libs/assets/bg_nickname.jpg";
import logo from "/libs/assets/logo.png";
import smallLogo from "/libs/assets/logo_v2.png";
 
const mateServer = "mate";
const mediaServer = "jitsi";
 
export const MateOptions = {
    style : {
        logo : {
            background: `url(${logo}) no-repeat center/contain`,
            width: "220px",
            minHeight: "68px"
        },
        smallLogo: {
            display: `inline-flex`,
            justifyContent: `center`,
            alignItems: `center`,
            background: `url(${smallLogo}) no-repeat center/contain `,
            minWidth: "111px", height: "33px"
        },
        lobbyBackGround : {
            background: `url(${bgLobby}) no-repeat 65% center/cover fixed`,
        },
        nicknameBackGround: {
            background: `url(${bgNickname}) no-repeat 65% center/cover fixed`,
        },
        deivceBackGround: {
            background: `url(${bgDevice}) no-repeat 65% center/cover fixed`,
        }
    },
    mateServer,
    mediaServer,
    connection: [mateServer, mediaServer],
    name: "isPark",
    lobby: {
        myPage: true,
        login: true,
        subText: false
    },
    ui: {
        layout: {
            mode: {
                GRID_MODE: true,
                PINNED_MODE: true,
                VAD_MODE: true,
                SEMINAR_MODE: false,
                whiteboard: true,
                document: true,
                common_note: true,
                screenShare: true,
                vod: false
            },
            chat: {
                file: true,
                whisper: true,
                emotion: true,
                close: true
            },
            participant: {
                allPermission: true,
                invite: true,
                search: true,
                handler: true,
                pin: true,
                video: true,
                mic: true,
                kick: true,
                writing: true,
                changeRole: true,
                close: true
            },
            header: {
                logo: true,
                invite: true,
                attendance: true,
                fullScreen: true
            },
            container: {
                pin: true
            },
            footer: {
                video: true,
                mic: true,
                speaker: true,
                group: true
            },
            option: {
                fullScreen: true,
                blur: true,
                dualMonitor: true,
                recorder: true,
                setDevice: true,
                allPermission: true,
                survey: true,
                security: true,
                group: true,
                canvasRec: true
            }
        },
        function: {
            pin: true,
            chat: true,
            participant: true,
            security: {
                e2ee: true,
                lock: true
            },
            setDevice: {
                micTest: true,
                speakerTest: true
            },
            allPermission: {
                micOn: true,
                micOff: true,
                videoOn: true,
                videoOff: true,
                writingOn: true,
                writingOff: true
            },
            invite: {
                email: false,
                url: true
            },
            record: {
                local: true,
                server: true,
                canvasRec: true,
                startCanvasRec: false
            },
            mobile: {
                slider: true,
                hideUI: true
            },
            tempLogin: false            
            
        }
    }
}