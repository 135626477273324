// @flow

import { Component } from 'react';

export default class AbstractAudio extends Component {
    constructor(props) {
        super(props);
        this.setAudioElementImpl = this.setAudioElementImpl.bind(this);
    }
    pause() {
        this._audioElementImpl && this._audioElementImpl.pause();
    }

    play() {
        this._audioElementImpl && this._audioElementImpl.play();
    }

    setAudioElementImpl(element) {
        this._audioElementImpl = element;

        // setRef
        const { setRef } = this.props;

        // $FlowFixMe
        typeof setRef === 'function' && setRef(element ? this : null);
    }

    setSinkId(sinkId) {
        this._audioElementImpl
            && typeof this._audioElementImpl.setSinkId === 'function'
            && this._audioElementImpl.setSinkId(sinkId)
                .catch(error => logger.error('Error setting sink', error));
    }

    stop() {
        this._audioElementImpl && this._audioElementImpl.stop();
    }
}
