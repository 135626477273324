import { ReducerRegistry } from "../base/redux";
import { SET_SCREEN_MONITOR, SET_SHARE_SCREEN_ID } from "./actionTypes";

const DEFAULT_STATE = {
    dual: false,
    start_share: false,
    share: null,
    isSharingVideo: false, // 내가 화면 공유중인지 확인 
    share_member: false
};

ReducerRegistry.register('features/screen-share', (state = DEFAULT_STATE, action) => {
    
    switch (action.type) {
        case SET_SHARE_SCREEN_ID:
            return {
                ...state,
                share: action.jitsi_id,
                share_member: action.user_id,
                start_share: action.jitsi_id ? true : false,
                isSharingVideo: action.isLocal ? true : false
            }

        case SET_SCREEN_MONITOR:
            return {
                ...state,
                dual: action.isDual
            }
    }

    return state;
});
