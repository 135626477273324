// 회원가입
export const SIGN_UP_MODAL_ID = "signup";

// 로그인
export const LOGIN_MODAL_ID="login";

// 방 잠금 
export const ROOM_LOCK_MODAL_ID = "room-lock";

// 마이 페이지 
export const ROOM_MODAL_ID = "mypage";

// 방 입장 전 비밀번호 확인 창 
export const CHECKING_ROOM_PASSWORD_MODAL_ID = "required-password";

// 초대 
export const INVITE_GUEST_MODAL_ID = "inviteGuest";

// 옵션
export const OPTION_ETC_MODAL_ID = "optionEtc";

// 장치 권한 관리
export const SETTING_MODAL_ID = "setting";

// 설문 조사 
export const SURVEY_MODAL_ID = "survey";

// 가상 배경 
export const VIRTUAL_BACK_MODAL_ID = "virtual-background";

// 회의실 종료 
export const CLOSE_MODAL_ID = "close";

// 출석 확인 
export const CREATE_ATTENDANCES_MODAL_ID = "create-attendances";
export const ALERT_ATTENDANCES_MODAL_ID = "alert-attendances";

// 사용자 음량
export const SET_SPEAKER_VOLUME_MODAL_ID = "speaker-volume";

// 녹화 
export const RECORD_MODAL_ID = "record";

// 화면 녹화 타입 선택
export const SET_SCREEN_TYPE_MODAL_ID = "set-screen-layout-type";

// vod 첨부 파일 선택
export const SET_VOD_ATT_TYPE = "set-vod-attached-type";

// 장치 설정 
export const DEVICE_SETTING_MODAL_ID = "device-setting";

// 그룹
export const GROUP_MODAL_ID = "grouping";
// 그룹 무작위 추가 
export const GROUP_RANDOM_MODAL_ID = "random-grouping";

export const GRID_COUNT = 'grid_count';