/* @flow */

import React from 'react';

import { connect } from '../../../redux';
import AbstractVideoTrack from '../AbstractVideoTrack';

import Video from './Video';

class VideoTrack extends AbstractVideoTrack {
    static defaultProps = {
        className: '',

        id: ''
    };

    render() {
        const {
            _noAutoPlayVideo,
            className,
            id,
            muted,
            videoTrack,
            style,
            eventHandlers
        } = this.props;

        return (
            <Video
                autoPlay = { true }
                className = { className }
                eventHandlers = { eventHandlers }
                id = { id }
                muted = { muted }
                onVideoPlaying = { this._onVideoPlaying }
                style = { style }
                webkitPlaysinline={true}
                playsInline={true}
                videoTrack = { videoTrack } />
        );
    }

    _onVideoPlaying;
}

export default connect()(VideoTrack);
