// @flow

import { MEDIA_TYPE } from '../media';
import { MiddlewareRegistry } from '../redux';
import { TRACK_ADDED, TRACK_REMOVED } from '../tracks';

import {
    CONFERENCE_JOINED,
    SET_ROOM
} from './actionTypes';
import {
    getCurrentConference
} from './functions';

import { setConferenceError } from '.';
import { CREATE_ROOM } from '../../lobby/constants';
import { setRoomLocked } from '../../room-lock';
import { getUserAccessToken, setUserToken } from '../localuser';
import { logoutUser } from '../localuser/action';

let beforeUnloadHandler;

MiddlewareRegistry.register(store => next => action => {
    const { dispatch, getState } = store;
    
    switch (action?.type) {
        case CONFERENCE_JOINED:
            return _conferenceJoined(store, next, action);

        case SET_ROOM:
            return _setRoom(store, next, action);

        // case TRACK_ADDED:
        // case TRACK_REMOVED:
        //     return _trackAddedOrRemoved(store, next, action);
    }

    return next(action);
});

// conference 입장
function _conferenceJoined(store, next, action) {
    const result = next(action);

    window.addEventListener('beforeunload', beforeUnloadHandler);

    return result;
}

async function _setRoom({ dispatch, getState }, next, action) {
    const { room, actionType } = action; 
    
    let userAToken = getUserAccessToken(getState());
    let tempLogin = false;
    let isSetRoom = false;

    if (room) {
        const { name } = room;

        if (!userAToken) {
            // 임시 로그인 
            const { complete, message } = await APP.API.tempLogin();
            
            if (complete) {
                userAToken = message?.access_token;
                tempLogin = true;
            }
        }
        
        if (!userAToken) {
            dispatch(setConferenceError("api.unableTempUser"));
            return false;
        }

        let uuid = name;

        if (actionType === CREATE_ROOM) {
            const { message, complete } = await APP.API.createRoom({ aToken: userAToken, name });
            if (complete) uuid = message?.meeting_uuid;
            else {
                dispatch(setConferenceError(message?.text));
                return;
            }
        }  
        
        // 회의 존재 여부 파악 
        let complete;
        let message = null;
        const response = await searchRoom(action, {uuid}, userAToken);
        if (response.complete) {
            isSetRoom = true;
            message = response.message;
        } else {

            if (response.message !== "api.closedRoom") {
                const response = await searchRoom(action, {code: uuid}, userAToken);
                if (response.complete) {
                    isSetRoom = true;
                    message = response.message;
                } else {
                    message = response.message;
                }
            }  else {
                message = response.message;
            }   
        }

        if (!isSetRoom) {
            if (tempLogin) dispatch(logoutUser());
            dispatch(setConferenceError(message));
            return;
        } 

        if (message) {
            if (message.password_checking) await dispatch(setRoomLocked(true));
        }
        // if (message?.ui) {
        //     APP.layout = message.ui.layout;
        //     APP.function = message.ui.function;
        // }
        if (tempLogin) dispatch(setUserToken({ access_token: userAToken }));
       

        return next(message);
    } else {
        return next({
            ...action,
            room: null
        });
    }
}

export async function searchRoom(action, data, aToken) {
    const { message, complete } = await APP.API.searchRoom({ data, aToken });
            
    if (complete) {
        if (message?.found_count > 0) {
            const { uuid, code, name, password_checking, ui, media_server_info, status } = message.items[0];
            if (status === "closed") {
                return {
                    complete: false,
                    message: "api.closedRoom"
                }
            }

            return {
                complete,
                message: {
                    ...action, room: { uuid, code, name }, password_checking, ui, media_server_info
                }
            };
        } 
        return {complete: false, message: "api.errorNoRoom"};
        
    }
    return {
        complete: false, 
        message
    };
}

function _trackAddedOrRemoved(store, next, action) {
    const track = action.track;

    if (track && track.local && track.mediaType !== MEDIA_TYPE.PRESENTER) {
        return (
            _syncConferenceLocalTracksWithState(store, action)
                .then(() => next(action)));
    }

    return next(action);
}

function _syncConferenceLocalTracksWithState({ getState }, action) {
    const conference = getCurrentConference(getState);
    let promise;
    if (conference) {
        const track = action.track.track;

        if (action.type === TRACK_ADDED) {
            promise = APP.mateManagement.addConferenceTrack([ track ]);
        } else {
            promise = APP.mateManagement.removeConferenceTrack([ track ]);
        }
    }

    return promise || Promise.resolve();
}
