import { ADD_LOCAL_MEMBER } from "../../member/actionType";
import { PAGE_TYPE, setAppPage } from "../app";
import { storage } from "../localStorage";
import { MiddlewareRegistry } from "../redux";
import { setLocalUserProperty, setUserToken } from "./action";
import { LOGOUT } from "./actionTypes";

MiddlewareRegistry.register(store => next => action => {
    switch(action.type) {
        case LOGOUT:
            storage.remove("token");
            store.dispatch(setUserToken(null));

            store.dispatch(setAppPage(PAGE_TYPE.LOBBY));
            break;

        case ADD_LOCAL_MEMBER:
            store.dispatch(setLocalUserProperty({
                permissions: action.member.permissions, 
                role: action.member.role
            }));
            break
    }

    return next(action);
});