import { toState } from "../base/redux";
import aliases from 'react-emoji-render/data/aliases';
import emojiAsciiAliases from 'react-emoji-render/data/asciiAliases';

import { escapeRegexp } from '../base/util';
import { EMPTY_FILE, FILE_MAX_SIZE, MAX_FILE, NO_EXT_FILE, NO_FILE, OK_FILE, POSSIBLE_EXT_LIST } from "./constants";

const EMOTICON_REGEXP_ARRAY = [];

(function() {
    for (const [ key, value ] of Object.entries(aliases)) {
        let escapedValues;
        const asciiEmojies = emojiAsciiAliases[key];

        if (asciiEmojies) {
            escapedValues = asciiEmojies.map(v => escapeRegexp(v));
        } else {
            escapedValues = [];
        }

        escapedValues.push(escapeRegexp(`:${key}:`));

        const regexp = `\\B(${escapedValues.join('|')})\\B`;

        EMOTICON_REGEXP_ARRAY.push([ new RegExp(regexp, 'g'), value ]);
    }
})();

export function replaceNonUnicodeEmojis(message) {
    let replacedMessage = message;

    for (const [ regexp, replaceValue ] of EMOTICON_REGEXP_ARRAY) {
        replacedMessage = replacedMessage.replace(regexp, replaceValue);
    }

    return replacedMessage;
}

export function getUnreadCount(state) {
    const { lastReadMessage, messages } = state['features/chat'];
    if (!messages) return 0;
    const messagesCount = messages.length;

    if (!messagesCount) {
        return 0;
    }

    const lastReadIndex = messages.lastIndexOf(lastReadMessage);

    return messagesCount - (lastReadIndex + 1);
}

export function isChatOpen(state) {
    const { isOpen } = state['features/chat'];
    return Boolean(isOpen);    
}

export function unReadChat(state) {
    const { unreadCount } = state['features/chat'];
    
    return (unreadCount > 0) ? "waiting" : false;
}


export function bytesToSize(bytes) {
    let sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 B';
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    let size = (bytes / Math.pow(1024, i));
    if (size.toFixed(2).slice(-2) == '00') {
        size = Math.round(size);
    } else {
        size = size.toFixed(2);
    }
    return size + ' ' + sizes[i];
}

export function downloadFile(link, name) {
    if (link) {
        const id = link.split("/")[5];
        const filename = link.split("/")[6];

        APP.API.downloadFile(id, filename);
        return true;
    }
    return false;
}

export function isSendTextMessageValid(text) {
    if (text.trim() === "") return false;
    return true;
}

export function isSendFileMessageValid(file) {
    return true;
}

// get down
export async function getDownLoadLink(formData) {
    const { data: response, error } = await APP.API.convertFile(formData);

    // error check 
    if (error || response.status !== 200) return false;
    const { data: responseData } = response;

    let downloadLink = responseData.download_link;
    const downloadLinkArr = downloadLink.split("/");
    let setLink = "https://" + location.host + "/files/download/" + downloadLinkArr[4] +"/"+ downloadLinkArr[5];

    return setLink;
}

export function getPrivateMessageRecipient(state) {
    const { privateMessageRecipient } = state['features/chat'];

    return privateMessageRecipient;  
}


/**
 * 전송 가능 파일인지 확인
 * @param {Object} file 
 * @returns 
 */
export function isTransferableFile(file) {
    const { name, size } = file;
    const extArr = name?.split("\.") || null;
    if (!extArr) return NO_EXT_FILE;
    
    const ext = extArr[extArr.length - 1];

    if (POSSIBLE_EXT_LIST.indexOf(ext) === -1) {
        return NO_EXT_FILE;
    } 

    const sizeMB = size / 1024 / 1024;
    if (sizeMB <= 0) return EMPTY_FILE;
    if (sizeMB > FILE_MAX_SIZE) return MAX_FILE;
    
    return OK_FILE;
}