import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { getFormatterTime } from "../../base/i18n";
import { getLocalUserUUID } from "../../base/localuser";
import { removeDocumentFile, setDocumentSharedFile } from "../actions";
import { DEFAULT_DOCUMENT } from "../constants";
import DocumentAction from "../DocumentAction";

const document_data = {
    uuid: null, 
    file: { name: null },
    isWhiteBoard: false,
    shared: false,
    page: null,
    list: null, 
    count: 1,
    complete: false,
    user_uuid: null
};

export default function AddDocumentButton({
    t, isWhiteBoard
}) {
    const dispatch = useDispatch();

    const handlerAddDocument = e => {
        const file = e.target.files[0];

        DocumentAction.addDoumentFile(dispatch, file)
            .then(() => {
                e.target.value = "";
            })
            .catch(err => {                
                const { message, uuid } = err;
                // 지원 하지 않는 파일 입니다. or 한글 파일 
                APP.UI.alertMessage(t(message));
                e.target.value = "";
            });
    }

    const handlerAddWhite = () => {
        DocumentAction.addDocoumetItem(dispatch);
    }

    return (
        <div className="add_btn noClick">
            { !isWhiteBoard ? 
                <input type="file" id="add_document" onChange={handlerAddDocument} /> : 
                <input type="button" id="add_document" onClick={handlerAddWhite} /> }
            <label htmlFor="add_document">
                <div>
                    <dd className="icoImg"></dd>
                    <dt> { isWhiteBoard ? t("document.addWhiteBoard") : t("document.addDocument")} </dt>
                </div>
            </label>
        </div>
    )
}