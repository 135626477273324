import i18next from 'i18next';
import { JitsiParticipantConnectionStatus, JitsiTrackStreamingStatus } from '../base/lib-jitsi-meet';

/**
 * Checks if the passed track's streaming status is active.
 *
 * @param {Object} videoTrack - Track reference.
 * @returns {boolean} - Is streaming status active.
 */
export function isTrackStreamingStatusActive(videoTrack) {
    const streamingStatus = videoTrack?.streamingStatus;

    return streamingStatus === JitsiTrackStreamingStatus.ACTIVE;
}

/**
 * Checks if the passed track's streaming status is inactive.
 *
 * @param {Object} videoTrack - Track reference.
 * @returns {boolean} - Is streaming status inactive.
 */
export function isTrackStreamingStatusInactive(videoTrack) {
    const streamingStatus = videoTrack?.streamingStatus;

    return  streamingStatus && streamingStatus === JitsiTrackStreamingStatus?.INACTIVE;
}

/**
 * Checks if the passed track's streaming status is interrupted.
 *
 * @param {Object} videoTrack - Track reference.
 * @returns {boolean} - Is streaming status interrupted.
 */
export function isTrackStreamingStatusInterrupted(videoTrack) {
    const streamingStatus = videoTrack?.streamingStatus;

    return streamingStatus && streamingStatus === JitsiTrackStreamingStatus.INTERRUPTED;
}

/**
 * Checks if the passed participant's connection status is active.
 *
 * @param {Object} participant - Participant reference.
 * @returns {boolean} - Is connection status active.
 */
export function isParticipantConnectionStatusActive(participant) {
    const connectionStatus = participant?.connectionStatus;

    return connectionStatus === JitsiParticipantConnectionStatus.ACTIVE;
}

/**
 * Checks if the passed participant's connection status is inactive.
 *
 * @param {Object} participant - Participant reference.
 * @returns {boolean} - Is connection status inactive.
 */
export function isParticipantConnectionStatusInactive(participant) {
    const connectionStatus = participant?.connectionStatus;

    return connectionStatus === JitsiParticipantConnectionStatus.INACTIVE;
}

/**
 * Checks if the passed participant's connection status is interrupted.
 *
 * @param {Object} participant - Participant reference.
 * @returns {boolean} - Is connection status interrupted.
 */
export function isParticipantConnectionStatusInterrupted(participant) {
    const connectionStatus = participant?.connectionStatus;

    return connectionStatus === JitsiParticipantConnectionStatus.INTERRUPTED;
}

export const _getConnectionStatusTip = () => {
    let tipKey;
    let percent;
    switch (true) {
        case _isConnectionStatusInterrupted:
            tipKey = 'connectionindicator.quality.lost';
            break;

        case _isConnectionStatusInactive:
            tipKey = 'connectionindicator.quality.inactive';
            break;

        default: {
            percent = stats?.percent;
            if (typeof percent === 'undefined') {
                // If percentage is undefined then there are no stats available
                // yet, likely because only a local connection has been
                // established so far. Assume a strong connection to start.
                tipKey = 'connectionindicator.quality.good';
            } else {
                const config = _getDisplayConfiguration(percent);

                tipKey = config.tip;
            }
        }
    }

    return i18next.t(tipKey);
}
