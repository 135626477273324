import styled from "styled-components";

export const LayoutContainerStyled = styled.div`
    position: relative;
    display: inline-flex;
    width: ${(props) => props.isHide ? 0 : '100%'};
    height: 100%;
    overflow: hidden;
    flex-direction: ${(props) => (props.horizontal ? 'column' : 'row')};
    justify-content: ${(props) => (props.isGrid ? 'center' : 'flex-start')};
    align-items: ${(props) => (props.isGrid ? 'center' : 'flex-start')};

    > * { margin: 0; }

    .item {
        box-sizing: border-box;
        position: relative;        
        background-color: #000;
        margin: 0;
        overflow: hidden;

        &.mirror {
            border: 1px solid #f9bb3d; 

            video {
                transform: scale(-1, 1);
                -moz-transform: scale(-1, 1);
                -webkit-transform: scale(-1, 1);
                -o-transform: scale(-1, 1);
        
                object-fit: contain;
            }  
        }
        
        &.no_mirror {
            border: 1px solid #123; 
        }

        &.noPin {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 12px;
        }

        .videoWrap {
            overflow: hidden;
            width: 100%;
            height: 100%;
            position: relative;

            video {
                width: 100%; height: 100%;
            }

            .default {
                width: 100%;
                height: 100%;
                position: relative;
            }
        }
    }

    .namePlate {
        position: absolute;
        width: 100%; height: 5%;
        box-sizing: border-box; bottom: 0;
        background: rgba(0,0,0, 0.7); color:#eee; 
        overflow: hidden; 
        padding: 0 10px; font-size: 12px; 
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        min-height: 25px;
        
        div > * {
            margin: 0;
        }
    
        .mike {float: right;}
    }

    .noMember {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .voice {
        display: inline-flex;
    }
    
    .horizontal {
        width: 100%;
        height: 98px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 2px;

        &.layout_center {
            justify-content: center;
        }
    }

    .vertical {
        width: 172px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 2px;

        &.layout_center {
            justify-content: center;
        }
    }

    .gridFlex {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: auto!important;
        height: auto!important;
    }

    .mainFlex {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .share_content {
        display: flex;
        flex-direction: column;
        position: relative;
        margin: 0;
        padding: 0;
        min-width: 350px;
        flex: 1;
        width: 100%;
        overflow: hidden;

        .gridFlex {
            margin: 0;
        }
    }
`;

export const PagingDesktopStyled = styled.div`
    position: ${props => props.isMaxSize ? 'relative' : 'absolute'};
    right: ${props => props.type === 'next' ? '0' : "auto"};
    left: ${props => props.type === 'prev' ? '0' : "auto"};
    width: ${props => props.vertical ? '100%' : '30px'};
    height: ${props => props.vertical ? '30px' : '100%'};
    background: #123;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin: 0;

    &:hover {
        svg {
            color: #76acfb!important;
        }
    }
`;

export const ShareLayoutStyled = styled.div`
    position: ${(props) => (props.expand ? "relative" : "absolute")};
    display: flex;
    max-height: 98px; 
    width: 100%;
    overflow: hidden; 
    justify-content: flex-start;

    & .document_expand {
        cursor: pointer;
        position: absolute;
        left: 146px;
        z-index: 1;
        top: 36px;
        transform: ${(props) => (props.expand ? "rotate(180deg)" : "rotate(0deg)")};

        &:hover {
            --icon-primary-color: #76acfb!important;
        }
    }
`;

export const ScreenLayoutStyled = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;

    .video {
        width: 172px;
        height: 100%;
    }

    .screen {
        display: flex;
        height: 100%;
        width: ${props => props.isFull ? '100%' : `calc(100% - 172px)`};
        position: relative;

        video {
            width: 100%;
            height: 100%;
        }
    }

    .local {
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 2;
    }

    > * { margin: 0; }
`;

export const SliderStyled = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.columns}, 1fr);
    grid-template-rows: repeat(
        ${props => props.rows},
        1fr
    );
    width: 100%;
    height: ${props => (props.maxPage > 1 ? 'calc(100% - 15px)' : '100%')};
`;

export const PagingStyled = styled.div`
    width: 100%;
    height: 15px;
    display: flex;
    gap: 4px;

    .bar {
        width: ${props => props.width}%;
        height: 100%;
        margin: 0;
        margin-left: ${props => props.margin}%;
        background: #b3b3b3;
    }
`;

export const DualStyled = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-dircetion: row;

    .gridFlex {
        width: auto!important;
        height: auto!important;
    }

    .dualOption {
        min-width: 350px;
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        .screen {
            width: 100%;
            height: 172px;
            background: #000;

            video {
                width: 100%;
                height: 100%;
            }
        }

        .chatBtn {
            svg {display: none;}
            width: 100%;
            padding: 8px;
            background: #123;
            color: #fff;
        }

        .buttons {
            margin: 4px 0;
            display: flex;
            gap: 8px;
            justify-content: center;
            align-items: center;
        }
    }

    .right_wrapper {
        width: 100%;
        flex: 1;
        overflow: hidden;
    }

    .toggleSide {
        background: #123;
        border-radius: 4px;
        cursor: pointer;
        margin: 0;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
    }

    .top_label {
        width: 100%;    
        height: 38px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        button {
            margin: 0 4px;
        }

        .icon {
            gap: 4px;
            margin: 0 auto  0 20px;
        }
    }

    .layout_wrapper {
        flex: 1;
        background: black;        
    }
`;