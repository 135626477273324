import { CONFERENCE_LEFT } from "../base/conference";
import { ReducerRegistry } from "../base/redux";
import { SET_RECORDING_ENABLED, SET_RECORD_CLASS } from "./actionType";

const DEFAULT_STATE = {
    server: {
        enabled: false,
        option: null,
    },
    local: {
        enabled: false,
        option: null,
    }    
};

ReducerRegistry.register('features/recording', (state = DEFAULT_STATE, action) => {
    switch (action.type) {       
        case SET_RECORDING_ENABLED:
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    enabled: action.enabled,
                    option: action.option
                }
            }

        case SET_RECORD_CLASS:
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    recordClass: action.recordClass
                }
            }
    }
    
    return state;
});
