import { MEDIA_TYPE, setAudioMuted, setVideoMuted, VIDEO_MUTISM_AUTHORITY } from "../base/media";
// import { muteRemoteParticipant } from "../base/participants";
import { getLocalIDMember } from "../member";

/**
 * 로컬 참가자 음소거
 */
export function muteLocal(enable, mediaType) {
    return (dispatch, getState) => {
        const isAudio = mediaType === MEDIA_TYPE.AUDIO;

        if (!isAudio && mediaType !== MEDIA_TYPE.VIDEO) {
            console.error(`Unsupported media type: ${mediaType}`);
            return;
        }

        dispatch(isAudio 
            ? setAudioMuted(enable, true) 
            : setVideoMuted(enable, mediaType, VIDEO_MUTISM_AUTHORITY.USER, true));
    };
}

/**
 * 주어진 ID로 원격 참가자를 음소거합니다.
 */
export function muteRemote(participantId, mediaType, muted) {
    return (dispatch) => {
        if (mediaType !== MEDIA_TYPE.AUDIO && mediaType !== MEDIA_TYPE.VIDEO) {
            console.error(`Unsupported media type: ${mediaType}`);

            return;
        }
        
        if (mediaType === MEDIA_TYPE.VIDEO) {
            APP.mateManagement.setMemberCamera(participantId, muted);
        } else if (mediaType === MEDIA_TYPE.AUDIO) {
            APP.mateManagement.setMemberMic(participantId, muted);
        }
    };
}

/**
 * 전체 참가자 음소거
 */
export function muteAllParticipants(exclude, mediaType, muted) {
    return (dispatch, getState) => {
        const state = getState();
        const localId = getLocalIDMember(state).user_uuid;

        if (!exclude.includes(localId)) {
            if (mediaType === MEDIA_TYPE.VIDEO) {
                APP.mateManagement.setMemberCamera("all", muted);
                // dispatch(setVideoMuted(muted));
            } else if (mediaType === MEDIA_TYPE.AUDIO) {
                APP.mateManagement.setMemberMic("all", muted);
                // dispatch(setAudioMuted(muted));
            }
        }
    };
}