export const SET_RECORDING_ENABLED = "SET_RECORDING_ENABLED";
export const SET_RECORD_CLASS = "SET_RECORD_CLASS";

/**
 * 임시 서버 녹화 시작 
 */
export const TEMP_SERVER_RECORD_START = 'TEMP_SERVER_RECORD_START';

/**
 * 임시 서버 녹화 중지 
 */
export const TEMP_SERVER_RECORD_STOP = 'TEMP_SERVER_RECORD_STOP';