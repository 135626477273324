import axios from "axios";

function objectToQueryString(obj) {    
    return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
}

export async function getVodChannel({ data, aToken, offset = 0, limit = 20 }) {
    const url = objectToQueryString(Object.assign({}, {...data}, {offset}, {limit}));

    const response = await axios({
        method: "GET",
        url: "/api/watch/v1/channels?" + url
    });

    return response;
}

export async function getVodList({ data, aToken, offset = 0, limit = 20 }) {
    const url = objectToQueryString(Object.assign({}, {...data}, {offset}, {limit}));
    const response = await axios({
        method: "GET",
        url: "/api/watch/v1/medias?" + url
    });

    return response;
}