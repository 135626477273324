import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { ClickButton } from "../../../base/buttons";
import { RightHeader } from "../../../base/right-menu";
import { getDisplayName } from "../../../base/settings";
import { getLocalIDMember } from "../../../member";
import { addMessage, sendMessage, setPrivateMessageRecipient, toggleChat } from "../../actions";
import { OK_FILE } from "../../constants";
import { getDownLoadLink, isTransferableFile } from "../../functions";
import ChatBodyContainer from "./ChatBodyContainer";
import ChatFooterContainer from "./ChatFooterContainer";
import ChatPopup from "./ChatPopup";

export default function ChatLayout({
    handlerOpen
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const local_uuid = useSelector(state => getLocalIDMember(state));
    const displayName = useSelector(state => getDisplayName(state));

    const [ popup, setPopup ] = useState(false);
    const [ file, setFile ] = useState();

    const handlerClose = () => {
        dispatch(toggleChat(false));
    }

    const handlerFileMessage = (files, recipient) => {
        const file = files[0];
        const result = isTransferableFile(file);

        setFile({ file, result, recipient });
    
        setPopup(true);
    }

    const handlerSendFile = data => {
        dispatch(sendMessage(data));
    }

    const renderSendFileButton = () => {
        const handlerValidSendFile = async () => {
            setPopup(false);
            dispatch(setPrivateMessageRecipient(file.recipient));

            const { name, size } = file.file;
            let formData = new FormData();
            formData.append("file", file.file, name);

            let sendData = {
                type: "file",
                content: {name, size, downloadLink: null},
                uuid: uuidv4(),
                recipient: file.recipient,
                displayName
            };

            // 미리 전송 
            dispatch(addMessage({
                isMe: true,
                hasRead: true, 
                from: local_uuid,
                message: JSON.stringify(sendData),
                privateMessage: false,
                timestamp: new Date().getTime()
            }));
            handlerSendFile(sendData);

            const downloadLink = await getDownLoadLink(formData);            
            if (downloadLink) {
                sendData.content.downloadLink = downloadLink;
                
                handlerSendFile(sendData);
            }
        }
        if ( file.result === OK_FILE ) {
            return (
                <ClickButton className="text-button pointer" 
                    text={t('chat.send')} size={20} onClick={handlerValidSendFile} />
            )
        }        
    }

    return (
        <>
            <RightHeader name="chat" open={open}
                handlerOpen={handlerOpen} handlerClose={handlerClose} />

            <ChatBodyContainer />
            <ChatFooterContainer displayName={displayName}
                 handlerFileMessage={handlerFileMessage} />

            { popup && <>
                <div style={{position: "absolute", top: "0", left: "0", background: "rgba(0, 0, 0, 0.5)",  width: "100%", height: "100%", zIndex: "2"}} />
                <ChatPopup t={t} file={file} 
                    renderSendFileButton={renderSendFileButton} 
                    handlerClose={() => setPopup(false)} />    
            </> }
        </>
    )
}