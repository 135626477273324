import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "../../base/buttons";
import { translate } from "../../base/i18n";
import { GROUP_ID, NICKNAME, PARTICIPANT_ALL_COUNT, PARTICIPANT_BELONG_COUNT, ROLE_NAME, SELECT, STATUS } from "../constants";
import { GroupDropDown } from "./ChangeGroup";
import { GroupTabelStyled } from "./styles";
import { ROLE } from "../../member";
import { getGroupList } from "../functions";

function TableHead({ t, sort, sortType , config, handlerSortType }) {
    return (        
        <div className="group_tr">
            { config.map(c => {
                const title = c.name;
                return (
                    <div key={title} className="group_th" style={{ width: `${100 / config.length - 1}%` }}
                        onClick={c.sort ? () => handlerSortType(title) : null}
                    >
                        <div className={`over ${c.sort && "hover"}`}> 
                            <p>{t(`group.${title}`)}</p>
                            { c.sort && <IconButton mode={sortType === title && sort ? 'desc' : 'asc'} size={20} /> }
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

function GroupTable({
    t, filter, tableConfig, contentList, handler, children, heigtAuto,
    handlerGroupChange, handlerGroupRoleChange, alertMessage
}) {
    const dispatch = useDispatch();
    const test = useRef();
    const groupList = useSelector(state => getGroupList(state));
    const [ sort, setSort ] = useState(false);
    const [ sortType, setSortType ] = useState('nickname');
    const [ list, setList ] = useState(new Array());
    const [ itemList, setItemList ] = useState(new Array());
    const [ showCount, setShowCount ] = useState(20);

    useEffect(() => {
        if (contentList) {
            let list = contentList.filter(item => ((filter && item.nickname.indexOf(filter) !== -1) || !filter) ? true : false).sort((a, b) => {
                const upperCaseA = typeof a[sortType] === 'string' ? a[sortType].toUpperCase() : "";
                const upperCaseB = typeof b[sortType] === 'string' ? b[sortType].toUpperCase() : "";
                
                if(upperCaseA > upperCaseB) return sort ? -1 : 1;
                if(upperCaseA < upperCaseB) return sort ? 1 : -1;
                if(upperCaseA === upperCaseB) return 0;
            }).sort((a, b) => a.status === b.status ? 0 : a.status ? -1 : 1);

            setList(list);
        }
    }, [sort, sortType, filter, contentList]);

    useEffect(() => {
        setItemList(list.slice(0, showCount));
    }, [list, showCount]);

    const handlerSortType = (type) => {
        if (sortType === type) {
            setSort(!sort);
            return;
        } else {
            setSortType(type);
            setSort(true);
        }
    }

    /**
     * 그룹 변경 
     * @param {*} item 
     * @param {*} newGroup 
     * @param {*} e 
     */
    const onGroupChange = async (item, newGroup, e) => {
        const curRole = !item || item.role_name === "" ? ROLE.PARTICIPANT : item.role_name;
        let data;
        if (newGroup !== "") data = { members: [{ user_id: item.user_id, role_name: ROLE.PARTICIPANT }], method: "reset" };
        else {
            newGroup = item.group_id;
            data = { members: [{ user_id: item.user_id, role_name: item.role_name }], method: "unset" };
        }

        const { complete, message } = await APP.API.assignGroup(data, newGroup);
        if (!complete) {
            alertMessage && alertMessage(t(message));
            e.target.value = item.group_id;

        }
    }

    const onGroupRoleChange = async (item, newRole, e) => {
        const data = { members: [ {user_id: item.user_id, role_name: newRole} ], method: "reset" };
        const { complete, message } = await APP.API.assignGroup(data, item.group_id);

        if (!complete) {
            alertMessage && alertMessage(t(message));

            e.target.value = item.role_name;
        }
    }

    const wordProcess = (item, name, config) => {
        switch (name) {
            case SELECT:
                return (
                    <span> {handler.handlerChecked(item.user_id) ? "선택" : "비선택"} </span>
                )
            case STATUS:
                return <p>{item[name] ? "O" : "X"}</p>
                
            case ROLE_NAME:
                const role = item[name] ? t(`role.${item[name]}`) : "선택 안함";
                return (
                    <>
                        { config.change ? <GroupDropDown t={t} type={ROLE_NAME} item={item} alertMessage={alertMessage} handler={handlerGroupRoleChange || onGroupRoleChange} /> :
                            <div className="over"> 
                                <p className="overText">{role}</p>
                            </div>                        
                        }
                    </>
                );                    

            case GROUP_ID:
                const group = groupList.find(groupItem => groupItem.uuid === item.group_id);

                return (
                    <>
                        { config.change ? <GroupDropDown t={t} type={GROUP_ID} item={item} alertMessage={alertMessage}
                            handler={handlerGroupChange || onGroupChange} /> :
                            <div className="over"> 
                                <p className="overText">{group?.name || "선택 안함"}</p>
                            </div>                        
                        }
                    </>
                ); 
            case PARTICIPANT_ALL_COUNT:
            case PARTICIPANT_BELONG_COUNT:
                return <p>{ t("common.person", { count: item[name] }) }</p>

            case NICKNAME:
                return <p className="overText">{item[name] || "user_name"}</p>

            default:
                return <p className="overText">{item[name] || "-"}</p>
        }
    }

    const fetchMoreData = () => {
        setTimeout(() => {
            setShowCount(Math.min(showCount + 20, contentList.length));
        }, 500);
    };

    return (
        <>
            <GroupTabelStyled style={{ border: "none", maxHeight: "100%", height: `${heigtAuto ? "auto" : "100%"}` }}>
                { children }
                <div className="group_table">
                    <TableHead t={t} config={tableConfig} sort={sort} sortType={sortType} handlerSortType={handlerSortType} />
                    { list.length < 1 ?
                        <div className="group_tr">
                            <div className="no_group" colSpan={tableConfig.length}>{ t(`group.noSelectParticipant`) }</div>
                        </div>
                        : <div id="scrollableDiv" className="group_scroll" ref={test}>
                            <InfiniteScroll dataLength={showCount} next={fetchMoreData} scrollableTarget="scrollableDiv"
                                hasMore={list.length > showCount ? true : false} loader={<h4 className="no_group">Loading...</h4>}>
                                    { itemList.map((item, index) => {
                                        return (
                                            <div key={index} className={`group_tr ${handler?.hover && 'hover'}`} onClick={handler?.onClick ? () => handler.onClick(item) : null}>
                                                { tableConfig.map(c => {
                                                    const title  = c.name;
                                                    return (
                                                        <div key={`${title}_${index}`} className="group_td" style={{ width: `${100 / tableConfig.length}%` }}>
                                                            { wordProcess(item, title, c) }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    }) }    
                            </InfiniteScroll>
                        </div>
                    } 
                </div>                
            </GroupTabelStyled>
        </>
    )
}

export default translate(GroupTable);