import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ROLE_NAME } from "../constants";
import { getGroupList } from "../functions";
import { ROLE, ROLE_LEVEL } from "../../member";

export const GroupDropDown = React.memo(({ t, type, item, handler, alertMessage }) => {
    return (
        <ChangeRoleStyled className="group_drop">
            { type === ROLE_NAME ? 
                <ChangeRole t={t} item={item} alertMessage={alertMessage} handlerGroupRoleChange={handler} /> : 
                <ChangeGroup t={t} item={item} alertMessage={alertMessage} handlerGroupChange={handler} /> }
        </ChangeRoleStyled>
    )
});

const ChangeRole = React.memo(({ 
    t, item, handlerGroupRoleChange, alertMessage
}) => {
    const element = useRef();
    const [ role, setRole ] = useState(null);

    useEffect(() => {
        let nRole = item.role_name;
        if (!nRole || nRole === "") nRole = ROLE.PARTICIPANT;

        setRole(nRole);

        if (element && element.current) element.current.value = nRole;
    }, [item.role_name]);

    const onChange = async e => {
        if (e.target.value === ROLE.ADMIN) {
            alertMessage && alertMessage("관리자 권한은 할당 할 수 없습니다.");
            e.target.value = role;
            return;
        } else if (e.target.value === ROLE.PRESENTER) {
            alertMessage && alertMessage("발표자 권한은 분임 강의실에서 사용 가능합니다.");
            e.target.value = role;
            return;
        }
        const newRole = e.target.value;
        handlerGroupRoleChange(item, newRole, e);
    }

    return (
        <>
            { item.group_id ? 
                <select ref={element} key={role} defaultValue={role} onChange={onChange}>
                    { ROLE_LEVEL.map(r => {
                        return (
                            <option key={r} value={r} hidden={r === ROLE.ADMIN ? "hidden" : ""}>{t(`role.${r}`)}</option>
                        )
                    }) }
                </select> : <span> 선택 안함 </span>
            }   
        </>
    );
});

const ChangeGroup = React.memo(({
    item, handlerGroupChange
}) => {
    const element = useRef();
    const groupList = useSelector(state => getGroupList(state));
    const [ group, setGroup ] = useState(null);

    useEffect(() => {
        let nGroup = item.group_id;

        setGroup(nGroup);

        if (element && element.current) element.current.value = nGroup;
    }, [item.group_id]);

    const onChange = e => {
        // change group
        let newGroup = e.target.value;
        
        handlerGroupChange(item, newGroup, e);
    }

    return (
        <>
            { groupList.length < 1 ? <span> 선택 안함 </span>
                : <select ref={element} key={group} defaultValue={group} onChange={onChange}>
                    <option value={""}>선택 안함</option>
                    { groupList.map(g => {
                        return (
                            <option key={g.uuid} value={g.uuid}>{g.name}</option>
                        )
                    })}
                </select>
            }
        </>
    )
});

const ChangeRoleStyled = styled.div`
    .group_drop {
        position: relative;
        width: 150px;
        height: 35px;
        border-radius: 4px;
        border: 2px solid lightcoral;

        select {
            
            width: inherit;
            height: inherit;
            background: transparent;
            border: 0 none;
            outline: 0 none;
            padding: 0 5px;
            position: relative;
            z-index: 3; // select가 위로 올라와야 함

            option {
                background: lightcoral;
                color: #fff;
                padding: 3px 0;
                font-size: 16px;
            }
        }

        .icoArrow {
            position: absolute; 
            top: 0; 
            right: 0; 
            z-index: 1; 
            width: 35px; 
            height: inherit;
            border-left: 2px solid lightcoral;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 50%;
                transition: .3s; // 부드럽게 회전
            }

            &:focus + .icoArrow img {
                transform: rotate(180deg);
            }
        }
    }
`

