const type = 'mate';

import { conferenceLeft } from "../../features/base/conference";
import MateConference from "./MateConference";
import MateConnection from './MateConnection';
import MateEventManager from './MateEventManager';
import MateTrack from "./MateTrackEvents";

export default function Mate(groupId) {
    const connection = new MateConnection(this);
    const conference = new MateConference(this);

    this.groupId = groupId;
    this.version = '2.1';
    this.type = "mate";
    this.websocket = null;
    this.joined = false;

    this.connection = connection;
    this.conference = conference;
    this.track = new MateTrack(); 
    this.events = new MateEventManager(this);
} 

Mate.prototype.constructor = Mate;

Mate.prototype.setWebsocket = function(websocket) {
    this.websocket = websocket;
} 

Mate.prototype.init = function() {
    this.events.init();
    this.conference.init();
}

Mate.prototype.leave = function() {        
    return this.events.leave();    
}
