import React, { useEffect, useState } from "react";
import { getGroupById } from "../functions";
import { useSelector } from "react-redux";
import { FocusButtonStyled, GroupChoiceStyled } from "./styles";
import GroupSearchBar from "./GroupSearchBar";
import { G_FOCUS_ITEM, addParticipantGroup } from "../constants";
import { ROLE, getHostMembers, getMemberList } from "../../member";
import GroupTable from "./GroupTable";
import { IconButton } from "../../base/buttons";

const add = 'add';
const remove = 'remove';
const init = 'maintain';

export default function AddGroupMember({
    t, focusItem, setShow
}) {
    const group = useSelector(state => getGroupById(state, focusItem));
    const members = useSelector(state => getMemberList(state, { excludeIds: [...getHostMembers(state)] }, false, true, true));

    const [ groupUser, setGroupUser ] = useState(new Array());
    const [ filter, setFilter ] = useState();

    useEffect(() => {
        let data = new Array();
        
        members.map(user => {
            console.log(user)
            user?.group_id === focusItem && data.push({  ...user, choice: init });
        });

        data.length > 0 && setGroupUser([...data]);
    }, []);

    const onClick = async () => {
        let unset = new Array();
        let assign = new Array();

        groupUser.map(user => {
            // 할당 해제 
            if (user.choice === remove) unset.push({ user_id: user.user_id, role_name: user.role_name });
            // 할당
            else if (user.choice === add) assign.push({ user_id: user.user_id, role_name: ROLE.PARTICIPANT });
        });

        if (assign.length > 0) await APP.API.assignGroup({ members: assign, method: "reset" }, focusItem);
        if (unset.length > 0) await APP.API.assignGroup({ members: unset, method: "unset" }, focusItem);
        
        setShow(G_FOCUS_ITEM);
    }

    function handlerSetGroupUser(data) {
        const userIndex = groupUser.findIndex(user => user.user_id === data.user_id);
        
        if (userIndex !== -1) {
            const choice = groupUser[userIndex]?.choice !== remove ? remove : add;
            groupUser.splice(userIndex, 1, { ...data, choice });
            setGroupUser([...groupUser]);
        } else {
            setGroupUser([...groupUser, { ...data, choice: add }]);
        }
    }

    const handlerChecked = user_id => {
        const user = groupUser.find(user => user.user_id === user_id);
        
        return user && user.choice !== remove ? true : false;
    }

    return (
        <>
            <GroupChoiceStyled>
                { groupUser.filter(g => g.choice !== remove).length < 1 ? <div>{ t("group.noSelectParticipant") }</div> : 
                    <>
                        { groupUser.map(item => {
                            if (item && item.choice === remove) return null;
                           
                            return (
                                <IconButton key={item.user_id} mode="close" 
                                    name={item.nickname || "user_name"} size={16} 
                                    onClick={() => handlerSetGroupUser(item)}  />
                            );
                        }) } 
                    </> 
                }
            </GroupChoiceStyled>

            <GroupTable filter={filter} tableConfig={addParticipantGroup} contentList={members} handler={{
                hover: true,
                onClick: (user) => handlerSetGroupUser(user),
                handlerChecked: (user_id) => handlerChecked(user_id)
            }}>
                <GroupSearchBar setFilter={setFilter} />
            </GroupTable>

            <FocusButtonStyled className="button">
                <IconButton clsName="user_btn" mode={"previous"} name={t("common.previous")} onClick={() => setShow(G_FOCUS_ITEM)} />
                <IconButton clsName="user_btn" mode={"save"} name={t("common.add")} onClick={onClick} />
            </FocusButtonStyled> 
        </>
    );
}