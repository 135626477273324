/**
 * 유효성 검사 
 * @param {string} name 
 * @param {string} value 
 * @returns 
 */
export function formValidation(name, value) {
    let message = "";

    if (value === "") message = "필수 입력 입니다.";
    
    return message;
}
