import React from "react";
import { useSelector } from "react-redux";
import { DocumentTool } from "../../../share-document";
import DocumentLeft from "../../../share-document/components/DocumentLeft";
import { getGroupId, getRoomOption } from "../../conference";
import { translate } from "../../i18n";

function CommonNoteLayout({
    t, mobile, expand, isLeft, setIsLeft, style
}){
    const roomCode = useSelector(state => getRoomOption(state)?.code);
    const groupId = useSelector(state => getGroupId(state));

    return (
        <div className={`addition_layout share_document_layout left_${expand}`} >
            <DocumentLeft mobile={mobile} expand={expand}
                isLeft={isLeft}
                name={t("layout.common_note")}>
            </DocumentLeft>

            <DocumentTool isLeft={isLeft} setIsLeft={setIsLeft} />
            
            <div style={{flex: "1", position: "relative", background: "#f7f7f7"}} >
                <iframe src={`/ep/p/${roomCode}_${groupId}`} style={style}/>               
            </div>
        </div>
    );
}

export default translate(CommonNoteLayout);