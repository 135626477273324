export function getSharedSurvey(state) {
    let sortList = new Map();
    const list = state['features/survey']?.sharedSurvey;
    sortList = new Map([...list.entries()].sort((a, b) => a[1].created - b[1].created));

    return sortList ? [...sortList] : [];
}

export function isExistSurvey(state, uuid) {
    const list = state['features/survey']?.sharedSurvey;

    return list.has(uuid);
}