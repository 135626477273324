import React from "react";
import { CREATE_ATTENDANCES_MODAL_ID } from "../../constants";
import { translate } from "../../../base/i18n";
import { connect } from "react-redux";
import { ClickButton } from "../../../base/buttons";
import { getGroupId } from "../../../base/conference";
import { ROLE } from "../../../member";

function CreateAttendancesButton({ t, role, isShow }) {
    if (isShow === false) return null;
    const onClick = () => {
        APP.UI.openModal(CREATE_ATTENDANCES_MODAL_ID);
    };

    return (
        <>
            { role === ROLE.HOST && <ClickButton clsName="attendances" size={6} text={t("attendances.check")} onClick={onClick} /> }
        </>
    )
}

function _mapStateToProps(state) {
    const { role } = state['features/base/localuser'];
    const currentGropuId = getGroupId(state);

    return {
        role,
        isShow: currentGropuId === "" ? true : false
    }    
}

export default translate(connect(_mapStateToProps)(CreateAttendancesButton));