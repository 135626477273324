/* @flow */

import { SHOW_NOTIFICATION } from '.';
import { getCurrentConference } from '../base/conference';
import { MiddlewareRegistry, StateListenerRegistry } from '../base/redux';

import {
    clearNotifications,
    hideNotification,
    removeAttendancesNotification
} from './actions';

var interfaceConfig = {};
let sleepTime = [];
let requestPresentation;
MiddlewareRegistry.register(({ dispatch, getState }) => next => action => {
    switch (action.type) {
        // case PARTICIPANT_JOINED: {
        //     const { participant: p } = action;
        
        //     var currentGroupId = getGroupId(getState());
        //     if (p && !p.local && p.status && p.status === PARTICIPANT_STATUS.OCCUPIDE && currentGroupId === action.group_id) {
        //         const nickname = p.nickname;
        //         dispatch(showParticipantJoinedNotification(nickname, p.existed));
        //     }
        //     break;
        // }

        // case PARTICIPANT_LEFT: {
        //     const participant = action.participant;

        //     var currentGroupId = getGroupId(getState());
        //     if ( typeof interfaceConfig === 'object' && participant && !participant.local && currentGroupId === action.group_id ) {
        //         dispatch(showNotification({ titleKey: 'notify.disconnected', titleArguments: {name: participant.nickname} }, NOTIFICATION_TIMEOUT));
        //     }

        //     break;
        // }

        // case PARTICIPANT_UPDATED: {
        //     const {id, role} = action.participant;
        //     const oldParticipant = getParticipantById(getState(), id);
        //     const oldRole = oldParticipant?.role;

        //     if (oldRole && oldRole !== role && role === ROLE.MODERATOR) {
        //         const displayName = getParticipantDisplayName(getState(), id);

        //         dispatch(
        //             showNotification(
        //                 {
        //                     titleKey: 'notify.grantedTo',
        //                     titleArguments: {
        //                         to: displayName || '$t(notify.somebody)',
        //                     },
        //                 },
        //                 NOTIFICATION_TIMEOUT,
        //             ),
        //         );
        //     }

        //     return next(action);
        // }
        case SHOW_NOTIFICATION:
            const uid = action.uid;

            if (action.props.isRequestPresent) {
                if (action.props?.isMe) {
                    requestPresentation && dispatch(hideNotification(requestPresentation));
                    requestPresentation = uid;
                }
                return next(action);
            }
            if (action.props.isAttendances) {
                dispatch(removeAttendancesNotification());
            }

            break;
    }

    return next(action);
});

StateListenerRegistry.register(
    state => getCurrentConference(state),
    (conference, {dispatch}) => {
        if (!conference) {
            dispatch(clearNotifications());
        }
    },
);
