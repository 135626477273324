import i18next from 'i18next';
import $ from 'jquery';
import React from 'react';
import * as ReactDOMClient from "react-dom/client";
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { compose, createStore } from 'redux';
import Thunk from 'redux-thunk';
import Mate from './App';
import { MiddlewareRegistry, ReducerRegistry, StateListenerRegistry } from './features/base/redux';
import { setConfig } from './features/config/test';
import { default as mateManagement } from './mateManagement';
import './middlewares';
import { API } from './modules/API';
import { UI } from './modules/UI';
import './reducers';

const resolveURL = require('resolve-url');
const { MateOptions: option } = require('/libs/' + setConfig() + 'Config');


const window = global;

const store = _createStore();
function _createStore() {
    const reducer = ReducerRegistry.combineReducers();
    let middleware = MiddlewareRegistry.applyMiddleware(Thunk);
    let devToolsExtension;
    if (
        typeof window === 'object' &&
        (devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__)
    ) {
        middleware = compose(middleware, devToolsExtension());
    }
    const store = createStore(reducer, middleware);
    StateListenerRegistry.subscribe(store);

    return store;
}



window.$ = $;
window.APP = {
    API,
    UI,
    mateManagement,
    mateOption: option,
    store,
    layout: option.ui.layout,
    function: option.ui.function,
    canvasList: new Map()
};

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

root.render(
    <I18nextProvider i18n={i18next}>
        <Provider store={store}>
            <Mate />
        </Provider>
    </I18nextProvider>
);
