import { equals } from '../redux';
import {
    getUserSelectedOutputDeviceId,
    updateSettings
} from '../settings';

import {
    ADD_PENDING_DEVICE_REQUEST,
    CHECK_AND_NOTIFY_FOR_NEW_DEVICE,
    DEVICE_PERMISSIONS_CHANGED,
    NOTIFY_CAMERA_ERROR,
    NOTIFY_MIC_ERROR,
    REMOVE_PENDING_DEVICE_REQUESTS,
    SET_AUDIO_INPUT_DEVICE,
    SET_VIDEO_INPUT_DEVICE,
    UPDATE_DEVICE_LIST
} from './actionTypes';
import {
    areDeviceLabelsInitialized,
    getDeviceIdByLabel,
    getDeviceLabelById,
    setAudioOutputDeviceId
} from './functions';

// device list get 
export function updateDeviceList(devices) {
    return {
        type: UPDATE_DEVICE_LIST,
        devices
    };
}

export function addPendingDeviceRequest(request) {
    return {
        type: ADD_PENDING_DEVICE_REQUEST,
        request
    };
}

export function notifyCameraError(error) {
    return {
        type: NOTIFY_CAMERA_ERROR,
        error
    };
}

export function notifyMicError(error) {
    return {
        type: NOTIFY_MIC_ERROR,
        error
    };
}

export function removePendingDeviceRequests() {
    return {
        type: REMOVE_PENDING_DEVICE_REQUESTS
    };
}

export function setAudioInputDevice(deviceId) {
    return {
        type: SET_AUDIO_INPUT_DEVICE,
        deviceId
    };
}

export function devicePermissionsChanged(permissions) {
    return (dispatch, getState) => {
        if (!equals(getState()['features/base/devices'].permissions, permissions)) {
            dispatch(_setDevicePermission(permissions));
        }
    }
}

function _setDevicePermission(permissions) {
    return {
        type: DEVICE_PERMISSIONS_CHANGED,
        permissions
    };
}

export function setAudioInputDeviceAndUpdateSettings(deviceId) {
    return function(dispatch, getState) {
        const deviceLabel = getDeviceLabelById(getState(), deviceId, 'audioInput');

        dispatch(setAudioInputDevice(deviceId));
        dispatch(updateSettings({
            userSelectedMicDeviceId: deviceId,
            userSelectedMicDeviceLabel: deviceLabel
        }));
    };
}

export function setAudioOutputDevice(deviceId) {
    return function(dispatch, getState) {
        const deviceLabel = getDeviceLabelById(getState(), deviceId, 'audioOutput');

        return setAudioOutputDeviceId(deviceId, dispatch, true, deviceLabel);
    };
}

export function setVideoInputDevice(deviceId) {
    return {
        type: SET_VIDEO_INPUT_DEVICE,
        deviceId
    };
}

export function setVideoInputDeviceAndUpdateSettings(deviceId) {
    return function(dispatch, getState) {
        const deviceLabel = getDeviceLabelById(getState(), deviceId, 'videoInput');

        dispatch(setVideoInputDevice(deviceId));
        dispatch(updateSettings({
            userSelectedCameraDeviceId: deviceId,
            userSelectedCameraDeviceLabel: deviceLabel
        }));        
    };
} 

export function checkAndNotifyForNewDevice(newDevices, oldDevices) {
    
    return {
        type: CHECK_AND_NOTIFY_FOR_NEW_DEVICE,
        newDevices,
        oldDevices
    };
}


/**
 * 회의가 시작되기 전에 초기 A/V 장치를 구성합니다.
 * @returns {Function}
 */
export function configureInitialDevices() {
    return async (dispatch: IStore['dispatch'], getState: IStore['getState']) => {
        const deviceLabels = {};
        let updateSettingsPromise;

        if (deviceLabels) {
            updateSettingsPromise = dispatch(getAvailableDevices()).then(() => {
                const state = getState();

                if (!areDeviceLabelsInitialized(state)) {
                    Object.keys(deviceLabels).forEach(key => {
                        dispatch(addPendingDeviceRequest({
                            type: 'devices',
                            name: 'setDevice',
                            device: {
                                kind: key.toLowerCase(),
                                label: deviceLabels[key]
                            },
                            // eslint-disable-next-line no-empty-function
                            responseCallback() {}
                        }));
                    });
                } else {
                    const newSettings = {};

                    Object.keys(deviceLabels).forEach(key => {
                        const label = deviceLabels[key];
                        const deviceId = getDeviceIdByLabel(state, label, key);

                        if (deviceId) {
                            const settingsTranslationMap = DEVICE_TYPE_TO_SETTINGS_KEYS[key];

                            newSettings[settingsTranslationMap.currentDeviceId] = deviceId;
                            newSettings[settingsTranslationMap.userSelectedDeviceId] = deviceId;
                            newSettings[settingsTranslationMap.userSelectedDeviceLabel] = label;
                        }
                    });

                    dispatch(updateSettings(newSettings));
                }                
            });
        } else {
            updateSettingsPromise = Promise.resolve();
        }

        return updateSettingsPromise
            .then(() => {
                const userSelectedAudioOutputDeviceId = getUserSelectedOutputDeviceId(getState());

                return setAudioOutputDeviceId(userSelectedAudioOutputDeviceId, dispatch)
                    .catch(ex => console.warn(`Failed to set audio output device. Default audio output device will be used instead ${ex}`));
            });
    };
}


/**
 * 연결된 A/V 입력 및 출력 장치를 쿼리하고 알려진 장치의 redux 상태를 업데이트합니다.
 *
 * @returns {Function}
 */
export function getAvailableDevices() {
    return async (dispatch: IStore['dispatch']) => 
        new Promise(resolve => {
            const { mediaDevices } = JitsiMeetJS;

            if (mediaDevices.isDeviceListAvailable()
                    && mediaDevices.isDeviceChangeAvailable()) {
                mediaDevices.enumerateDevices(devices => {
                    dispatch(updateDeviceList(devices));

                    resolve(devices);
                });
            } else {
                resolve([]);
            }
        });
}
