import { getConferenceState, getGroupId } from "../base/conference";
import { toState } from "../base/redux";
import { getStartGroup } from "../groups";
import { PARTICIPANT_STATUS, getLocalIDMember, getMemberList, getPinnedMember, getPinnedMemberCount, getVoiceMember } from "../member";
import { getLocalSharingStatus, getScreenSharingId, getScreenUserID } from "../screen-share";
import { HORIZONTAL_MODE, SHARE_MODE_LIST, SHARE_PERMISSION_MODE, videoLayoutMode } from "./constant";

/**
 * 현재 모드 가져오기
 * @param {Function} statful 
 * @param {Object|undefined} data 
 * @returns 
 */
export function getCurrentMode(statful, data) {
    const state = toState(statful);

    const mode = data ? data.mode : state['features/video-layout'].mode;
    const viewVisible = data ? data.viewVisible : state['features/video-layout'].viewVisible;

    let current = mode;
    const viewMode = Object.entries(viewVisible).find(([name, value])=> {
        if (value) return name;
    });

    if (viewMode && viewMode[0]) current = viewMode[0];

    return current;
}

/**
 * 현재 그리드 모드에 표시 되는 숫자
 * @param {Function} stateful 
 * @returns 
 */
export function getGridCount(stateful) {
    const state = toState(stateful);

    return state['features/video-layout'].gridCount;
}

/**
 * 모드에 표시 되는 숫자
 * @param {Function} stateful 
 * @returns 
 */
export function getShowCount(stateful) {
    const state = toState(stateful);

    return state['features/video-layout'].showCount;
}

/**
 * 모드에 표시 되는 숫자
 * @param {Function} stateful 
 * @returns 
 */
export function getPage(stateful) {
    const state = toState(stateful);

    const { page, maxPage } =state['features/video-layout'];
    return { page, maxPage };
}

/**
 * 듀얼 모니터 
 * @param {Function} stateful 
 * @returns 
 */
export function getDualMonitor(stateful) {
    const state = toState(stateful);

    return state['features/video-layout'].isDualMonitor;
}


/**
 * 현재 그리드 모드에 표시 되는 숫자
 * @param {Function} stateful 
 * @returns 
 */
export function getShowMembers(stateful) {
    const state = toState(stateful);

    return state['features/video-layout'].showMembers;
}

/**
 * 현재 그리드 모드에 표시 되는 숫자
 * @param {Function} stateful 
 * @returns 
 */
export function getMembers(stateful, isNotCount = false) {
    const state = toState(stateful);

    let { option, showCount: count, page } = state['features/video-layout'];

    let members = [];
    let maxPage = 1; 

    const mode = getCurrentMode(stateful);
    if (getIsMobile(stateful)) {
        const { pinPage, voicePage } = getPlusPage(stateful);   
        
        switch (mode) {
            case videoLayoutMode.pin:
                if (page <= pinPage) {
                    members = getPinnedLayoutMembers(state, mode);
                }
                break;

            case videoLayoutMode.voice:
                if (voicePage !== 0 && page <= voicePage) {
                    members = [ getVoiceMember(state) ];
                } else if (pinPage !== 0 && page - voicePage <= pinPage) {
                    members = getPinnedLayoutMembers(state, mode);
                    page = page - voicePage;
                }
                break;

            case videoLayoutMode.seminar:
                members = getPinnedLayoutMembers(state, mode);

                break;
        }
        
        if (count === 8) {            
            members = getMemberList(stateful, option);    
            page = page - pinPage - voicePage;
        }

        if (videoLayoutMode.seminar === mode) {
            maxPage = 1;
        } else {
            maxPage = Math.ceil(members.length / 8) + pinPage + voicePage;
        }
    } else {
        if (videoLayoutMode.screen === mode && (APP.layout.screen && !getLocalSharingStatus(state) && !getDualMonitor(state))) {
            maxPage = 1;
        } else {
            members = getMemberList(stateful, option);
            
            maxPage = Math.ceil(members.length / count);
        }
    }
    
    let showMembers = [];
    if (isNotCount) {
        return showMembers;
    } else {
        const start = count * (page - 1);
        const end = count * page;
        
        showMembers = members.slice(start, end);
    }
    

    return { members: showMembers, maxPage };
}

/**
 * 현재 레이아웃 스타일 크기 가져오기
 * @param {Function} stateful 
 * @returns 
 */
export function getLayoutWidthAndHeight(stateful) {
    const state = toState(stateful);

    return {
        width: state['features/video-layout'].layoutWidth, 
        height: state['features/video-layout'].layoutHeight
    };
}

/**
 * 현재 레이아웃 스타일 크기 가져오기
 * @param {Function} stateful 
 * @returns 
 */
export function getIsMobile(stateful) {
    const state = toState(stateful);

    return state['features/video-layout'].isMobile;
}

/**
 * 핀 사용자 가져오기
 * @param {Function} stateful 
 * @param {string} mode
 * @returns 
 */
export function getPinnedLayoutMembers(stateful, mode, compulsion = false) {
    const state = toState(stateful);
    const option = state['features/video-layout'].option;
    const voiceMember = getVoiceLayoutMember(stateful);

    if (option.excludePin || compulsion) {
        let pin = getPinnedMember(state, voiceMember);
        if (mode === videoLayoutMode.seminar) {
            pin = [...pin].slice(0, 1);
        } else {
            pin = [...pin];
        }

        if ((!pin || pin.length < 1) && mode === videoLayoutMode.seminar) {
            return [getLocalIDMember(stateful)];
        }

        return pin;
    }

    return [];
}

/**
 * 보이스 사용자 가져오기
 * @param {Function} stateful 
 * @returns 
 */
export function getVoiceLayoutMember(stateful) {
    const state = toState(stateful);
    const option = state['features/video-layout'].option;

    if (option.excludeVoice) return getVoiceMember(state);
    return null;
}

/**
 * 사용자 크기 가져오기 
 * @param {Function} stateful 
 * @param {Number} memberCount 
 * @param {Object} style 
 * @param {Boolean} half 
 * @returns 
 */
export function getFilmStyle(stateful, memberCount, style, half) {
    const state = toState(stateful);
    const showCount = memberCount; // Math.min(state['features/video-layout'].showCount, memberCount);

    let { width, height } = style || getLayoutWidthAndHeight(stateful);
    if (!showCount || !width || !height) return { width: 0, height: 0 };

    height = half ? height / 2 : height;

    const columns = getGirdColumns(showCount);
    const rows = getGirdRows(showCount);
    
    let newWidth = width / columns;
    let newHeight = height / rows;

    let filmWidth = newWidth;
    let filmWHeight = (9 * filmWidth) / 16;

    if (Math.floor(newHeight / filmWHeight) < rows && newHeight < filmWHeight) {
        filmWHeight = newHeight;
        filmWidth = (16 * filmWHeight) / 9;
    }
    
    return { width: filmWidth, height: filmWHeight };
}

/**
 * 그리드 행 가져오기 
 * @param {Number} count 
 * @returns 
 */
function getGirdColumns(count) {
    const rows = Math.round(Math.sqrt(count));
    const alpha = count - rows * rows;

    const plus = alpha > 0 ? 1 : 0;
    const columns = rows + plus;

    return columns;
}

/**
 * 그리드 열 가져오기 
 * @param {Number} count 
 * @returns 
 */
function getGirdRows(count) {
    const rows = Math.round(Math.sqrt(count));
    return rows;
}


/**
 * 수평의 화면에서 사용자 화면 개수 가져오기
 * @param {Function} store 
 * @param {Number} width 
 * @param {String} mode 
 * @returns 
 */
function getHorizontalCount(state, width, mode) {
    let excludePin = true;
    let excludeVoice = false;
    let count = Math.ceil(width / 172);
    
    if (SHARE_MODE_LIST.includes(mode)) {
        if (!getExpand(state)) {
            count = 0;
        } else {
            const pinCount = getPinnedMemberCount(state);

            if (pinCount >= count) excludePin = false;
            else count = count - pinCount;
        }        
    } else if (videoLayoutMode.voice === mode) {
        excludeVoice = true;
    }

    return {
        count, excludePin, excludeVoice
    }    
}

/**
 * 수직 화면에서 사용자 개수 가져오기
 * @param {Function} store 
 * @param {Number} width 
 * @param {String} mode 
 * @returns 
 */
function getVerticalCount(state, height, mode) {
    let excludePin = true;
    let excludeVoice = false;
    let count = Math.ceil(height / 98);

    const pinCount = getPinnedMemberCount(state);

    if (pinCount > count) excludePin = false;
    else count = count - pinCount;

    return {
        count, excludePin, excludeVoice
    }
}

/**
 * 옵션 가져오기
 * @param {*} store 
 * @param {*} mode 
 * @param {*} layoutStyle 
 * @returns 
 */
export function getOption(state, mode, layoutStyle) {
    let count;
    let excludePin = false, excludeVoice = false;  
    let excludeIds = null;
    let groupIds = null;
    let excludeGroup = false;

    if (getIsMobile(state)) {
        // 모바일 인 경우 
        const { pinPage, voicePage } = getPlusPage(state);
        const { page } = getPage(state);
        
        count = 8;
        if (mode === videoLayoutMode.pin) {
            excludePin = true;    
            if (page <= pinPage) count = 4;
        } else if (mode === videoLayoutMode.voice) {
            excludePin = true;  
            excludeVoice = true;

            if (voicePage !== 0 && page <= voicePage) {
                count = 1;
            } else if (pinPage !== 0 && page - voicePage <= pinPage) {
                count = 4;
            }
        } else if (mode === videoLayoutMode.seminar) {
            excludePin = true; 
            count = 1;
        } else if (SHARE_PERMISSION_MODE.includes(mode)) { 
            count = 0;
        } 
    } else {
        // 듀얼 모니터 인 경우 
        const isDual = getDualMonitor(state);
        if (isDual) {
            count = getGridCount(state);

            if (videoLayoutMode.seminar === mode) {
                // 현재 핀 사용자 한명 제외 (이걸 어떻게 빼오지 ,,)
                excludeIds = getPinnedLayoutMembers(state, mode, true);
            } else if (videoLayoutMode.grid === mode) {
                // excludeIds 추가 
                const localID = getLocalIDMember(state);
                excludeIds = [localID];
            } else if (videoLayoutMode.pin === mode) {
                excludePin = true;
            } else if (videoLayoutMode.voice === mode) {
                excludePin = true;
                excludeVoice = true;
            } else if (videoLayoutMode.screen === mode) {
                excludePin = false;
                excludeVoice = false;
            }
        } else {
            // 웹 
            if (mode === videoLayoutMode.grid) {
                count = getGridCount(state);
            } else if (HORIZONTAL_MODE.includes(mode)) {                             
                const property = getHorizontalCount(state, layoutStyle.width, mode);
        
                count = property.count;
                excludePin = property.excludePin;
                excludeVoice = property.excludeVoice;
            } else if (videoLayoutMode.screen === mode) {
                if (APP.layout.screen && !getLocalSharingStatus(state)) {
                    count = 0;
                } else {
                    const property = getVerticalCount(state, layoutStyle.height, mode);
        
                    count = property.count;
                    excludePin = property.excludePin;
                    excludeVoice = property.excludeVoice;
                }                
            }
        }   
    }

    const currentGropuId = getGroupId(state);
    const startGroup = getStartGroup(state, true);
    if (currentGropuId) {
        // 그룹이 시작 
        if (startGroup && startGroup.has(currentGropuId)) {
            // 그룹에 속한 경우 : 그룹에 속한 사용자 
            groupIds = [currentGropuId];
            excludeGroup = false;
        }      
    } else {
        // 그룹에 속하지 않은 경우 : 그룹이 시작된 사용자 제외 
        if (startGroup && startGroup.size > 0) {
            groupIds = [...startGroup];
            excludeGroup = true;
        }   
    }    

    return { 
        count, 
        option: { 
            status: [PARTICIPANT_STATUS.OCCUPIDE],
            excludePin, excludeVoice, excludeIds, groupIds, excludeGroup
        }
    };
}

export function getPlusPage(stateful) {
    const state = toState(stateful);
    
    const option = state['features/video-layout'].option;
    const voice = getVoiceLayoutMember(stateful);
    const pinCount = getPinnedMemberCount(stateful, voice);
    

    let pinPage = 0;
    let voicePage = 0;
    if (option.excludePin) {        
        pinPage = pinCount > 0 ? Math.ceil(pinCount / 4) : 0;
    }
    if (option.excludeVoice) {
        if (voice) voicePage = 1;
    }

    return {pinPage, voicePage};
}

const VIDEO_QUALITY_LEVELS = {
    ULTRA: 2160,
    HIGH: 720,
    STANDARD: 360,
    LOW: 180,
    NONE: 0
};

export function getJitsiQuality(mode, members, pinMembers, voice, bridgeList, screen, excludeId) {
    const receiverConstraints = {
        lastN: -1,
        constraints: {},
        defaultConstraints: { 'maxHeight': VIDEO_QUALITY_LEVELS.NONE },
        // onStageEndpoints: [],
        // selectedEndpoints: []
    }; 

    let constraintsList = {
        [VIDEO_QUALITY_LEVELS.LOW]: [],
        [VIDEO_QUALITY_LEVELS.STANDARD]: [],
        [VIDEO_QUALITY_LEVELS.HIGH]: []
    };

    switch (mode) {
        case videoLayoutMode.grid:
            if (members.length < 10) constraintsList[VIDEO_QUALITY_LEVELS.HIGH] = members
            else if (members.length < 20) constraintsList[VIDEO_QUALITY_LEVELS.STANDARD] = members;
            else constraintsList[VIDEO_QUALITY_LEVELS.LOW] = members;
            break;

        case videoLayoutMode.pin:
        case videoLayoutMode.seminar:
            constraintsList[VIDEO_QUALITY_LEVELS.HIGH] = [...pinMembers];
            constraintsList[VIDEO_QUALITY_LEVELS.LOW] = members;
            break;

        case videoLayoutMode.voice:
            constraintsList[VIDEO_QUALITY_LEVELS.HIGH] = [...pinMembers, voice];        
            constraintsList[VIDEO_QUALITY_LEVELS.LOW] = members;        
            break;

        case videoLayoutMode.screen:
            if (screen?.track_id) receiverConstraints.constraints[screen.track_id] = { 'maxHeight' : VIDEO_QUALITY_LEVELS.HIGH };
            if (APP.layout.screen && !screen.isMe) {
                if (screen?.user_id) constraintsList[VIDEO_QUALITY_LEVELS.STANDARD] = [screen.user_id];
            } else {
                constraintsList[VIDEO_QUALITY_LEVELS.LOW] = [...pinMembers, ...members];
            }
            
            break;
            
        case videoLayoutMode.document:
        case videoLayoutMode.white:
        case videoLayoutMode.note:
        case videoLayoutMode.vod:
            constraintsList[VIDEO_QUALITY_LEVELS.LOW] = [...pinMembers, ...members];
            break;
    }

    Object.keys(constraintsList).forEach(quality => {
        if (constraintsList[quality] && constraintsList[quality].forEach) {
            constraintsList[quality].forEach(user_uuid => {
                // if (!user_uuid || user_uuid === excludeId) return;
                const jitsi_id = bridgeList.get(user_uuid);
                if (jitsi_id) {
                    receiverConstraints.constraints[jitsi_id] = { 'maxHeight' : Number(quality) };
    
                    // if (Number(quality) === VIDEO_QUALITY_LEVELS.HIGH) { 
                    //     receiverConstraints.onStageEndpoints.push(jitsi_id);
                    // }
                }
            });
        }        
    });

    return receiverConstraints;
}

/**
 * 공유 모드에서 화면이 펼쳐져있냐 안펼쳐져있냐 
 * @param {Function} stateful 
 * @returns 
 */
export function getExpand(stateful) {
    const state = toState(stateful);

    return state['features/video-layout'].expand;
}

/**
 * 오디오 트랙 아이디
 * @param {} stateful 
 * @returns 
 */
export function getRoomAudioTrack(stateful) {
    let groupIds = null;
    let excludeGroup = false;
    let data = { excludeLocal: true, status: [PARTICIPANT_STATUS.OCCUPIDE] };    
    const currentGropuId = getGroupId(stateful);
    const startGroup = getStartGroup(stateful, true);

    if (currentGropuId !== '') {
        // 그룹이 시작 
        if (startGroup && startGroup.has(currentGropuId)) {
            // 그룹에 속한 경우 : 그룹에 속한 사용자 
            groupIds = [currentGropuId];
            excludeGroup = false;
        }      
    } else {
        // 그룹에 속하지 않은 경우 : 그룹이 시작된 사용자 제외 
        if (startGroup && startGroup.size > 0) {
            groupIds = [...startGroup];
            excludeGroup = true;
        }   
    }    
    
    const member = getMemberList(stateful, { ...data, groupIds, excludeGroup }, false, false, true);


    return member.map(m => m.jitsi_id);
}