import React from "react";
import styled from "styled-components";

const FooterIconStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;

    .btnImg {
        width: ${props => props.width}px;
        height: ${props => props.height}px;

        background-position-y: ${props => props.checked ? '0px' : '-55px'};
        
        ${props => props.mode === 'video' && `background-position-x: -758px;`}
        ${props => props.mode === 'mike' && `background-position-x: -31px;`}
        ${props => props.mode === 'speaker' && `background-position-x: -702px;`}
        ${props => props.mode === 'group' && `background-position-x: -535px;`}
        ${props => props.mode === 'participant' && `background-position-x: -143px;`}
        ${props => props.mode === 'chat' && `background-position-x: -198px;`}
        ${props => props.mode === 'chat_waiting' && `background-position-x: -645px;`}
        ${props => props.mode === 'screen' && `background-position-x: -254px;`}
        ${props => props.mode === 'document' && `background-position-x: -311px;`}
        ${props => props.mode === 'option' && `background-position-x: -477px;`}
        ${props => props.mode === 'close' && `background-position-x: -591px;`}

    }
`;

export default function FooterIconButton({
    mode, name, width = 42, height = 43, checked, onClick
}) {
    return (
        <FooterIconStyled checked={checked} width={width} height={height} mode={mode}>
            <span >{ name }</span>
            <span className={`btnImg ${mode}`} onClick={onClick}></span>
        </FooterIconStyled>
    )
}