import React from "react";
import { connect } from "react-redux";
import { SURVEY_MODAL_ID } from "../../options";
import { translate } from "../../base/i18n";

import AbstractSwitchButton from "../../options/components/AbstractSwitchButton";
import { MdHowToVote } from "react-icons/md";
import styled from "styled-components";
import { IconButton } from "../../base/buttons";

function SwitchSurveyButton({
    t
}) {
    if (!APP.layout.option.survey) return null;

    const onClick = () => {
        APP.UI.openModal(SURVEY_MODAL_ID);
    }

    return (
        <IconButton mode="survey" 
            name={t("options.survey")} 
            size={19}
            onClick={onClick} /> 
    )
}

export default translate(SwitchSurveyButton);