import React from "react";
import { equals } from "../../base/redux";

export default class AbstractPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalID : null
        };

        this._setModalID = this._setModalID.bind(this);
        this._switchModal = this._switchModal.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(!equals(this.props, nextProps) || !equals(this.state, nextState)) return true;
        return false;
    }

    _setModalID(id) {
        this.setState({
            modalID: id
        });
    }
    
    _switchModal() {
        APP.UI.closeModal();    
    }

    _renderPopupHeader(t, title) {
        const switchModal = () => {
            this._switchModal();
        };

        return (
            <div className="pop_head">
                <span className="title nonSelect"> {t(title)} </span>
                <dl>
                    <span className="nonSelect" onClick={switchModal}>
                        {t("dialog.close")}
                    </span>
                </dl>
            </div>
        )
    }

    _renderPopupBody(children, style) {
        return (
            <div className={`pop_cotent ${this.state.modalID}`} style={style}>
                { children }
            </div>
        )
    }

    _renderPopupFooter(children) {
        return (
            <div className="pop_footer">
                { children }
            </div>
        )
    }
}