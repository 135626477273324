/* @flow */
import {
    LIB_DID_DISPOSE,
    LIB_DID_INIT,
    LIB_INIT_ERROR,
    LIB_WILL_DISPOSE,
    LIB_WILL_INIT
} from './actionTypes';

export function disposeLib() {
    return (dispatch) => {
        dispatch({ type: LIB_WILL_DISPOSE });
      
        dispatch({ type: LIB_DID_DISPOSE });
    };
}

export function initLib() {
    return (dispatch, getState) => {
        dispatch({ type: LIB_WILL_INIT });
        
        try {
            JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
            JitsiMeetJS.init();

            dispatch({ type: LIB_DID_INIT });
        } catch (error) {
            dispatch(libInitError(error));
        }
    };
}

export function libInitError(error) {
    return {
        type: LIB_INIT_ERROR,
        error
    };
}
