import jitsiServer from ".";
import JitsiMeetJS, { JitsiTrackErrors } from "../../features/base/lib-jitsi-meet";
import { trackAdded } from "../../features/base/tracks";
import { setShareScreenUserId } from "../../features/screen-share";

function _handleScreenSharingError(error) {
    let descriptionKey;
    let titleKey;
    
    if (error.name === JitsiTrackErrors.SCREENSHARING_USER_CANCELED) {
        descriptionKey = 'dialog.screenSharingCancel';
        titleKey = 'dialog.screenSharingCancelError';
    } else if (error.name === JitsiTrackErrors.PERMISSION_DENIED) {
        descriptionKey = 'dialog.screenSharingPermissionDeniedError';
        titleKey = 'dialog.screenSharingFailedTitle';
    } else if (error.name === JitsiTrackErrors.CONSTRAINT_FAILED) {
        descriptionKey = 'dialog.cameraConstraintFailedError';
        titleKey = 'deviceError.cameraError';
    } else if (error.name === JitsiTrackErrors.SCREENSHARING_GENERIC_ERROR) {
        descriptionKey = 'dialog.screenSharingFailed';
        titleKey = 'dialog.screenSharingFailedTitle';
    } 

    APP.UI.messageHandler.showError({
        descriptionKey,
        titleKey
    });
} 



export default class ScreenShare {
    constructor() {
        this.jitsiServer = new jitsiServer();
        this.handler = null;

        this.desktopStream = null;
        this.shareUserID = null;

        this.videoSwitchInProgress = false;
        
        this.hendlerScreenShare = this.hendlerScreenShare.bind(this);
    }

    startScreenShare() {
        if (this.videoSwitchInProgress) {
            return Promise.reject('Switch in progress.');
        }

        if (!JitsiMeetJS.isDesktopSharingEnabled()) {
            return Promise.reject('Cannot toggle screen sharing: not supported.');
        }
    
        
        return this.switchToScreenSharing() 
            .then((streams) => {
                return this.jitsiServer && this.jitsiServer.connect(true)
                    .then(conference => {
                        streams.map(async s => {                            
                            APP.store.dispatch(trackAdded(s, conference.myUserId()));

                            await this.jitsiServer.addTrack(conference, s, true);
                        });

                        this.desktopStream = streams;                        
                        return Promise.resolve(conference);
                    });
            });
    } 

    stopScreenShare() {
        this._unToggleScreenSharing();
    }

    switchToScreenSharing() {
        APP.UI.showLoading(true);
        this.videoSwitchInProgress = true;

        return this.jitsiServer.deviceManager.createDesktopTrack({
            unToggleScreenSharing: this.hendlerScreenShare
        }).catch(error => {
            this.videoSwitchInProgress = false;
            APP.UI.showLoading(false);

            if (this._untoggleScreenSharing) this._untoggleScreenSharing();

            _handleScreenSharingError(error);
            return Promise.reject();
        });
    }

    hendlerScreenShare() {
        APP.store.dispatch(setShareScreenUserId(null, null));
        this.stopScreenShare();
    }
    
    _unToggleScreenSharing() {
        if (this.videoSwitchInProgress) {
            this.videoSwitchInProgress = false;
            this.desktopStream.map(s => s && s.dispose && s.dispose());
            this.jitsiServer.disconnect();
        }        
    }
}