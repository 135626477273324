let gContext;
let gMic;
let gScript;

export default function SoundMeter(context) {
    gContext = context;
    this.instant = 0.0;
    
    gScript = context.createScriptProcessor(2048, 1, 1);
    const that = this;
    gScript.onaudioprocess = event => {
        const input = event.inputBuffer.getChannelData(0);
        
        let sum = 0.0;
        let clipcount = 0;
        
        input.map(i => {
            sum += i * i;
            if (Math.abs(i) > 0.99) clipcount += 1;
        });

        that.instant = Math.sqrt(sum / input.length);
    };
}

  
SoundMeter.prototype.connectToSource = (stream, callback) => {
    try {
        gMic = gContext.createMediaStreamSource(stream);
        gMic.connect(gScript);
        gScript.connect(gContext.destination);

        if (typeof callback !== 'undefined') {
            callback(null);
        }
    } catch (e) {
        if (typeof callback !== 'undefined') {
            callback(e);
        }
    }
  };
  
SoundMeter.prototype.stop = () => {
    gContext.close();

    gMic.disconnect();
    gScript.disconnect();
};