import _ from "lodash";
import { MEDIA_TYPE } from "../base/media";
import { toState } from "../base/redux";
import { getTracksByParticipantId } from "../base/tracks";
import { PARTICIPANT_STATUS, PARTICIPANT_STATUS_LEVEL, ROLE_LEVEL } from "./constants";

/**
 * 모든 사용자 가져오기 + filter
 * @param {Function} stateful 
 * @param {Array} status 
 * @returns 
 */
export function getMemberList(stateful, data, isSort = true, isGroup = false, isAll = false) {
    const state = toState(stateful);
    
    let { local, remote } = state["features/memeber"];
    const list = [local, ...remote];
    const status = data?.status || null;
    const excludePin = data?.excludePin || false;
    const excludeLocal = data?.excludeLocal || false;
    const excludeVoice = data?.excludeVoice || false;
    const excludeIds = data?.excludeIds || null;
    const nickname = data?.nickname || null;
    const groupIds = data?.groupIds || null;
    const excludeGroup = data?.excludeGroup || false;

    let members = [];
    list.map(uuid => {
        if (!uuid) return;
        const user = getMemberTrackByUserID(state, uuid, true);

        if ((status ? status.includes(user.status) : true) &&
            (user.local ? !excludeLocal : true) && 
            (excludeVoice ? getVoiceMember(state) !== user.user_uuid : true) && 
            (excludePin ? !user.pinned : true) && 
            (excludeIds ? !excludeIds.includes(user.user_uuid) : true) && 
            (nickname ? user.nickname.indexOf(nickname) !== -1 : true) &&
            (groupIds ? groupIds.includes(user.groupId) === !excludeGroup : true) && true
        ) {
            if (isGroup) {
                members.push({
                    user_id: user.user_uuid,
                    nickname: user.nickname,
                    role: user.role,
                    role_name: user.groupRole,            
                    status: user.status === PARTICIPANT_STATUS.OCCUPIDE ? true : false,
                    group_id: user.groupId,
                    group_name: user.groupName
                })
            } else {
                members.push(user);
            }
        }
    });

    if (isSort) {
        members
            .sort((a, b) => a.nickname.localeCompare(b.nickname) )
            .sort((a, b) => a.pinned === b.pinned ? 0 : a.pinned ? -1 : 1 )
            .sort((a, b) => a.local === b.local ? 0 : a.local ? -1 : 1 )
            .sort((a, b) => ROLE_LEVEL.indexOf(a.role) - (ROLE_LEVEL.indexOf(b.role)))
            .sort((a, b) => PARTICIPANT_STATUS_LEVEL.indexOf(a.status) - (PARTICIPANT_STATUS_LEVEL.indexOf(b.status)));
    }

    return isAll ? members : members.map(t => t.user_uuid);
}

/**
 * 현재 입장 중인 사용자 수 가져오기
 * @param {*} stateful 
 * @returns 
 */
export function getMemberCount(stateful) {
    const state = toState(stateful);

    const members = getMemberList(stateful, { 
        status: [PARTICIPANT_STATUS.OCCUPIDE]
    }, false);

    return members.length;
}

/**
 * 음성 사용자 가져오기
 * @param {Function} stateful 
 * @returns 
 */
export function getVoiceMember(stateful) {
    const state = toState(stateful);

    return state["features/memeber"].voice;
}

/**
 * 로컬 사용자 정보 가져오기
 * @param {*} stateful 
 * @returns 
 */
export function getLocalMember(stateful) {
    const state = toState(stateful);

    const local_uuid = state["features/memeber"].local;

    return getMemberByUserID(stateful, local_uuid);
}

/**
 * host 사용자 정보 가져오기
 * @param {*} stateful 
 * @returns 
 */
export function getHostMembers(stateful) {
    const state = toState(stateful);

    return state["features/memeber"].hostList;
}

/**
 * 모든 사용자 ID 조회(방에 없는 사용자 까지)
 * @param {Function} stateful 
 * @param {string} user_uuid 
 * @returns 
 */
export function getMemberByUserID(stateful, user_uuid) {
    const state = toState(stateful);

    const members = state["features/memeber"].members;

    return members.get(user_uuid);
}

/**
 * 현재 권한 확인 
 * @param {Function} stateful 
 * @param {string} user_uuid 
 */
export function getRoleByUserID(stateful, user_uuid) {
    const members = getMemberByUserID(stateful, user_uuid);
    
    return members && members.role;
}

/**
 * 사용자 member ID로 user ID 가져오기
 */
export function getUserIDByMemberId(stateful, member_uuid) {
    const state = toState(stateful);
    const memberIDs = state["features/memeber"]?.memberIDs;

    return memberIDs && memberIDs.get(member_uuid);
}

/**
 * 사용자 아이디로 사용자 트랙 아이디 가져오기
 * @param {Function} stateful 
 * @param {string} user_uuid 
 * @returns 
 */
export function getBridgeIDByUserID(stateful, user_uuid) {
    const state = toState(stateful);

    const bridgeList = state["features/memeber"].bridgeList;

    return bridgeList.get(user_uuid);
}

/**
 * 사용자 아이디로 트랙 정보 가져오기 
 * @param {Function} stateful 
 * @param {string} user_uuid 
 */
export function getMemberTrackByUserID(stateful, user_uuid, isTrack = true) {
    const state = toState(stateful);
    const member = getMemberByUserID(state, user_uuid);
    if (!member) {
        return null;
    }

    const jitsi_id = getBridgeIDByUserID(state, user_uuid);
    const videoTrack = isTrack && getTracksByParticipantId(state, member.local ? "local" : jitsi_id, MEDIA_TYPE.VIDEO);
    const audioTrack = isTrack && getTracksByParticipantId(state, member.local ? "local" : jitsi_id, MEDIA_TYPE.AUDIO);
    const group = getGroupMemberByUserId(state, user_uuid);

    return {
        ...member,
        jitsi_id,
        videoTrack,
        audioTrack,
        groupId: group ? group.group_id : '',
        groupName: group ? group.group_name : '',
        groupRole: group ? group.role_name : ''
    }
}

/**
 * local 사용자 아이디 가져오기
 * @param {Function} stateful 
 * @returns 
 */
export function getLocalIDMember(stateful) {
    const state = toState(stateful);
    
    return state["features/memeber"].local;
}

/**
 * 권한 레벨 확인
 * @param {string} localRole 
 * @param {string} memberRole 
 * @returns 
 */
export function checkRoleLevel(localRole, memberRole) {
    if (ROLE_LEVEL.indexOf(localRole) >= ROLE_LEVEL.indexOf(memberRole)) return false;
    return true;
}

/**
 * 사용자 핀 가져오기
 * @param {Function} stateful 
 * @returns 
 */
export function getPinnedMember(stateful, excludeId) {
    const state = toState(stateful);
    
    const pinList = state["features/memeber"].pinList;
    const copyPin = _.cloneDeep(pinList);
    excludeId && copyPin.delete(excludeId);    

    return copyPin;
}

/**
 * 사용자 핀 개수 가져오기
 * @param {Function} stateful 
 * @returns 
 */
export function getPinnedMemberCount(stateful, excludeId) {
    const pin = getPinnedMember(stateful, excludeId);
    return pin.size;
}

/**
 * user_id로 그룹 정보 가져오기
 * @param {*} stateful 
 * @param {*} user_uuid 
 * @returns 
 */
export function getGroupMemberByUserId(stateful, user_uuid) {
    const state = toState(stateful);

    const group = state["features/memeber"].group;
    return group.get(user_uuid);
}

export function getMemberRecordList(stateful) {
    const state = toState(stateful);
    const list = state["features/memeber"].recordList;
    return list && list.size > 0 ? [...list] : false;
}