/**
 * 로컬 사용자 저장 
 * @param {object} member 
 * @returns 
 */
export const ADD_LOCAL_MEMBER = 'ADD_LOCAL_MEMBER';

/**
 * 사용자 퇴장
 * @param {object} member 
 * @returns 
 */
export const DELETE_MEMBER = 'DELETE_MEMBER';

/**
 * remote 사용자 저장 
 * @param {object} member 
 * @returns 
 */
export const ADD_MEMBER = 'ADD_MEMBER';

/**
 * 사용자 pin 설정 추가 
 * @param {string} user_uuid 
 * @param {Boolean} pinned 
 * @returns 
 */
export const ADD_PINNED = 'ADD_PINNED';
export const DELETE_PINNED = 'DELETE_PINNED';

/**
 * 녹화 사용자 
 * @param {String} user_uuid 
 * @param {Boolean} record 
 * @param {Boolean} isRemote 
 * @returns 
 */
export const ADD_RECORD = 'ADD_RECORD';
export const DELETE_RECORD = 'DELETE_RECORD';

/**
 * 사용자 정보 업데이트
 * @param {object} member 
 * @returns 
 */
export const UPDATE_MEMBER = 'UPDATE_MEMBER';

/**
 * 사용자 브릿지 아이디 
 * @param {string} user_id 
 * @param {string} bridge_session_id 
 * @returns 
 */
export const SET_BRIDGE_ID = 'SET_BRIDGE_ID';

/**
 * 상태 값에 따른 업데이트 
 * @param {string} status 
 * @param {string} user_uuid 
 * @returns 
 */
export const SET_STATUS_LIST = 'SET_STATUS_LIST';

/**
 * 보이스 사용자 업데이트 
 * @param {string} user_uuid 
 * @returns 
 */
export const SET_MEMBER_VOICE = 'SET_MEMBER_VOICE';

/**
 * 그룹 멤버 
 * @param {Object} members 
 * @returns 
 */
export const SET_GROUP_MEMBERS = 'SET_GROUP_MEMBERS';

export const LEFT_MEMBER = 'LEFT_MEMBER';