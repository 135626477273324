import styled from "styled-components";

export const CanvasLayoutStyled = styled.div`
    position: relative;
    background-color: ${props => props.isVod ? "transparent" : "#f7f7f7"};
    overflow: hidden;
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .canvas_input {
        position: absolute;

        textarea {
            padding: 5px;
            box-sizing: border-box;
            overflow: auto;
            resize: none;
            transform-origin: left top;
            border: none;
            z-index: 2;
        }
    }

    .noDocument {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #808080;
        font-size: 16px;
        flex-direction: column;
        gap: 24px;
        
        .documet_img {
            width: 32px;
            height: 35px;
            background-position: -641px -143px;
            transform: scale(1.5);
        }
    }


    iframe {
        margin: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: none;
    }
`;