
import React, { useEffect, useRef, useState } from "react";
import { Group, Shape, Transformer } from 'react-konva';
import { shallowEqual } from "react-redux";
import { CIRCLE, RECT, TRIANGLE } from "../constants";

const initShapeProps = {
    width: 0, height: 0, x: 0, y: 0
};

export default function FigureCanvas({
    canvasTool, points, ratio, 
    selected, handlerSelectedLine, handlerChangeCanvasPoint, handlerDeleteLine
}) {
    const shapeRef = useRef();
    const trRef = useRef();
    
    const [shapeProps, setShapeProps] = useState(initShapeProps);

    const { thickness, color, opacity, tool } = canvasTool;

    useEffect(() => {
        if (selected) {
            if (trRef && trRef.current && shapeRef && shapeRef.current) {
                trRef.current.nodes([shapeRef.current]);
                trRef.current.getLayer().batchDraw();
            }
        }       
    }, [selected]);

    useEffect(() => {
        let [x, y] = points;

        setShapeProps({
            width: canvasTool.figureWidth,
            height: canvasTool.figureHeight,
            x,
            y
        });
    }, [points, canvasTool], shallowEqual);

    const handlerUpdatePoint = (x, y, width, height) => {
        handlerChangeCanvasPoint([ x, y ], {
            ...canvasTool,
            figureWidth: width, 
            figureHeight: height
        });
    }

    const drawFun = (context, shape) => {
        context.lineWidth = thickness;
        context.fillStyle = color;
        context.strokeStyle = color;

        const { tool } = canvasTool;
        let { width, height, x, y } = shapeProps;

        context.beginPath();
        
        if (tool.includes(RECT.name)) {
            context.rect(0, 0, width, height);
        } else if (tool.includes(CIRCLE.name)) {            
            width = Math.abs(width);
            height = Math.abs(height);

            context.ellipse(width / 2, height / 2, width / 2, height / 2, 0, 0, 2 * Math.PI);
        } else if (tool.includes(TRIANGLE.name)) {
            // const newWidth = width; // canvasTool?.flipX ? width * -1 : width;
            // const newHeight =  height;  //canvasTool?.flipY ? height * -1 : height;
            context.moveTo(width / 2 , 0);
            context.lineTo(0, height); 
            context.lineTo(width, height);

            // context.scale(canvasTool?.flipX ? -1 : 1, canvasTool?.flipY ? -1 : 1);
            context.closePath();
        }

        if (tool.indexOf("fill") !== -1) context.fill();
        else context.stroke();

        context.closePath();
        context.fillStrokeShape(shape);
    }

    const hitStrokeWidth = (canvasTool.thickness + 5) / ratio;
    const strokeWidth = (canvasTool.thickness) / ratio;

    return (
        <Group
            scaleX={ratio} scaleY={ratio}  
            hitStrokeWidth={hitStrokeWidth}
            onClick={handlerSelectedLine} 
            onTap={handlerSelectedLine}
            onMouseOver={handlerDeleteLine}
            onMouseMove={handlerDeleteLine}
            onTouchMove={handlerDeleteLine}    
            width={shapeProps.width}
            height={shapeProps.height}
        >
            <Shape
                ref={shapeRef}
                strokeWidth={strokeWidth}
                hitStrokeWidth={hitStrokeWidth}
                opacity={canvasTool.opacity / 10}
                width={shapeProps.width}
                height={shapeProps.height}
                x={shapeProps.x}
                y={shapeProps.y}
                draggable={selected}                
                onDragEnd={(e) => {
                    const data = {
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y()
                    };

                    setShapeProps(data);

                    handlerUpdatePoint(data.x, data.y, data.width, data.height);
                }}
                onTransformEnd={(e) => {
                    if (!selected) return;
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();
                    
                    node.scaleX(1);
                    node.scaleY(1);
                    const data = {
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        // set minimal value
                        width: Math.max(5, node.width() * scaleX),
                        height: Math.max(node.height() * scaleY),
                    };
                    setShapeProps(data);
    
                    handlerUpdatePoint(data.x, data.y, data.width, data.height);
                }}
                sceneFunc={drawFun}
                hitFunc={drawFun}
            />
            { selected && <Transformer ref={trRef} 
                keepRatio={false}
                rotateEnabled={false}
                centeredScaling={true}
                enabledAnchors={['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right']}
                boundBoxFunc={(oldBox, newBox) => {
                    if (newBox.width < 5 || newBox.height < 5) {
                        return oldBox;
                    }
                        
                    return newBox;
                }}
            /> }
        </Group>
    )
}