
import React, { Component } from 'react';

export default class Video extends Component {
    static defaultProps = {
        className: '',
        autoPlay: true,
        id: '',
        playsinline: true
    };

    constructor(props) {
        super(props);
        this._videoElement = null;

        this._onVideoPlaying = this._onVideoPlaying.bind(this);
        this._setVideoElement = this._setVideoElement.bind(this);
    }

    componentDidMount() {
        
        if (this._videoElement) {
            this._videoElement.volume = 0;
            this._videoElement.onplaying = this._onVideoPlaying;
            this._videoElement.disablePictureInPicture = true;
        }

        this._attachTrack(this.props.videoTrack);

        if (this._videoElement && this.props.autoPlay) {
            this._videoElement.play()
                .catch(error => {
                    if (this._mounted) {
                        throw error;
                    }
                });
        }
    }

    componentWillUnmount() {
        this._detachTrack(this.props.videoTrack);
    }

    shouldComponentUpdate(nextProps) {
        const currentTrack = this.props.videoTrack
            && this.props.videoTrack.track;
        const nextTrack = nextProps.videoTrack
            && nextProps.videoTrack.track;

        if (currentTrack !== nextTrack) {
            this._detachTrack(this.props.videoTrack);
            this._attachTrack(nextProps.videoTrack);
        }

        if (this.props.style !== nextProps.style || this.props.className !== nextProps.className) {
            return true;
        }

        return false;
    }

    render() {
        const {
            autoPlay,
            className,
            id,
            muted,
            playsinline,
            style,
            eventHandlers
        } = this.props;

        return (
            <video
                autoPlay = { autoPlay }
                className = { className }
                id = { id }
                muted = { muted }
                playsInline = { playsinline }
                ref = { this._setVideoElement }
                style = { style }
                { ...eventHandlers } />
        );
    }

    _attachTrack(videoTrack) {
        if (!videoTrack || !videoTrack.track) {
            return;
        }

        videoTrack.track.attach(this._videoElement);
    }
    _detachTrack(videoTrack) {
        if (this._videoElement && videoTrack && videoTrack.track) {
            videoTrack.track.detach(this._videoElement);
        }
    }
    
    _onVideoPlaying() {
        if (this.props.onVideoPlaying) {
            this.props.onVideoPlaying();
        }
    }
    
    _setVideoElement(element) {
        this._videoElement = element;
    }
}
