
import React, { Fragment, useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import styled from "styled-components";
// import { ClickButton } from "../../../base/ui";
import { BsFillFileMinusFill, BsFillPlusSquareFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { ROLE } from "../../../member";
import { setCanvasOption } from "../action";
import { ALL_ERASER, COLOR, ERASER, LINE_ERASER, OPACITY, PART_ERASER, THICKNESS, ZOOM } from "../constants";

const CanvasIconStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;
    width: 43px;
    height: 43px;
    z-index: 2;
    justify-content: center;
    align-items: center;
`;

export default function ToolButton({
    role, tool, item, focusTool, isMobile, option, scale, isWidthFit, 
    setFocusTool, handlerUpdateCanvasTool
}) {
    const dispatch = useDispatch();
    const [ showChild, setShowChild ] = useState();
    const [ value, setValue ] = useState();
    const [ widthFit, setWidthFit ] = useState(isWidthFit);

    const handlerChangeOption = (name, value) => {
        if (name !== 'isWidthFit') setValue(value);
        else setWidthFit(value);

        dispatch(setCanvasOption({ [name]: value }));
    }    

    useEffect(() => {
        setShowChild(null);
    }, [focusTool]);

    const _onClickHandler = () => {
        if (!item.children) {
            handlerUpdateCanvasTool(item.name);

            setFocusTool(null);
        } else {
            if (item && item.name) {
                if (OPACITY.name === item.name) {
                    setValue(option[item.name]);
                } else {
                    setValue(option[item.name]);
                }            
            }

            setFocusTool(item);
        }
    }

    const _onClickChildrenHandler = (focusItem) => {
        const childrenItem = focusTool && focusTool.children.find(item => item.name === focusItem.name);
        if (childrenItem && childrenItem.children) {
            setShowChild(focusItem);
        } else {
            handlerUpdateCanvasTool(focusItem.name);

            setFocusTool(null);
        }
    }    

    const _renderDialog = (focusTool, dialogStyle) => {
        return (
            <div className="option_box" style={{ ...dialogStyle, flexDirection: `${isMobile ? "column" : "row"}`}}> 
                { 
                    focusTool.name === THICKNESS.name ? 
                        <ProgressBar value={value} min={1} max={20} step={1} name={THICKNESS.name} 
                            onChange={handlerChangeOption} /> :
                    focusTool.name === OPACITY.name ? <ProgressBar value={value} min={1} max={10} step={1} name={OPACITY.name} onChange={handlerChangeOption} /> :
                    focusTool.name === COLOR.name ? <ColorBox value={value} name={COLOR.name} onChange={handlerChangeOption} /> :
                    focusTool.name === ZOOM.name ? <ZoomBox scale={option.scale} isWidthFit={widthFit} name={ZOOM.name} onChange={handlerChangeOption} /> :
                    null                
                }
            </div>
        )
    }

    const _renderArrayDialog = (focusTool, dialogStyle) => {
        return (
            <div className="option_box" style={{ ...dialogStyle, flexDirection: `${isMobile ? "column" : "row"}`}}>
                { focusTool.children.map(item => {
                    const name = item.name;
                    const checked = tool === name || showChild?.name === name ? true : false;

                    return (
                        <dl key={`d${name}`}
                            className={`boardImg ${name} use_${checked}`}
                            icon={name} 
                            size={20} color={checked ? "#29abe3" : "#545454"}
                            onClick={() => _onClickChildrenHandler(item)} />
                    )
                })}
            </div>
        )   
        
    }

    const _renderChoiceDialog = (focusTool) => {
        return (
            <>
                { _renderArrayDialog(focusTool) }

                { showChild && (typeof showChild.children === 'boolean' 
                    ? _renderDialog(showChild, { left: "43px", top: "130px" })
                    : _renderArrayDialog(showChild, { left: "43px", top: "130px" }, 'sub')) }

            </>
        )
    }

    const _renderButton = () => {        
        let name = item.children && typeof item.children === 'object' && item.children.find(i => i.name === tool)?.name || item.name;
        const checked = tool === name || focusTool?.name === name ? true : false;
        const showDialog = focusTool && focusTool.name === item.name && focusTool.children;

        if (role === ROLE.PARTICIPANT && name === LINE_ERASER.name) name = ERASER.name;
        return (
            <CanvasIconStyled>
                <dl key={`d${name}`}
                    className={`boardImg ${name} use_${checked}`}
                    icon={name} 
                    size={20} color={checked ? "#29abe3" : "#545454"}
                    onClick={() => _onClickHandler(item)} />

                { showDialog && (typeof focusTool.children === 'boolean' ? _renderDialog(focusTool) : _renderChoiceDialog(focusTool)) }
            </CanvasIconStyled>
        );
    }

    return (
        _renderButton()
    )
}

function ProgressBar({ className = 'field', value, min, max, step, name, onChange }) {
    const handlerChange = (e) => {
        const data = e.target.value;

        onChange(name, Number(data));
    }

    return (
        <div className={`zoom_box ${className}`}>
            <div className="value">{ min }</div>
            <input 
                type="range" name="range"
                min={min} max={max}
                step={step}
                value={value} 
                onChange={handlerChange} />
            <label htmlFor="range"> {value} </label>
            <div className="value nonSelect">{max}</div>
        </div>
    )
}

function ColorBox({ value, name, onChange}) {
    const handlerChange = (e) => {
        const data = e.hex;

        onChange(name, data);
    }

    return (
        <SketchPicker className="color_box"
            color={value}
            onChange={handlerChange}
        />
    )
}

function ZoomBox({ scale, isWidthFit, name, onChange }) {
    const handlerWidthChange = (value) => {
        onChange('isWidthFit', value);
    }

    const handlerScaleChange = (value) => {
        onChange('scale', Number(value));
    }

    const renderSwitchButton = (name, type) => {
        const isOn = type === isWidthFit;

        const onClick = () => {
            handlerWidthChange(type);
        }
        
        return (
            <span style={{margin: "0 8px"}} className={`boardImg ${name} ${name}_${isOn}`}  
                onClick={onClick} onTouchEnd={onClick}></span>
        )
    }

    return (
        <Fragment>
            <div className="zoom_box field option_scale">
                <BsFillFileMinusFill onClick={() => handlerScaleChange(scale - 10)} size={18} />
                <span> { scale } </span>
                <BsFillPlusSquareFill onClick={() => handlerScaleChange(scale + 10)} size={18} />
            </div>

            <div style={{ display: "flex", gap: "20px"}}>
                { renderSwitchButton("fitPage", undefined) }
                { renderSwitchButton("fitWidth", true) }
                { renderSwitchButton("fitHeight", false) }
            </div>
        </Fragment>
    )
}