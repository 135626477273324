import axios from "axios";

export async function shareDocumentConvert({file, meeting_uuid}) {
    const response = await axios.post(`/svc/meeting/meetings/${meeting_uuid}/documents`, file);
    
    return {
        status: response.status,
        data: response.data,
    };
}

export async function getSharedDocument(uuid) {
    const response = await axios({
        method: "GET", 
        url: `/docconv/get/${uuid}`
    });
    
    return {
        status: response.status,
        data: response.data,
    };
}

export async function addRecordResource({ aToken, meeting_id, group_id }) {
    const response = await axios({
        method: "POST", 
        url: `/api/meeting/v1/meetings/${meeting_id}/recording/resources`,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        },
        data: {
            group_id
        }
    });
    
    return {
        status: response.status,
        data: response.data,
    };
}