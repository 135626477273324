import React from "react";
import { AbstractDialog } from "../../../base/dialog";
import { translate } from "../../../base/i18n";
import { EXT_STRING, FILE_MAX_SIZE } from "../../constants";
import { ChatPopStyled } from "./styles";

function ChatPopup({
    t, file, renderSendFileButton, handlerClose
}) {
    return (
        <AbstractDialog t={t} name="fileSend" width={300} handlerClose={handlerClose}>
            <ChatPopStyled>
                <div className="guid"> 
                    <p>{ t("chat.maxVolume", { volume: FILE_MAX_SIZE }) }</p>
                    <p>{ t("chat.sendFileGuid", { file: EXT_STRING }) }</p>
                </div>
                { file.result && <div className="send_body">
                    <span className="title">{t(`chat.${file.result}`)}</span>
                    <div className="list">
                        <p className="chat_list">{ file.file.name }</p>    
                    </div> 
                </div> }

                { renderSendFileButton() }
            </ChatPopStyled>
        </AbstractDialog>
    )
}

export default ChatPopup;