import React, { useEffect, useState } from 'react';
import { IconButton } from '../../../base/buttons';
import { translate } from '../../../base/i18n';
import { isIOSDevice } from '../../../base/util';
import { layoutType } from '../../../video-layout';

function FullScreenButton({ t, isOption, userAgentType }) {
    if (isIOSDevice()) return null;
    if (!APP.layout.option.fullScreen) return null;

    const [ initfull, setInitFull ] = useState(false);
    const [ fullScreen, setFullScreen ] = useState(false);

    const switchFullScreen = () => {
        if (initfull) {
            alert("F11을 눌러 전체 화면을 취소하세요.");
            return;
        }
        if (document.fullscreenEnabled === false) {
            const message = t("common.enableBrowser");
            alert(message);
            return;
        }

        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            
            setFullScreen(true);
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                setFullScreen(false);
            }
        }
    }
    
    useEffect(() => {
        test();

        
        window.addEventListener("resize", test);
        return () => {
            window.removeEventListener("resize", test);
        }
    }, []);

    const test = () => {
        if (screen.height === window.innerHeight && !document.fullscreenElement){
            // setFullScreen(true);
            setInitFull(true);
        } else {
            setInitFull(false);
            setFullScreen(Boolean(document.fullscreenElement));
        }
    }
    
    return (
        <>
            { ((!isOption && userAgentType !== layoutType.desktop) || isOption) && 
                <IconButton 
                    mode={fullScreen ? "fullScreen" : "fullScreenExit"} 
                    name={isOption ? t(`options.${fullScreen}FullScreen`) : null}
                    size={22} 
                    onClick={switchFullScreen}
                />
            }
        </>
    )
}

export default translate(FullScreenButton);