/* @flow */
import { MiddlewareRegistry } from '../redux';

import JitsiMeetJS from '.';
import { LIB_WILL_INIT } from './actionTypes';
import { disposeLib, initLib } from './actions';
import { DELETE_MEMBER } from '../../member/actionType';


MiddlewareRegistry.register(store => next => action => {
    switch (action?.type) {
        case LIB_WILL_INIT:
            if (typeof APP !== 'undefined') {
                _setErrorHandlers();
            }
            break;

        // case SET_NETWORK_INFO:
        //     JitsiMeetJS.setNetworkInfo({
        //         isOnline: action.isOnline
        //     });
        //     break;

        case DELETE_MEMBER:
            action.member.local && store.dispatch(disposeLib());
            break;
        }

        return next(action);
    }
);


function _setConfig({ dispatch, getState }, next, action) {
    const { initialized } = getState()['features/base/lib-jitsi-meet'];

    if (initialized) {
        dispatch(disposeLib());
    }
    const result = next(action);

    dispatch(initLib());

    return result;
}


function _setErrorHandlers() {
    if (JitsiMeetJS.getGlobalOnErrorHandler) {
        const oldOnErrorHandler = window.onerror;

        window.onerror = (message, source, lineno, colno, error) => {
            const errMsg = message || (error && error.message);
            const stack = error && error.stack;

            // JitsiMeetJS.getGlobalOnErrorHandler(errMsg, source, lineno, colno, stack);

            if (oldOnErrorHandler) {
                oldOnErrorHandler(message, source, lineno, colno, error);
            }
        };

        const oldOnUnhandledRejection = window.onunhandledrejection;

        window.onunhandledrejection = function(event) {
            let message = event.reason;
            let stack = 'n/a';

            if (event.reason instanceof Error) {
                ({ message, stack } = event.reason);
            }

            // JitsiMeetJS.getGlobalOnErrorHandler(message, null, null, null, stack);

            if (oldOnUnhandledRejection) {
                oldOnUnhandledRejection(event);
            }
        };
    }
}
