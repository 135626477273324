/**
 * Create an action for when the settings are updated.
 *
 * {
 *     type: SETTINGS_UPDATED,
 *     settings: {
 *         audioOutputDeviceId,
 *         avatarURL,
 *         cameraDeviceId,
 *         displayName,
 *         email,
 *         localFlipX,
 *         micDeviceId,
 *         serverURL,
 *         startAudioOnly,
 *         startWithAudioMuted,
 *         startWithVideoMuted
 *     }
 * }
 */
export const SETTINGS_UPDATED = 'SETTINGS_UPDATED';

export const SET_DUAL_OPTION = 'SET_DUAL_OPTION';