import React from "react";
import { BsCameraVideoOff } from "react-icons/bs";
import { useSelector } from "react-redux";
import { MEDIA_TYPE, VideoTrack } from "../../base/media";
import { getTracksByParticipantId } from "../../base/tracks";
import { getLocalSharingStatus, getScreenSharingId } from "../../screen-share";
import { LocalLayout, MembersLayout, PinsLayout, ScreenUserLayout } from "./MembersLayout";
import { ScreenLayoutStyled } from "./styles";
import { useTranslation } from "react-i18next";
import { MdScreenShare } from "react-icons/md";

export default function ScreenScreen({
    mode, isFull, isDual
}) {
    const isLocalRecording = useSelector(state => getLocalSharingStatus(state));
    const screenId = useSelector(state => getScreenSharingId(state));

    if (isDual) {
        if (!screenId) return null;
        return (
            <div className="screen">
                <ScreenVideo className="gridFlex" screenId={screenId} />
            </div>
        )
    }

    if (APP.layout.screen && !isLocalRecording && !isFull) {
        return (
            <ScreenLayoutStyled isFull={true}>
                <div className="local">
                    <ScreenUserLayout noShowMember={true}
                        mode={mode} maxStyle={{ width: 206, height: 140 }} 
                        vertical={true}
                    />
                </div>
                <div className="screen">
                    <ScreenVideo screenId={screenId} />
                </div>
            </ScreenLayoutStyled>
        )
    } 

    return (
        <ScreenLayoutStyled isFull={isFull}>
            { !isFull && <div className="vertical">
                <PinsLayout className="gridFlex"
                    mode={mode} 
                    noShowMember={true} vertical={true}
                    maxStyle={{ width: 172, height: 98 }} />
                <MembersLayout className="gridFlex" noShowMember={true}
                    mode={mode} maxStyle={{ width: 172, height: 98 }} vertical={true}
                />
            </div> }
            
            <div className="screen">
                <ScreenVideo screenId={screenId} />
            </div>
        </ScreenLayoutStyled>
    )
}

const ScreenVideo = React.memo(function ScreenVideo({
    screenId
}) {
    const { t } = useTranslation();
    const videoTrack = useSelector(state => getTracksByParticipantId(state, screenId, MEDIA_TYPE.VIDEO));

    if (!videoTrack) {
        return (
            <div className="default">
                <dl className="center" style={{ width: "15%!important", textAlign: "center"}}>
                    <MdScreenShare color={"#fff"} size={18} />
                    { t("conference.prepareScreen") }
                </dl>
            </div>
        )
    }
    /**
     * 사용자 비디오 UI
     * @returns 
     */
    const renderVideo = () => {
        
        const show = videoTrack && !videoTrack.muted;

        return (
            show 
                ? <VideoTrack videoTrack={videoTrack} />
                : renderMutedVideo()
        )
    }

    /**
     * 비디오 음소거 기본 UI
     * @returns 
     */
    const renderMutedVideo = () => {
        return (
            <div className="default" style={{height: "100%"}}> 
                <dl className="center">
                    <BsCameraVideoOff color={"#fff"} />
                </dl>
            </div>
        )
    }

    return renderVideo();
});