import { PERMISSION } from "../../member";
import { isCheckPermission } from "../localuser";
import { toState } from "../redux";

export function getScreenFitStyle(width, height, imageWidth, imageHeight, isWidthFit ) {
    if (isWidthFit) {
        // 폭맞춤
        return { width , height: imageHeight * (width / imageWidth) || height };
    } else {
        // 쪽맞춤
        return { width: imageWidth * (height / imageHeight) || width, height };
    }
}

export function getFitSize(canvasStyle, pdfLength, isWidthFit) {
    let fitStyle = getScreenFitStyle(canvasStyle.width, canvasStyle.height, pdfLength.width, pdfLength.height, isWidthFit);

    if (isWidthFit === undefined) {
        if (canvasStyle.height < fitStyle.height || canvasStyle.width < fitStyle.width) {
            fitStyle = getScreenFitStyle(canvasStyle.width, canvasStyle.height, pdfLength.width, pdfLength.height, true);
        }
    } 

    return { 
        width: Math.floor(fitStyle.width) < 0 ? pdfLength.width : Math.floor(fitStyle.width), 
        height: Math.floor(fitStyle.height) < 0 ? pdfLength.height : Math.floor(fitStyle.height),
        fitWCanvas: isWidthFit
    }
}


export function getPdfItemByUUID(stateful: Function, uuid: String) {
    const state = toState(stateful);

    const canvas = state['features/base/canvas'].canvas;

    return canvas.get(uuid) || {
        pdf: null,
        list: null
    };
}

export function getItemByUUIDAndIndex(stateful: Function, uuid: String, index: String) {
    const state = toState(stateful);
    
    try {
        const canvas = state['features/base/canvas'].canvas;
        const item = canvas.get(uuid);

        return item;
    } catch {
        return null;
    }
}

export function getPdfDrawByUUID(stateful: Function, uuid: String) {
    const state = toState(stateful);

    const draw = state['features/base/canvas'].draw;

    return draw.get(uuid) || new Map();
}

export function getPdfDrawByUUIDAndIndex(stateful: Function, uuid: String, index: String) {
    const draw = getPdfDrawByUUID(stateful, uuid);

    let list = draw.get(index);

    return list ? [...list.keys()] : [];
}

export function getPdfDrawByUUIDAndIndexAndKey(stateful, uuid, index, key) {
    const draw = getPdfDrawByUUID(stateful, uuid);

    let list = draw.get(index);

    return list.get(key);
}

export function readBlobAsDataURL(blob, callback) {
    var a = new FileReader();
    a.onload = function(e) {callback(e.target.result);};
    a.readAsDataURL(blob);
}

export function dataURLtoBlob(dataurl) {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], {type:mime});
}

/**
 * uuid로 문서 아이템 가져오기 
 * @param {Function} stateful 
 * @param {String} uuid 
 * @param {String} mode 
 * @returns 
 */
 export function isExistDocumentItem(stateful: Object, uuid: String) {
    const state = toState(stateful);
    const document = state['features/share-document'].document;
    const whiteboard = state['features/share-document'].whiteboard;

    const isDocument = document && document.get(uuid) || false;
    const isWhiteBoard = whiteboard && whiteboard.get(uuid) || false;

    return isDocument || isWhiteBoard;
}

/**
 * 방 설정 권한 확인 (캔버스 에서만  사용)
 * @param {Function} stateful 
 * @param {String} type 
 * @returns Boolean
 */
export function checkCanvasPermission(stateful: Object, type: String) {
    return isCheckPermission(stateful, type);
}