import { LOCAL_PARTICIPANT_DEFAULT_ID } from '../../member';
import { getCurrentConference } from '../conference';
import { JitsiTrackErrors, JitsiTrackEvents } from '../lib-jitsi-meet';
import {
    setAudioMuted,
    setVideoMuted,
} from '../media';

import {
    TRACK_ADDED,
    TRACK_CREATE_CANCELED,
    TRACK_CREATE_ERROR,
    TRACK_NO_DATA_FROM_SOURCE,
    TRACK_REMOVED,
    TRACK_STOPPED,
    TRACK_UPDATED,
    TRACK_WILL_CREATE
} from './actionTypes';


export function trackAdded(track, isDesktop) {
    return (dispatch, getState) => {
        track.on(JitsiTrackEvents.TRACK_MUTE_CHANGED, () => dispatch(trackMutedChanged(track)));

        const local = isDesktop ? false : track.isLocal();
        const mediaType = track.getType();
        let participantId;
        let trackId;
        
        if (local) { 
            trackId = LOCAL_PARTICIPANT_DEFAULT_ID;
            participantId = LOCAL_PARTICIPANT_DEFAULT_ID;  
            track.on(JitsiTrackEvents.NO_DATA_FROM_SOURCE, () => APP.store.dispatch(noDataFromSource({ track })));

            track.on(JitsiTrackEvents.LOCAL_TRACK_STOPPED,
                () => dispatch(trackStopped(track)));
        } else {
            trackId = isDesktop ? isDesktop : track.getParticipantId();
            participantId = isDesktop ? isDesktop :  track.getParticipantId();
        }

        dispatch({
            type: TRACK_ADDED,
            track: {
                track,
                participantId: trackId,
                muted: track.isMuted(),
                local,
                mediaType,
                videoType: track.type
            }
        });
    }
    
}

export function trackRemoved(track) {
    return {
        type: TRACK_REMOVED,
        track
    };
}


export function trackMutedChanged(track) {
    return {
        type: TRACK_UPDATED,
        track: {
            track,
            muted: track.isMuted()
        }
    };
}

export function noDataFromSource( track ) {
    return {
        type: TRACK_NO_DATA_FROM_SOURCE,
        track
    };
}

export function replaceLocalTrack(oldTrack, newTrack, conference) {
    return async (dispatch, getState) => {
        try {
            const joined = getCurrentConference(getState());
            
            if (joined) {   
                await APP.mateManagement.replaceConferenceTrack(oldTrack, newTrack);                
            }

            return dispatch(replaceStoredTracks(oldTrack, newTrack));
        } catch (er) {
            console.log(er)
        }
        
    };
}

/**
 * 통과된 트랙을 폐기합니다.
 *
 * @param {(track|remoteTrack)[]} tracks - List of tracks.
 * @private
 * @returns {Promise} - A Promise resolved once {@link JitsiTrack.dispose()} is
 * done for every track from the list.
 */
function _disposeTracks(tracks: any[]): Promise<any> {
    return Promise.all(
        tracks.map(t =>
            t && t.dispose && t.dispose()
                .catch((err: Error) => {
                    console.log(err);
                    return Promise.resolve();
                })));
}

/**
 * 통과된 트랙을 폐기하고 제거하라는 신호를 보냅니다.
 *
 * @param {(track|remoteTrack)[]} tracks - List of tracks.
 * @protected
 * @returns {Function}
 */
export function _disposeAndRemoveTracks(tracks: any[]) {
    return async (dispatch) => {
        _disposeTracks(tracks)
            .then(() => Promise.all(tracks.map(t => dispatch(trackRemoved(t)))))
            .catch(err => console.log(err))
    }
}


/**
 * 저장된 트랙을 다른 트랙으로 바꿉니다.
 *
 * @param {JitsiLocalTrack|null} oldTrack - The track to dispose.
 * @param {JitsiLocalTrack|null} newTrack - The track to use instead.
 * @returns {Function}
 */
export function replaceStoredTracks(oldTrack: any, newTrack: any) {
    return async (dispatch) => {
        // 교체를 수행한 후 dispose를 호출합니다.
        // 트랙 자체가 제거된 후 새로운 o/a를 시도하고 수행합니다. 그것을하고
        // after는 JitsiLocalTrack.conference가 이미 있음을 의미합니다.
        // 지워졌으므로 o/a를 시도하지 않습니다.
        if (oldTrack) {
            await dispatch(_disposeAndRemoveTracks([ oldTrack ]));
        }

        if (newTrack) {
            const setMuted = newTrack.isVideoTrack() ? setVideoMuted : setAudioMuted;
            const isMuted = newTrack.isMuted();

            await dispatch(setMuted(isMuted));
            await dispatch(trackAdded(newTrack));
        }
    };
}

export function disposeAndRemoveTracks(tracks) {
    return dispatch => dispatch(_disposeAndRemoveTracks(tracks));
}

export function trackNoDataFromSourceNotificationInfoChanged(track, noDataFromSourceNotificationInfo) {
    return {
        type: TRACK_UPDATED,
        track: {
            track,
            noDataFromSourceNotificationInfo
        }
    };
}

export function trackStopped(track) {
    return {
        type: TRACK_STOPPED,
        track: {
            track
        }
    }
}


export function trackVideoStarted( track ) {
    return {
        type: TRACK_UPDATED,
        track: {
            track,
            videoStarted: true
        }
    };
}

export function trackVideoTypeChanged(track, videoType) {
    return {
        type: TRACK_UPDATED,
        track: {
            track,
            videoType
        }
    };
}

function _cancelGUMProcesses(getState) {
    const logError
        = error =>
            logger.error('gumProcess.cancel failed', JSON.stringify(error));

    return Promise.all(
        getState()['features/base/tracks']
            .filter(t => t.local)
            .map(({ gumProcess }) =>
                gumProcess && gumProcess.cancel().catch(logError)));
}

export function onCreateLocalTracksRejected({ gum }, device) {
    return dispatch => {
        if (gum) {
            const { error } = gum;

            if (error) {
                dispatch({
                    type: TRACK_CREATE_ERROR,
                    permissionDenied: error.name === 'SecurityError',
                    trackType: device
                });
            }
        }
    };
}

export function trackCreateCanceled(mediaType) {
    return {
        type: TRACK_CREATE_CANCELED,
        trackType: mediaType
    };
}
export function showNoDataFromSourceVideoError(track) {
    return (dispatch, getState) => {
        let notificationInfo;

        if (!track) {
            return;
        }

        if (track.isReceivingData) {
            notificationInfo = undefined;
        } else {
            const notificationAction = showErrorNotification({
                descriptionKey: 'dialog.cameraNotSendingData',
                titleKey: 'dialog.cameraNotSendingDataTitle'
            });

            dispatch(notificationAction);
            notificationInfo = {
                uid: notificationAction.uid
            };
        }
        dispatch(trackNoDataFromSourceNotificationInfoChanged(track, notificationInfo));
    };
}
