import { getGroupId } from '../../features/base/conference';
import { getLocalUserUUID } from '../../features/base/localuser';
import { getDisplayName } from '../../features/base/settings';
import { _disposeAndRemoveTracks, getLocalTracks, getTrackState } from '../../features/base/tracks';
import { getCookieValue } from '../../features/base/util';
import { getMediaItem } from '../../features/base/vod/function';
import { getLocalIDMember, getLocalMember, getMemberByUserID } from '../../features/member';
import { getFocusItem, getFocusItemByMode } from '../../features/share-document';
import { getCurrentMode, getGridCount, videoLayoutMode } from '../../features/video-layout';
import { createTaskQueue } from '../../modules/util/helpers';
import { command } from './comand';

function getState() {
    return APP.store.getState();
}

export default function MateEventManager(mate) {
    this.mate = mate;

    this.screenUserId = null;
}

MateEventManager.prototype.constructor = MateEventManager;

MateEventManager.prototype.init = function() {
    const { dispatch } = APP.store;
    
    this.dispatch = dispatch;
    this.onMessage = [];
    this.nextId = 0;

    this.sendCommand = this.sendCommand.bind(this);
}

// websocket message ( 받은 메시지 )
const task = createTaskQueue();
MateEventManager.prototype.reciveCommand = function(event) {
    let data = JSON.parse(event?.data);
    const currentGroupId = getGroupId(getState());
    
    // 이벤트 응답
    this.onMessage.forEach((handle) => {
        handle(event.data);
    });
    // 메인룸 + 그룹룸
    switch (data.class) {
        case command['join-room']:
            if (data.status === 200) {
                task.enqueue(onFinish => {
                    this.mate.conference.join(data.payload);
                    setTimeout(onFinish, 1000);
                });
            }
            
            return;

        // 사용자 입장
        case `Event.${command['join-room']}ed`:
            task.enqueue(onFinish => {
                this.mate.conference.participantJoined(data.payload, data.payload.group_id);
                onFinish();
            });
            break;       
        
        // 사용자 퇴장
        case `Event.${command['left-member-room']}`:
            task.enqueue(onFinish => {
                this.mate.conference.participantLeave(data.payload, data.payload.group_id);
                onFinish();
            });
            break;

        //  메시지 전달
        case `Event.${command['recive-notification']}`:
            this.mate.conference.getNotification(data.payload);
            break;
    
    }

    // 오직 메인 룸에서
    if (!this.mate.groupId) {
        switch (data.class) {
            // 사용자 session bridge 
            case `Event.${command['set-jitsi-id']}`:
                this.mate.conference.setJitsiId(data.payload);
                break;

            // role 변경 
            case `Event.${command['set-role']}`:
                this.mate.conference.setParticipantRole(data.payload, data.payload.group_id);
                break;

            /**
             * 그룹
             */
            // 그룹 이름이 변경된 경우
            // case `${command['group-name-changed']}`:
            // case `${command['group-remove']}`:
            //     this.mate.conference.setGroup(data.payload);
            //     break;
                
            // 그룹 상태가 변경된 경우
            // case `${command['group-changed']}`:    
            //     if (data.payload.status === "opened") this.mate.conference.startGroup(data.payload);
            //     else this.mate.conference.endGroup(data.payload);
            //     break; 

            // // 그룹이 할당된 경  우 
            // case `${command['group-assined']}`:
            //     this.mate.conference.assginGroup(data.payload);
            //     break;
            // // 그룹 할당 해지
            // case `${command['group-member-left']}`:
            //     this.mate.conference.unsetGroup(data.payload);
            //     break;

            // 문서 공유 
            case `Meeting.Document.StatusChanged`:
                this.mate.conference.statusChanged(data.payload);
                break;
                
            // 그룹 상태 수정 
            case `${command['group-name-changed']}`:
            case `${command['group-changed']}`:
            case `${command['group-raised']}`:
            // case `${command['group-member-left']}`:
            // case `${command['group-assined']}`:
                this.mate.conference.setGroup(data.payload);
                break;

            case `Event.${command['group-status']}`:
                this.mate.conference.setGroupRunnigStatus(data.payload);
                break;

        }
    }

    if (currentGroupId && !this.mate.groupId) return;
    switch (data.class) {
        // 중복 사용자 강퇴 
        case `Event.${command['member-kicked']}`:
            this.mate.conference.kickMemeber(data.payload);
            break;
        /**
         * 문서
         */
        
        // 문서 저장
        case `Event.${command['set-document']}`:
            this.mate.conference.setDocument(data.payload);
            break;
        // 문서 삭제
        case `Event.${command['del-document']}`:
            this.mate.conference.delDocument(data.payload);
            break;
        // 문서 캔버스 저장
        case `Event.${command['set-canvas']}`:
            this.mate.conference.setCanvas(data.payload);
            break;
        // 문서 캔버스 삭제
        case `Event.${command['del-canvas']}`:
            this.mate.conference.delCanvas(data.payload);
            break;

        /**
         * 발표권
         */
        // 발표자 요청
        case `Event.${command['request-presentation']}`:
            this.mate.conference.requestPresentation(data.payload);
            break;
        // 발표자 응답
        case `Event.${command['response-presentation']}`:
            this.mate.conference.responsePresentation(data.payload);
            break;

        /**
         * 레이아웃
         */
        // 화면 가시성
        case `Event.${command['set-layout-visiblity']}`:
            this.mate.conference.setLayoutViewVisible(data.payload);
            break;
        // focus item 
        case `Event.${command[videoLayoutMode.document]}`:
            this.mate.conference.setFocusDocument(data.payload, videoLayoutMode.document); // payload, isWhiteBoard
            break;
        case `Event.${command[videoLayoutMode.white]}`:
            this.mate.conference.setFocusDocument(data.payload, videoLayoutMode.white);
            break;
        // 레이아웃 모드 
        case `Event.${command[videoLayoutMode.grid]}`:
            // this.mate.conference.setGridOption(data.payload);
        case `Event.${command[videoLayoutMode.pin]}`:
        case `Event.${command[videoLayoutMode.voice]}`:
        case `Event.${command[videoLayoutMode.seminar]}`:
            this.mate.conference.setLayoutMode(data.class);
            break;
        // 화면 공유 
        case `Event.${command[videoLayoutMode.screen]}`:
            this.screenUserId = data.payload.sharing_uuid;

            this.mate.conference.setScreenOption(data.payload);
            break;
        // 핀 모드 옵션 
        case `Event.${command['member-add-pin']}ed`:
            this.mate.conference.setPinOption(data.payload, true);
            break;
        case `Event.${command['member-del-pin']}d`:
            this.mate.conference.setPinOption(data.payload, false);
            break;

        // 녹화 
        case `Event.${command['set-member-record']}`:
            this.mate.conference.setRecording(data.payload, false);
            break;

        /**
         * vod
         */
        // vod 선택
        case `Event.${command['focus-vod-item']}`:
            this.mate.conference.selectVodItem(data.payload);
            break;
        // vod 등록
        case `Event.${command['set-vod-file']}`:
        case `Event.${command['set-vod-url']}`:
            this.mate.conference.setVodList(data.payload);
            break;
        // vod 삭제
        case `Event.${command['del-vod-file']}`:
        case `Event.${command['del-vod-url']}`:
            this.mate.conference.deleteVodItem(data.payload);
            break;

        /**
         * 채팅
         */
        case `Event.${command['send-text']}`:
        case `Event.${command['send-private-text']}`:
            this.mate.conference.sendMessage(data.payload);
            break;
            
        /**
         * 설문 조사
         */
        // 설문 조사 알림
        case `Event.${command['share-survey']}`:
            this.mate.conference.notifySurvey(data.payload);
            break;

        // 설문 조사 응답 알림
        case `Event.${command['reply-survey']}`:
            this.mate.conference.replySurvey(data.payload);
            break;

        /**
         * 사용자
         */
        
        // 강퇴
        case `Event.${command['member-kick']}ed`:
            this.mate.conference.kickMemeber(data.payload);
            break;
        // 카메라
        // 마이크 
        case `Event.${command['set-video-true']}`:
        case `Event.${command['set-all-video-true']}`:
            this.mate.conference.setVideoMuted(true);
            break;

        case `Event.${command['set-audio-true']}`:
        case `Event.${command['set-all-audio-true']}`:
            this.mate.conference.setAudioMuted(true);
            break;

        case `Event.${command['set-video-false']}`:
        case `Event.${command['set-all-video-false']}`:
            this.mate.conference.setVideoMuted(false);
            break;

        case `Event.${command['set-audio-false']}`:
        case `Event.${command['set-all-audio-false']}`:
            this.mate.conference.setAudioMuted(false);
            break;

        /**
         * 출석 확인
         */
        case `${command['start-attendance']}`:
            this.mate.conference.startAttendance(data.payload);
            break;

        /**
         * 손들기 
         */
        case `Event.${command['set-handler-up']}`:
            this.mate.conference.raiseHand(data.payload, true);
            break;
        case `Event.${command['set-handler-down']}`:
            this.mate.conference.raiseHand(data.payload, false);
            break;
        
        /**
         * policy
         */
        case `Event.${command['set-policy']}d`:
            this.mate.conference.setPolicy(data.payload);
            break;
    } 
}

// 메시지 요청 
MateEventManager.prototype.sendCommand = function(clazz, payload, timeout = 5000) {   
    try {
        const websocket = this.mate.websocket;
        if (!websocket || websocket.readyState !== WebSocket.OPEN) {
            // websocket 연결 끊겼을 때 재연결 시도 할것인가??
            console.log("웹소켓 연결 안됨.", payload)
            return Promise.resolve();
        }

        const meeting_id = APP.mateManagement.getMeetingId();
        const group_id = payload?.group_id || this.mate.groupId || "";
        const requestID = `${Date.now()}-${++this.nextId}`;

        websocket.send( 
            JSON.stringify({ 
                class: clazz, 
                id: requestID, 
                payload: {
                    ...payload,
                    group_id,
                    meeting_id 
                } 
            }) 
        );

        return this._waitFor((data) => JSON.parse(data), (res) => res?.payload?.id === requestID || res?.id === requestID, timeout, clazz);
    } catch (err) {
        console.log(err);
    }
}

MateEventManager.prototype._subscribe = function(handle) {
    this.onMessage.push(handle);

    return () => {
        const index = this.onMessage.findIndex((element) => {
            return element == handle;
        });
        
        if (index >= 0) {
            this.onMessage.splice(index, 1);
        }
    };
}

MateEventManager.prototype._waitFor = async function(hook, check, timeout, clazz) {
    return new Promise((resolve, reject) => {
        let timerID;
        let unsubcribe;
        
        const cleanup = () => {
            clearTimeout(timerID);

            unsubcribe();
        };

        unsubcribe = this._subscribe((data) => {
            try {
                const result = hook(data);
                if (!check(result)) return;
          
                cleanup();
                resolve(result);
            } catch (err) {
                cleanup();
                reject(err);
            }
        });
        
        timerID = setTimeout(() => {
            cleanup();
            reject('time out' + clazz);
        }, timeout);
    });
}


// set-auth
MateEventManager.prototype.setAuth = function() {
    const access_token = APP.mateManagement.getAccessToken();

    return this.sendCommand(command['auth-setting'], { access_token });
}

// 입장
MateEventManager.prototype.join = function(result_only, change_role) {
    const nickname = getDisplayName(getState()) || "test";
    const role = change_role || getCookieValue('meeting_role');
    const password = getState()['features/room-lock'].password;

    return this.sendCommand(command['join-room'], { 
        password,
        nickname,
        type: "",
        role_name_forced: role,
        result_only: result_only
    });
}

// 퇴장
MateEventManager.prototype.leave = function() {
    if (!this.mate.groupId || this.mate.groupId === "") {
        const tracks = getTrackState(APP.store.getState());
        APP.store.dispatch(_disposeAndRemoveTracks(getLocalTracks(tracks).map(t => t.track) ));
    }

    return this.sendCommand(command['left-room'], null);
}

// set bridge session id 
MateEventManager.prototype.setJitsiId = function (bridge_session_id) {
    const localMemberID = getLocalUserUUID(getState());

    return this.sendCommand(command['set-jitsi-id'], { 
        bridge_session_id, user_id: localMemberID 
    });
}

/**
 * 발표권
 */
// 요청 (발표자)
MateEventManager.prototype.requestPresentation = function() {
    const local = getLocalMember(getState);
    return this.sendCommand(command['request-presentation'], { id: local.member_uuid });
}
// 응답 (발표자)
MateEventManager.prototype.responsePresentation = function(user_uuid, accepted) {
    this.sendCommand(command['response-presentation'], { user_uuid, accepted });
}

/**
 * 손들기
 */
MateEventManager.prototype.raiseHand = function(user_uuid, handler) {
    const event = (handler === true) ? command['set-handler-up'] : command['set-handler-down'];

    return this.sendCommand(event,  { user_uuid });
}

/**
 * 사용자
 */
// role 변경
MateEventManager.prototype.setParticipantRole = function(user_uuid, role) {
    return this.sendCommand(command['set-role'], { user_uuid, role });
}
// 강퇴
MateEventManager.prototype.kickMemeber = function(user_uuid) {
    return this.sendCommand(command['member-kick'], { user_uuid });
}
// 카메라 
MateEventManager.prototype.setMemberCamera = function(user_uuid, muted) {
    if (user_uuid === 'all') {
        this.sendCommand(command[`set-all-video-${muted}`]);
    } else {
        this.sendCommand(command[`set-video-${muted}`], { user_uuid });
    }
}
// 마이크
MateEventManager.prototype.setMemberMic = function(user_uuid, muted) {
    if (user_uuid === 'all') {
        this.sendCommand(command[`set-all-audio-${muted}`]);
    } else {
        this.sendCommand(command[`set-audio-${muted}`], { user_uuid });
    }
}

/**
 * 레이아웃
 */
// 레이아웃 모드 
MateEventManager.prototype.setLayoutMode = async function(mode, viewVisible) {
    let response;
    switch (mode) {
        case videoLayoutMode.grid:
            response = await this.setGridCount();
            break;

        case videoLayoutMode.pin:        
        case videoLayoutMode.voice:
        case videoLayoutMode.seminar:
            response = await this.sendCommand(command[mode], null); 
            break;

        case videoLayoutMode.document:
        case videoLayoutMode.white:
        case videoLayoutMode.note:
        case videoLayoutMode.vod:
        case videoLayoutMode.screen: 
            response = { status: 200 };
            break;
    }

    if (response.status === 200) {
        return this.sendCommand(command['set-layout-visiblity'], viewVisible);
    }

    console.log("error")
    return null;
}
// 그리드 모드 set count
MateEventManager.prototype.setGridCount = function(count) {
    if (!count) count = getGridCount(getState);
    
    return this.sendCommand(command[videoLayoutMode.grid], { grid_count: count });
}
// 문서 모드 set focus item 
MateEventManager.prototype.focusShareItem = function(uuid, index, mode) {        
    return this.sendCommand(command[mode], { uuid, index });
}
// 화면 공유 모드 set option 
MateEventManager.prototype.setScreenOption = function(jitsi_id) {
    const member = (jitsi_id) ? getMemberByUserID(getState, getLocalIDMember(getState)) : null; 

    return this.sendCommand(command[videoLayoutMode.screen], { jitsi_id, member_uuid: member ? member.member_uuid : null });    
}
// 핀 모드 set pin 
MateEventManager.prototype.setMemberPinned = function({ user_uuid, pinned }) {
    const event = pinned ? command['member-add-pin'] : command['member-del-pin'];

    return this.sendCommand(event, { user_uuid });
}

/**
 * 녹화
 */
// 녹화 set
MateEventManager.prototype.setRecording = function(user_uuid, recording) {
    return this.sendCommand(command['set-member-record'], { recording, user_uuid });
}

/**
 * 메시지 알림
 */
MateEventManager.prototype.sendNotification = function(message, group_id) {
    return this.sendCommand(command['send-notification'], { message, group_id });
}

/**
 * VOD
*/
// vod 등록
MateEventManager.prototype.setVodList = function(file) {
    if (file.type === VOD_FILE) {
        this.sendCommand(command['set-vod-file'], { ...file });
    } else if (file.type === VOD_URL) {
        this.sendCommand(command['set-vod-url'], { ...file });
    }
}
// vod 삭제
MateEventManager.prototype.deleteVodItem = function(video_uuid) {
    const item = getMediaItem(getState(), video_uuid);

    if (item.type === VOD_FILE) {
        this.sendCommand(command['del-vod-file'], { video_uuid });
    } else if (item.type === VOD_URL) {
        this.sendCommand(command['del-vod-url'], { video_uuid });
    }
}
// focus vod
MateEventManager.prototype.selectVodItem = function(video_uuid) {
    this.sendCommand(command['focus-vod-item'], { video_uuid });
}

/**
 * 채팅 
 */
// 전체
MateEventManager.prototype.sendTextMessage = function(message) {
    this.sendCommand(command['send-text'], { message: JSON.stringify(message) });
}
// 개인
MateEventManager.prototype.sendPrivateTextMessage = function(participants, message) {
    participants.map(p => {
        this.sendCommand(command['send-private-text'], { message: JSON.stringify(message), user_uuid: p.id });
    });
}

/**
 * 설문조사
 */
MateEventManager.prototype.notifySurvey = function(survey_id) {
    this.sendCommand(command['share-survey'], { survey_id });
}
MateEventManager.prototype.replySurvey = function(survey_id) {
    this.sendCommand(command['reply-survey'], { survey_id });
}

/**
 * 문서
 */
// 문서 저장
MateEventManager.prototype.shareItem = function(data) {
    return this.sendCommand(command['set-document'], data);
}
// 문서 삭제
MateEventManager.prototype.deleteShareItem = function(uuid) {
    return this.sendCommand(command['del-document'], { uuid });
}
// 문서 캔버스 내용 
MateEventManager.prototype.sendSharePoints = function(uuid, index, key, property) {    
    return this.sendCommand(command['set-canvas'], { uuid, index: String(index), key, value: property });
}
// 문서 캔버스 내용 삭제
MateEventManager.prototype.deleteShareLine = function(uuid, index, key, isAll) {    
    return this.sendCommand(command['del-canvas'], { uuid, index: String(index), key, isAll });
}
// 문서 캔버스 내용 삭제
MateEventManager.prototype.deleteShareCanvasIndex = function(uuid, index) {    
    return this.sendCommand(command['del-canvas'], { uuid, index: String(index), key: null, isAll: true });
}

/**
 * 그룹
 */
// 그룹 상태 수정 
MateEventManager.prototype.setGroupRunnigStatus = function(running) {
    return this.sendCommand(command['group-status'], { running });
}

// 캔버스 내용 변환 
function stringifyCanvasLine(canvasList) {
    let list;
    
    canvasList.forEach((value, index) => {
        list = {
            ...list, 
            [index]: Object.fromEntries(value)
        };
    });

    return list === undefined ? null : list;
}

/**
 * 방 권한 설정
 */
MateEventManager.prototype.updatePolicy = function(policy) {
    this.sendCommand(command['set-policy'], policy);
}