import { CLEAR_ROOM } from "../base/conference";
import { ReducerRegistry } from "../base/redux";
import { ADD_SHARED_SURVEY, SET_SURVEY_REPLIED } from "./actionType";

const DEFAULT_STATE = {
    sharedSurvey: new Map(),
    lastRepliedUUID: null
};

ReducerRegistry.register('features/survey', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case CLEAR_ROOM:
            return {
                sharedSurvey: new Map(),
                lastRepliedUUID: null
            }
        case ADD_SHARED_SURVEY:
            var survey = state.sharedSurvey;
            let data = action.data;
        
            if (!data?.replied) {
                data.replied = new Set();
            } else if (Array.isArray(data.replied)) {
                data.replied = new Set(data.replied);
            }
            
            survey.set(action.uuid, data);

            return {
                ...state,
                sharedSurvey: survey
            };
        
        case SET_SURVEY_REPLIED:
            const value = state.sharedSurvey.get(action.uuid);
            
            if (value) {
                var survey = state.sharedSurvey;
                value.replied.add(action.replied);

                survey.set(action.uuid, value);

                return {
                    ...state, 
                    sharedSurvey: survey,
                    lastRepliedUUID: `${action.uuid}_${action.replied}`
                }
            }
            break;
    }

    return state;
});
