import { getConferenceError, getRoomNameFromUrl, getRoomOption } from "../conference";
import { getDisplayName } from "../settings";
import { setAppPage } from "./action";
import { PAGE_TYPE } from "./constants";

export const getCurrentPage = (state) => {
    return state['features/base/app'].page;
}

export const enableByPage = (state, page) => {
    const isEnable = state['features/base/app'].enable[page];

    return isEnable || false;
}

export const pageLoadComplete = (state) => {
    return state['features/base/app'].load;
}

export function getCurrentPageByURL() {
    const url = location.href;

    if (url.includes('room')) {
        if (url.includes(PAGE_TYPE.NICKNAME)) return PAGE_TYPE.NICKNAME;
        else if (url.includes(PAGE_TYPE.DEVICE)) return PAGE_TYPE.DEVICE;
        
        return PAGE_TYPE.MAIN;
    } else if (url.includes(PAGE_TYPE.ERROR)) return PAGE_TYPE.ERROR;
    
    return PAGE_TYPE.LOBBY;
}

export function checkEnabaledPage({ dispatch, getState }, page) {
    const state = getState();

    if (PAGE_TYPE.ERROR === page) return false;
    
    if (enableByPage(state, page) === false) {
        // 유효한 uuid 인지 확인 필요 & nickname 확인 필요 
        const conferenceError = getConferenceError(state);
        const nickname = getDisplayName(state);
        const uuid = getRoomNameFromUrl(location.pathname) || getRoomOption(state).uuid;

        if (nickname && !conferenceError && uuid) {
            dispatch(setAppPage(PAGE_TYPE.DEVICE));
        } else {
            dispatch(setAppPage(PAGE_TYPE.ERROR));
        }

        return false;
    }

    return true;
}