/* global APP */
import { browser } from '../lib-jitsi-meet';
import { MEDIA_TYPE, VIDEO_TYPE } from '../media';
import { toState } from '../redux';

export const getTrackState = state => state['features/base/tracks'];

export function isParticipantMediaMuted(participant, mediaType, state) {
    if (!participant) {
        return false;
    }

    if (participant.local) {
        const tracks = getTrackState(state);

        return isLocalTrackMuted(tracks, mediaType, "local");
    }

    return isRemoteTrackMuted(state, mediaType, participant.local ? "local" : participant.user_uuid);
}

/**
 * audio muted 여부
 */
export function isParticipantAudioMuted(participant, state) {
    return isParticipantMediaMuted(participant, MEDIA_TYPE.AUDIO, state);
}

/**
 * video muted 여부
 */
export function isParticipantVideoMuted(participant, state) {
    return isParticipantMediaMuted(participant, MEDIA_TYPE.VIDEO, state);
}

/**
 * get audio local Track 
 */
export function getLocalAudioTrack(tracks) {
    return getLocalTrack(tracks, MEDIA_TYPE.AUDIO);
}

export function getLocalTrack(tracks, mediaType, includePending = false) {
    return (
        getLocalTracks(tracks, includePending)
            .find(t => t.mediaType === mediaType));
}

export function getLocalTracks(tracks, includePending = false) {
    return tracks.filter(t => t.local && (t.track || includePending));
}

export function getLocalVideoTrack(tracks) {
    return getLocalTrack(tracks, MEDIA_TYPE.VIDEO);    
}

export function getLocalVideoType(tracks) {
    const presenterTrack = getLocalTrack(tracks, MEDIA_TYPE.PRESENTER);

    return presenterTrack ? MEDIA_TYPE.PRESENTER : MEDIA_TYPE.VIDEO;
}

export function getTrackByMediaTypeAndParticipant(
    tracks,
    mediaType,
    participantId) {
       
    return tracks.find(
        t => Boolean(t.track) && t.participantId === participantId && t.mediaType === mediaType
    );
}

export function getTracksByMediaType(tracks, mediaType) {
    return tracks.filter(t => t.mediaType === mediaType);
}

export function isLocalCameraTrackMuted(tracks) {
    // const presenterTrack = getLocalTrack(tracks, MEDIA_TYPE.PRESENTER);
    const videoTrack = getLocalTrack(tracks, MEDIA_TYPE.VIDEO);

    return videoTrack && videoTrack.muted;
    
    // if (presenterTrack) {
    //     return isLocalTrackMuted(tracks, MEDIA_TYPE.PRESENTER);
    // } else if (videoTrack) {
    //     return videoTrack.videoType === 'camera'
    //         ? isLocalTrackMuted(tracks, MEDIA_TYPE.VIDEO) 
    //         : videoTrack.videoType === "video" ? videoTrack?.muted : true;
    // }

    // return true;
}

export function isLocalTrackMuted(tracks, mediaType) {
    const track = getLocalTrack(tracks, mediaType);
    
    return !track || track.muted;
}

export function isLocalVideoTrackDesktop(state) {
    const videoTrack = getLocalVideoTrack(state['features/base/tracks']);

    return videoTrack && videoTrack.videoType === VIDEO_TYPE.DESKTOP;
}

export function isRemoteTrackMuted(state, mediaType, user_uuid) {
    try {
        let trackId;
        if (user_uuid === "local") {
            trackId = "local";
        } else {
            const { bridgeParticipant } = state['features/'];
            trackId = bridgeParticipant.get(user_uuid);
        }

        const track = getTracksByParticipantId(state, trackId, mediaType);

        return !track || track.muted;
    } catch (err) {
        console.log(err)
        return false;
    }    
}

export function isUserInteractionRequiredForUnmute(state) {
    return browser.isUserInteractionRequiredForUnmute()
        && window
        && window.self !== window.top
        && !state['features/base/user-interaction'].interacted;
}

export function setTrackMuted(track, muted) {
    muted = Boolean(muted);

    if (track.muted === muted) {
        return Promise.resolve();
    }

    const f = muted ? 'mute' : 'unmute';

    return APP.mateManagement.setTrackMuted(track, f);
}

export function getTracksByParticipantId(stateful, jitsiId, mediaType) {
    const state = toState(stateful)
    const tracks = getTrackState(state);
    
    return tracks && tracks.filter(t => t.participantId === jitsiId)
        .find(t => t.mediaType === mediaType);
}

// export function getAudioTrackInGroup(state, includeLocal) {
//     const participants = getSortedParticipantJitsiIds(state);
//     const tracks = state['features/base/tracks'];
//     const sharingVideo = state['features/screen-share'].sharingVideo;

//     if (includeLocal) participants.push("local");

//     const track =  tracks.filter(t => ((participants.indexOf(t.participantId) !== -1 || t.participantId === sharingVideo.track_id ) && t.mediaType === MEDIA_TYPE.AUDIO));
//     return track;
// }