import React, { useEffect, useState } from "react";
import { GroupStyled } from "./styles";
import { G_DELET_POP, G_FOCUS_ITEM, G_FOCUS_PP_LIST, G_GUIDE, G_MODIFY_POP, G_PP_LIST, G_RANDOM_MAKE, G_STATUS } from "../constants";
import { ClickButton } from "../../base/buttons";
import { useTranslation } from "react-i18next";
import AddGroupButton from "./AddGroupButton";
import GroupList from "./GroupList";
import DeleteGroup from "./DeleteGroup";
import ModifyGroup from "./ModifyGroup";
import RandomGroup from "./RandomGroup";
import GroupMemberList from "./GroupMemberList";
import FocusGroup from "./FocusGroup";
import AddGroupMember from "./AddGroupMember";

export default function GroupLayout({
    alertMessage
}) {
    const { t } = useTranslation();
    const [ show, setShow ] = useState(G_PP_LIST);
    const [ popup, setPopup ] = useState(null);
    const [ popItem, setPopItem ] = useState(null);
    const [ focusItem, setFocusItem ] = useState(null);

    useEffect(() => {
        if (focusItem) setShow(G_FOCUS_ITEM);
    }, [focusItem]);

    useEffect(() => {
        if (G_FOCUS_ITEM !== popItem &&
            G_FOCUS_PP_LIST !== popItem ) {
                setFocusItem(null);
            }
    }, [popItem]);

    const renderMenuButton = (name) => {
        const onClick = () => {
            if (G_RANDOM_MAKE === name) setPopup(G_RANDOM_MAKE);
            else setShow(name);
        }

        return (
            <ClickButton key={name} size={16} text={t(`group.${name}`)} checked={show === name} onClick={onClick} />
        )
    }

    const getContent = type => {
        switch (type) {
            case G_GUIDE:
                return (
                    <>
                        <div className="section section_button"> 
                            { [G_PP_LIST, G_RANDOM_MAKE, G_STATUS].map(name => renderMenuButton(name)) }
                        </div>
                        <AddGroupButton t={t} alertMessage={alertMessage} />
                        <GroupList t={t} detail={false} alertMessage={alertMessage} 
                            setPopup={setPopup} setPopItem={setPopItem} setFocusItem={setFocusItem}
                        />
                    </>
                )
        }
    }

    const getPopup = () => {
        if (!popup) return null;
        
        switch (popup) {
            case G_DELET_POP:
                return <DeleteGroup t={t} popItem={popItem} setPopup={setPopup} alertMessage={alertMessage} />

            case G_MODIFY_POP:
                return <ModifyGroup t={t} popItem={popItem} setPopup={setPopup} alertMessage={alertMessage} />

            case G_RANDOM_MAKE:
                return <RandomGroup t={t} setPopup={setPopup} alertMessage={alertMessage} />
        }
    }

    const getRight = () => {
        switch (show) {
            case G_PP_LIST:
                return <GroupMemberList alertMessage={alertMessage} />

            case G_STATUS:
                return <GroupList t={t} isMobile={false} detail={true} alertMessage={alertMessage} 
                    setPopup={setPopup} setPopItem={setPopItem} setFocusItem={setFocusItem} />

            case G_FOCUS_ITEM:
                return <FocusGroup t={t} isMobile={false} focusItem={focusItem} setShow={setShow} />

            case G_FOCUS_PP_LIST:
                return <AddGroupMember t={t} isMobile={false} focusItem={focusItem} setShow={setShow} />

        }
    }
    
    return (
        <>
            <GroupStyled style={{ filter: `${popup ? "blur(4px)" : "none"}` }}>
                <div className="article article_left"> { getContent(G_GUIDE) } </div>

                <div className="article article_right">
                    { getRight() }
                </div>
            </GroupStyled>
            
            { popup && 
                <>
                    <div style={{position: "absolute", top: "0", left: "0", background: "rgba(0, 0, 0, 0.2)",  width: "100%", height: "100%", zIndex: "2"}}> </div> 
                    { getPopup() }
                </>
            }
        </>
    )
}
