import { CLEAR_ROOM, getGroupId } from "../base/conference";
import { MiddlewareRegistry } from "../base/redux";
import { getGroupStatus, getStartGroup, getStartGroupById, startLocalGroup } from "../groups";
import { NOTIFICATION_TIMEOUT, showNotification, showParticipantJoinedNotification } from "../notifications";
import { ADD_LOCAL_MEMBER, ADD_MEMBER, DELETE_MEMBER, LEFT_MEMBER } from "./actionType";
import { leftMember, setMemberPinned, setMemberRecord, updateMember } from "./actions";
import { PARTICIPANT_STATUS } from "./constants";
import { getGroupMemberByUserId, getLocalIDMember } from "./functions";

MiddlewareRegistry.register(store => next => action => {
    const { getState, dispatch } = store;

    let member;
    let notification = true;
    switch (action.type) {
        case ADD_LOCAL_MEMBER:
        case ADD_MEMBER:        
            member = action.member;
        
            store.dispatch(setMemberPinned(member.user_uuid, member.pinned, true));
            store.dispatch(setMemberRecord(member.user_uuid, member.recording, true));

            if (member.status === PARTICIPANT_STATUS.OCCUPIDE && !member.local) {
                // 이미 입장해 있던 회원
                let currentGropuId;
                if (member.existed) {
                    const group = startLocalGroup(getState);
                    if (group.start) currentGropuId = group.group_id;
                } else {
                    currentGropuId = getGroupId(getState);
                }
                
                const startGroup = getStartGroup(getState, true);

                if (currentGropuId) {
                    if (startGroup && startGroup.has(currentGropuId)) {
                        notification = false;
                    }
                }

                notification && store.dispatch(showParticipantJoinedNotification(member.nickname, member.existed));
            }
            break;

        case DELETE_MEMBER:
            member = action.member;
            store.dispatch(updateMember({
                ...member,
                status: PARTICIPANT_STATUS.VACATED
            }));

            // 이미 입장해 있던 회원
            let currentGropuId;
            currentGropuId = getGroupId(getState);            
            const startGroup = getStartGroup(getState, true);

            if (currentGropuId) {
                if (startGroup && startGroup.has(currentGropuId)) {
                    notification = false;
                }
            }

            notification && store.dispatch(showNotification({ 
                titleKey: 'notify.disconnected', titleArguments: {name: member.nickname} 
            }, NOTIFICATION_TIMEOUT));
            break;

        case CLEAR_ROOM:
            const members = store.getState()["features/memeber"].members;

            members.forEach(element => {
                store.dispatch(leftMember(element));
            });            
            break;
    }

    return next(action);
});