import React from 'react';

import { formValidation } from '../function';

const btnText = {
    true: "account.complete",
    false: "account.checkCode"
};

export default function CheckCertCodeButton({
    t, uuid, certCode, isSendMailComplete, isCheckCodeComplete,
    clearRemainingTime, setRemainingTime, setErrorData, setIsCheckCodeComplete
}) {
    const onClick = async () => {
        if (!isCheckCodeComplete) {
            let errorMsg = formValidation(t, "certCode", certCode);
            
            errorMsg = !isSendMailComplete ? t("inputErrNeedEmail") : errorMsg;

            if (errorMsg !== "") {
                setErrorData({ "certCode": errorMsg });
                return;
            }                

            const { complete, message } = await APP.API.checkCertificationCode({ code: certCode, uuid });
            setIsCheckCodeComplete(complete);
            if (complete) {
                clearRemainingTime();
                setRemainingTime(0);
                return;
            }
            setErrorData({"certCode": t(message) });
        }
    }

    return (
        <>
            <span className="btn btn_small" onClick={onClick}>
                { t(btnText[isCheckCodeComplete]) }
            </span>
        </>
    );
}