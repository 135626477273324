// @flow
import { CONFERENCE_LEFT } from '../conference';
import { ReducerRegistry } from '../redux';

import { SETTINGS_UPDATED, SET_DUAL_OPTION } from './actionTypes';

const DEFAULT_STATE = {
    audioOutputDeviceId: undefined,
    cameraDeviceId: undefined,
    disableCallIntegration: undefined,
    disableCrashReporting: undefined,
    disableP2P: undefined,
    displayName: 'test',
    email: undefined,
    micDeviceId: undefined,
    serverURL: undefined,
    startAudioOnly: false,
    startWithAudioMuted: false,
    startWithVideoMuted: false,
    
    initial_camera: true,
    initial_mic: true,
    hands_up: "keep",
    hands_up_time: 10000,

    request_camera: true,
    request_mic: true,
    userSelectedAudioOutputDeviceId: undefined,
    userSelectedCameraDeviceId: undefined,
    userSelectedMicDeviceId: undefined,
    userSelectedAudioOutputDeviceLabel: undefined,
    userSelectedCameraDeviceLabel: undefined,
    userSelectedMicDeviceLabel: undefined,
    userSelectedSkipPrejoin: undefined,
    
    userSelectedCameraMuted: false,
    userSelectedAudioMuted: false,

    speakerVolume: 0.5,
    dualOption: false
};

ReducerRegistry.register('features/base/settings', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SETTINGS_UPDATED:
            return {
                ...state,
                ...action.settings
            };
        case CONFERENCE_LEFT:
            return {
                ...DEFAULT_STATE,
                displayName: state.displayName
            }

        case SET_DUAL_OPTION:
            return {
                ...state,
                dualOption: action.option
            }
    }

    return state;
});