import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { translate } from "../../base/i18n";
import { DEFAULT_DOCUMENT, DEFAULT_INDEX } from "../constants";
import DocumentAction from "../DocumentAction";
import { getDocumentItemByUUID } from "../functions";
import { connect } from "../../base/redux";
import { ClickButton } from "../../base/buttons";
import { MdDelete, MdShare, MdSkipPrevious } from "react-icons/md";

function DocumentItem({
    t, dispatch, uuid, isSharedPermission, focus, focusUuid, focusIndex, item, isFocusItem, isFocus, setFocus, mode
}) {
    const [imageList, setImageList] = useState([]);
    const [labelList, setLabelList] = useState(['shared', 'title', 'delete']);

    const { isWhiteBoard, index, file } = item;

    const handlerFocusItem = (e, uuid, index) => {
        e.preventDefault();
        if (!isFocus && !isWhiteBoard && item.list?.length > 1) {
            setFocus(uuid);            
        } else DocumentAction.focusShareItem(dispatch, uuid, index, mode);
    };

    const handlerDeleteItem = () => {
        DocumentAction.deleteShareItem(dispatch, uuid);
    };

    const handlerShareDocument = () => {
        DocumentAction.currentShareItem(dispatch, uuid, isWhiteBoard);
    }

    const handlerFocusOut = e => {
        setFocus(null);
    }

    useEffect(() => {
        if (isFocusItem) {
            if (!isWhiteBoard && item.list?.length > 1) {
                setFocus(uuid);
            }
        }
    }, [isFocusItem, item?.list?.length]);


    useEffect(() => {
        if (isWhiteBoard && uuid === DEFAULT_DOCUMENT) {
            let label_list = ['title'];
            setLabelList(label_list)
            return;
        }

        let label_list = ['title', isSharedPermission && 'shared', isSharedPermission && 'delete'];

        if (isFocus) {
            if (!isWhiteBoard) label_list = ['title', 'previous', isSharedPermission && 'shared',  isSharedPermission && 'delete'];
            else label_list = ['title','previous', isSharedPermission && 'shared', isSharedPermission && 'delete'];
        }

        setLabelList(label_list);
    }, [isFocus, isSharedPermission]);

    const _renderLabel = () => {
        return (
            <div key="label" className={`item_label ${isFocusItem ? 'focus' : 'null'}`}>
                { labelList.map(label => {
                    if (!label) return null;
                    return (
                        <div key={`${label}_${uuid}`} className={`label_child ${label}`} 
                            onClick={ label === 'title' ? (e) => handlerFocusItem(e, uuid, DEFAULT_INDEX) : null}>
                            { label === 'title' ? 
                                file.name !== "" ? file.name : t("document.whiteBoard") :
                                label === 'previous' ? <MdSkipPrevious size={22} onClick={handlerFocusOut} /> :
                                label === 'delete' ?  <MdDelete size={16} onClick={handlerDeleteItem} /> : 
                                label === 'shared' ? <MdShare size={16} onClick={handlerShareDocument} /> : null }
                        </div>
                    )
                }) }

                { item.shared && <div className="share_label"></div>}
            </div>
        )
    }

    const _renderImage = () => {
        if (item.isWhiteBoard) return null;
        return (
            <div key="image" className="item_image_wrapper">
                { item.complete ? 
                    <DocumentImage uuid={item.uuid} list={item.list} 
                        isFocus={isFocus} focusIndex={isFocusItem ? focusIndex : null}
                        isSharedPermission={isSharedPermission}
                        handlerFocusItem={handlerFocusItem} /> 
                    : <div> 변환중 </div> }
            </div>
        )
    }

    if (focus && !isFocus) return null;

    const contents = [
        _renderLabel(),
        _renderImage()
    ].filter(Boolean);

    return (
        <div className="share_item"> 
            { contents }
        </div>
    )
}

function DocumentImage({ uuid, list, isFocus, focusIndex, isSharedPermission, handlerFocusItem }) {
    const [imageList, setImageList] = useState([]);

    useEffect(() => {
        if (isFocus) setImageList(list);
        else setImageList(list.slice(0, 1));
    }, [list, isFocus]);

    return (                
        <div className={`item_image ${!isSharedPermission && 'no-pointer'}`}>
            { imageList.map((item, index) => {
                return (
                    <LazyLoadImage key={item.thumb_url} 
                        className={index === focusIndex ? "focus_img" : "img"}
                        draggable={false} 
                        alt={<div>로딩</div>}
                        src={item.thumb_url} 
                        onClick={e => handlerFocusItem(e, uuid, index)} 
                        width={130}   
                        height={130} /> )}
                )
            }
        </div>         
    )
}

function _mapStateToProps(state: Object, ownProps) {
    const item = getDocumentItemByUUID(state, ownProps.uuid);
    return {
        item
    }
}

export default translate(connect(_mapStateToProps)(DocumentItem));