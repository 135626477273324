// @flow
import React from 'react';

import { translate } from '../../../base/i18n';
import { connect } from '../../../base/redux';
import AbstractNotificationsContainer, { _abstractMapStateToProps } from '../AbstractNotificationsContainer';
import Notification from './Notification';


class NotificationsContainer extends AbstractNotificationsContainer {
    render() {
        if (this.props._iAmSipGateway) {
            return null;
        }

        return (
            <div className="notice">
                { this._renderFlags() }
            </div>
        );
    }

    _renderFlags() {
        const { _notifications } = this.props;

        return _notifications.map(notification => {
            const { props, uid } = notification;
            
            return (
                <Notification
                    data= { props }
                    id = { uid }
                    key = { uid }
                    onDismissed = { this._onDismissed }
                    uid = { uid } />
            );
        });
    }
}

export default translate(connect(_abstractMapStateToProps)(NotificationsContainer));
