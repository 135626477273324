import { VOD_FILE } from "./constants";

export function getMediaList(state) {
    return state['features/base/vod'].list;
}

export function getMediaItem(state, uuid) {
    const list = getMediaList(state);
    const file = list.get(uuid);

    if (!file) return null;
    return file;
}

export function getFocusVodItem(state) {
    const uuid = state['features/base/vod'].vod_shared_video_uuid;

    if (uuid) return getMediaItem(state, uuid);

    return null;
}

export function getChannelID(state, uuid) {
    const list = getMediaList(state);
    const file = list.get(uuid);

    if (!file) return null;
    if (file.type === VOD_FILE) {
        return file.channel_id;
    }
}

export function extractYoutubeIdOrURL(input: string) {
    if (!input) {
        return;
    }

    const trimmedLink = input.trim();

    if (!trimmedLink) {
        return;
    }

    const youtubeId = getYoutubeId(trimmedLink);

    if (youtubeId) {
        return youtubeId;
    }

    try {
        new URL(trimmedLink);
    } catch (_) {
        return;
    }

    return trimmedLink;
}

function getYoutubeId(url: string) {
    if (!url) {
        return null;
    }

    const p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|(?:m\.)?youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;// eslint-disable-line max-len
    const result = url.match(p);

    return result ? result[1] : null;
}