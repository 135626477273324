import React from "react";
import { IconButton } from "../../base/buttons";
import { translate } from "../../base/i18n";
import { DEVICE_SETTING_MODAL_ID } from "../../options";

function SwitchDeviceSetButton({ t }) {
    if (!APP.layout.option.setDevice) return null; 

    const onClick = () => {
        APP.UI.openModal(DEVICE_SETTING_MODAL_ID);
    }

    return (
        <IconButton mode="setDevice" name={t("options.deviceSetting")} size={22} onClick={onClick} />
    );
}

export default translate(SwitchDeviceSetButton);