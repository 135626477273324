import axios from "axios";

function objectToQueryString(obj) {    
    return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
}

// 미참석 알림
export async function nVacatedMember({meeting_uuid, aToken}) {
    const response = await axios({
        method: "POST",
        url: `/api/meeting/v1/meetings/${meeting_uuid}/notify-vacated-member`,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        }
    });

    return response;
}

// 회의실 종료 
export async function closeRoom({
    meeting_uuid, aToken
}) {
    const response = await axios({
        method: "POST",
        url: `/api/meeting/v1/meetings/${meeting_uuid}/close`,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        }
    });

    return response;
}

export async function getAllParticipant({
    meeting_id, aToken, data, offset = 0, limit = 500
}) {
    const url = objectToQueryString(Object.assign({}, data, {offset}, {limit}));

    const response = await axios({
        method: "GET",
        url: `/api/meeting/v1/members?meeting_id=${meeting_id}&${url}`,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        }
    });

    return response;
}

// 회의 초대 
export async function invitationsConference({ meeting_uuid, items, aToken }) {
    const response = await axios({
        method: "PUT",
        url: `/svc/meeting/meetings/${meeting_uuid}/invitations`,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        },
        data: {
            items
        }
    });

    return response;
}
// 초대 알림
export async function notifyInvitationsConference({ meeting_uuid, aToken }) {
    const response = await axios({
        method: "POST",
        url: `/svc/meeting/meetings/${meeting_uuid}/invitations/notify`,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        },
        data: {
            method: {
                email: true,
                sms: false
            }
        }
    });

    return response;
}

// 회의 검색 
export async function searchConference({ data, aToken, offset = 0, limit = 20 }) {
    const url = objectToQueryString(Object.assign({}, { ...data }, { offset }, { limit }));
    const response = await axios({
        method: "GET",
        url: "/svc/meeting/meetings?" + url,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        },
    });

    return {
        status: response.status,
        data: response.data,
    };
}

// 회의 생성
export async function createConference(aToken) {
    const response = await axios({
        method: "POST",
        url: "/svc/meeting/meetings",
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        },
    });

    return response;
}
// 회의 시작
export async function holdConference({ aToken, name, meeting_uuid }) {
    const response = await axios({
        method: "POST",
        url: `/svc/meeting/meetings/${meeting_uuid}/hold`,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        },
        data: {
            "entry_option": null,
            name,
            "password": null,
            "progress_duration": 3600000
        }
    });

    return response;
}
// 회의 삭제
export async function deleteConfernce({ aToken, meeting_uuid }) {
    const response = await axios({
        method: "DELETE",
        url: `/api/meeting/v1/meetings/${meeting_uuid}`,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        }
    });

    return response;
}


// 회의 예약 
export async function reservationConference({
        aToken, meeting_uuid, data  
    }) {
    
    const response = await axios({
        method: "POST",
        url: `/svc/meeting/meetings/${meeting_uuid}/book`,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        },
        data: {
            ...data,
            start_time: new Date(Date.parse(data.start_time)).toISOString(),
        }
    });

    return response;
}

// 회의 속성 변경
export async function setPropertyConference({ meeting_uuid, aToken, data }) {
    const response = await axios({
        method: "PUT",
        url: `/svc/meeting/meetings/${meeting_uuid}/property`,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        },
        data: {
            ...data
        }
    });

    return response;
}

// 회의 예약 
export async function inviteGuest({ uuid, aToken, data }) {
    const response = await axios({
        method: "PUT",
        url: `/svc/meeting/meetings/${uuid}/property`,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        },
        data
    });

    return response;
}

// 회의 알림
export async function notifyConference({ uuid, aToken }) {
    const response = await axios({
        method: "POST",
        url: `/svc/meeting/meetings/${uuid}/invitations/notify`,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        },
        data: {
            method: {
                email: true,
                sms: false
            }
        },
    });

    return response;
}

// 출석 생성
export async function createAttendances({ meeting_uuid, aToken, name, check_start_time, check_duration }) { // data = { name, check_start_time, check_duration }
    const response = await axios({
        method: "POST",
        url: `/svc/meeting/meetings/${meeting_uuid}/attendances`,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        },
        data: {
            name, 
            check_start_time, 
            check_duration
        }
    });

    return response;
}
// 출석 기록 가져오기 
export async function getAttendancesCheck({ data, aToken }, offset=0, limit=200) {
    const url = objectToQueryString(Object.assign({}, {...data}, {offset}, {limit}));
    const response = await axios({
        method: "GET",
        url: "/svc/meeting/attendances?" + url,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        },
    });

    return response;
}

export async function checkedAttendances({ attendance_id, aToken }) {
    const response = await axios({
        method: "POST",
        url: `/svc/meeting/attendances/${attendance_id}/check`,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        }
    });

    return response;
}

// 증복 로그인 
export async function umCheckUserId({ meeting_uuid, user_id }) {
    const response = await axios({
        method: "GET",
        url: `/svc/meeting/members?meeting_uuid=${meeting_uuid}&user_uuid=${user_id}&status=occupied`
    });

    return response;
}

// 중복 로그인 강퇴 

export async function umKickUser({ meeting_uuid, kick_user_id, aToken }) {    
    const response = await axios({
        method: "POST",
        url: `/api/meeting/v1/meetings/${meeting_uuid}/kick`,
        headers: {
            'Authorization': 'Bearer ' + aToken,
            'Content-Type': 'application/json; charset=UTF-8',
        }, data: {
            user_id: kick_user_id
        }
    });

    return response;
}