import React, { useRef } from "react";
import { ClickButton } from "../../../base/buttons";
import { AbstractDialog } from "../../../base/dialog";
import { translate } from "../../../base/i18n";

function SendNotification({
    t, group_id, setPopup
}) {
    const content = useRef();
    const handlerClose = () => {
        setPopup(false);
    }

    const handlerSendMesssage = async () => {
        const element = content.current;
        if (element) {
            const message = element.value;
            if (message && message.trim() !== "") {
                const response = await APP.mateManagement.sendNotification(message, group_id);

                if (response.status === 200) {
                    handlerClose();
                } else {
                    console.log(response);
                }

                
            } else {
                alert(t("options.placeholderNotificationGroup"));
            }           
        }
    }
    
    return (
        <AbstractDialog name="sendMessage" width="400" handlerClose={handlerClose}>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <textarea ref={content} style={{ width: "80%", height: "6.25em", border: "none", resize: "none", padding: "12px" }}
                    placeholder={t("options.placeholderNotificationGroup")}
                />
                <ClickButton text={t("chat.send")} size={20} onClick={handlerSendMesssage} />
            </div>
        </AbstractDialog>
    )
}

export default translate(SendNotification);