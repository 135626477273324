import React from "react";
import styled from "styled-components"
import media from "../../../assets/media";
import { translate } from "../i18n";
import "../../../assets/css/dialoag.scss"

const DialogStyled = styled.div`
    ${media.desktop` 
        ${props => props.name === 'option' && `
            transform: translate(24%, 0);
        `}
    `};
    ${media.tabletV` 
        ${props => props.name === 'option' && `
            transform: translate(4%, 0);
        `}
    `};
    ${media.tabletH`
        ${props => props.name === 'option' && `
            transform: translate(-50%, -50%);
            top: 50%;
            bottom: auto;
        `}
    `};

    ${media.mobile`
        ${props => props.name === 'group' && `
            width: 100%;
            height: 100%;
        `}
    `};

    ${props => props.name === 'record' ? `
        top: 0;
        transform: translate(-50%, 0%);
        box-shadow: none;
        background: #fff;
        left: 50%;

        .header {
            color: #000;
        }

        img { width: 100%; }
        .container { text-align: center; }
    ` : props.name === 'option' ? `
        bottom: 73px;
        left: 50%;
    ` : `
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        margin: 0;
    `}

    width: ${props => props.width ? props.width + 'px' : '200px'};
    height: ${props => props.height ? props.height + 'px' : 'auto'};
    overflow: ${props => props.name === 'group' ? "visible": "auto"};
`;

function AbstractDialog({
    t, name, width, height, style, children, close, handlerClose=undefined
}) {
    const onClose = () => APP.UI.closeModal();

    return (
        <DialogStyled id="dialog" className="dialog nonSelect" name={name} width={width} height={height} style={style}>
            <div className="header">
                <h2 className="name">{ t(`options.${name}`) }</h2>
                <span className="close" onClick={handlerClose || onClose}>{t("dialog.close")}</span>
            </div>
            <div className="container">
                {children}
            </div>
        </DialogStyled>
    )
}

export default translate(AbstractDialog);
