import { debounce } from "lodash";
import { setAudioInputDeviceAndUpdateSettings, setVideoInputDeviceAndUpdateSettings, updateDeviceList } from "../../features/base/devices";
import { getCurrentCameraDeviceId, getCurrentMicDeviceId } from "../../features/base/settings";
import SoundMeter from "./SoundMeter";

export default function MateTrack() {
    this.track = null;
    
    this.meterRefresh = null;
    this.prevSoundMeter = null;
}

MateTrack.prototype.constructor = MateTrack;

// (GET) 장치 권한 및 리스트 
MateTrack.prototype.loadDevice = async function() {
    const tracks = await getStream({ video: true, audio: true });
    const devices = await getDeviceList();

    return { tracks, devices };
};

// (EVENT) 장치 변경
MateTrack.prototype.addDeviceListChange = function(handler = _remoteDevicechange) {
    navigator.mediaDevices.ondevicechange = null;
    navigator.mediaDevices.ondevicechange = debounce(async e => {        
        const devices = await getDeviceList();            

        handler(devices);
    }, 1000);
};

// (EVENT) 장치 변경 이벤트 제거 
MateTrack.prototype.removeDeviceListChange = function() { 
    navigator.mediaDevices.ondevicechange = null;
};

// (EVENT) 장치 음량
MateTrack.prototype.listenForAudioUpdates = function(stream) { 
    if (stream) {
        let meterRefresh = null;
        let context = new AudioContext();
        const soundMeter = new SoundMeter(context);

        soundMeter.connectToSource(stream, e => {
            if (e) {
                console.log(e); 
                return;
            }

            meterRefresh = setInterval(() => {
                const volume = soundMeter.instant.toFixed(2);
                APP.UI.updateAudioLevel(volume);
            }, 200);
        });

        this.meterRefresh = meterRefresh;
        this.prevSoundMeter = soundMeter;
    } else {
        this.prevSoundMeter = null;
    } 
};

// (EVENT) 장치 음량 이벤트 제거 
MateTrack.prototype.stopListenForAudioUpdates = function() {
    this.prevSoundMeter && this.prevSoundMeter.stop();

    this.meterRefresh && clearInterval(this.meterRefresh);
}

// (GET) 장치 권한 및 리스트 (비디오)
MateTrack.prototype.getVdoErrorMsg = async function(error) {
    if (!error) return {
        type: "ok",
        message: "device.cameraOk"
    };
    let message = "deviceError.cameraError";
    const { name } = error;
    
    switch (name) {
        case "NotReadableError":
            message = "deviceError.cameraError";    
            break;
        case "AbortError":
            message = "deviceError.cameraAbort";    
            break;
        case "NotAllowedError":
            message = "deviceError.cameraPermission";    
            break;
        // case "NotFoundError":
        // case "OverconstrainedError":
        // case "SecurityError":
        // case "TypeError":
        //     message = "deviceError.cameraError";
        //     break;
    }

    return {
        kind: "video",
        type: "warning",
        message
    };
};
// 오디오
MateTrack.prototype.getMicErrorMsg = async function(error) {
    if (!error) return {
        type: "ok",
        message: "device.microphoneOk"
    };
    let message = "deviceError.microphoneError";
    const { name } = error;
    
    switch (name) {
        case "NotReadableError":
            message = "deviceError.microphoneError";    
            break;
        case "AbortError":
            message = "deviceError.microphoneAbort";    
            break;
        case "NotAllowedError":
            message = "deviceError.microphonePermission";    
            break;
        // case "NotFoundError":
        // case "OverconstrainedError":
        // case "SecurityError":
        // case "TypeError":
        //     message = "deviceError.cameraError";
        //     break;
    }

    return {
        kind: "audioInput",
        type: "warning",
        message
    };
};

/**
 * event
 */
// (DISPATCH) 장치 리스트 변경 
async function _remoteDevicechange(devices) {
    const { dispatch, getState } = APP.store;
    dispatch(updateDeviceList(devices));

    const selectMicId = getCurrentMicDeviceId(getState());
    const selectVdoId = getCurrentCameraDeviceId(getState());

    const changeDeviceMic = devices.filter(item => !(selectMicId === 'default' || selectMicId === 'communications') && item.deviceId === selectMicId && item.kind === 'audioinput' );
    const changeDeviceVdo = devices.filter(item => item.deviceId === selectVdoId && item.kind === 'videoinput');

    if (changeDeviceVdo.length < 1) dispatch(setVideoInputDeviceAndUpdateSettings(-1));
    if (changeDeviceMic.length < 1) dispatch(setAudioInputDeviceAndUpdateSettings(-1));
};

// (GET) 장치 권한 얻기
function getStream(options) {
    return navigator.mediaDevices.getUserMedia(options).then(stream => {
        return stream.getTracks();
    }).catch(err => {
        return undefined;
    });
}

// (GET) 장치 리스트 
function getDeviceList() {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        // 지원하지않는 브라우저 확인 불가능 
        return Promise.resolve(false);
    }
    
    return navigator.mediaDevices.enumerateDevices().then(devices => {
        return Promise.resolve(devices);
    });
}
