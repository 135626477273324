import React from "react";
import { useSelector } from "react-redux";
import { IconButton } from "../../../base/buttons";
import { translate } from "../../../base/i18n";
import { ROLE, getLocalMember } from "../../../member";
import { SETTING_MODAL_ID } from "../../constants";

function SwitchRemoteDeviceSetting({ t }) {
    if (!APP.layout.option.allPermission) return null;
    const role = useSelector(state => getLocalMember(state)?.role);
    if (role !== ROLE.HOST && role !== ROLE.MANAGER) return null;
    
    const onClick = () => {
        APP.UI.openModal(SETTING_MODAL_ID);
    }

    return (
        <IconButton clsName='set_icon' mode="deviceManager" name={t("options.setFullPermission")} size={22}
            onClick={onClick}
        />
    )
}

export default translate(SwitchRemoteDeviceSetting);