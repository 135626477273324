import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IconButton } from "../../../base/buttons";
import { MEDIA_TYPE } from "../../../base/media";
import { muteAllParticipants } from "../../../video-menu";

export default function MuteSetting({
    initial_camera, initial_mic, handlerUpdatePolicy
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const audioMuted = (isMuted) => {
        dispatch(muteAllParticipants([], MEDIA_TYPE.AUDIO, isMuted));

        setPolicy(!isMuted, initial_camera);
    }
    
    const videoMuted = (isMuted) => {
        dispatch(muteAllParticipants([], MEDIA_TYPE.VIDEO, isMuted));

        setPolicy(initial_mic, !isMuted);
    }

    const setPolicy = (initial_mic, initial_camera) => {
        const mic = initial_mic ? "on" : "off";
        const cam = initial_camera ? "on" : "off";
        const policy = { initial_mic: mic, initial_camera: cam };

        handlerUpdatePolicy(policy);        
    }

    return (
        <>
            <div className="guid">
                { t("options.muteSetGuid") }
            </div>
            <div className="device_wrapper">
                <div className="item">
                    <IconButton clsName={`button_${initial_mic ? "on" : "off"}`}
                        mode="mic_on" size={24} name={t("options.onMic")} onClick={() => audioMuted(false)} />

                    <IconButton clsName={`button_${!initial_mic ? "on" : "off"}`}
                        mode="mic_off" size={24} name={t("options.offMic")} onClick={() => audioMuted(true)} />
                </div>
                <div className="item">
                    <IconButton clsName={`button_${initial_camera ? "on" : "off"}`}
                        mode="camera_on" size={24} name={t("options.onVideo")} onClick={() => videoMuted(false)}/>

                    <IconButton clsName={`button_${!initial_camera ? "on" : "off"}`}
                        mode="camera_off" size={24} name={t("options.offVideo")} onClick={() => videoMuted(true)}/>
                </div>
            </div>
        </>
    )
}