import { MiddlewareRegistry } from "../base/redux";
import { SET_GROUP, SET_GROUP_STATUS } from "./actionTypes";
import { setEndGroup, setStartGroup } from "./actions";
import { getGroupIdsList } from "./functions";

MiddlewareRegistry.register(store => next => action => {
    switch (action.type) {
        case SET_GROUP:
            const group = action.group;
            if (group.status === "closed") {
                store.dispatch(setEndGroup(action.group_id));
            } else {
                store.dispatch(setStartGroup(action.group_id));
            }
            break;

        case SET_GROUP_STATUS:
            if (!action.isRemote) {
                const list = getGroupIdsList(store.getState);

                const type = action.status ? 'open' : 'close';
                let data = {};
                list && [...list].map(uuid => {
                    data = { ...data, [uuid]: {switch: type}};
                });

                APP.API.allUpdateGroup({ groups: data })
                    .then(async response => {
                        if (!response.complete) {
                            response.message && action.alertMessage && action.alertMessage(response.message);
                        } else {
                            const result = await APP.mateManagement.setGroupRunnigStatus(action.status);
                            console.log(result);
                        }
                        
                    });
                }
                break;

    }
    
    return next(action);
});
