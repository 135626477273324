import React from "react";
import { connect } from "react-redux";
import { PERMISSION } from "../../../member";
import { isCheckPermission } from "../../localuser";
import { setFocusVod } from "../action";
import { VOD_FILE } from "../constants";
import { getMediaList } from "../function";

function VodList({
    dispatch, mediaList, focusUuid, permission
}) {
    return (
        <div style={{ width: "100%", height: "100%", overflow: "auto" }}> 
            <div className="share_item"> 
                { mediaList.map(([uuid, item]) => {
                    const handlerSelectMedia = () => {
                        if (!permission) return;
                        dispatch(setFocusVod(item.uuid));
                    };

                    // const handlerDeleteMedia = () => {
                    //     dispatch(delVodList(item.uuid));
                    // }
                    
                    return (
                        <div key={item} className={`item_label ${focusUuid === item.uuid ? 'focus' : ''}`}>
                            <div className={`label_child nonSelect`} onClick={handlerSelectMedia}>
                                <span className="text">
                                    { item.type === VOD_FILE ? item.name : item.url }
                                </span>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

function _mapStateToProps(state) {
    const list =  getMediaList(state);
    const focusUuid = state['features/base/vod'].vod_shared_video_uuid;
    const permission = isCheckPermission(state, PERMISSION.SHARE);
    
    return {
        mediaList: [...list],
        focusUuid,
        permission
    }
}

export default connect(_mapStateToProps)(VodList);
