import { getFocusItemByMode } from "../../share-document";
import { getCurrentMode } from "../../video-layout";
import { ReducerRegistry, StateListenerRegistry } from "../redux";
import { setCanvasOption } from "./action";
import { ADD_CANVAS_LIST, SET_CANVAS_DRAW, SET_CANVAS_OPTION, SET_CANVAS_TOOL } from "./actionTypes";
import { ALL_ERASER } from "./constants";

const DEFAULT_STATE = {
    tool: 'pen',
    option: {
        color: '#000',
        scale: 100,
        isWidthFit: undefined,
        thickness: 2,
        opacity: 10
    },
    canvas: new Map(),
    draw: new Map()
};

ReducerRegistry.register('features/base/canvas', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case ADD_CANVAS_LIST:
            state.draw = action.list;
            return {
                ...state
            }

        case SET_CANVAS_DRAW: 
            state.draw.set(action.uuid, action.data);
            
            return {
                ...state
            }

        case SET_CANVAS_TOOL:
            if (action.tool === ALL_ERASER.name) {
                return { ...state };
            }

            return {
                ...state,
                tool: action.tool
            }

        case SET_CANVAS_OPTION: 
            return {
                ...state,
                option: Object.assign(state.option, action.option)
            }
    }
    return state;
});

StateListenerRegistry.register(state => getFocusItemByMode(state, getCurrentMode(state)),
    (focus, store) => {
        if (focus) {
            store.dispatch(setCanvasOption({
                scale: 100,
                // isWidthFit: true
            }));
        }
    }
)