import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../../../../assets/css/account.scss";
import background from "../../../../assets/images/bg/bg_join.jpg";
import { translate } from "../../../base/i18n";
import { updateModalState } from "../../../base/modal/actions";
import { LOGIN_MODAL_ID } from "../../../options";
import { formValidation, formatTime, getRemainingTime } from "../function";
import CheckCertCodeButton from "./CheckCertCodeButton";
import SendMailButton from "./SendMailButton";

function Signup({
    t, onClose, isModal
}) {
    const dispatch = useDispatch();
    const [data, setData] = useState({
        id: "", password: "", rePassword: "",
        email: "", certCode: "", nickname: "", profile: ""
    });
    const [error, setError] = useState({
        id: "", password: "", rePassword: "",
        email: "", certCode: "", nickname: "", profile: ""
    });
    const [uuid, setUuid] = useState(null);
    const [remainingTime, setRemainingTime] = useState(0);
    const [isSendMailComplete, setIsSendMailComplete] = useState(false);
    const [isCheckCodeComplete, setIsCheckCodeComplete] = useState(false);
    const [isShowEmail, setIsShowEmail] = useState(false);

    var intervalTime = null;

    const setSignupData = (newData) => {
        setData({ ...data, ...newData })
    };
    const setErrorData = (newError) => {
        setError({...error, ...newError});
    };
    const handlerChange = e => {
        if (e.keyCode === 32) {
            e.preventDefault();
            return;
        }
        
        const { name, value } = e.target;
        setSignupData({ [name]: value });

        const message = formValidation(t, name, value, data);
        setErrorData({ [name]: message });
    };

    useEffect(() => {
        const requireEmail = async () => {
            const { complete, message } = await APP.API.requireEmailVerification();
        
            if (complete) {
                setIsShowEmail(message.joining.email_verification_required);
            }
        }
        requireEmail();
    }, []);

    useEffect(() => {
        if (!isShowEmail) {
            setIsSendMailComplete(true);
            setIsCheckCodeComplete(true);
        }
    }, [isShowEmail]);

    useEffect(() => {
        if (isSendMailComplete) {
            setUuid(null);
            setRemainingTime(0);
            setIsSendMailComplete(false);
            
            if (isCheckCodeComplete) setIsCheckCodeComplete(false);
        }
    }, [data.email]);
    useEffect(() => {
        if (isCheckCodeComplete) {
            setUuid(null);
            setIsSendMailComplete(false);
            setIsCheckCodeComplete(false);
        }
    }, [data.certCode]);

    // 유효 시간 확인 
    const setExpirationTime = useCallback((expirationUnixTime) => {
        if (expirationUnixTime !== 0) {
            const time = formatTime(getRemainingTime(expirationUnixTime));
            setRemainingTime(time);

            intervalTime = setInterval(() => {  
                const remainingTime = getRemainingTime(expirationUnixTime);
                if (remainingTime === 0) {
                    clearInterval(intervalTime);
                    return;
                }
              
                if ( remainingTime <= 0) {
                    clearInterval(intervalTime);
                    
                    setUuid(null);
                    setIsSendMailComplete(false);

                    if (!isCheckCodeComplete) {     
                        setRemainingTime(0);
                        setIsCheckCodeComplete(false);                     
                    }
                    return;
                }

                const time = formatTime(remainingTime);                
                setRemainingTime(time);
            }, 1000);
        }
    }, [intervalTime, isCheckCodeComplete]);

    // 회원 가입 
    const signup = async () => {
        let isSignup = true;
        let errorData = {};
        
        for (const [ key, value ] of Object.entries(data)) {
            if (!isShowEmail) {
                if (key === 'email') break;
                else if (key === 'cerCode') break;
            }
            let message = formValidation(t, key, value, data, isShowEmail);
            
            if (message === "" && isShowEmail) {
                if (!isSendMailComplete && key === "email") {
                    message = t("account.inputErrNeedEmail");
                } else if (!isCheckCodeComplete && key === "certCode") {
                    message = t("account.inputErrNeedCheckCode");
                }
            }

            if (message !== "") isSignup = false;            
            errorData = {...errorData, [key]: message };
        }
        setErrorData(errorData);
        
        if (isSignup) {
            const { complete, message } = await APP.API.signup({ data, email_challenge_uuid: uuid });
            if (complete) {
                const text = t("account.successSignup");
                alert(text);
                if (isModal) onClose();
                else console.log("location 이동");
                return;
            }
            alert(t(message));
        }
    };

    const login = () => {
        if (isModal) APP.UI.openModal(LOGIN_MODAL_ID);
        else location.href = `/login${location.search}`;
    };

    const clearRemainingTime = () => {
        clearInterval(intervalTime);
    };

    const style = !isModal 
        ? { background: `url(${background}) no-repeat 65% center/cover`, width: "100%", height: "100%" } 
        : {width: "500px", height: "auto"};

    return (
        <div style={style}>
            <div className="account signup center">
                <div className="option_header">
                    <h3 className="title">{t("account.signup")}</h3>
                    <ul>
                        <li onClick={login}>{t("account.login")}</li>
                        {isModal ? <li onClick={onClose}>{t("dialog.close")}</li> : null}
                    </ul>
                </div>
                <div className="option_content">
                    { isShowEmail && <p className="guid">{t("account.signupGuid")}</p> }

                    <form>
                        <table className="nonSelect">
                            <tbody>
                            <tr>
                                <th>* {t("account.id")}</th>
                                <td colSpan="2">
                                    <input
                                        type="text"
                                        name="id"
                                        onKeyDown={handlerChange}
                                        onChange={handlerChange}
                                        placeholder={t("account.placeholderId")}
                                    />
                                    <p>{t(error.id)}</p>
                                </td>
                            </tr>
                            <tr>
                                <th>* {t("account.password")}</th>
                                <td colSpan="2">
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder={t("account.placeholderPassword")}
                                        onKeyDown={handlerChange}
                                        onChange={handlerChange}
                                        autoComplete="off"
                                    />
                                    <p>{t(error.password)}</p>
                                </td>
                            </tr>
                            <tr>
                                <th>* {t("account.rePassword")}</th>
                                <td colSpan="2">
                                    <input
                                        type="password"
                                        name="rePassword"
                                        placeholder={t("account.placeholderRePassword")}
                                        onKeyDown={handlerChange}
                                        onChange={handlerChange}
                                        autoComplete="off"
                                    />
                                    <p>{t(error.rePassword)}</p>
                                </td>
                            </tr>
                            {isShowEmail && 
                            <>
                                <tr>
                                    <th>* {t("account.email")}</th>
                                    <td>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder={t("account.placeholderEmail")}
                                            onChange={handlerChange}                                        
                                        />                                    
                                        <p>{t(error.email)}</p>
                                    </td>
                                    <td className="signup_btn">
                                        <SendMailButton 
                                            t={t}
                                            email={data.email}
                                            remainingTime={remainingTime}
                                            isSendMailComplete={isSendMailComplete}
                                            isCheckCodeComplete={isCheckCodeComplete}

                                            setErrorData={setErrorData}  
                                            setUuidData={setUuid}    
                                            setIsSendMailComplete={setIsSendMailComplete}                           
                                            setExpirationTime={setExpirationTime}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>* {t("account.certCode")}</th>
                                    <td>
                                        <input
                                            type="text"
                                            name="certCode"
                                            placeholder={t("account.placeholderCertCode")}
                                            onChange={handlerChange}
                                        />
                                        <p>{t(error.certCode)}</p>
                                    </td>
                                    <td className="signup_btn">
                                        <CheckCertCodeButton 
                                            t={t}
                                            uuid={uuid}
                                            certCode={data.certCode}                                                    
                                            isSendMailComplete={isSendMailComplete}
                                            isCheckCodeComplete={isCheckCodeComplete}

                                            setErrorData={setErrorData}  
                                            setRemainingTime={setRemainingTime}
                                            setIsCheckCodeComplete={setIsCheckCodeComplete}
                                            clearRemainingTime={clearRemainingTime}
                                        />
                                        
                                        <span>
                                            { remainingTime === 0 ? null : remainingTime }
                                        </span>
                                    </td>
                                </tr>
                            </>
                            }
                            {/* <tr>
                                <th>{t("account.nickname")}</th>
                                <td colSpan="2">
                                    <input
                                        type="text"
                                        name="nickname"
                                        onChange={handlerChange}
                                        placeholder={t("account.placeholderNickname")}
                                    />
                                    <p>{t(error.nickname)}</p>

                                </td>
                            </tr>
                            <tr>
                                <th>{t("account.profile")}</th>
                                <td colSpan="2">
                                    <input type="file" name="profile" onChange={handlerChange} />
                                    <span>
                                        {t("account.placeholderProfile")}
                                    </span>
                                    <p>{t(error.profile)}</p>
                                </td>
                            </tr> */}
                            </tbody>
                        </table>
                    </form>
                </div>
                <div className="option_footer">
                    <span 
                        className="btn btn_middle" 
                        onClick={signup}
                        style={{ margin: "0 auto" }}                                 
                    >
                        {t("account.signup")}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default translate(Signup);