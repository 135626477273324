// @flow
import { updateSettings } from '../settings';

const webrtcKindToJitsiKindTranslator = {
    audioinput: 'audioInput',
    audiooutput: 'audioOutput',
    videoinput: 'videoInput'
};

export function areDeviceLabelsInitialized(state) {
    if (APP.mateManagement.getLocalTracksInitialized()) {
        return true;
    }

    for (const type of [ 'audioInput', 'audioOutput', 'videoInput' ]) {
        if ((state['features/base/devices'].availableDevices[type] || []).find(d => Boolean(d.label))) {
            return true;
        }
    }

    return false;
}

export function getDefaultDeviceId(state, kind) {
    const kindToSearch = webrtcKindToJitsiKindTranslator[kind] || kind;
    const defaultDevice = (state['features/base/devices'].availableDevices[kindToSearch] || [])
        .find(d => d.deviceId === 'default');

    // Find the device with a matching group id.
    const matchingDevice = (state['features/base/devices'].availableDevices[kindToSearch] || [])
        .find(d => d.deviceId !== 'default' && d.groupId === defaultDevice.groupId);

    if (matchingDevice) {
        return matchingDevice.deviceId;
    }
}

export function getDeviceIdByLabel(state, label, kind) {
    const kindToSearch = webrtcKindToJitsiKindTranslator[kind] || kind;

    const device
        = (state['features/base/devices'].availableDevices[kindToSearch] || [])
            .find(d => d.label === label);

    if (device) {
        return device.deviceId;
    }
}

export function getDeviceLabelById(state, id, kind) {
    const kindToSearch = webrtcKindToJitsiKindTranslator[kind] || kind;
 
    const device
        = (state['features/base/devices'].availableDevices[kindToSearch] || [])
        .find(d => d.deviceId === id);

    if (device) {
        return device.label;
    }
}

export function getDevicesFromURL(state) {
    const devices = {};

    (devices.audioOutput = audioOutput);
    (devices.videoInput = videoInput);
    (devices.audioInput = audioInput);
    
    return devices;
}

export function groupDevicesByKind(devices) {
    return {
        audioInput: devices.filter(device => device.kind === 'audioinput'),
        audioOutput: devices.filter(device => device.kind === 'audiooutput'),
        videoInput: devices.filter(device => device.kind === 'videoinput')
    };
}

export function filterAudioDevices(devices) {
    return devices.filter(device => device.kind === 'audioinput');
}

export function formatDeviceLabel(label) {
    let formattedLabel = label;

    const ix = formattedLabel.lastIndexOf('(');

    if (ix !== -1) {
        formattedLabel = formattedLabel.substr(0, ix);
    }

    return formattedLabel;
}

export function getVideoInputDeviceData(state) {
    return state['features/base/devices'].availableDevices.videoInput.map(
        ({ deviceId, label }) => {
            return {
                deviceId,
                label
            };
        });
}

export function getAudioInputDeviceData(state) {
    return state['features/base/devices'].availableDevices.audioInput.map(
        ({ deviceId, label }) => {
            return {
                deviceId,
                label
            };
        });
}

export function getAudioOutputDeviceData(state) {
    return state['features/base/devices'].availableDevices.audioOutput.map(
        ({ deviceId, label }) => {
            return {
                deviceId,
                label
            };
        });
}

export function getVideoDeviceIds(state) {
    return state['features/base/devices'].availableDevices.videoInput.map(({ deviceId }) => deviceId);
}

export function getDevicePermission(state) {
    return state['features/base/devices'].permissions;
}


/**
 * 특정 유형의 장치가 있거나 기본 플랫폼에 있는 경우 true를 반환합니다.
 */
export function hasAvailableDevices(state, type) {
    if (state['features/base/devices'] === undefined) {
        return true;
    }

    return state['features/base/devices'].availableDevices[type].length > 0;
}

export function getAudioOutputDeviceId() {
    return APP.mateManagement.getAudioOutputDeviceId();
}

export function setAudioOutputDeviceId(newId = 'default', dispatch, userSelection = false, newLabel) {
    return APP.mateManagement.setAudioOutputDevice(newId)
        .then(() => {
            const newSettings = {
                audioOutputDeviceId: newId,
                userSelectedAudioOutputDeviceId: undefined,
                userSelectedAudioOutputDeviceLabel: undefined
            };

            if (userSelection) {
                newSettings.userSelectedAudioOutputDeviceId = newId;
                newSettings.userSelectedAudioOutputDeviceLabel = newLabel;
            } else {
                // a flow workaround, I needed to add 'userSelectedAudioOutputDeviceId: undefined'
                delete newSettings.userSelectedAudioOutputDeviceId;
                delete newSettings.userSelectedAudioOutputDeviceLabel;
            }

            return dispatch(updateSettings(newSettings));
        })
        .catch(err => {
            // console.log(err)
        });
}
