// @flow

import moment from 'moment';

import 'moment-duration-format';
// require('moment/locale/ko');
const duration = moment.duration;
export function getLocalizedDurationFormatter(durationTime) {
    if (duration(durationTime).format('h') !== '0') {
        return duration(durationTime).format('hh:mm:ss');
    }

    return duration(durationTime).format('mm:ss', { trim: false });
}

export function getFormatter(time) {
    return moment(time).format('HH:mm');
}

export function getFormatterTime(time) {
    return moment(time).format('HH:mm:ss');
}

export function getFormatterDate(time) {
    return moment(time).format('YYYY-MM-DD HH:mm');
}

export function getFormatterYear(time) {
    return moment(time).format('YYYY-MM-DD');
}

export function getFormatterNowTime(format) {
    let time = new Date();
    time = moment(time).add(1, 'minutes');
    return time.format(format);
}