export const setConfig = () => {
    const search = location.search;

    if (search.indexOf('mate') !== -1) {
        return 'mate';
    } else if (search.indexOf('innovationDev') !== -1) { // 이노베이션
        return 'innovationDev';
    } else if (search.indexOf('innovation') !== -1) { // 이노베이션
        return 'innovation';
    } else if (search.indexOf('merti') !== -1) { // 해양
        return 'merti';
    } else if (search.indexOf('gs') !== -1) { // gs
        return 'gs';
    } else if (search.indexOf('edu') !== -1) { // 중앙
        return 'edu';
    } else if (search.indexOf('yoga') !== -1) { // 요가
        return 'yoga';
    } else if (search.indexOf('tube') !== -1) { // intube
        return 'tube';
    } else if (search.indexOf('senstee') !== -1) { // intube
        return 'senstee';
    } else if (search.indexOf('hccp') !== -1) { // hccp
        return 'hccp';
    }

    return 'mate';
}