// @flow

import { ReducerRegistry } from '../base/redux';

import { BACKGROUND_ENABLED, SET_VIRTUAL_BACKGROUND } from './actionTypes';

const STORE_NAME = 'features/virtual-background';

ReducerRegistry.register(STORE_NAME, (state = {}, action) => {
    const { virtualSource, backgroundEffectEnabled, blurValue, backgroundType, selectedThumbnail } = action;

    // PersistenceRegistry.register(STORE_NAME, state.backgroundType !== VIRTUAL_BACKGROUND_TYPE.DESKTOP_SHARE);

    switch (action.type) {
        case SET_VIRTUAL_BACKGROUND: {
            return {
                ...state,
                virtualSource,
                blurValue,
                backgroundType,
                selectedThumbnail
            };
        }
        case BACKGROUND_ENABLED: {
            return {
                ...state,
                backgroundEffectEnabled
            };
        }
    }

    return state;
});
