import React from "react";
import { AbstractDialog } from "../../base/dialog";
import { translate } from "../../base/i18n";
import { connect } from "../../base/redux";
import { startScreenShare } from "../action";

function ChoiceScreenShareTypePopup({
    t, dispatch
}) {
    const onClick = (openModal) => {
        dispatch(startScreenShare(openModal));
    }

    return (
        <AbstractDialog name="choiceScreenShare" width={350}>
            <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", gap: "12px" }}>
                <p style={{ margin: 0 }} className="btn btn_middle btn_true" onClick={() => onClick(true)}>{t("options.popupScreen")}</p>
                <p style={{ margin: 0 }} className="btn btn_middle btn_true" onClick={() => onClick(false)}>{t("options.currentScreen")}</p>
            </div>
        </AbstractDialog>
    )
}

function _mapStateToProps(state) {
    return {
        isSharingVideo: state['features/screen-share'].sharingVideo
    }
}
export default translate(connect(_mapStateToProps)(ChoiceScreenShareTypePopup));