// @flow
import throttle from 'lodash/throttle';
import React from 'react';
import { ALERT_MESSAGE, REMOVE_ATTENDANCES_NOTIFICATION, REMOVE_PARTICIPANT_NOTIFICATION } from '.';
import { PromptAlertAttendances, PromptRaiseHand } from '../options';

import { ROLE, getLocalMember, getMemberByUserID } from '../member';
import {
    CLEAR_ALERT_MESSAGE,
    CLEAR_NOTIFICATIONS,
    HIDE_NOTIFICATION,
    SET_NOTIFICATIONS_ENABLED,
    SHOW_NOTIFICATION
} from './actionTypes';
import { NOTIFICATION_TIMEOUT, NOTIFICATION_TYPE } from './constants';

export function clearNotifications() {
    return {
        type: CLEAR_NOTIFICATIONS
    };
}

export function removeParticipantNotification(id) {
    return {
        type: REMOVE_PARTICIPANT_NOTIFICATION,
        id
    }
}
export function removeAttendancesNotification() {
    return {
        type: REMOVE_ATTENDANCES_NOTIFICATION
    }
}
export function hideNotification(uid) {
    return {
        type: HIDE_NOTIFICATION,
        uid
    };
}

export function setNotificationsEnabled(enabled) {
    return {
        type: SET_NOTIFICATIONS_ENABLED,
        enabled
    };
}

export function showErrorNotification(props) {
    return showNotification({
        ...props,
        appearance: NOTIFICATION_TYPE.ERROR
    });
}
export function alertMeesgae(title, props) {
    return {
        type: ALERT_MESSAGE,
        title,
        props
    }
}
export function clearAlertMessage() {
    return {
        type: CLEAR_ALERT_MESSAGE
    }
}
/**
 * Queue 알림 
 */
export function showNotification(props = {}, timeout) {
    return function(dispatch, getState) {
        // const { notifications } = getState()['features/base/config'];

        // const shouldDisplay = (!notifications
        //         || notifications.includes(props.descriptionKey)
        //         || notifications.includes(props.titleKey));

        // if (shouldDisplay) {
    
        return dispatch({
            type: SHOW_NOTIFICATION,
            props,
            timeout,
            uid: window.Date.now()
        });
        // }
    };
}

export function showRequestPresentation(user_uuid) {
    return function(dispatch, getState) {
        dispatch(removeParticipantNotification(user_uuid));

        const member = getMemberByUserID(getState, user_uuid);
        if (member) {
            const component = <PromptRaiseHand dispatch={dispatch} displayName={member.nickname} user_uuid={user_uuid} />;

            dispatch(showNotification({ id: user_uuid, isRequestPresent: true, component, titleKey: 'conference.requestPresenter'}, 300000 ));

        }
    }
}

export function showStartAttendance({ name, uuid, check_duration }) {
    return function(dispatch, getState) {
        const local = getLocalMember(getState);
        
        if (local && local.role !== ROLE.HOST && local.role !== ROLE.MANAGER) {
            const component = <PromptAlertAttendances dispatch={dispatch} uuid={uuid} name={name} />;
            
            uuid && dispatch(showNotification({isAttendances: true, component, titleKey: 'attendances.request', order: 2}, check_duration));
        };        
    }
}

/**
 * 표시를 위해 경고 알림
 */
export function showWarningNotification(props) {
    return showNotification({
        ...props,
        appearance: NOTIFICATION_TYPE.WARNING
    });
}
/**
 * 회의에 참가한 참가자의 이름 배열입니다.
 */
let joinedParticipantsNames = [];

/**
 * 참가자 합류를 알리는 알림
 */
const _throttledNotifyParticipantConnected = throttle((dispatch, ) => {
    const joinedParticipantsCount = joinedParticipantsNames.length;

    let notificationProps;

    if (joinedParticipantsCount >= 3) {
        notificationProps = {
            titleArguments: {
                name: joinedParticipantsNames[0].displayName,
                count: joinedParticipantsCount - 1
            },
            titleKey: joinedParticipantsNames[0].existed ? 'notify.prevConnectedThreePlusMembers' :'notify.connectedThreePlusMembers'
        };
    } else if (joinedParticipantsCount === 2) {
        notificationProps = {
            titleArguments: {
                first: joinedParticipantsNames[0].displayName,
                second: joinedParticipantsNames[1].displayName
            },
            titleKey: joinedParticipantsNames[0].existed ? 'notify.prevConnectedTwoMembers' : 'notify.connectedTwoMembers'
        };
    } else if (joinedParticipantsCount) {
        notificationProps = {
            titleArguments: {
                name: joinedParticipantsNames[0].displayName
            },
            titleKey: joinedParticipantsNames[0].existed ? 'notify.prevConnectedOneMember' : 'notify.connectedOneMember'
        };
    }

    if (notificationProps) {
        dispatch(
            showNotification(notificationProps, NOTIFICATION_TIMEOUT));
    }

    joinedParticipantsNames = [];

}, 1000, { leading: false });

/**
 * 참가자의 알림 표시를 대기열에 넣습니다.
 */
export function showParticipantJoinedNotification(displayName, existed) {
    joinedParticipantsNames.push({ displayName, existed });

    return (dispatch) => _throttledNotifyParticipantConnected(dispatch);
}
