import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch } from "react-redux";
import styled from "styled-components";
import TempMate from "../../../../server/mate/TempMate";
import { ClickButton, IconButton } from "../../../base/buttons";
import { getGroupId } from "../../../base/conference";
import LoadImage from "../../../base/loading/components/LoadImage";
import { connect } from "../../../base/redux";
import { MembersContainer, PARTICIPANT_STATUS, ROLE, getGroupMemberByUserId, getLocalMember, getMemberTrackByUserID } from "../../../member";
import { setGroupDual } from "../../actions";
import { G_ADD_POP, G_DELET_POP, G_LOADING, G_MODIFY_POP, G_PP_LIST, G_STATUS, startMemberGroup } from "../../constants";
import { getStartGroupList } from "../../functions";
import AddGroupButton from "../AddGroupButton";
import DeleteGroup from "../DeleteGroup";
import GroupMemberList from "../GroupMemberList";
import ModifyGroup from "../ModifyGroup";
import StatusGroupButton from "../StatusGroupButton";
import { FocusButtonStyled } from "../styles";
import GroupCard from "./GroupCard";
import GroupChat from "./GroupChat";
import SendNotification from "./SendNotification";
import GroupMember from "./GroupMember";

const CHAT = 'chat';
const PARTICIPANT = 'participant';

let prevFocus = { id: null, name: null };
function GroupPanelLayout({
    isMobile, alertMessage,
    currentGroupId , local, assginGroupId, list
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [ monitorMode, setMonitorMode ] = useState(false);
    const [ show, setShow ] = useState(G_STATUS);
    const [ popup, setPopup ] = useState(null);
    const [ popItem, setPopItem ] = useState(null);
    const [ focusItem, setFocusItem ] = useState(null);

    const [ participant, setParticipant ] = useState(new Map()); // 모든 사용자 
    const [ chatParticipant, setChatParticipant ] = useState(new Map()); // 현재 입장 중인 사용자 
    const [ messages, setMessages ] = useState(new Array()); // 채팅 메시지 
    const [ openDash, setDash ] = useState(PARTICIPANT);
    const [ focusGroup, setFocusGroup ] = useState({ id: null, name: null });
    const [ choiceGroup, setChoiceGroup ] = useState(false);
    const [ mate, setMate ] = useState(null);

    const handlerClose = () => {
        setPopup(false);
    }

    const handlerMessagePop = (uuid) => {
        setPopup("message");
        setMessageUuid(uuid);
    }

    const handlerGroup = (id, name) => {
        setChoiceGroup(false)
        if (currentGroupId === id) return;
        if (focusGroup.id === id) setFocusGroup({ id: null, name: null });
        else setFocusGroup({ id, name });
    }

    useEffect(() => {
        if (!list.find(item => item.uuid === focusGroup.id)) {
            setFocusGroup({ id: null, name: null });
        }
    }, [list]);

    useEffect(() => {
        if (focusGroup.id) {
            // 할당
            APP.API.assignGroup({ members: [{user_id: local.user_uuid, role_name: ROLE.ADMIN, type: "hidden"}], method: "reset" }, focusGroup.id)
                .then(response => {
                    if (response.complete) {
                        const mate = new TempMate(focusGroup.id, {
                            reciveMessage,
                            handlerChangeParticipantRole,
                            handlerJoinParticipant,
                            handlerLeftParticipant,
                            handlerPin,
                            handlerHandsup
                        });
            
                        mate.connectionAndJoin()
                            .then((response) => {
                                const { members, member_uuid } = response;
                                let participant = new Map();
                                members.map(async m => {
                                    if (m.member_uuid === member_uuid) return
                                    if (m.status === PARTICIPANT_STATUS.BANNED) return;
                        
                                    let nickname = m.nickname || "-";
                                    // if (!nickname || nickname !== "") {
                                    //     const group = getGroupMemberByUserId(getState, m.user_uuid);
                                        
                                    //     const response = await APP.API.getAllParticipant({ user_id: m.user_uuid, group_id: "" });
                                    //     if (response.complete && response.message && response.message.length > 0) {
                                    //         nickname = response.message[0]?.nickname;
                                    //     } 
                                    // }
                                    participant.set(m.user_uuid, { ...m, nickname, local: false, existed: true })
                                });

                                setParticipant(participant);
                                setMate(mate);
                            });
                    } else console.log(response);
                })
                .catch(err => {
                    alertMessage("group.groupOut");
                });
        } else {
            if (prevFocus.id) {
                APP.API.assignGroup({ members: [{user_id: local.user_uuid, role_name: ROLE.ADMIN, type: "hidden"}], method: "unset" }, prevFocus.id)
                    .catch(err => {
                        alertMessage("group.groupOut");
                    });
            }  
        }

        return () => {
            setMate(null);   

            prevFocus = focusGroup;
        }
    }, [focusGroup], shallowEqual);

    useEffect(() => {
        return () => {
            if (mate) {
                mate.leave();
            } 
        }
    }, [mate]);

    /**
     * 채팅 (대시보드) 
     */
    // 웹소켓 채팅 불러오기 
    const reciveMessage = ({ message, from }) => {
        const isMe = local.user_uuid === from;

        const parseMessage = JSON.parse(message);
        if (parseMessage.type === 'text' || parseMessage.type === 'file') {
            const text = { isMe, ...parseMessage, message: parseMessage.content, messageType: parseMessage.type, error: undefined };

            handlerSetMessage(text);

            return;
        }
    }
    
    // 웹소켓 채팅 하나
    const handlerSetMessage = (text) => {
        setMessages(messages => { return [ ...messages, text ] });
    }
    // 보내는 사람 불러오기 
    const setRecipient = recipientList => {
        try {
            let recipient = new Array();
            recipientList.forEach((name, id) => {
                recipient.push({ id, name });
            });
            return recipient;
            
        } catch (e) {
            return new Array();
        }
        
    }
    // 채팅 텍스트 전송
    const handlerChatMessage = (newChat, recipientList) => {
        const message = newChat && newChat.trim() !== "" ? newChat : false;
        if (message) {
            const time = new Date().getTime();
            const recipient = setRecipient(recipientList);

            const data = {
                type: 'text',
                uuid: uuidv4(),
                content: newChat,
                recipient,
                displayName: local.nickname
            };

            setMessages( [ ...messages, {
                isMe: true,
                from: local.user_uuid,
                message: data.content,
                messageType: data.type,
                recipient: data.recipient,
                displyName: data.displyName,
                uuid: data.uuid,
                privateMessage: recipient.length > 0 ? true : false,
                timestamp: time
            }]);

            if (recipient.length > 0) {
                controller && controller.sendPrivateTextMessage(recipient, data);
            } else {
                controller && controller.sendTextMessage({ ...data, time });
            }
        }
    }
    // 채팅 파일 전송
    const handlerChatFile = (newFile, recipientList) => {
        if (newFile) {
            console.log(newFile, recipientList)
        }  
    }

    // /**
    //  * 참석자 목록 (대시보드)
    //  */
    // const handlerParticipant = async (participant, group_id) => {        
    //     const group_members = getFocusGroupParticipant(APP.store.getState(), group_id);
        
    //     let newParticipant = new Map();
    //     let newChatParticipant = new Map();
        
    //     participant && participant.map(p => {
    //         const inGroup = group_members.find(g => g.user_id === p.user_uuid);
    //         if (inGroup) {
    //             if (p.nickname === "") p.nickname = inGroup.nickname === "" ? "-" : inGroup.nickname;
    //             if (p.member_uuid === local.member_uuid) return;
    //             if (p.type === "hidden") return;
    //             if (p.status === PARTICIPANT_STATUS.BANNED) return;

    //             newParticipant.set(p.user_uuid, p);
    //         }
    //     });

    //     setParticipant(newParticipant);
    //     setChatParticipant(newChatParticipant);
    // }
    // 참석자 입장
    const handlerJoinParticipant = ({ message: p }) => {
        let data = { ...p, local: false, status: PARTICIPANT_STATUS.OCCUPIDE, entryTime: Date.now(), existed: true };
        setParticipant(participant => {
            participant.set(p.user_uuid, data);

            return new Map(participant);
        });
        setChatParticipant(chatParticipant => {
            chatParticipant.set(p.user_uuid, data); 

            return new Map(chatParticipant);  
        });
    }
    // 참석자 퇴장  
    const handlerLeftParticipant = ({ from }) => {
        setParticipant(participant => {
            let user_uuid = null;
            participant.forEach(k => {
                if (k.member_uuid === from) user_uuid = k.user_uuid;
            });

            const leaveParticipant = participant.get(user_uuid);
            if (leaveParticipant) {
                participant.set(leaveParticipant.user_uuid, { ...leaveParticipant, status: PARTICIPANT_STATUS.VACATED });
            }
            return new Map(participant);    
        });
        

        setChatParticipant(chatParticipant => { 
            chatParticipant.delete(from);    
            return new Map(chatParticipant);            
        });
    }

    // // 참석자 역할 변경 
    const handlerChangeParticipantRole = ({ message }) => {
        try {
            const { target, role } = message;

            setParticipant(participant => {
                const prevParticipant = participant.get(target);
                
                if (prevParticipant) {
                    participant.set(prevParticipant.user_uuid, { ...prevParticipant, role });
                }
                return new Map(participant);    
            });
        } catch (err) {
            console.log(err)
        }
    }

    // // 참석자 핀 변경 
    const handlerPin = ({ message }, pinned) => {
        try {
            const { target, role } = message;

            setParticipant(participant => {
                const prevParticipant = participant.get(target);
                
                if (prevParticipant) {
                    participant.set(prevParticipant.user_uuid, { ...prevParticipant, pinned });
                }
                return new Map(participant);    
            });
        } catch (err) {
            console.log(err)
        }
    }

    // // 참석자 손들기 변경 
    const handlerHandsup = (data, hands_up) => {
        try {
            const { to } = data;
           
            
            setParticipant(participant => {
                let user = [...participant.values()].find(p => p.member_uuid === to);

                if (user && user.user_uuid) {
                    const prevParticipant = participant.get(user.user_uuid);
                
                    if (prevParticipant) {
                        participant.set(prevParticipant.user_uuid, { ...prevParticipant, hands_up });
                    }
                    return new Map(participant);  
                }
                  
            });    
        } catch (err) {
            console.log(err)
        }
    }

    // 전체 회의실 종료
    const handlerEndConference = async () => {
        // 나의 권한은 무조건 호스트 또는 매니저 
        if (assginGroupId) {
            // UNSET 
            await APP.API.assignGroup({ members: [{ user_id: local.user_uuid, role_name: local.role }], method: "unset" }, assginGroupId);
            // APP.mateManagement.changeMeetingRoom("");
        }

        let data = {};
        list.map(async i => {
            data = { ...data, [i.uuid]: {switch: "close"}};
        });

        const { complete, message } = await APP.API.allUpdateGroup({groups: data});

        if (complete) {
            APP.mateManagement.setGroupRunnigStatus(false); 

            APP.UI.closeModal();
        } else {
            console.log(message);
        }
    }

    // 개인 회의실 상태 변경 
    const handlerChangeGroupStatus = async (group_id, status) => {
        if (status === "opened") {
            console.log({ group_id, assginGroupId })
            if (group_id === assginGroupId) {
                await APP.API.assignGroup({ members: [{ user_id: local.user_uuid, role_name: ROLE.ADMIN }], method: "unset" }, assginGroupId);
            }
            const { complete, message } = await APP.API.updateGroup({switch: "close"}, group_id);

            if (complete === false) {
                alert(message);
            }
        } else {
            const { complete, message } = await APP.API.updateGroup({switch: "open"}, group_id);

            if (!complete) {
                console.log(message);
            }
        }
    }

    // 참관 or 입장
    const handlerChangeConference = async (group_id) => {
        setMate(null);
        // 그룹 아이디와 입장한 방의 아이디가 동일한 경우 -> 퇴장 
        if (group_id === currentGroupId) {
            // 그룹 아이디와 할당된 방의 아이디가 동일한 경우 
            if (group_id === assginGroupId) {
                // unset 
                // 퇴장  
                const { complete, message } = await APP.API.assignGroup({ members: [{user_id: local.user_uuid, role_name: ROLE.ADMIN}], method: "unset" }, assginGroupId);
                if (complete) {
                    APP.mateManagement.changeMeetingRoom(currentGroupId, false);
                    dispatch(setGroupDual(false));
                }
            }
        } else {
            // 입장
            const { complete, message } = await APP.API.assignGroup({ members: [{user_id: local.user_uuid, role_name: ROLE.ADMIN}], method: "reset" }, group_id);
            if (complete) {
                APP.mateManagement.changeMeetingRoom(currentGroupId, group_id);
                dispatch(setGroupDual(false));
            }
        }        
    }

    const getPopup = () => {
        if (!popup)
            return null;

        switch (popup) {
            case G_DELET_POP:
                return <DeleteGroup t={t} popItem={popItem} setPopup={setPopup} alertMessage={alertMessage} />;

            case G_MODIFY_POP:
                return <ModifyGroup t={t} popItem={popItem} setPopup={setPopup} alertMessage={alertMessage} />;
            
            case G_ADD_POP:
                const handlerClose = () => setPopup(false);
                return <AddGroupButton t={t} popup={popup} handlerClose={handlerClose} alertMessage={alertMessage} />

            case G_LOADING:
                return <LoadImage isLoad={true} />

            default:
                return <SendNotification group_id={popup} setPopup={setPopup} />
        }
    }

    const getRight = () => {
        switch (show) {
            case G_STATUS:
                return <GroupCard setPopup={setPopup} 
                    handlerChangeConference={handlerChangeConference}
                    handlerChangeGroupStatus={handlerChangeGroupStatus} />
            case G_PP_LIST:
                return <GroupMemberList alertMessage={alertMessage} tableConfig={startMemberGroup}/>

        }
    }

    const renderMonitoring = (mode, checked) => {
        const onClick = () => setMonitorMode(mode);
        return (
            <IconButton clsName="user_btn" name={t(`group.${mode ? 'monitoring' : 'manage'}`)} mode="group" checked={checked}
                onClick={onClick} />
        )
    }

    const renderChangeTap = (item) => {
        return (
            <ClickButton key={item} text={t(`common.${item}`)} size={20} 
                checked={openDash === item} onClick={() => setDash(item)} />
        )
    }

    const renderGroupList = () => {
        if (!choiceGroup) return null;
        return (
            <div style={{ position: "absolute", width: "100%", borderTop: "1px solid #fff",  zIndex: "2", top: "48px", left: 0 }}>
                { list && list.map(item => {
                    if (item.status === "closed") return null;
                    if (item.uuid === currentGroupId) return null;

                    const focus = focusGroup.id === item.uuid ? true : false;
                    const onClick = () => handlerGroup(item.uuid, item.name);
                    return (
                        <div key={item.uuid} 
                            className={`group_list button button_${focus ? "on" : "off"}`} 
                            onClick={onClick}> 
                            <span className="overText">{item.name}</span>
                        </div>
                    )
                }) }
            </div>
        )
    }

    const renderMonitorBody = () => {
        return (
            <div className="inner_body">
                <div className="layout_name choice" > 
                    <IconButton mode={`${choiceGroup ? "desc" : "asc"}`} 
                        name={focusGroup.id ? focusGroup.name : t("group.choice")} 
                        size={20} onClick={() => setChoiceGroup(!choiceGroup)} clsName={"choice_group"} /> 
                    <div className="button_right">
                        { [PARTICIPANT, CHAT].map(item => renderChangeTap(item)) }
                    </div>
                    { renderGroupList() }
                </div>
                { focusGroup.id 
                    ? openDash === PARTICIPANT ? <GroupMember participant={participant} isMonitoring={true} focusGroupId={focusGroup.id} />
                        : <GroupChat messages={messages} /> 
                    : <div style={{ textAlign: "center", marginTop: "20px" }}>{ t("group.noChoice") }</div> }
            </div>
        )
    }

    return (
        <Groupstyled isMobile={isMobile} monitorMode={monitorMode}>
            <div className="layout_name">{t("layout.dashboard")}</div> 
            <div className="section dashboard">
                { isMobile && <FocusButtonStyled className="button">
                    { renderMonitoring(true, monitorMode) }
                    { renderMonitoring(false, !monitorMode) }
                </FocusButtonStyled> } 
                <div className="dash_inner">                       
                    { renderMonitorBody() }
                </div> 
                <div className="section_layout group">
                    { getRight() }
                </div>
            </div>

            <FocusButtonStyled className="button">
                { show !== G_STATUS && <ClickButton clsName="user_btn" text={ t("group.preview") } size={20} onClick={() => setShow(G_STATUS)} />}
                <ClickButton clsName="user_btn" text={ t("group.add") } size={20} onClick={() => { setPopup(G_ADD_POP); }} />
                { show !== G_PP_LIST && <ClickButton clsName="user_btn" text={ t("group.change") } size={20} onClick={() => setShow(G_PP_LIST)} />}
                <StatusGroupButton clsName="user_btn" t={t} alertMessage={alertMessage} type={"close"} />
            </FocusButtonStyled> 

            { popup && 
                <>
                    <div style={{position: "absolute", top: "0", left: "0", background: "rgba(0, 0, 0, 0.2)",  width: "100%", height: "100%", zIndex: "2"}}> </div> 
                    { getPopup() }
                </>
            }
        </Groupstyled>           
    )
}

function _mapStateToProps(state) {
    const currentGroupId = getGroupId(state);
    const local = getLocalMember(state);
    const assginGroupId = getGroupMemberByUserId(state, local.user_uuid);
    const list = getStartGroupList(state);

    return {
        currentGroupId , local, assginGroupId, list
    }
}
export default connect(_mapStateToProps)(GroupPanelLayout);


const Groupstyled = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    width: 100%;
    padding: 4px;
    box-sizing: border-box;

    .section_layout {
        background: #0c0e11;
        flex-direction: column;
        overflow: hidden;
        display: ${props => props.isMobile && props.monitorMode ? "none" : "flex"};
        margin: 0;

        div { height: auto; }
    }

    .layout_name {
        display: flex;
        padding: 12px;
        background: #000;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: keep-all;
    }

    .dashboard {
        overflow: hidden;
        display: flex;
        margin: 0;
        width: 100%;
        height: 100%;
        flex-direction: ${props => props.isMobile ? "column" : "initial"};

        .dash_inner { 
            display: ${props => props.isMobile && !props.monitorMode ? "none" : "flex"};
            height: 100%;
            box-sizing: border-box;
            flex-direction: column;
            background: #fff;
            overflow: hidden;
            flex-basis: ${props => props.isMobile ? "1" : "30%"};
            max-width: ${props => props.isMobile && props.monitorMode ? "100%" : "370px"};
            min-width: ${props => props.isMobile && props.monitorMode ? "100%" : "350px"};
            margin: 0;

            .group_list {
                width: 100%;
                height: 35px;
                background: #000000;
                display: flex;
                align-items: center;
                padding: 0 20px;
                box-sizing: border-box;
                color: #fff;
                justify-content: flex-start;
                gap: 12px;

                &.button {
                    cursor: pointer;
    
                    &_on { font-weight: 500; color: #12a3f8;}
                }    

                & > * { margin: 0; }
            }

            .inner_body { 
                width: 100%; height: 100%; overflow: auto; display: flex; flex-direction: column;
                position: relative;

                .choice { 
                    padding: 12px 4px;
                    box-sizing: border-box;
                    .choice_group { margin: 0 auto 0 10px; gap: 12px;     
                        
                        span {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            word-break: keep-all;
                            width: 98px;
                            display: block;
                        }
                    }
                }
                & > * { margin: 0;}
            }
        }
    }

    .group {
        flex: 1;
    
        .group_list {
            width: 100%;
            height: 100%;
            padding: 20px;
            box-sizing: border-box;
            display: grid;
            grid-gap: 10px;
            grid-auto-rows: 350px;
            margin-bottom: 30px;
            overflow: auto;

            &:first-child {
                grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
              }
              
            &:last-child {
                grid-template-columns: repeat(300px, minmax(240px, 1fr));
            }

            .group_item {
                margin: 0;
                background: #1a1d25;
                display: flex;
                flex-direction: column;

                .item_body {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    width: 100%;
                    height: 100%;
                    align-items: flex-end;
                    padding: 20px;
                    box-sizing: border-box;
                    overflow: hidden;
                    
                    .table {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;

                        ul {
                            display: flex;
                            width: 100%;
                            gap: 4px;
                            color: #fff;
                            border-bottom: 1px solid;

                            svg {display: none;}
    
                            li {
                                margin: 0;
                                padding: 12px;
                                box-sizing: border-box;
                                word-break: keep-all;
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                &:nth-child(1) {
                                    background: #000;                                    
                                }
                            }
                        }
                    } 

                }         
            }
        }

        .button {margin: 20px auto!important;}
    }

    .button_right { 
        margin: 0 0 0 auto;

        button {
            margin: 0 4px;
        }
    }

    .button_center {
        margin: 10px auto!important;
        display: flex;
        justify-content: flex-end;

        button {
            margin: 0 4px;
        }
    }

    .section_add {
        padding: 8px 0 0 8px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        input {
            width: 80%;
            border-radius: 6px;
            border: none;
            padding: 8px 12px;
        }

        padding-bottom: 12px!important;
    } 
`;