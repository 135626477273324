import { throttle } from "lodash";
import { deleteCanvasDraw, deleteCanvasIndex, setCanvasDraw } from "../../features/base/canvas";
import { conferenceJoined, getGroupId, setMainRole, setRoomJoined } from "../../features/base/conference";
import { getLocalUserUUID, isCheckPermission, setLocalUserProperty } from "../../features/base/localuser";
import { setAudioMuted, setVideoMuted } from "../../features/base/media";
import { updateSettings } from "../../features/base/settings";
import { VOD_FILE, VOD_URL, delVodList, setFocusVod, setVodItem } from "../../features/base/vod";
import { addMessage, clearMessages, endpointMessageReceived } from "../../features/chat";
import { getGroups, startLocalGroup } from "../../features/groups";
import { setGroupStatus } from "../../features/groups/actions";
import {
    PARTICIPANT_STATUS,
    PERMISSION, ROLE,
    addLocalMember,
    addMember, addMembers, deleteMember,
    getLocalIDMember,
    getLocalMember,
    getMemberByUserID, getUserIDByMemberId, setBridgeId,
    setHandlerMember,
    setMemberPinned, setMemberRecord, setMemberRole
} from "../../features/member";
import { NOTIFICATION_TIMEOUT, removeParticipantNotification, showNotification, showRequestPresentation, showStartAttendance } from "../../features/notifications";
import { SURVEY_MODAL_ID } from "../../features/options";
import { getLocalSharingStatus, setShareScreenUserId, stopScreenShare } from "../../features/screen-share";
import { DEFAULT_INDEX, addDocumentList, removeShareItem, setFocusShareItem, updateShareItem } from "../../features/share-document";
import { addSharedSurvey, isExistSurvey, setSurveyReplied } from "../../features/survey";
import { getCurrentMode, setGridCount, setVideoLayoutMode, videoLayoutMode } from "../../features/video-layout";
import messageHandler from "../../modules/UI/util/MessageHandler";
import { createTaskQueue } from "../../modules/util/helpers";
import { command } from "./comand";

function getState() {
    return APP.store.getState();
}

export default function MateConference(mate) {
    this.mate = mate;
    this.layoutMode = null;

    this.dispatch = null;
}

const task = createTaskQueue();
const isRemote = true;
MateConference.prototype.constructor = MateConference;

MateConference.prototype.init = function() {
    const { dispatch } = APP.store;
    
    this.dispatch = dispatch;
}

// 참석자 퇴장 (local 제외)
MateConference.prototype.participantLeave = function({ from }, group_id = false) {
    this.dispatch(deleteMember(from));
}

// 참석자 입장 (local 제외)
MateConference.prototype.participantJoined = async function({ message: data }, groupId = false) {
    const currentGropuId = getGroupId(getState);

    
    if (groupId === currentGropuId) {
        if (getLocalIDMember(APP.store.getState) === data.user_uuid) return;
        const member = { 
            ...data,
            status: PARTICIPANT_STATUS.OCCUPIDE,
            entryTime: Date.now(), 
            existed: false
        };        
        this.dispatch(addMember(member));
    }
}

// 참석자 bridge_session_id 추가 
MateConference.prototype.setJitsiId = function({ message: data }) {
    const jitsi_id = data.bridge_session_id;
    const user_id = data.user_id;
    // if (user_id === getLocalIDMember(getState)) return;
    this.dispatch(setBridgeId(user_id, jitsi_id));
}

/**
 * 문서
 */
// 문서 공유 상태 
MateConference.prototype.statusChanged = function(data) {
    const { uuid, resource_id, status: documentStatus, completed_pages, complete } = data;

    if (completed_pages.length >= 1) {
        this.dispatch(updateShareItem(uuid, {
            uuid, page: resource_id, count: completed_pages.length, list: completed_pages, 
            complete: true, isWhiteBoard: false
        }));
    }  
}
// 문서 저장
MateConference.prototype.setDocument = function(data) {
    const { document } = data;
    const user_uuid = getMemberByUserID(getState, document.member_uuid);
    const localId = getLocalIDMember(getState);

    if (document.shared || user_uuid === localId) {
        this.dispatch(updateShareItem(document.uuid, {
            ...document,
            user_uuid
        }, isRemote));
    }     
}
// 문서 삭제
MateConference.prototype.delDocument = function({ uuid }) {
    this.dispatch(removeShareItem(uuid, isRemote));
}
// 문서 캔버스 저장
MateConference.prototype.setCanvas = function(data) {
    const { uuid, index, key, value } = data;

    this.dispatch(setCanvasDraw(uuid, Number(index), key, value));
}
// 문서 캔버스 삭제 
MateConference.prototype.delCanvas = function(data) {
    const { uuid, index, key, isAll } = data;

    if (!isAll) this.dispatch(deleteCanvasDraw(uuid, Number(index), key, isRemote));
    else this.dispatch(deleteCanvasIndex(uuid, Number(index), isRemote));
}

/**
 * 채팅
 */
MateConference.prototype.sendMessage = function({ from, message: data }) {
    const local = getLocalMember(getState);
    const isMe = local && local.member_uuid === from;

    const parseMessage = JSON.parse(data);
    
    if (parseMessage.type === 'text' || parseMessage.type === 'file') {
        const { isOpen: isChatOpen } = getState()['features/chat'];
        this.dispatch(addMessage({
            isMe,
            hasRead: isMe || isChatOpen,
            from,
            message: data,
            privateMessage: false,
            timestamp: parseMessage.time
        }));
        return;
    }

    if (!isMe) {
        this.dispatch(endpointMessageReceived(from, parseMessage));
    }
}


// 녹화 
MateConference.prototype.setRecording = function({ to, recording }) {
    const user_uuid = getUserIDByMemberId(getState(), to);
    _handlerSetParticipant('record', recording, user_uuid);
}

/**
 * 발표권
 */
// 발표자 요청
MateConference.prototype.requestPresentation = function({ from }) {
    const user_uuid = getUserIDByMemberId(getState(), from);

    const local = getLocalMember(getState);
    if (local && local.role === ROLE.HOST) {
        this.dispatch(showRequestPresentation(user_uuid));       
    }
}
// 발표자 응답
MateConference.prototype.responsePresentation = function({ to, accepted }) {
    const local = getLocalMember(getState);
    
    if (to === local.member_uuid) {
        this.dispatch(showNotification({ 
            titleKey: `notify.${accepted}ResponsePresenter`
        }, NOTIFICATION_TIMEOUT));
    } 

    if (local && local.role === ROLE.HOST) {
        this.dispatch(removeParticipantNotification(to));
    }
}

/**
 * 그룹
 */
// 그룹 생성 및 삭제 
MateConference.prototype.setGroup = throttle(function() {
    getGroups(this.dispatch, getState);
}, 400, { trailing: false });

/**
 * 메시지 전달
 */
MateConference.prototype.getNotification = function({ message, group_id }) {
    const currentGroupId = getGroupId(getState());

    if (currentGroupId === group_id) {
        this.dispatch(showNotification({titleKey: 'notify.notifyMessage', description: message}, NOTIFICATION_TIMEOUT));
    }
}


/**
 * 사용자
 */
// 참석자 role 변경
MateConference.prototype.setParticipantRole = async function({ message: data }, groupId ) {
    var { target: user_uuid, role, permissions } = data;
    const currentGropuId = getGroupId(getState);


    if (groupId !== '') {
        await getGroups(this.dispatch, getState);
    }
    
    if (groupId === currentGropuId) {
        this.dispatch(setMemberRole(user_uuid, role, isRemote, false));

        if (getLocalIDMember(APP.store.getState) === user_uuid) {
            this.dispatch(setLocalUserProperty({ permissions, role }));
        }       
    } else {
        this.dispatch(setMemberRole(user_uuid, role, isRemote, groupId === '' ? false : true));
    }
}
// 강퇴
MateConference.prototype.kickMemeber = function({ to, from, type }) {
    const toUserId = getUserIDByMemberId(getState, to);
    const fromUserId = getUserIDByMemberId(getState, from);
    const localID = getLocalUserUUID(getState);

    if (type === 'close') APP.mateManagement.disconnect();
    else {
        if (localID === toUserId) {
            if (fromUserId !== toUserId) {
                const fromMember =  getMemberByUserID(getState, fromUserId);
                const displayName = fromMember.nickname;
    
                APP.mateManagement.disconnect();
                messageHandler.alertMeesgae('notify.kickParticipant', { displayName });
            } else {
                APP.mateManagement.disconnect();
                messageHandler.alertMeesgae('notify.dupLogin');
            }
        }
    }
}
// 카메라 
MateConference.prototype.setVideoMuted = function(muted) {
    this.dispatch(setVideoMuted(muted));
}
// 마이크 
MateConference.prototype.setAudioMuted = function(muted) {
    this.dispatch(setAudioMuted(muted));
}

/**
 * VOD
 */
// vod 등록
MateConference.prototype.setVodList = function({ video_uuid }) {
    this.dispatch(setVodItem(video_uuid, true));
}
// vod 삭제
MateConference.prototype.deleteVodItem = function({ video }) {
    this.dispatch(delVodList(video));
}
// focus vod
MateConference.prototype.selectVodItem = function({ video_uuid }) {
    this.dispatch(setFocusVod(video_uuid, true));
}

/**
 * 설문 조사 
 */
// 설문 조사 알림
MateConference.prototype.notifySurvey = async function({ survey_id }) {
    if (isExistSurvey(getState(), survey_id)) return;
    var { complete, message } = await APP.API.getSurveyInfo(survey_id);

    if (complete) {
        this.dispatch(addSharedSurvey(survey_id, message));
        if (getState()['features/base/modal'].name !== SURVEY_MODAL_ID) {
            APP.UI.openModal(SURVEY_MODAL_ID);
        }
    }
}
// 설문 조사 응답
MateConference.prototype.replySurvey = function({ survey_id, from }) {
    const user_uuid = getUserIDByMemberId(getState, from);
    this.dispatch(setSurveyReplied(survey_id, user_uuid));
}

/**
 * 출석 확인
 */
MateConference.prototype.startAttendance = function({ name, uuid, check_duration }) {
    this.dispatch(showStartAttendance({ name, uuid, check_duration }));
}

/**
 * 레이아웃 
 */
// 화면 설정
MateConference.prototype.setLayoutViewVisible = function({ view_visible }) {
    const mode = getCurrentMode(getState, { mode: null, viewVisible: view_visible });
    this.dispatch(setVideoLayoutMode(mode, isRemote));
}
// 레이아웃 모드
MateConference.prototype.setLayoutMode = function(commandName) {
    let mode = videoLayoutMode.grid;
    if (commandName === `Event.${command[videoLayoutMode.pin]}`) mode = videoLayoutMode.pin;
    else if (commandName === `Event.${command[videoLayoutMode.voice]}`) mode = videoLayoutMode.voice;
    else if (commandName === `Event.${command[videoLayoutMode.seminar]}`) mode = videoLayoutMode.seminar;

    this.dispatch(setVideoLayoutMode(mode, isRemote));
}
// 그리드 모드 option 
MateConference.prototype.setGridOption = function({ grid_count }) {
    // this.dispatch(setVideoLayoutProperty({ gridCount: grid_count }));
    this.dispatch(setGridCount(grid_count, isRemote));
}
// 핀 모드 option 
MateConference.prototype.setPinOption = function({ message: data }, pinned) {
    _handlerSetParticipant('pin', pinned, data.target);
}
// 화면 공유 모드 option 
MateConference.prototype.setScreenOption = function({ jitsi_id, target }) {
    if (getLocalSharingStatus(getState) && !jitsi_id) {
        this.dispatch(stopScreenShare());
        return;
    }

    const user_id = !jitsi_id ? null : getUserIDByMemberId(getState, target);
    this.dispatch(setShareScreenUserId(jitsi_id, user_id, isRemote));
}
// focus item - document
MateConference.prototype.setFocusDocument = function({ uuid, index }, mode) {  
    this.dispatch(setFocusShareItem(uuid, index, mode, isRemote));
}

/**
 * 손들기
 */
MateConference.prototype.raiseHand = function(data, handler) {
    const { to } = data;
    const user_uuid = getUserIDByMemberId(getState, to);
    user_uuid && this.dispatch(setHandlerMember(user_uuid, handler, isRemote));
}

/**
 * policy   
 */
MateConference.prototype.setPolicy = function({ policy }) {
    const initial_camera = policy.initial_camera === "off" ? "off" : "on";
    const initial_mic = policy.initial_mic === "off" ? "off" : "on";
    const hands_up = policy.hands_up;
    this.dispatch(updateSettings({ initial_camera, initial_mic, hands_up, hands_up_time: policy.hands_up_time }));

    const local = getLocalMember(getState);
    if (hands_up === "fadeout" && local.hands_up) {
        this.dispatch(setHandlerMember(local.user_uuid, false));
    }
}

// local 사용자 입장
MateConference.prototype.join = async function(data) {
    const meeting_uuid = APP.mateManagement.getMeetingId();
    const { 
        member_uuid, layout, view_visible, grid_count, role, permissions, pinned, members, document_list, video_list, group_id, 
        last_notification, running_group
    } = data;

    this.dispatch(clearMessages());

    await getGroups(this.dispatch, getState);
    const user_uuid = getLocalUserUUID(getState);    
    this.dispatch(addLocalMember({
        user_uuid,
        member_uuid,
        group_id,   
        role,
        permissions,
        hidden: false,
        pinned
    }));

    if (group_id === "") {
        this.dispatch(setMainRole(role));
        this.dispatch(setGroupStatus(running_group, null, isRemote));
    }
    
    
    // remote 사용자 입장 
    this.dispatch(addMembers(members, member_uuid));

    // 현재 모드 변경 
    const mode = getCurrentMode(getState, { mode: layout, viewVisible: view_visible });
    this.dispatch(setVideoLayoutMode(mode, isRemote));

    // grid    
    this.dispatch(setGridCount(grid_count, isRemote));

    // 화면 공유 모드 
    const screen_track_id = data.screen_shared_jitsi_id;
    if (screen_track_id) {
        const screen_member_id = data.screen_shared_member_uuid;
        const screen_user_id = getMemberByUserID(getState, screen_member_id);

        this.dispatch(setShareScreenUserId(screen_track_id, screen_user_id || screen_member_id, isRemote));
    }

    if (video_list) {
        video_list[VOD_FILE] && video_list[VOD_FILE].map(item =>  this.dispatch(setVodItem({ ...item, type: VOD_FILE })));
        video_list[VOD_URL] && video_list[VOD_URL].map(item =>  this.dispatch(setVodItem({ ...item, type: VOD_URL })));
    }
    
    // document_list
    this.dispatch(addDocumentList(document_list, member_uuid));

    if (data.vod_shared_video_uuid) {            
        this.dispatch(setFocusVod(data.vod_shared_video_uuid, true));

        this.dispatch(setFocusShareItem(data.vod_shared_video_uuid, DEFAULT_INDEX, videoLayoutMode.vod, isRemote));
    }
    // share document 
    if (data.document_focus_item) {
        const { uuid, index } = data.document_focus_item;
        
        this.dispatch(setFocusShareItem(uuid, index, videoLayoutMode.document, isRemote));
    }
    
    if (data.whiteboard_focus_item) {
        const { uuid, index } = data.whiteboard_focus_item;

        this.dispatch(setFocusShareItem(uuid, index, videoLayoutMode.white, isRemote));
    }

    if (last_notification !== "") {
        this.dispatch(showNotification({titleKey: 'notify.notifyMessage', description: last_notification}, NOTIFICATION_TIMEOUT));
    }    

    const initial_camera = data.policy.initial_camera === "off" ? "off" : "on";
    const initial_mic = data.policy.initial_mic === "off" ? "off" : "on";
    if (!( data.role === ROLE.MANAGER ||  data.role === ROLE.HOST ||  data.role === ROLE.PRESENTER || data.role === ROLE.ADMIN) && !data.pinned) {
        if (data.policy)  {
            // 카메라, 오디오 초기 설정 
            const request_camera = initial_camera === "off" ? false : true;
            const request_mic = initial_mic === "off" ? false : true;

            this.dispatch(updateSettings({ request_camera, request_mic }));

            if (!request_camera) this.dispatch(setVideoMuted(true));
            if (!request_mic) this.dispatch(setAudioMuted(true));
        }           
    }

    
    const hands_up = data.policy.hands_up;
    this.dispatch(updateSettings({ initial_camera, initial_mic, hands_up, hands_up_time: data.policy.hands_up_time }));

    // 이전 설문 조사 등록
    getSurveyList(meeting_uuid);

    const { start, group_id: assginGroupId } = startLocalGroup(getState);
    
    // 그룹 권한이 있는 사용자인 경우 할당 해제 
    if (role === ROLE.HOST && group_id === "") {
        if (assginGroupId) {
            await APP.API.assignGroup({ members: [{ user_id: user_uuid, role_name: ROLE.ADMIN }], method: "unset" }, assginGroupId);
        } 
        APP.store.dispatch(setRoomJoined(true));
    } else {
        if (start && assginGroupId && group_id === "") {
            APP.mateManagement.startGroupMeeting(assginGroupId);
            return;
        }

        APP.store.dispatch(setRoomJoined(true));
    } 

    this.dispatch(conferenceJoined({
        conference: meeting_uuid,
        joined: undefined,
        leaving: undefined
    }, "mate"));  
}

export function setShareDocumentList(value, remote) {
    const { dispatch, getState } = APP.store;
    
    const sharedPremission = isCheckPermission(getState(), PERMISSION.SHARE);
    const local = getLocalMember(getState());

    if (value.shared 
        || (sharedPremission && value.member_uuid === local.member_uuid) 
        || (sharedPremission && value.user_uuid === local.user_uuid) 
        || value.uuid === DEFAULT_DOCUMENT) {

        if (value.canvasList) {
            const list = parseCanvaseLine(value.canvasList);
            dispatch(setCanvasLine(list, value.uuid));
        }   

        dispatch(setDocumentSharedFile({ ...value }, null, remote));
    }   
}

function parseCanvaseLine(canvasList) {
    let list = new Map();
    canvasList && Object.keys(canvasList).forEach(index => {
        list = {
            ...list,
            [index]: new Map(Object.entries(canvasList[index]))
        }
    });
    
    return list === undefined ? new Map() : new Map(Object.entries(list));
}

function _handlerSetParticipant(type, action, user_uuid) {
    const { dispatch, getState } = APP.store;

    if (type === 'pin') {
        dispatch(setMemberPinned(user_uuid, action, isRemote));
    } else {
        // 
        dispatch(setMemberRecord(user_uuid, action, isRemote));
    }
}

// 설문 조사
async function getSurveyList(meeting_uuid) {
    const { dispatch } = APP.store;

    var { complete, message } = await APP.API.getAllSurvey(meeting_uuid);
    if (complete) {
        if (message === null) return;
        message.map(value => {
            if (!value.uuid) return;
            const deadline = value.info.deadline;
            if ( deadline && Date.parse(deadline) < Date.parse(new Date())) {
                if (Array.isArray(value.info.replied) ) {
                    value.info.replied = new Set(value.info.replied);
                }

                if ( !value.info.replied.has(user_uuid) && role !== ROLE.HOST ) return;
            }
            dispatch(addSharedSurvey(value.uuid, value.info));
        });
    }
}
MateConference.prototype.setGroupRunnigStatus = function({ running }) {
    this.dispatch(setGroupStatus(running, false, isRemote));
}

// export function initGroup () {
//     return new Promise(function(resolve, reject) {
//         APP.API.getGroupList()
//             .then(response => {
//                 if (response.complete) {
//                     console.log(response);

//                     let groups = new Map();
//                     let members = new Map();
//                     Object.entries(groups).map(([group_id, group]) => {
//                         if (group_id === "lobby") return;
//                         groups.set(group_id, group);

//                         group.members && group.members.map(m => {
//                             members.set(m.user_id, { ...m, group_id, group.name});
//                         });
//                     });

//                     dispatch(setGroupMembers(members));
//                     // let items = new Map();
//                     // let data = new Array();
//                     // const groups = response.message;

//                     // Object.keys(groups).map(uuid => {
//                     //     const group = groups[uuid];
            
//                     //     const list = {
//                     //         ...group, 
//                     //         uuid,
//                     //         name: group.name
//                     //     };
            
//                     //     data.push(list);

//                     //     group.members && group.members.map(m => {
//                     //         items.set(m.user_id, { ...m, group_id: uuid });
//                     //     });  
//                     // });

//                     // APP.store.dispatch(initGroupList(data));
//                     // APP.store.dispatch(setGroupParticipant(items));

//                     resolve({ participant: items });
//                 }
//                 resolve(false);
//             }).catch(err => {
//                 console.log(err);
//                 resolve(false);
//             });
//     });
// }