import _ from "lodash";
import React, {  useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CREATE_ATTENDANCES_MODAL_ID } from "../..";
import { translate } from "../../../base/i18n";
import AbstractPopup from "../AbstractPopup";
import { ATTENDANCES_LIST, ENTRANCE_ATTENDANCES, LEAVING_ATTENDANCES, MIDDLE_ATTENDANCES } from "./constants";

const CheckStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    .guid {
        margin: 0 auto;
        white-space: pre-line;
        text-align: center;
    }

    .btn {margin: 0 auto;}

    .btn_bundle {
        display: inline-flex;
        flex-direction: rows;
        gap: 8px;
    }
`;

const TimeStyled = styled.td`
    height: 36px;   
    position: relative;

    ul {
        max-height: 150px;
        overflow-y: auto;
        overflow-x: hidden;

        li {
            padding: 4px 12px; 
            z-index: 2;
            width: 32px; 
            background: #fff;
            color: #1a1d25;
            display: none;
            cursor: pointer;

            &.init {
                display: block;
                text-align: center;
            }

            &:hover:not(&.init), &.isSelect {
                background: #b1b1b1;
            }
        }

        

        &.isSelected { 
            top: 0;
            transform: translate(-50%, 5px);
            li {
                display: block;
                &.init { display: none; }
            }        
        }
    }
    
`;


class CheckAttendances extends AbstractPopup {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this._setModalID(CREATE_ATTENDANCES_MODAL_ID);
    }

    render() {
        const content = <Create t={this.props.t} />;
        
        return (
            <>
                <div className="option_pop center" style={{width: "400px"}}>
                    { this._renderPopupHeader(this.props.t, `attendances.${this.props.type}`) }
                    { this._renderPopupBody(content) }
                </div>  
            </>
        );
    }
}

function Create ({
    t
}) {
    const [list, setList] = useState(new Map());
    const [state, setState] = useState();
    const [startTime, setStartTime] = useState();


    useEffect(() => {
        let attendances_list = new Map();
        ATTENDANCES_LIST.map(item => {
            attendances_list.set(t(item), {
                key: item, isShow: true
            });
        });

        APP.API.getAttendancesCheck()
            .then(({ complete, message }) => {
                if (complete) {
                    let newGroup = new Set();
                    message.items.map(item => {
                        newGroup.add(item.name);
                    });                    

                    console.log(newGroup)
                    const isEntrance = newGroup.has(ENTRANCE_ATTENDANCES);
                    const isLeaving = newGroup.has(LEAVING_ATTENDANCES);

                    if (!isEntrance) {
                        attendances_list.set(LEAVING_ATTENDANCES, {
                            key: LEAVING_ATTENDANCES, isShow: false
                        });

                        attendances_list.set(MIDDLE_ATTENDANCES, {
                            key: MIDDLE_ATTENDANCES, isShow: false
                        });
                    } else {
                        if (isLeaving) {
                            attendances_list.set(MIDDLE_ATTENDANCES, {
                                key: MIDDLE_ATTENDANCES, isShow: false
                            });

                            attendances_list.set(LEAVING_ATTENDANCES, {
                                key: LEAVING_ATTENDANCES, isShow: false
                            });
                        } else {
                            attendances_list.set(MIDDLE_ATTENDANCES, {
                                key: MIDDLE_ATTENDANCES, isShow: true
                            });

                            attendances_list.set(LEAVING_ATTENDANCES, {
                                key: LEAVING_ATTENDANCES, isShow: true
                            });
                        }
                        
                        attendances_list.set(ENTRANCE_ATTENDANCES, {
                            key: ENTRANCE_ATTENDANCES, isShow: false
                        });
                    }


                    setList(attendances_list);
                }
            });
            
    }, []);

    return (
        <CheckStyled>
        { !state ?
            <>  
                <div className="guid">
                    <p style={{fontWeight: "900",  color: "#e3e3e3"}}>
                        {t("attendances.createGuid")} 
                    </p>
                    <br/>
                    <p> { t("attendances.attachments", { name : t(`attendances.${ENTRANCE_ATTENDANCES}`) }) } </p>
                    <p> { t("attendances.attachments", { name : t(`attendances.${LEAVING_ATTENDANCES}`) }) } </p>
                    <p> { t("attendances.middleAttachments", { name : t(`attendances.${MIDDLE_ATTENDANCES}`) }) } </p>
                </div>

                <div className="btn_bundle">
                    { [...list].map(( [name, { isShow, key }], index ) => {
                            const onClick = () => {
                                if (isShow) setState(key);
                            }
                            
                            return (
                                <button key={index} className={`btn btn_middle btn_${isShow}`} onClick={onClick}>{ t(`attendances.${name}`) }</button>
                            );
                        })
                    }
                </div>
            </>
            : state === MIDDLE_ATTENDANCES ? <TimeSetting t={t} setState={setState} setStartTime={setStartTime} />
            : <NextPage t={t} state={state} setState={setState} startTime={startTime} />
        }
        </CheckStyled>
    );
}

function SelectTime({ count, unit, setRemoteTime }) {
    const [ time, setTime ] = useState(0);
    const ulElement = useRef();

    const unfoldTime = () => {
        const element = ulElement.current;
        if (element.className.includes("isSelected")) element.classList.remove("isSelected");
        else element.classList.add("isSelected");
    };

    const changeTime = (e) => {
        const time = e.target.value;

        setTime(time);
        setRemoteTime(time);
    }

    return (
        <TimeStyled>
            <ul className="center" ref={ulElement} onClick={unfoldTime}>
                <li className="init"> {time.toString().padStart(2, '0')} </li>
                { _.range(0, count, unit).map(value => {
                    const num = value.toString().padStart(2, '0');

                    return (
                        <li key={value}
                            className={ time === num ? "isSelect" : null }
                            value={num} 
                            onClick={changeTime}> { num } </li>
                    )
                })}
            </ul>
        </TimeStyled>
    )
}

function TimeSetting({
    t, setState, setStartTime
}) {
    const [hour, setHour] = useState(0);
    const [minute, setMinute] = useState(0);

    const onSave = () => {
        let startDate = new Date();
        startDate.setHours(hour);
        startDate.setMinutes(minute);
        startDate.setSeconds(0);

        setStartTime(startDate);

        setState(`${MIDDLE_ATTENDANCES}_result`);
    }

    const setPrevious = () => {
        setState();
    }  

    return (
        <>
            <div className="guid">
                { t("attendances.middleAttachmentsGuid") }
            </div>
            <div style={{ width: "50%" }}>
                <table style={{ tableLayout: "fixed" }}>
                    <thead>
                        <tr>
                            <th>{ t("attendances.hour") }</th>
                            <th>{ t("attendances.minute") }</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <SelectTime count={25} unit={1} setRemoteTime={setHour} />
                            <SelectTime count={60} unit={5} setRemoteTime={setMinute} />
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="btn_bundle">
                <button className="btn btn_middle" onClick={setPrevious}>{t("common.previous")}</button>
                <button className="btn btn_middle" onClick={onSave}>{t("common.okay")}</button>
            </div>   
        </>
    )
}

function NextPage({ t, state, setState, startTime=new Date() }) {
    const name = state;

    const onClick = () => {       
        console.log(state)
        if (new Date() > startTime && state === 'middle_result' ) {
            alert('현재 시간보다 이전 시간에 출석 설정은 불가능합니다. 다시 설정해주세요.');
            return;
        }
        const check_duration = 300000; // 10분 

        APP.API.createAttendancesCheck({
            name, 
            check_start_time: startTime.toJSON(),
            check_duration
        }).then(({ complete, message }) => {
            if (complete) APP.UI.closeModal();
            else alert(t(message));
        });
    }

    const setPrevious = () => {
        setState();
    }

    return (
        <>
            <div className="guid">
                { t("attendances.nextAttachments", { name: t(`attendances.${name}`) } ) }
            </div>
            <div className="btn_bundle">
                <button className="btn btn_middle" onClick={setPrevious}>{t("common.previous")}</button>
                <button className="btn btn_middle" onClick={onClick}>{t("common.okay")}</button>
            </div>            
        </>
    )
}

export default translate(CheckAttendances);