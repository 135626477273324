import { CLOSE_MODAL } from ".";
import { CHAT_VIEW_MODAL_ID, resetSendFileList } from "../../chat";
import { DEVICE_SETTING_MODAL_ID } from "../../options";
import { setAudioInputDeviceAndUpdateSettings, setVideoInputDeviceAndUpdateSettings } from "../devices";
import { MEDIA_TYPE } from "../media";
import { MiddlewareRegistry } from "../redux";
import { getLocalTracks, getTrackState } from "../tracks";
import { closeModal } from "./actions";
import { SET_MODAL_NAME } from "./actionTypes";

MiddlewareRegistry.register(store => next => action => {
    const { name: currentName } = store.getState()["features/base/modal"];
    
    switch (action?.type) {
        case SET_MODAL_NAME:
            if (currentName === CHAT_VIEW_MODAL_ID) store.dispatch(resetSendFileList());
            break;

        case CLOSE_MODAL:
            if (currentName === CHAT_VIEW_MODAL_ID) store.dispatch(resetSendFileList());
            // else if (currentName === DEVICE_SETTING_MODAL_ID) {
            //     APP.UI.showLoading(true);
            //     setTimeout(() => {
            //         window.videoTrackDeviceId &&store.dispatch(setVideoInputDeviceAndUpdateSettings(window.videoTrackDeviceId));
            //         window.audioTrackDeviceId && store.dispatch(setVideoInputDeviceAndUpdateSettings(window.audioTrackDeviceId));
                    

            //         APP.UI.showLoading(false);
            //     }, 1000);
            // }
            break;
    }
    return next(action);
});