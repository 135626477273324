import { ReducerRegistry, StateListenerRegistry } from "../redux";
import { cloneDeep } from "lodash";
import { DEL_VOD_LIST, SET_FOCUS_VOD_UUID, SET_UPLOAD_FILE, SET_VOD_LAYOUT_OPEN, SET_VOD_LIST } from "./actionTypes";
import { setVodLayoutOpen } from "./action";
import { getUserAccessToken } from "../localuser";

const DEFAULT_STATE = {
    open: false,
    vod_shared_video_uuid: null,
    list: new Map()
};

ReducerRegistry.register('features/base/vod', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_VOD_LAYOUT_OPEN:
            return {
                ...state,
                open: action.open
            }

        case SET_VOD_LIST:
            const uuid = action.list.uuid;
            const newList = _fileList(state.list.get(uuid), action.list);
            state.list.set(uuid, newList);
            
            return {
                ...state
            }

        case SET_FOCUS_VOD_UUID:
            return {
                ...state,
                vod_shared_video_uuid: action.uuid
            }

        case DEL_VOD_LIST:
            state.list.delete(action.uuid);

            return {
                ...state
            }
    }

    return state;
});

function _fileList(state, fileList) {
    const newState = { ...state };
    
    for (const key in fileList) {        
        newState[key] = cloneDeep(fileList[key]);
    }

    return newState;
}

StateListenerRegistry.register(state => state['features/base/conference'].joined,
    (joined, { dispatch }) => {
        if (!joined) {
            dispatch(setVodLayoutOpen(false));
        } else {
            dispatch(setVodLayoutOpen(true));
        }
    }
);
