import { getCurrentConference, setConferenceError } from "../conference";
import { getUserRole, logoutUser } from "../localuser";
import { ReducerRegistry, StateListenerRegistry } from "../redux";
import { setAppPage } from "./action";
import { APP_MOUNT, SET_APP_ENABLE_PAGE, SET_APP_PAGE } from "./actionType";
import { PAGE_TYPE } from "./constants";
import { checkEnabaledPage, getCurrentPage } from "./functions";

const DEFAULT_STATE = {
    load: false,
    page: PAGE_TYPE.MAIN,
    enable: {
        [PAGE_TYPE.LOBBY]: true,
        [PAGE_TYPE.NICKNAME]: true,
        [PAGE_TYPE.DEVICE]: true,
        [PAGE_TYPE.MAIN]: true,
        [PAGE_TYPE.ERROR]: true
    }
};

ReducerRegistry.register('features/base/app', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case APP_MOUNT:
            return {
                ...state,
                load: action.isLoad,
            };
        
        case SET_APP_ENABLE_PAGE:
            return {
                ...state,
                enable: {
                    ...state.enable,
                    ...action.data
                }
            };
        
        case SET_APP_PAGE:
            return {
                ...state,
                page: action.page
            }
    }

    return state;
});

StateListenerRegistry.register(state => state['features/base/app'].page, 
    (page, store, prevPage) => {
        store.dispatch(setConferenceError(null));  
        if (page === PAGE_TYPE.LOBBY && APP.function.tempLogin) {
            const role = getUserRole(store.getState());

            if (role === "anonymous") {
                store.dispatch(logoutUser());
            }
        }
    }
);

StateListenerRegistry.register(state => getCurrentConference(state),
    (joined, { dispatch, getState }, prevJoined) => {
        if (prevJoined === true && joined === false && APP.function.tempLogin) {
            const role = getUserRole(getState());
            
            if (role === "anonymous") {
                dispatch(setAppPage(PAGE_TYPE.LOBBY))
            }
        }
    }
);


StateListenerRegistry.register(state => state['features/base/app'].enable, 
    (enable, store) => {
        const page = getCurrentPage(store.getState());
        if (page === null) return;
        
        checkEnabaledPage(store, page);
    }   
);