/**
 * 그룹 하나 변경 
 * @param {string} group_id 
 * @param {Object} group 
 * @returns 
 */
export const SET_GROUP = 'SET_GROUP';

/**
 * 종료된 그룹 변경
 * @param {string} group_id 
 * @returns 
 */
export const SET_END_GROUP = 'SET_END_GROUP';

/**
 * 시작된 그룹 변경
 * @param {string} group_id 
 * @returns 
 */
export const SET_START_GROUP = 'SET_START_GROUP';

/**
 * 그룹 듀얼 모니터 수정 
 * @param {Boolean} isDual 
 * @returns 
 */
export const SET_GROUP_DUAL = 'SET_GROUP_DUAL';

/**
 * 그룹 삭제 
 * @param {Array} groups 
 * @returns 
 */
export const REMOVE_GROUPS = 'REMOVE_GROUPS';

/**
 * 그룹 상태 변경
 * @param {Boolean} status 
 * @returns 
 */
export const SET_GROUP_STATUS = 'SET_GROUP_STATUS';