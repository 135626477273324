import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getRoomOption } from "../../../base/conference";
import { AbstractDialog } from "../../../base/dialog";
import { ROLE_LEVEL, getLocalMember } from "../../../member";
import { NOTIFICATION_TIMEOUT, showNotification } from "../../../notifications";


// function CopyInviteCode({
//     t, roomCode, copyURL
// }) {
//     const localRole = useSelector(state => getLocalMember(state)?.role);
//     const [copied, setCopied] = useState(false);
//     const [notifyPermission, setNotifyPermission] = useState(false);

//     useEffect(() => {
//         if (copied) copyURL();
//     }, [copied]);

//     useEffect(() => {
//         const level = ROLE_LEVEL.indexOf(localRole) >= 3 ? true : false ;
//         console.log(level)
//         const show = level && APP.function.invite.notify; 
//         setNotifyPermission(show);
//     }, [localRole]);

//     const onCopy = () => {
//         setCopied(true);
//     }

//     const onNotify = async () => {
//         await APP.API.notifyVacatedMember();
//     }

//     console.log(notifyPermission)
//     return (
//         <dl className="copy" style={{display: "flex", flexDirection:"column"}}>
            
//         </dl>  
//     )
// }

// export default function InviteGuest({
//     onClose
// }) {
//     const { t } = useTranslation();
//     const dispatch = useDispatch();
//     const { code: roomCode } = useSelector(state => getRoomOption(state));
    
//     const renderContent = () => {
//         const copyURL = () => {
//             onClose ? onClose() : APP.UI.closeModal();
//             dispatch(showNotification({titleKey: 'invite.copyRoom'}, NOTIFICATION_TIMEOUT));
//         }
        
//         if (!APP.function.invite.url) return null;
//         return <CopyInviteCode t={t} roomCode={roomCode} copyURL={copyURL} />
               
//     }   

//     return (
//         <AbstractDialog t={t} name={ "inviteGuest" } width={440} handlerClose={onClose}>
//             { renderContent() }
//         </AbstractDialog>
//     )
// }

export default function InviteGuest({
    onClose
}) {
    const { t } = useTranslation();
    
    const localRole = useSelector(state => getLocalMember(state)?.role);
    const [notifyPermission, setNotifyPermission] = useState(false);

    useEffect(() => {
        const level = ROLE_LEVEL.indexOf(localRole) >= 3 ? false : true;
        setNotifyPermission(level);
    }, [localRole]);

    const urlShow =  APP.function.invite.url;
    const notifyShow =  APP.function.invite.notify && notifyPermission;
    return (
        <AbstractDialog  t={t} name={ "inviteGuest" } width={440} handlerClose={onClose}>
            <dl className="copy" style={{display: "flex", flexDirection:"column"}}>
                { urlShow && <p className="guid"> {t("options.copyGuid")} </p> }
                { notifyShow && <p className="guid"> {t("options.notifyGuid")} </p> }
                <div style={{ display: "flex", gap: "4px" }}>
                    { urlShow && <CopyInviteCode t={t} onClose={onClose} /> }
                    { notifyShow && <NotifyButton t={t} onClose={onClose} notifyPermission={notifyPermission} /> }
                </div>
            </dl>
        </AbstractDialog>
    )
} 

function CopyInviteCode({
    t, onClose
}) {
    const dispatch = useDispatch();
    const { code: roomCode } = useSelector(state => getRoomOption(state));
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied) copyURL();
    }, [copied]);

    const onCopy = () => {
        setCopied(true);
    }

    const copyURL = () => {
        onClose ? onClose() : APP.UI.closeModal();
        dispatch(showNotification({titleKey: 'invite.copyRoom'}, NOTIFICATION_TIMEOUT));
    }

    return (
        <CopyToClipboard text={roomCode} onCopy={onCopy}>
            <div className="btn btn_large nonSelect" style={{margin: '16px auto'}}>
                {t("options.copyInviteCode")}
            </div>
        </CopyToClipboard>
    )
}

function NotifyButton({
    t, onClose, notifyPermission
}) {
    const onNotify = async () => {
        await APP.API.notifyVacatedMember();
    }

    if (!notifyPermission) return null;
    return (
        <div className="btn btn_large nonSelect" 
            onClick={onNotify}
            style={{margin: '16px auto'}}>
            { t("options.notifyVacatedMember") }
        </div>
    )
}