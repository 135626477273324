import { throttle } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { BsFillMicFill, BsFillMicMuteFill, BsFillVolumeOffFill, BsFillVolumeUpFill } from "react-icons/bs";
import "../../../assets/css/device.scss";
import { PAGE_TYPE, setAppPage } from "../../base/app";
import { Progressbar } from "../../base/canvas";
import { setAudioInputDevice, setVideoInputDevice } from "../../base/devices";
import { AbstractDialog } from "../../base/dialog";
import { translate } from "../../base/i18n";
import { createLocalTrack, createLocalTracks } from "../../base/lib-jitsi-meet";
import { MEDIA_TYPE, setAudioMuted, setVideoMuted } from "../../base/media";
import { closeModal } from "../../base/modal";
import { connect } from "../../base/redux";
import { updateSettings } from "../../base/settings";
import { isIOSDevice } from "../../base/util";
import { PreviousButton } from "../../previous-button";
import { getVdoErrorMsg } from "../functions";
import AudioInputTest from "./AudioInputTest";
import AudioOutputPreview from "./AudioOutputPreview";
import DeviceSelector from "./DeviceSelector";
import VideoInputPreview from "./VideoInputPreview";
import { setRoomLocked } from "../../room-lock";
import { CHECKING_ROOM_PASSWORD_MODAL_ID } from "../../options";

let unMounted = true;
function Device({
    t, dispatch, isRoom, availableDevices,
    hasAudioPermission, hasVideoPermission, 
    selectedAudioInputId, selectedAudioOutputId, selectedVideoInputId, initVolume
}) {
    const [volume, setVolume] = useState(0);
    const [previewVideoTrack, setPreviewVideoTrack] = useState();
    const [previewAudioTrack, setPreviewAudioTrack] = useState();
    const [previewVideoTrackError, setPreviewVideoTrackError] = useState();
    const [previewAudioTrackError, setPreviewAudioTrackError] = useState();

    useEffect(() => {
        APP.UI.showLoading(true);
        Promise.allSettled([
            createLocalTracks([ 'audio', 'video' ], 5000)
        ]).then(result => {
            result[0].value.map(t => {
                t.dispose && t.dispose();
            });           
            
            return Promise.reject();
        }).catch(() => {
            initDevice();
        }); 

        return () => {            
            unMounted = true;    
            
            APP.UI.showLoading(false);
        }
    }, []);

    useEffect(() => {
        initVolume && setVolume(initVolume);
    }, [initVolume]);

    /**
     * 비디오 트랙 변경 했을 때 
     */
    useEffect(() => {
        if (selectedVideoInputId) _createVideoInputTrack(selectedVideoInputId);
    }, [selectedVideoInputId]);

    /**
     * 오디오 트랙 변경 했을 때
     */
    useEffect(() => {
        if (selectedAudioInputId) _createAudioInputTrack(selectedAudioInputId);
    }, [selectedAudioInputId]);

    /**
     * 오디오 트랙 볼륨 이벤트 추가 
     */
    useEffect(() => {
        if (!previewAudioTrack) return;
        if (previewAudioTrack.deviceId !== selectedAudioInputId) {
            onSelectDevice({ userSelectedMicDeviceId: previewAudioTrack.deviceId });
        }

        APP.mateManagement.listenForAudioUpdates(previewAudioTrack.stream);

        return () => {
            _disposeAudioInputPreview();
            APP.mateManagement.stopListenForAudioUpdates();
        }
    }, [previewAudioTrack]);

    /**
     * 비디오  
     */
    useEffect(() => {
        if (!previewVideoTrack) return;
        if (previewVideoTrack && previewVideoTrack.deviceId !== selectedVideoInputId) {
            onSelectDevice({ userSelectedCameraDeviceId: previewVideoTrack.deviceId });
        }

        return () => {
            _disposeVideoInputPreview();
        }
    }, [previewVideoTrack]);

    /**
     * 장치 초기화 
     */
    const initDevice = () => {
        unMounted = false;
        Promise.all([
            _createVideoInputTrack(selectedVideoInputId, true),
            _createAudioInputTrack(selectedAudioInputId, true)
        ]).then(() => {
            APP.UI.showLoading(false);
        });
    }

    /**
     * 현재 오디오 입력 미리보기를 삭제하기 위한 유틸리티 기능입니다.
     *
     * @private
     * @returns {Promise<*>}
     */
    const _disposeAudioInputPreview = () => {
        return previewAudioTrack ? previewAudioTrack.dispose() : Promise.resolve();
    }
    
    /**
     * 현재 비디오 입력 미리보기를 삭제하기 위한 유틸리티 기능입니다.
     *
     * @private
     * @returns {Promise}
     */
    const _disposeVideoInputPreview = () => {
        return previewVideoTrack ? previewVideoTrack.dispose() : Promise.resolve();
    };

    /**
     * 오디오 입력 미리보기를 위한 Track을 생성합니다.
     *
     * @param {string} deviceId - The id of audio input device to preview.
     * @private
     * @returns {void}
     */
    const _createAudioInputTrack = (deviceId) => {
        return _disposeAudioInputPreview()
            .then(() => createLocalTrack('audio', deviceId, 5000))
            .then(localTrack => {
                if (!localTrack || unMounted) {
                    localTrack && localTrack.dispose();

                    return;
                }

                setPreviewAudioTrack(localTrack);
                setPreviewAudioTrackError(null);
            })
            .catch(err => {                
                setPreviewAudioTrack(null);
                setPreviewAudioTrackError(err);
            });
    };

    /**
     * 비디오 입력 미리보기를 위한 Track을 생성합니다
     *
     * @param {string} deviceId - The id of video device to preview.
     * @private
     * @returns {void}
     */
    const _createVideoInputTrack = (deviceId) => {
        let options = null;
        if (navigator.product === 'ReactNative' && deviceId) {            
            if (deviceId === CAMERA_FACING_MODE.ENVIRONMENT) options = { facingMode: CAMERA_FACING_MODE.ENVIRONMENT };
            deviceId = null;
        }

        return _disposeVideoInputPreview()
            .then(() => createLocalTrack('video', deviceId, 5000, options))
            .then(localTrack => {
                if (!localTrack || unMounted) {
                    localTrack && localTrack.dispose();
                    return;
                }
                
                setPreviewVideoTrack(localTrack);
                setPreviewVideoTrackError(null);
            })
            .catch(err => {
                const status = getVdoErrorMsg(err);

                setPreviewVideoTrack(null);
                setPreviewVideoTrackError(status);
            });
    };

     /**
     * 전달된 구성을 기반으로 DeviceSelector 인스턴스를 만듭니다.
     *
     * @private
     * @param {Object} deviceSelectorProps - The props for the DeviceSelector.
     * @returns {ReactElement}
     */
     const _renderSelector = (deviceSelectorProps) => {
        return (
            <dl className={deviceSelectorProps.className}>                
                <dt> { t(deviceSelectorProps.label) } </dt>
                <div className="inner">
                    <DeviceSelector {...deviceSelectorProps} />
                    { deviceSelectorProps.childern && deviceSelectorProps.childern() }
                </div>
                <div style={{ margin: 0 }}>
                    { _renderMutedButtons(deviceSelectorProps.id) }
                </div>
            </dl>
        );
    }  

    const onSelectDevice = device => {
        dispatch(updateSettings(device));
    }

    const onSubmit  = throttle(() => {    
        dispatch(setVideoInputDevice(previewVideoTrack?.deviceId));
        dispatch(setAudioInputDevice(previewAudioTrack?.deviceId));
        dispatch(updateSettings({ speakerVolume: volume }));
        selectedAudioOutputId && APP.mateManagement.setAudioOutputDevice(selectedAudioOutputId);

        dispatch(closeModal());
        APP.UI.showLoading(false);
    }, 1000, { trailing: false });

    const enterRoom = throttle(async () => {
        APP.UI.showLoading(true);
        selectedAudioOutputId && APP.mateManagement.setAudioOutputDevice(selectedAudioOutputId);
        dispatch(updateSettings({ speakerVolume: volume }));
        // updateDeviceSetting
        
        const { complete, message } = await APP.API.checkOccupiedUser();
        if ( complete === false ) {
            alert(message);
            return;
        } else if (complete === 'duplicate') {
            const answer = confirm(t("notify.alertDup"));

            if (!answer) {
                dispatch(setAppPage(PAGE_TYPE.LOBBY));
                return;
            } else {
                const { message, complete } = await checkRoomLocked();
                if (complete) {                
                    const kick_user_id = message.user_uuid;
                    const response = await APP.API.kickOccupiedUser(kick_user_id);

                    if (response.complete) {
                        setTimeout(() => {
                            connection();
                        }, 2000);

                        return;
                    } else {
                        APP.UI.showLoading(false);
                        alert(response.message);
                        return;
                    }
                }
            }
        } else if (complete === true) {
            connection();
        }
    }, 2000, { trailing: false });

    const connection = async () => {
        APP.UI.showLoading(true);
        const { message, complete } = await checkRoomLocked();
        if (complete) {
            APP.mateManagement.connection();
        } else {
            console.log(message);
        }
    }

    /**
     * 비디오 출력, 오디오 입력 및 오디오 출력을 위한 DeviceSelector 인스턴스를 만듭니다.
     *
     * @private
     * @returns {Array<ReactElement>} DeviceSelector instances.
     */
    const renderSelectors = (key, _renderSelector) => {        
        let config = null;

        if (key === 'videoInput') {
            config = {
                devices: availableDevices.videoInput,
                hasPermission: hasVideoPermission,
                id: key,
                label: 'deviceSelection.videoInput',
                className: "selected_video",
                onSelect: deviceId => onSelectDevice({ userSelectedCameraDeviceId: deviceId }),
                selectedDeviceId: previewVideoTrack && previewVideoTrack.deviceId,
                error: previewVideoTrackError,
                childern: () => {
                    return <VideoInputPreview track={previewVideoTrack} error={previewVideoTrackError} />
                }
            };
        } else if (key === 'audioInput') {
            config = {
                devices: availableDevices.audioInput,
                hasPermission: hasAudioPermission,
                id: key,
                label: 'deviceSelection.audioInput',
                className: "selected_audio_input",
                onSelect: deviceId => onSelectDevice({ userSelectedMicDeviceId: deviceId }),
                selectedDeviceId: previewAudioTrack && previewAudioTrack.deviceId,
                error: previewAudioTrackError,
                childern: () => {
                    return <AudioInputTest t={t} previewAudioTrack={previewAudioTrack} />
                }
            };
        } else if (key === 'audioOutput') {
            config = {
                devices: availableDevices.audioOutput,
                hasPermission: hasAudioPermission || hasVideoPermission,
                id: key,
                label: 'deviceSelection.audioOutput',
                className: "selected_audio_output",
                onSelect: deviceId => {
                    onSelectDevice({ userSelectedAudioOutputDeviceId: deviceId });
                },
                selectedDeviceId: selectedAudioOutputId || 'default',
                childern: () => {
                    return <AudioOutputPreview deviceId = { selectedAudioOutputId || 'default' } volume={volume} /> 
                }
            };
        }

        return config ? _renderSelector(config) : null;
    }

    const onMuted = (mediaType, muted) => {
        const setMuted = MEDIA_TYPE.AUDIO === mediaType ? setAudioMuted : setVideoMuted;
        dispatch(setMuted(muted));

    }
    
    /**
     * 카메라, 비디오 on / off를 위한 buttonsProps 인스턴스를 만듭니다. 
     *
     * @private
     * @returns {Array<ReactElement>} buttonsProps instances.
     */
    const _renderMutedButtons = (key) => {
        let config = null;
        if (key === 'videoInput') {
            config = {
                key,
                isDisabled: false,
                onChange: (e) => {
                    const muted  = e.target.checked;
                    onSelectDevice({ userSelectedCameraMuted: muted });
                    onMuted(MEDIA_TYPE.VIDEO, muted);
                }
            };
        } else if (key === 'audioInput') {
            config = {
                key,
                isDisabled: false,
                onChange: (e) => {
                    const muted  = e.target.checked;
                    onSelectDevice({ userSelectedAudioMuted: muted });
                    onMuted(MEDIA_TYPE.AUDIO, muted);
                },
                innerChildren: () => {
                    return (
                        <>
                            { isRoom && ( previewAudioTrack ? <BsFillMicFill size={12} /> : <BsFillMicMuteFill size={12} color='#ed1e1e' />) }
            
                            <Progressbar className="mute_range">
                                <input id="audioLevel" className="audio_level" type="range" name="range" />
                            </Progressbar>
                        </>
                    )
                },
                childern: () => {
                    return (
                        <dd>
                            <span className="guid">{t(`device.audioInputGuid`)}</span>
                            <br/>
                            { isIOSDevice() && <span className="guid" style={{ color: "red" }}>{ t("device.iosError") } </span> }
                        </dd>  
                    )
                }
            };
        } else if (key === 'audioOutput') {
            config = {
                key,
                onChange: (e) => {
                    if (e.target.checked) setVolume(0);
                    else setVolume(0.5);
                },
                innerChildren: () => {
                    const v = volume * 100;
                    return (
                        <>
                            { isRoom &&( volume === 0 ? <BsFillVolumeOffFill size={16} color='#ed1e1e' /> : <BsFillVolumeUpFill size={16}  />) }
                            <Progressbar className="mute_range">
                                <input type="range" name="range"
                                    min="0" max="100"
                                    step="1"
                                    value={v}
                                    onChange={(e) => setVolume(e.target.value / 100)}
                                    style={{ background: `linear-gradient(to right, #29abe2 0%, #29abe2 ${v}%, #d5d4d3 ${v}%, #d5d4d3 100%)`}}
                                />
                            </Progressbar>
                        </>
                    )
                }
            };
        }

        return config ? _renderMutedButton(config) : null;
    }

    /**
     * 전달된 구성을 기반으로 buttonsProps 인스턴스를 만듭니다.
     *
     * @private
     * @param {Object} buttonsProps - The props for the buttonsProps.
     * @returns {ReactElement}
     */
    const _renderMutedButton = (buttonsProps) => {
        return (
            <>
                <dd className="mute_check nonSelect">
                    { !isRoom && <>
                        <input type="checkbox" id={buttonsProps.key} onChange={buttonsProps.onChange}/>
                        <label htmlFor={buttonsProps.key}> {t(`deviceSelection.${buttonsProps.key}Muted`)} </label> 
                        </>}

                    { buttonsProps.innerChildren && buttonsProps.innerChildren() }
                </dd>
                { buttonsProps.childern && buttonsProps.childern() }
            </>
        )
    }
    
    const renderDevice = () => {
        return (
            <div className="child" style={!isRoom ? APP.mateOption.style.deivceBackGround : null}>
                <div className={`device_wrap ${isRoom && "room"} ${!isRoom && 'center'}`}>
                    { !isRoom && <div className="option_header">
                        <h3 className="title">{ t("device.connection") }</h3>
                    </div> }

                    <p className="guid">{ t("device.guid") }</p>
                    <div className="option_content">
                        { ['videoInput', 'audioInput', 'audioOutput'].map(name => {
                            return (
                                <Fragment key={name}>
                                    { renderSelectors(name, _renderSelector) }
                                </Fragment>
                                
                            );
                        }) }
                    </div>     

                    <div className="option_footer">
                        { !isRoom ?
                            <p className="btn btn_large btn_on" onClick={enterRoom} style={{ padding: '4px 28px', fontSize: '20px' }} >{ t("device.enter") }</p>
                            : <p className="btn btn_large btn_on" onClick={onSubmit}> { t("common.okay") } </p> }
                    </div>        
                </div>
                { !isRoom && <PreviousButton previousRoute={PAGE_TYPE.NICKNAME} /> }
            </div>
        )
    };

    if (isRoom) {
        return (
            <AbstractDialog t={t} name={"deviceSetting"} width={520}>
                { renderDevice() }
            </AbstractDialog>
        );
    } else {
        return renderDevice();
    }
}

function _mapStateToProps(state) {
    const settings = state['features/base/settings'];
    const { permissions } = state['features/base/devices'];

    const selectedAudioInputId = settings.userSelectedMicDeviceId;
    const selectedAudioOutputId = settings.userSelectedAudioOutputDeviceId; // getAudioOutputDeviceId();
    const selectedVideoInputId = settings.userSelectedCameraDeviceId;

    return {
        availableDevices: state['features/base/devices'].availableDevices,
        hasAudioPermission: permissions.audio,
        hasVideoPermission: permissions.video,
        selectedAudioInputId,
        selectedAudioOutputId,
        selectedVideoInputId,
        initVolume: settings.speakerVolume
    }
}

export default translate(connect(_mapStateToProps)(Device));

// let meterRefresh;
// let prevSoundMeter;

// function listenForAudioUpdates(stream)  {      
//     _stopListenForAudioUpdates();

//     if (stream) {
//         let context = new AudioContext();
//         const soundMeter = new SoundMeter(context);

//         soundMeter.connectToSource(stream, e => {
//             if (e) {
//                 console.log(e); 
//                 return;
//             }

//             meterRefresh = setInterval(() => {
//                 const volume = soundMeter.instant.toFixed(2);
//                 APP.UI.updateAudioLevel(volume);
//             }, 200);
//         });

//         prevSoundMeter = soundMeter;
//     } else {
//         prevSoundMeter = null;
//     } 
// }

// function  _stopListenForAudioUpdates() {
//     prevSoundMeter && prevSoundMeter.stop();

//     meterRefresh && clearInterval(meterRefresh);
// }

async function checkRoomLocked(groupId) {
    if (groupId) {
        return { complete: true, message: true }
    }
    const { dispatch, getState } = APP.store;
    const state = getState();

    const code = APP.mateManagement.getRoom('code');
    const aToken = APP.mateManagement.getAccessToken();

    const { message, complete } = await APP.API.searchRoom({ data: { code }, aToken });

    let isLocked = false;
    if (complete) {
        if (message.found_count > 0) {
            isLocked = message.items[0].password_checking ? true : false;
            dispatch(setRoomLocked(isLocked));
        }
    } 

    if (isLocked) {
        const password = state['features/room-lock'].password;
        if (!password) {
            APP.UI.openModal(CHECKING_ROOM_PASSWORD_MODAL_ID);

            APP.UI.showLoading(false);
            return { message, complete: false }; 
        }
    }

    return { message, complete };
}
