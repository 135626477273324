import { toState } from "../base/redux";
import { getMemberByUserID, getUserIDByMemberId } from "../member";

/**
 * 현재 공유중인 아이디 가져오기
 * @param {Function} stateful 
 * @returns 
 */
export function getScreenSharingId(stateful) {
    const state = toState(stateful);

    return state['features/screen-share'].share;
}

/**
 * 현재 공유 중??
 * @param {Function} stateful 
 * @returns 
 */
export function getStartScreenShare(stateful) {
    const state = toState(stateful);

    return state['features/screen-share'].start_share;
}

export function getLocalSharingStatus(stateful) {
    const state = toState(stateful);

    return state['features/screen-share'].isSharingVideo;
}

/**
 * 스크린 모드 듀얼 
 * @param {*} stateful 
 * @returns 
 */
export function isScreenDual(stateful) {
    const state = toState(stateful);

    return state['features/screen-share'].dual;
}

/**
 * 현재 공유 중??
 * @param {Function} stateful 
 * @returns 
 */
export function getScreenUserID(stateful) {
    const state = toState(stateful);

    const share_member = state['features/screen-share'].share_member;
    if (share_member) {
        const member = getMemberByUserID(stateful, share_member);
        if (member) return share_member;
        else {
            const user_uuid = getUserIDByMemberId(stateful, share_member);
            return user_uuid;
        }
    }
    

    return null;
}
