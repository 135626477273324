/* global $, APP */

import { showErrorNotification, showNotification, alertMeesgae } from "../../../features/notifications/actions";

// import {
//     NOTIFICATION_TIMEOUT,
//     showErrorNotification,
//     showNotification,
//     showWarningNotification
// } from '../../../react/features/notifications';

const messageHandler = {
    OK: 'dialog.OK',
    CANCEL: 'dialog.close',
    _getFormattedTitleString(titleKey) {
        const $titleString = $('<h2>');

        $titleString.addClass('aui-dialog2-header-main');
        $titleString.attr('data-i18n', titleKey);

        return $('<div>').append($titleString)
            .html();
    },
    _getDialogClasses(size = 'small') {
        return {
            box: '',
            form: '',
            prompt: `dialog aui-layer aui-dialog2 aui-dialog2-${size}`,
            close: 'aui-hide',
            fade: 'aui-blanket',
            button: 'button-control',
            message: 'aui-dialog2-content',
            buttons: 'aui-dialog2-footer',
            defaultButton: 'button-control_primary',
            title: 'aui-dialog2-header'
        };
    },
    openCenteredPopup(url, w, h, onPopupClosed) {
        const l = window.screenX + (window.innerWidth / 2) - (w / 2);
        const t = window.screenY + (window.innerHeight / 2) - (h / 2);
        const popup = window.open(
            url, '_blank',
            String(`top=${t}, left=${l}, width=${w}, height=${h}`));

        if (popup && onPopupClosed) {
            const pollTimer = window.setInterval(() => {
                if (popup.closed !== false) {
                    window.clearInterval(pollTimer);
                    onPopupClosed();
                }
            }, 200);
        }

        return popup;
    },
    showError(props) {
        APP.store.dispatch(showErrorNotification(props));
    },
    showWarning(props) {
        APP.store.dispatch(showWarningNotification(props));
    },
    participantNotification( // eslint-disable-line max-params
            displayName,
            displayNameKey,
            cls,
            messageKey,
            messageArguments,
            timeout = NOTIFICATION_TIMEOUT) {
        APP.store.dispatch(showNotification({
            descriptionArguments: messageArguments,
            descriptionKey: messageKey,
            titleKey: displayNameKey,
            title: displayName
        },
        timeout));
    },
    alertMeesgae(titleKey, props) {
        APP.store.dispatch(alertMeesgae(titleKey, props));
    }
};

export default messageHandler;
