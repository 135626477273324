/**
 * 캔버스 현재 사용하는 툴 수정 
 * 
 * {
 *      type: SET_CANVAS_TOOL,
 *      tool: String
 * }
 */
export const SET_CANVAS_TOOL = 'SET_CANVAS_TOOL';

/**
 * 캔버스 현재 사용하는 옵션 수정 
 * 
 * {
 *      type: SET_CANVAS_OPTION,
 *      option: {
 *          thickness?: Number,
 *          scale?: Number,
 *          opacity?: Number
 *          isWidthFit?: Boolean|undefined,
 *      }
 * }
 */
export const SET_CANVAS_OPTION = 'SET_CANVAS_OPTION';

/**
 * 초기 캔버스 리스트 세팅
 * @param {*} data 
 * @returns 
 */
export const SET_CANVAS_DRAW = 'SET_CANVAS_DRAW';

/**
 * 초기값 설정(PDF 인덱스 리스트 ) 
 * 
 * @param {Array} list 
 * @returns 
 */
export const ADD_CANVAS_LIST = 'ADD_CANVAS_LIST';