import React from "react";
import { AbstractDialog } from "../../base/dialog";
import { GroupAlertStyled } from "./styles";
import { ClickButton } from "../../base/buttons";

export default function DeleteGroup({
    t, popItem, setPopup, alertMessage
}) {
    const handlerClose = () => setPopup(null);
    const onDelete = async () => {
        const { message, complete } = await APP.API.deleteGroup(popItem.uuid);
        if (!complete) {
            message && alertMessage(message);
        } else {
            handlerClose();
        }
    }

    return (
        <AbstractDialog name="deleteGroup" width={400} handlerClose={handlerClose}>
            <GroupAlertStyled>
                <h3 className="guid">
                    <span className="groupName">{ popItem.name }</span> 
                    {t("common.resDelete")}
                </h3>
                <p className="guid">{t("group.clearGuid")}</p>
                <div className="g_button">
                    <ClickButton size={16} text={t("common.previous")} onClick={handlerClose} />
                    <ClickButton size={16} text={t("common.delete")} onClick={onDelete} />
                </div>
            </GroupAlertStyled>
        </AbstractDialog>
    )
}