// @flow
import React from "react";
import { IconButton } from "../../base/buttons";
import { getConferenceTimestamp } from '../../base/conference/functions';
import { getLocalizedDurationFormatter } from '../../base/i18n';
import { connect } from '../../base/redux';
import { getGroupById } from "../../groups";

class ConferenceTimer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            timerValue: getLocalizedDurationFormatter(0)
        };
    }

    componentDidMount() {
        this._startTimer();
    }

    componentWillUnmount() {
        this._stopTimer();
    }

    render() {
        const { timerValue } = this.state;
        const { _startTimestamp } = this.props;

        if (!_startTimestamp) {
            return null;
        }

        return (
            <IconButton clsName="work_time" hover={false} mode="workTime" size={20} name={timerValue} />
        )
    }

    _setStateFromUTC(refValueUTC, currentValueUTC) {
        if (!refValueUTC || !currentValueUTC) {
            return;
        }

        if (currentValueUTC < refValueUTC) {
            return;
        }

        const timerMsValue = currentValueUTC - refValueUTC;

        const localizedTime = getLocalizedDurationFormatter(timerMsValue);

        this.setState({
            timerValue: localizedTime
        });
    }

    _startTimer() {
        if (!this._interval) {
            this._setStateFromUTC(this.props._startTimestamp, (new Date()).getTime());

            this._interval = setInterval(() => {
                this._setStateFromUTC(this.props._startTimestamp, (new Date()).getTime());
            }, 1000);
        }
    }

    _stopTimer() {
        if (this._interval) {
            clearInterval(this._interval);
        }

        this.setState({
            timerValue: getLocalizedDurationFormatter(0)
        });
    }
}

function _mapStateToProps(state, ownProps) {
    let time = getConferenceTimestamp(state);
    if (ownProps.currentGroupId) {
        const group = getGroupById(state, ownProps.currentGroupId);
        time = group && group.status === "opened" ? new Date(group.open_time).getTime() : "closed";
    }

    return {
        _startTimestamp: time
    };
}

export default connect(_mapStateToProps)(ConferenceTimer);