import styled from "styled-components";
export const GroupStyled = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    margin: 0;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;

    .article { 
        display: inline-flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        box-sizing: border-box;
        margin: 0;
        gap: 12px;
        padding: 0 16px;
        overflow: auto;

        &_left {
            flex-basis: 30%;
            border-right: 2px solid;
            max-width: 240px;
            min-width: 150px;
        }

        &_right {
            flex: 1;
            box-sizing: border-box;

            .section_content {
                flex-direction: column;
                overflow-y: auto;
                overflow-x: hidden;
                height: 100%;
                flex: 1;              
            }
        }

        .section {
            display: flex;
            width: 100%;
            box-sizing: border-box;

            & > * {
                margin: 0;
            } 
        }

        .section_button {
            flex-direction: column;
            padding-bottom: 12px!important;
            border-bottom: 2px solid;
            button {
                margin: 4px!important;
            }
        }  
        
        .section_list {
            flex-direction: column;
            height: 100%;
            min-height: 120px;
            overflow-x: hidden;
            overflow-y: auto;
        }

        .section_add {
            padding: 8px 0 0 8px;
            input {
                width: inherit;
                border-radius: 6px;
                border: none;
                padding: 8px 12px;
            }

            padding-bottom: 12px!important;
        } 
    }
`;

export const GroupChoiceStyled = styled.div`
    border: 1px solid;
    padding: 12px;
    margin: 0;
    border-radius: 3px;
    overflow-y: hidden;
    display: flex;
    overflow-x: auto;
    gap: 4px;
    width: calc(100% - 32px);
    flex: none;

    .icon {
        padding: 8px 12px;
        background: #f2f2f2;
        color: #303030;
        gap: 12px;
        border-radius: 8px;
        margin: 0;
    }
`;

export const GroupRouterStyled = styled.div`
    font-size: 15px;
    .icon {
        max-width: 60%;
        justify-content: flex-start;
        margin: 0 8px!important;

        & > * {margin: 0;}
    }

    svg { min-width: 16px;  margin-right: 8px!important; }
    span {
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }  
`;

export const SearchButtonStyled = styled.div`
    margin: 0 4px 8px auto!important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    input:focus{
        outline: none;
    }

    & > * { margin: 0; }
    span { margin: 4px; }
    
    input {
        padding: 4px 12px 4px 8px;
        border-radius: 7px;
        border: none;
        color: #000;
        max-width: calc(100% - 52px);
    }

    .icon {
        padding: 4px 8px;
        background: #f2f2f2;
        color: #303030;
        border-radius: 4px;
    }
`;

export const FocusButtonStyled = styled.div`
    display: flex;
    gap: 8px;
    &:not(&.button) {
        margin: 10px 10px 10px auto!important;
    }

    &.button {
        margin: 10px auto;
    }

    .user_btn {
        display: inline-flex;
        gap: 12px;
        box-sizing: border-box;
    }
    .user_btn:not(.noback) {
        padding: 7px 12px;
        background: #f2f2f2;
        border-radius: 8px;
        margin: 0 4px;
        color: #3c3c3c;
    }
`;

export const GroupListStyled = styled.div`
    cursor: pointer;
    display: inline-flex;
    width: 100%;

    .name { 
        width: 80%;
        margin: 0 4px;
        overflow: hidden;
        padding: 4px 8px;
        background: #d8e6fb;
        border-radius: 7px;
        color: #000;
        float: left;

        .overText {
            width: 100%;
            margin: 0;
            float: left;
        }

        .count {
            float: right;
        }
    }

    .icon {
        margin: 0 4px;
    }
`;

export const GroupTabelStyled = styled.div`
    ${props => props.openPop && `
        overflow: visible!important;
        .infinite-scroll-component, .group_scroll {
            overflow: visible!important;
        }`}
    position: relative;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    
    & > * {
        margin: 0;
    }
    
    h2:not(.count) {
        display: flex;
        max-width: 70%;
        color: #fff;
        .overText {max-width: 100%;}
        p { margin: 0; }
        &::before {
            content: "[";
            margin: 0 4px;
        }
        &::after {
            content: "]";
            margin: 0 4px;
        }
    }

    h2 { margin: 0; }

    .more { 
        cursor: pointer;
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 15px;
        line-height: 15px; 

        & > * {margin: 0;}
        span {margin: 4px;}
    }

    
    .group_table {
        margin: 8px 0;
        height: calc(100% - 35px)!important;

        .no_group {
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff;
            width: 100%;
            color: #000;
        }
    
        .group_scroll {
            height: calc(100% - 35px)!important;
            width: 100%;
            overflow: auto;
        }
    
        .group_tr, .group_th, .group_td {
            display: flex;
            width: 100%;
            position: relative;
            word-break: keep-all;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    
        .group_th, .group_td {
            box-sizing: border-box;
            height: 35px;
            padding: 0 4px; 
    
            .over {
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                
    
                & > * {
                    margin: 0;
                }
    
                .overText {
                    width: 90%;
                }
            }
        }        

        .group_th {
            background-color: #000;
            color: #fff;
        }

        .group_td {
            background-color: #fff;
            color: #000;
            border: 1px solid;

            cursor: ${props => props.hover ? "pointer" : "auto"};
        }

        select {
            border: none;
            position: absolute;
            width: 100%;
            height: 100%;
            overflow:hidden; 
            white-space:nowrap; 
            text-overflow:ellipsis;
            top: 0;
            left: 0;
            text-align: center;
        }
    }
`;

export const GroupAlertStyled = styled.div`
    text-align: center;
    .guid {
        margin-bottom: 12px;

        .groupName {
            &::before {
                content: "[";
                margin: 0 4px;
            }
            &::after {
                content: "]";
                margin: 0 4px;
            }
        }
    }

    input {
        width: 100%;
        padding: 8px 12px;
        box-sizing: border-box;
        border: none;
        border-radius: 4px;
    }

    .random_group_table {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 13px;
        margin: 32px 0px;

        .tr {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;

            .th {
                flex-basis: 30%;
                margin: 0;
            }

            .td {
                flex: 1;
                input {
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        cusor: pointer;
                        margin: 0;
                        width: 30px;
                        height: 15px;
                    }
                }
                
            }
        }
    }

    .g_button {
        display: flex;
        gap: 8px;
        justify-content: center;
        margin: 24px 0 0 0;

        & > * {
            margin: 0;
        }
    }

`;