import { JitsiOptions } from '../config/jitsiConfig';
import { createRnnoiseProcessor } from '../stream-effects/rnnoise';

// conference join 할때 option get 
export function getConferenceOptions() {

    let options = JitsiOptions.conferenceOptions;

    options.createVADProcessor = createRnnoiseProcessor;
    
    console.log({ options })
    return options;
}
