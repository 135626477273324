import { isTrackStreamingStatusInactive, isTrackStreamingStatusInterrupted } from '../../connection-indicator';
import { showNotification } from '../../notifications';
import { getCurrentConference } from '../conference';
import { ReducerRegistry, StateListenerRegistry } from '../redux';

import {
    TRACK_ADDED,
    TRACK_CREATE_CANCELED,
    TRACK_CREATE_ERROR,
    TRACK_NO_DATA_FROM_SOURCE, TRACK_REMOVED,
    TRACK_UPDATED, TRACK_WILL_CREATE
} from './actionTypes';
import { disposeAndRemoveTracks } from './actions';
import { getLocalTrack, getLocalTracks, getLocalVideoTrack, getTrackState } from './functions';

function track(state, action) {
    
    switch (action.type) {
        case TRACK_UPDATED: {
            const t = action.track;

            if (state.track === t.track) {
                for (const p in t) {
                    if (state[p] !== t[p]) {
                        return {
                            ...state,
                            ...t
                        };
                    }
                }
            }
            break;
        }

        // case TRACK_PARTICIPANT_UPDATE:
        //     const t = action.track;
        //     console.log(t, state)
        //     if (t.trackId === state.trackId) {
        //         return {
        //             ...state,
        //             participantId: t.user_id
        //         }
        //     }
        //     break;

        case TRACK_NO_DATA_FROM_SOURCE: {
            const t = action.track;

            if (state.track === t.track) {
                const isReceivingData = t.track.isReceivingData();

                if (state.isReceivingData !== isReceivingData) {
                    return {
                        ...state,
                        isReceivingData
                    };
                }
            }
            break;
        }

    }

    return state;
}

ReducerRegistry.register('features/base/tracks', (state = [], action) => {
    switch (action.type) {
        case TRACK_NO_DATA_FROM_SOURCE:
        case TRACK_UPDATED:
        // case TRACK_PARTICIPANT_UPDATE: 
            return state.map(t => track(t, action));

        case TRACK_ADDED: {
            let withoutTrackStub = state;

            if (action.track.local) {
                withoutTrackStub
                    = state.filter(
                        t => !t.local || t.mediaType !== action.track.mediaType);
            }
            
            return [ ...withoutTrackStub, action.track ];
        }

        case TRACK_CREATE_CANCELED:
        case TRACK_CREATE_ERROR: {
            return state.filter(t => !t.local || t.mediaType !== action.trackType)
        }

        case TRACK_REMOVED:
            return state.filter(t => t.track !== action.track);

        case TRACK_WILL_CREATE:
            return [ ...state, action.track ];

        default:
            return state;
        }
});

StateListenerRegistry.register(
    state => getCurrentConference(state), 
    (conferenceJoin, { dispatch,getState }) => {
        if (!conferenceJoin) {
            
            const tracks = getTrackState(getState());
            const localTrack = getLocalTracks(tracks);

            dispatch(disposeAndRemoveTracks(localTrack.map(t => t.track)));
        }
    }
);