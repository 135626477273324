import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AbstractDialog } from "../../../base/dialog";
import { updateSettings } from "../../../base/settings";
import HandlerSetting from "./HandlerSetting";
import MuteSetting from "./MuteSetting";
import { DeviceStyled, SettingStyled } from "./styles";

const DEVICE_SET = 'deviceSet';
const HANDLER_SET = 'handlerSet';

export default function SettingPopup() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [ type, setType ] = useState(DEVICE_SET);
    const { initial_camera, initial_mic, hands_up, hands_up_time } = useSelector(state => state["features/base/settings"]);

    const renderTap = () => {
        return (
            <div className="tap">
                {[DEVICE_SET, HANDLER_SET].map(item => {
                    const isOn = item === type ? 'on' : 'off';
                    const onClick = () => setType(item);

                    return <div key={item} className={`tap_item ${isOn}`} onClick={onClick}>{ t(`options.${item}`) }</div>
                })}
            </div>
        )
    }

    const handlerUpdatePolicy = (option) => {
        const policy = { 
            initial_camera, initial_mic, hands_up, hands_up_time, ...option 
        };
        
        dispatch(updateSettings(policy));
        APP.mateManagement.updatePolicy(policy)
    }

    const getRenderContent = () => {
        switch (type) {
            case DEVICE_SET:
                return <MuteSetting 
                    initial_camera={initial_camera === "on" ? true : false} 
                    initial_mic={initial_mic === "on" ? true : false} 
                    handlerUpdatePolicy={handlerUpdatePolicy} 
                />

            case HANDLER_SET:
                return <HandlerSetting 
                    hands_up={hands_up}
                    hands_up_time={hands_up_time}
                    handlerUpdatePolicy={handlerUpdatePolicy} 
                />
        }
    }

    return (
        <AbstractDialog t={t} name='setFullPermission' width={540}>
            <SettingStyled>
                <h3 className="titleGuid">* { t("options.equalSetGuid") } *</h3>
                <div className="setting">
                    { renderTap() }
                    <DeviceStyled className="setting_content">
                        { getRenderContent() }
                    </DeviceStyled>                    
                </div>
            </SettingStyled>
        </AbstractDialog>
    )
}

// class DevicePermission extends AbstractPopup {
//     constructor(props) {
//         super(props);
//     }

//     componentDidMount() {
//         this._setModalID(DEVICE_PERMISSION_MODAL_ID);
//     }

    
    

//     _renderDeviceSetting() {
//         const {t, initial_mic, initial_camera} = this.props;
//         return (
//             <DeviceStyled >
//                 <div className="guid">
//                     전체 권한 설정은 관리자, 강사, 매니저, 발표자 권한의 참석자는 제외된 상태에서 모든 참석자의 마이크 & 카메라를 On/Off 할 수 있는 기능 입니다.
//                     <br/> 단, 설정 된 이후 입장하는 모든 참석자들도 동일하게 적용됩니다.
//                 </div>
//                 <div className="device_wrapper">
//                     <div className="item">
//                         <IconButton clsName={`button_${initial_mic ? "on" : "off"}`}
//                             mode="mic_on" size={24} name={t("options.onMic")} onClick={() => this.audioMuted(false)} />

//                         <IconButton clsName={`button_${!initial_mic ? "on" : "off"}`}
//                         mode="mic_off" size={24} name={t("options.offMic")} onClick={() => this.audioMuted(true)} />
//                     </div>
//                     <div className="item">
//                         <IconButton clsName={`button_${initial_camera ? "on" : "off"}`}
//                         mode="camera_on" size={24} name={t("options.onVideo")} onClick={() => this.videoMuted(false)}/>

//                         <IconButton clsName={`button_${!initial_camera ? "on" : "off"}`}
//                         mode="camera_off" size={24} name={t("options.offVideo")} onClick={() => this.videoMuted(true)}/>
//                     </div>
//                 </div>
//             </DeviceStyled>
//         )
//     }

//     const renderBody = () => {
//         return (
//             <div>
//                 { this._renderDeviceSetting() }
//             </div>
//         )
//     }

//     render() {
//         const content = 

//         return (
//             <div className="option_pop center" style={{width: "450px"}}>
//                 { this._renderPopupHeader(this.props.t, 'options.setFullPermission') }
//                 { this._renderPopupBody(content) }
//             </div>
//         )
//     }
// }

// function _mapStateToProps(state) {
//     const {  initial_camera, initial_mic, hands_up } = state["features/base/settings"];

//     return {
//         initial_camera,
//         initial_mic,
//         hands_up
//     }
// }

// export default translate(connect(_mapStateToProps)(DevicePermission));
