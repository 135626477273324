import React from "react";
import { useSelector } from "react-redux";
import { IconButton } from "../../../base/buttons";
import { INVITE_GUEST_MODAL_ID } from "../../constants";


function InviteMenuButton({
    onClickInvite
}) {
    const isOpen = useSelector(state => state["features/base/modal"].name === INVITE_GUEST_MODAL_ID ? true : false);
    const onClick = () => APP.UI.openModal(INVITE_GUEST_MODAL_ID);

    return (
        <IconButton clsName={`button button_${isOpen ? 'on': 'off'}`} mode="inivite" size={20} 
            onClick={onClickInvite ? onClickInvite : onClick} />
    );
}

export default InviteMenuButton;