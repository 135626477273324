import React, { useState } from "react";
import GroupTable from "./GroupTable";
import { PARTICIPANT_STATUS, getHostMembers, getMemberList } from "../../member";
import { useSelector } from "react-redux";
import { participantGroup } from "../constants";
import GroupSearchBar from "./GroupSearchBar";

function GroupMemberList({
    alertMessage, tableConfig
}) {
    const members = useSelector(state => getMemberList(state, { 
        excludeIds: [...getHostMembers(state)] }, false, true, true)
    );
    const [ filter, setFilter ] = useState();

    return (
        <GroupTable tableConfig={tableConfig ? tableConfig : participantGroup} 
            contentList={members} filter={filter}
            alertMessage={alertMessage}>
            <GroupSearchBar setFilter={setFilter} />
        </GroupTable>
    )
}

export default React.memo(GroupMemberList);