import React, { useCallback } from "react";
import { MdPeople } from "react-icons/md";
import { connect, useDispatch, useSelector } from "react-redux";
import { FooterIconButton, IconButton } from "../../base/buttons";
import { translate } from "../../base/i18n";
import { toggleParticipantRight } from "../action";

function SwitchParticipantRight({ t, isOption, clsName }) {
    if (!APP.function.participant) return null;

    const dispatch = useDispatch();
    const openParticipant = useSelector(state => state["features/participant-right"].isOpen);

    const onClick = () => {
        dispatch(toggleParticipantRight(!openParticipant));
        APP.UI.closeModal();
    };
    
    return (
        <>  
            { isOption ? 
                <IconButton clsName={clsName} mode={"participant"} 
                    name={`${ t(`options.${openParticipant}Participant`) } `} 
                    size={20}
                    onClick={onClick} />
                :
                <FooterIconButton mode="participant" 
                    name={ t("common.participant") } 
                    checked={openParticipant}
                    onClick={onClick} 
                />
            }             
        </>
    )
}


export default translate(SwitchParticipantRight);