import React from "react";
import { formValidation } from "../function";

const btnText = {
    true: "account.sendComplete",
    false: "account.sendMail"
};

export default function SendMailButton({
    t, email, isSendMailComplete,
    setErrorData, setUuidData, setIsSendMailComplete, setExpirationTime
}) {
    const onClick = async () => {
        if (!isSendMailComplete) {
            const errorMsg = formValidation(t, "email", email, null);
            if (errorMsg !== "") {
                setErrorData({ "email": errorMsg });
                return;
            }

            const { complete, message } = await APP.API.sendToEmail(email);  
            setIsSendMailComplete( complete );
            if (complete) {
                setErrorData({ "email": "" });
                const { uuid, check_duration, creation_time } = message;

                setUuidData(uuid);
                const expirationUnixTime = Math.floor(new Date().getTime() / 1000) + (check_duration / 1000);
                setExpirationTime(expirationUnixTime);
                return;
            }
            setErrorData({ "email": t(message) });
            return;            
        }
    };

    return (
        <>
            <span className="btn btn_small" onClick={onClick}>
                { t(btnText[isSendMailComplete]) }
            </span>
        </>
    );
}