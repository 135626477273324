import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getGroupIdsArray } from "../functions";
import GroupItem from "./GroupItem";
import StatusGroupButton from "./StatusGroupButton";

export default React.memo(function GroupList({ 
    t, detail, alertMessage, 
    setPopup, setPopItem, setFocusItem
}) {
    const list = useSelector(state => getGroupIdsArray(state), shallowEqual);

    return (
        <div className={`section section_${detail ? 'content' : 'list'}`}>
            { list && list.length > 0 ? 
                <div style={{ height: "100%", overflow: "auto", marginBottom: "20px" }}>
                    { list.map(uuid => <GroupItem key={uuid} t={t} uuid={uuid} detail={detail}
                        setPopup={setPopup} setPopItem={setPopItem} setFocusItem={setFocusItem}
                    /> )}
                </div>
                : <div style={{ margin: "12px 0", height: "100%", textAlign: "center" }}> 
                    {t("group.makeGroup")} 
                </div> 
            }
            { !detail && list.length >= 1 && 
                <StatusGroupButton t={t} alertMessage={alertMessage} type={"open"} /> }
        </div>
    )
});