import React from "react";
import styled from "styled-components";
import { videoLayoutMode } from "../../../video-layout";
import { CanvasContainer } from "../../canvas";
import { connect } from "../../redux";
import { getFocusVodItem } from "../function";

const VodStyled = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;

    video {
        flex: 1;
        max-width: 100%;
    }
`;

let prevChannelID;
function VodPlayer({
    item, expand, sidebar, isLeft, setIsLeft, shareDocumentPermission
}) {   
    return (            
        <CanvasContainer isVod={true} isWhiteBoard={true} mode={videoLayoutMode.vod}
            expand={expand} isLeft={isLeft} setIsLeft={setIsLeft}
        >
            { item && <iframe src={`${item.url}`} />}
        </CanvasContainer>
    )
}

function _mapStateToProps(state) {
    const item = getFocusVodItem(state);
    
    return {
        item
    };
}

export default connect(_mapStateToProps)(VodPlayer);