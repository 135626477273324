import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CanvasContainer, CanvasLayout, CanvasTool } from '../../base/canvas';
import { isCheckPermission } from '../../base/localuser/functions';
import AddDocumentButton from './AddDocumentButton';
import DocumentLeft from './DocumentLeft';
import DocumentTool from './DocumentTool';
import ShareDocumentList from './ShareDocumentList';
import { videoLayoutMode } from '../../video-layout';
import { PERMISSION } from '../../member';
import "./../../../assets/css/share.scss";

export default function ShareDocumentLayout({
    mode, mobile, expand, isLeft, setIsLeft 
}) {
    const { t } = useTranslation();

    const [ isWhiteBoard, setIsWhiteBoard ] = useState(true);
    const sharePermission = useSelector(state => isCheckPermission(state, PERMISSION.SHARE));
    
    useEffect(() => {
        setIsWhiteBoard(mode === videoLayoutMode.document ? false : true);
    }, [mode]);
    
    return (
        <div className={`addition_layout share_document_layout left_${expand}`}>
            <DocumentLeft mobile={mobile} expand={expand} isLeft={isLeft} 
                name={isWhiteBoard ? t("document.whiteBoard") : t("document.shareDocument")}>
                { sharePermission && (
                    <AddDocumentButton t={t} isWhiteBoard={isWhiteBoard} />
                )}

                <ShareDocumentList sharePermission={sharePermission} mode={mode} isWhiteBoard={isWhiteBoard} />
            </DocumentLeft>
                
            <CanvasContainer isWhiteBoard={isWhiteBoard} mode={mode}
                isLeft={isLeft} expand={expand} setIsLeft={setIsLeft}            
            />
        </div>
    );
}