const defaultState = message => {
    return ({
        complete: false,
        message: message && message.format ? message.format : "서버 오류입니다. 관리자에게 문의하세요."
    });    
};

export default class UpdateAPIStatus {
    // 메일 전송 
    sendToEmail({ status, data }) {
        let state = defaultState(data?.message);        
        if (200 <= status && status <= 202) {
            state = { complete: true, message: data }
        } 
        
        if (state.complete === false) {
            switch (status) {
                case 500:
                case 503:
                    state.message = "api.errorNotCorretEmail";
                    break;
            }
        }
        return state;
    }

    // 유효 코드 확인
    checkCertificationCode({ status, data }) {
        let state = defaultState(data?.message);       
        if (status === 200) {
            state = { complete: true, message: data }
        }
        
        if (state.complete === false) {
            switch (status) {
                case 400:
                    state.message = "api.errorCheckCertCode";
                    break;
            }
        }
        return state;
    }

    // 회원 가입
    tempSignup({ status, data }) {
        let state = defaultState(data?.message);        
        if (status === 201) {
            state = { complete: true, message: data }
        }

        return state;
    }
    signup({ status, data }) {
        let state = defaultState(data?.message);        
        if (status === 200) {
            state = { complete: true, message: data }
        }
        if (state.complete === false) {
            switch (status) {
                case 409:
                case 500:
                    state.message = "api.errorExistId";
                    break;
            }
        }

        return state;
    }

    // 로그인
    login({ status, data }) {
        let state = {
            key: "all",
            ...defaultState(data?.message)
        };
    
        if (status === 200) {
            state = {
                key: "all",
                complete: true,
                message: data
            };
        }
    
        if (state.complete === false) {
            switch (status) {
                case 404:
                case 400:
                    state = {
                        key: "password",
                        complete: false,
                        message: "api.errorCheckIdAndPassword"
                    };
                    break;
                case 403:
                    state = {
                        key: "all",
                        complete: false,
                        message: "api.errorCertExpired"
                    };
                    break;
            }
        } 
        return state;
    }

    getUser({ status, data }) {
        let state = defaultState(data?.message);        

        if (status === 200) {
            state = { complete: true, message: data }
        }

        if (state.complete === false) {
            switch (status) {
                case 400:
                    state.message = "api.errorCertTokenExpired";
                    break;
            } 
        }

        return state;
    }

    invitationsRoom({ status, data }) {
        let state = defaultState(data?.message);        
        
        if (status === 200) {
            state = { complete: true, message: data }
        }

        return state;
    }

    // 회의 객체 생성
    createObjectRoom({ status, data }) {
        let state = defaultState(data?.message);        
        
        if (status === 201) {
            state = { complete: true, message: data }
        }

        if (state.complete === false) {
            switch (status) {
                case 403:
                    state.message = "api.errorNoPermissionCreateRoom";
                    break;
            } 
        }

        return state;
    }
    // 회의 생성
    createRoom({ status, data }) {    
        let state = defaultState(data?.message);        
        
        if (status === 200) {
            state = { complete: true, message: data }
        }

        if (state.complete === false) {
            switch (status) {
                case 403:
                    state.message = "api.errorNoPermissionCreateRoom";
                    break;
            } 
        }

        return state;
    }
    reservationRoom({ status, data }) {    
        let state = defaultState(data?.message);        
        
        if (status === 200) {
            state = { complete: true, message: data }
        }

        if (state.complete === false) {
            switch (status) {
                case 400:
                    state.message = "api.errorTime";
                    break;
                case 500:
                    state.message = "api.errorLongRoomName";
                    break; 
            } 
        }

        return state;
    }
    // 회의 검색 
    searchRoom({ status, data }) {
        let state = defaultState(data?.message);        
        
        if (status === 200) {            
            state = { complete: true, message: data }
        }

        if (state.complete === false) {
            switch (status) {
                case 403:
                    state.message = "api.errorNoPermission";
                    break;
            } 
        }

        return state;
    }

    deleteRoom({ status, data }) {
        let state = defaultState(data?.message);        
        
        if (status === 200) {       
            state.complete = true;     
            // state = { complete: true, message: data }
        }

        return state;
    }

    setPropertyRoom({ status, data }) {
        let state = defaultState(data?.message);        

        if (status === 200) {       
            state.complete = true;     
        } 

        if (state.complete === false) {
            switch (status) {
                case 400:
                    state.message = "api.errorTime";
                    break;
                case 500:
                    state.message = "api.errorLongRoomName";
                    break;
            } 
        }

        return state;
    }

    // 문서 변환 
    convertShareDocument({ status, data }) {
        let state = defaultState(data?.message);        
        
        if (status === 202) {
            state = { complete: true, message: data }
        }

        if (state.complete === false) {
            switch (status) {
                case 503:
                    state.message = "api.errorNoApplyFile"; //"지원하지 않는 파일 입니다.";
                    break;
                case 413:
                    state.message = "api.errorBigVolume"; //"용량이 너무 큽니다.";
                    break;
            } 
        }

        return state;
    }

    // 출석 체크 
    createAttendancesCheck({ status, data }) {
        let state = defaultState(data?.message);        
        
        if (status === 201) {
            state = { complete: true, message: data }
        }

        if (state.complete === false) {
            switch (status) {
                case 403:
                    state.message = "api.errorNoPermission"; //"지원하지 않는 파일 입니다.";
                    break;
            } 
        }

        return state;
    }
    // 출석 체크 기록 가져오기
    getAttendancesCheck({ status, data }) {
        let state = defaultState(data?.message);        
        
        if (status === 200) {
            state = { complete: true, message: data }
        }

        return state;
    }

    // 출석 체크 확인 
    checkedAttendances({ status, data }) {
        let state = defaultState(data?.message);        
        
        if (status === 200) {
            state = { complete: true, message: data }
        }

        return state;
    }

    // 설문 조사 
    getSurveyInfo({ status, data }) {
        let state = defaultState(data?.message);        
        
        if (status === 200) {
            state = { complete: true, message: data }
        }

        return state;
    }

    getAllSurvey({ status, data }) {
        let state = defaultState(data?.message);        
        if (status === 200) {
            state = { complete: true, message: data.result }
        }

        return state;
    }

    requireEmailVerification({ status, data }) {
        let state = defaultState(data?.message);        
        if (status === 200) {
            state = { complete: true, message: data.result }
        }

        return state;
    }

    // 중복 로그인
    checkOccupiedUser({ status, data }) {
        let state = defaultState(data?.message);

        if (status === 200) {
            if (data.found_count > 0 ) {
                state = { complete: 'duplicate', message: data.items[0]};
            } else state = { complete: true, message: data.result };
        }

        return state;
    }

    // 중복 로그인 강퇴 
    kickOccupiedUser({ status, data }) {
        let state = defaultState(data?.message);

        if (status === 200) {
            state = { complete: true, message: data.result };
        }

        return state;
    }

    // ㅊㅐ널 조회 
    getMediaChannel({ status, data }) {
        let state = defaultState(data?.message);

        if (status === 200) {
            state = { complete: true, message: data.result };
        }

        return state;
    }


    // 그룹 조회 
    getGroupList({ status, data }) {
        let state = defaultState(data?.message);

        if (status === 200) {
            state = { complete: true, message: data.result.items[0].groups };
        }

        return state;
    }
    // 그룹 저장 (return uuid)
    createGroup({ status, data }) {
        let state = defaultState(data?.message);

        if (status === 201) {
            state = { complete: true, message: data.result.id };
        }

        return state;
    }

    // 그룹 삭제 (return uuid)
    deleteGroup({ status, data }) {
        let state = defaultState(data?.message);

        if (status === 200) {
            state = { complete: true, message: "성공" };
        }

        return state;
    }

    // 그룹 할당
    assignGroup({ status, data }) {
        let state = defaultState(data?.message);

        if (status === 200) {
            state = { complete: true, message: "성공" };
        }

        if (state.complete === false) {
            switch (status) {
                case 409:
                    state.message = "api.errorDupHost";
                    break;
            } 
        }



        return state;
    }

    // 참가자 전체 목록 
    getAllParticipant({ status, data }) {
        let state = defaultState(data?.message);

        if (status === 200) {        
            state = { complete: true, message: data.result.items };
        }

        return state;
    }

    // 무작위 그룹 
    randomizeGroup({ status, data }) {
        let state = defaultState(data.message);

        if (status === 200) {        
            state = { complete: true, message: "api.successAssgin" };
        }

        return state;
    }

    // 녹화 객체 생성
    addRecordResource({ status, data }) {
        let state = defaultState(data.message);

        if (status === 201) {        
            state = { complete: true, message: data.result };
        }

        return state;
    }
}
