/* @flow */
import { getCurrentPage } from '../app';
import { setAppPage } from '../app/action';
import { PAGE_TYPE } from '../app/constants';
import { ReducerRegistry, set, StateListenerRegistry } from '../redux';

import {
    CONNECTION_DISCONNECTED,
    CONNECTION_ESTABLISHED,
    CONNECTION_FAILED,
    SET_CONNECTION,
    SET_IS_CONNECTED
} from './actionTypes';

export const DEFAULT_STATE = {
    connect: {},
    isConnected: false
}

ReducerRegistry.register('features/base/connection', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case CONNECTION_DISCONNECTED:
            return _connectionDisconnected(state, action.serverType);

        case CONNECTION_ESTABLISHED:
            return _connectionEstablished(state, action);

        case CONNECTION_FAILED:
            return _connectionFailed(state, action);
        
        case SET_IS_CONNECTED:
            return _setIsConnected(state, action);

        case SET_CONNECTION:
            return _setRoom(state, action);
    }

    return state;
});


// connection 성공
function _connectionEstablished(state, { connection, timeEstablished, serverType }) {    
    return {
        ...state,
        connect: {
            ...state.connect,
            [serverType]: {
                isConnected: true,
                connecting: undefined,
                connection,
                error: undefined,
                timeEstablished
            }
        }
    };
}

// connection 실패
function _connectionFailed(state, { error, serverType }) {
    return {
        ...state,
        connect: {},
        isConnected: false
    }
}

// connection disconnect
function _connectionDisconnected(state, serverType) {
    return {
        ...state, 
        connect: {},
        isConnected: false
    };
}

function _setIsConnected(state, { connected }) {
    return set(state, 'isConnected', connected);
}